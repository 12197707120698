import React, { Component } from 'react'
import { InputText } from 'primereact/inputtext';
import { ProgressBar } from 'primereact/progressbar';
import { Message } from 'primereact/message';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Link } from 'react-router-dom';
import UploadService from "../../../middleware/download-upload-files.service"
import MessageDialog from '../../common/MessageDialog';
import { FIRMWARE, rolesValidator, CREATE } from '../../../utils/RolesValidator';
import msg from '../../../texts/msg';
import '../../common/css/common.css';


export class CreateFirmware extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedFiles: undefined,
            currentFile: undefined,
            progress: 0,
            message: "",
            fileInfos: [],
            name: '',
            description: '',
            isNotFile: false,
            isNotype: false,
            isDone: false,
            isPatternError: false,
            borneType: '',
            isNameValid: false,
            sessionExpired: false
        };
        this.isFirmwareNameExist = this.isFirmwareNameExist.bind(this);
    }

    componentDidMount() {
        this.props.loadFirmwares(0, 1000000);
        this.props.setDataInfoBar([{}]);
    }

    isFirmwareNameExist(event) {
        let name = event.files[0].name ? event.files[0].name.split('.').slice(0, -1).join('.') : "";
        let firmwares = this.props.firmwares && this.props.firmwares.firmwares ? this.props.firmwares.firmwares : null;
        if (firmwares) {
            let i = 0;
            for (i = 0; i < firmwares.length; i++) {
                if (firmwares[i].name === name) {
                    this.setState({ nameExiste: true });
                    break;
                }
            }
        }
    }

    onFileChange = event => {
        this.setState({ selectedFile: event.files[0] });
        this.setState({ name: event.files[0].name });
        this.setState({ progress: 0 });
        this.isFirmwareNameExist(event);

        let name = event.files[0].name ? event.files[0].name.toUpperCase().split('_') : "";

        if (name.length > 1 && (name.includes('TL16') || name.includes('TL17') || name.includes('TL18') || name.includes('TK2'))) {
            var yourRegex = /^[0-9]+$/g
            var atLeastOneMatches = name[1].split('.').some(e => yourRegex.test(e));
            if (atLeastOneMatches) {
                this.setState({ isNameValid: false });
            }

        } else {
            this.setState({ isNameValid: true });
            this.setState({ name: event.files[0].name });
        }

    };

    onFileUpload = () => {
        let valid = true;
        let currentFile = this.state.selectedFile;
        this.setState({
            progress: 0,
            currentFile: currentFile,
        });

        if (!this.state.selectedFile) {
            this.setState({ isNotFile: true });
            valid = false;
        } else {
            this.setState({ isNotFile: false });
        }

        if (valid)
            UploadService.upload(currentFile, this.state.description, null, null, (event) => {
                this.setState({ progress: Math.round((100 * event.loaded) / event.total), });
            }).then((response) => {
                this.setState({ currentFile: undefined, description: '' });
                this.setState({ isDone: true });
                this.setState({ progress: 0, currentFile: null });
                this.props.history.push('/firmwares');
            }).catch((error) => {
                if (error.response && (error.response.status === "401" || error.response.status === 401)) {
                    this.setState({ sessionExpired: true });
                } else {
                    this.setState({ isError: true, isPatternError: true, message: error.response ? error.response.data.message : "Unknown error" });
                    this.setState({ progress: 0, currentFile: null });
                }
            });
    };


    handleErrorChange(e) {
        this.setState({ isDone: false, isError: false, isNameValid: false, sessionExpired: false, selectedFile: null, message: null })
        this.setState({ currentFile: undefined });
    }

    handleErrorExpiredSessionChange(e) {
        this.setState({ isDone: false, isError: false, isNameValid: false, sessionExpired: false, selectedFile: null, message: null })
        this.setState({ currentFile: undefined });
        this.props.history.push('/');
        this.props.history.push('/firmwares');
    }

    componentDidUpdate(prevProps) {
        let prevError = prevProps.error && prevProps.error.timestamp;
        let lastError = this.props.error && this.props.error.timestamp;
        let error = (lastError && (!prevError || prevError < lastError));
        if (error) {
            let isError = this.props.error.message && this.props.error.message.startsWith('error.config.borne') ? true : false;
            this.setState({ isApiError: isError });
        }
    }

    render() {
        const {
            currentFile,
            progress,
        } = this.state;

        let authorized = rolesValidator.validate([FIRMWARE]);
        let cAuthorized = authorized.toString().includes(CREATE);        

        return cAuthorized && (
            <div className="commonClass roleClass">
                <div className="test p-col-12">
                    <h3 style={{ marginTop: '.5em' }}>{msg('firmware.fields.title')}</h3>
                </div>
                {currentFile && (<div className="test p-col-12">
                    <ProgressBar value={progress}></ProgressBar>
                </div>)}
                <div className="card card-w-title p-col-12">
                    <div className="p-grid form-group p-col-12">
                        <div className="p-grid form-group p-col-12">
                            {<div className="p-grid p-col-6">
                                <div className="p-grid p-col-6">
                                    <div className="p-col-12"> <label>{msg('firmware.fields.upload')}</label></div>
                                </div>
                                <div className="p-col-6">
                                    <span className="md-inputfield">
                                        <FileUpload mode="basic" name="rr" chooseLabel={msg('firmware.fields.upload.button.label')} onSelect={this.onFileChange} accept=".tar" maxFileSize={9000000000}
                                            emptyTemplate={<p className="p-m-0"></p>} />
                                    </span>
                                    {this.state.isNotFile && <Message severity="error" text={msg('config.input.file.required')} key="name" />}
                                </div>
                            </div>}

                            {<div className="p-grid p-col-6">
                                <div className="p-grid p-col-3">
                                    <div className="p-col-6"> <label>{msg('firmware.fields.description')}</label></div>
                                </div>
                                <div className="p-col-9">
                                    <span className="md-inputfield">
                                        <InputText className="inputClass" value={this.state.description} onChange={e => this.setState({ description: e.target.value })} placeholder="Description" type="text" size="60" />
                                    </span>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
                <br></br>
                <br />
                <div className="p-grid p-col-12">
                    <div className="p-grid p-col-9">
                    </div>

                    <div className="p-grid p-col-3">
                        <Button label={(msg('user.validate'))} title={(msg('user.validate'))} onClick={this.onFileUpload} className="button" disabled={!cAuthorized} />
                        <Link to="/firmwares">
                            <Button label={(msg('user.reset'))} className="button" />
                        </Link>
                    </div>
                </div>
                <br />
                <div className="alert alert-light" role="alert">

                    <MessageDialog className={'message-dialog error-message'} visible={this.state.nameExiste} title={msg('firmware.fields.upload.title.error')}
                        message={this.state.nameExiste && msg("error.config.borne.firmware.name.existe")}
                        onHide={e => this.setState({ nameExiste: false, selectedFile: null, currentFile: undefined })} onCancel={e => this.setState({ nameExiste: false, selectedFile: null, currentFile: undefined })} />

                    <MessageDialog className={'message-dialog error-message'} visible={this.state.isError} title={msg('firmware.fields.upload.title.error')}
                        message={this.state.message}
                        onHide={e => this.handleErrorChange(e)} onCancel={e => this.handleErrorChange(e)} />

                    <MessageDialog className={'message-dialog error-message'} visible={this.state.isDone} title={msg('firmware.fields.upload.title.done')}
                        message={this.state.isDone && msg("firmware.fields.upload.done")}
                        onHide={e => this.handleErrorChange(e)} onCancel={e => this.handleErrorChange(e)} />

                    <MessageDialog className={'message-dialog error-message'} visible={this.state.isNameValid} title={msg('firmware.fields.upload.title.error')}
                        message={msg('firmware.file.upload.format')}
                        onHide={e => this.handleErrorChange(e)}
                        onCancel={e => this.handleErrorChange(e)} />

                    <MessageDialog className={'message-dialog error-message'} visible={this.state.sessionExpired} title={msg('firmware.fields.upload.title.error')}
                        message={msg("login.unauthorized.internalauthenticationserviceexception")}
                        onHide={e => this.handleErrorExpiredSessionChange(e)} onCancel={e => this.handleErrorExpiredSessionChange(e)} />
                </div>

                <br />
            </div>);
    }

}

export default CreateFirmware;