import React, { Component } from 'react';
import { Dialog } from 'primereact/dialog';
import './css/ProgressSpinnerDialog.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import {progressSpinnerHandler} from '../../utils/ProgressSpinnerHandler'

class ProgressSpinnerDialog extends Component {
    constructor(props){
        super(props);
        this.state = {
            visible: false
        };
    }
    render() {
        return (
        <Dialog className={'progress-dialog'} visible={progressSpinnerHandler.isVisible()} modal={false}
            showHeader={false} closeOnEscape={false} onHide={()=>{ }} key={new Date().getTime()}>
            <ProgressSpinner />
        </Dialog>
        );
    }
}

export default ProgressSpinnerDialog;