/* Component pour afficher la liste des équipements et les transactions attachées a chaque équipement  */

import React, { Component } from 'react';
import ServiceInformationDialog from './ServiceInformationDialog.js';
import OpenAlarmsDialog from './OpenAlarmsDialog.js';
import BorneFilter from '../../common/BorneFilter';
import MessageDialog from '../../common/MessageDialog';
import TableData from '../../../utils/TableData.js';
import { rolesValidator, EQUIPMENTS } from '../../../utils/RolesValidator.js';
import { progressSpinnerHandler } from '../../../utils/ProgressSpinnerHandler'
import { userSessionParamsHandler, EQUIPMENTS_TABLE_ID } from '../../../utils/UserSessionParamsHandler.js';
import msg from '../../../texts/msg';
import '../../administration/Administration.css';
import '../../common/css/common.css';


const CODE_SERVER_IP = "127.0.0.1";
const BORNE_TL16 = "TL16";
const MAX_LENGTH = 20;
let sDate = new Date();
sDate.setDate(1);
sDate.setHours(0);
sDate.setMinutes(0);
sDate.setSeconds(0);
let eDate = new Date();
eDate.setHours(23);
eDate.setMinutes(59);
eDate.setSeconds(59);
let defaultActiviteFilter = {
    startDate: sDate,
    endDate: eDate,
    equipments: [],
    transactionTypes: ['P', 'S'],
    showAllAlarm: true,
    showOnlyOpenedAlarm: false,
    mandated: false,
    butonRadioChecked: 'all',
    commandsTypes: ['GET_LOGS', 'GET_KERNEL_LOGS', 'GET_STARTUP_REPORT', 'SPEED_TEST'],
    meanOfPaymentTypes: [],
    eventTypes: [],
    timestamp: new Date().getTime(),
    sortDirection: 'DESC'
};
export class Equipments extends Component {
    constructor(props) {
        super(props);
        let lFilter = Object.assign({}, defaultActiviteFilter);
        lFilter.startDate = null;
        let lBorneFilter = this.getRedirectBorneFilter();
        this.state = {
            first: 0,
            userExist: false,
            tieredItems: "test",
            display: false,
            closable: false,
            borneFilter: lBorneFilter,
            filter: lFilter,
            isServiceInformation: false,
            qrValue: "",
            isNeverTurned: false,
            sortDirection: "ASC",
            redirected: lBorneFilter.redirected
        }
        this.onSelection = this.onSelection.bind(this);
        this.loadEquipments = this.loadEquipments.bind(this);
        this.loadEquipmentsByLabel = this.loadEquipmentsByLabel.bind(this);
        this.loadEquipmentById = this.loadEquipmentById.bind(this);        
        this.applyBorneFilter = this.applyBorneFilter.bind(this);
        this.loadFilter = this.loadFilter.bind(this);
        this.loadFilterStations = this.loadFilterStations.bind(this);        
        this.sendQRCode = this.sendQRCode.bind(this);
        this.onSort = this.onSort.bind(this);
        this.loadSortedPropreties = this.loadSortedPropreties.bind(this);
        this.redirectToActivityJournal = this.redirectToActivityJournal.bind(this);
        this.showServiceInformation = this.showServiceInformation.bind(this);
        this.showActivityJournal = this.showActivityJournal.bind(this);
        this.showOpenAlarms = this.showOpenAlarms.bind(this);        
        this.onOpenAlarmsDialogHide = this.onOpenAlarmsDialogHide.bind(this);
        this.onQuickSearchChange = this.onQuickSearchChange.bind(this);
    }

    componentDidMount() {
        this.props.setDataInfoBar(this.dataInfobar(null));
        let lBorneFilter = this.getRedirectBorneFilter();
        if(lBorneFilter.station || lBorneFilter.alarmType) {
            this.setState({borneFilter: lBorneFilter});
        }
    }

    componentDidUpdate(prevProps) {
        if (!(prevProps.loadServiceInformationsData && prevProps.loadServiceInformationsData.id) 
        && (this.props.loadServiceInformationsData && this.props.loadServiceInformationsData.id)) {
            let Data = this.props.loadServiceInformationsData;
            this.setState({
                equipmentId: Data.id,
                equipmentName: Data.name,
                borneIp: (Data.borneIp === "") ? msg('common.undefined.status') : Data.borneIp,
                implant: Data.station ? Data.station.value : msg('common.undefined.status') ,
                stationName: Data.station ? Data.station.key : msg('common.undefined.status'),
                sapName: Data.station.sapName ?  Data.station.sapName : msg('common.undefined.status'),
                borneMask: (Data.borneMask === "") ? msg('common.undefined.status') : Data.borneMask,
                borneGateWay: (Data.borneGateWay === "") ? msg('common.undefined.status') : Data.borneGateWay,
                borneType: Data.borneType ? Data.borneType.value : msg('common.undefined.status'),
                serialNumber: Data.borne ? Data.borne.serialNumber : null,
                isTransaction: Data.isTransaction,
                neftisIp: (Data.neftisIp === "") ? msg('common.undefined.status') : Data.neftisIp,
                neftisId: (Data.neftisId === null) ? msg('common.undefined.status') : Data.neftisId.toString(),
                hardware: Data.hardware ? Data.hardware.value : msg('common.undefined.status'),
                isMulti : (Data.borne && Data.borneType) ? ((Data.borneType.value === BORNE_TL16) ? Data.borne.isMulti: undefined ) : undefined,
                qrValue: (Data.station  && Data.borneType && (Data.neftisId !== null)) ?
                Data.borneIp + ";" + Data.borneMask + ";" + Data.borneGateWay+ ";" + Data.id + ";" + Data.station.value + ";" +
                Data.borneType.value + ";"+ Data.name +";" + Data.neftisIp + ";" + Data.station.value + Data.neftisId : "",
                washCodeConfiguration: Data.codeServer ? (((Data.codeServer.ip === CODE_SERVER_IP) || (Data.codeServer.ip === Data.borneIp)) 
                                                       ? msg('equipment.configuration.washCode.master') 
                                                       : msg('equipment.configuration.washCode.slave'))
                                                       : msg('common.undefined.status'),
                isServiceInformation : true,
            });

        }
        if(this.props.equipments && this.props.equipments !== prevProps.equipments){
            progressSpinnerHandler.show(false);
        }
        let lFilter = Object.assign({}, this.state.filter);
        if(this.state.filter.startDate){            
            lFilter.startDate = null;
            this.setState({filter: lFilter});
        }
        
        let prevClose = prevProps.closeAlarmData && prevProps.closeAlarmData.lastChange;
        let lastClose = this.props.closeAlarmData && this.props.closeAlarmData.lastChange;
        if ((lastClose && (!prevClose || prevClose < lastClose))) {
            this.props.loadEquipmentById(this.state.equipmentId, 0, 20, lFilter);
        }
        let prevError = prevProps.error && prevProps.error.timestamp;
        let lastError = this.props.error && this.props.error.timestamp;
        let error = (lastError && (!prevError || prevError < lastError));
        if (error) {
            let isError = this.props.error.message && this.props.error.message.startsWith('error.equipment.') ? true : false;
            progressSpinnerHandler.show(false);
            this.setState({ isApiError: isError });

        }
        let prevSentQrCode = prevProps.sentQrCodeByIdData && prevProps.sentQrCodeByIdData.lastChange;
        let lastSentQrCode = this.props.sentQrCodeByIdData && this.props.sentQrCodeByIdData.lastChange;
        if ((lastSentQrCode && (!prevSentQrCode || prevSentQrCode < lastSentQrCode))) {
            progressSpinnerHandler.show(false);
            this.setState({ isSent: true });
        }
        if (this.state.redirected && this.props.equipments) {
            this.setState({ redirected: false });
        }
    }

    getRedirectBorneFilter() {
        let status = this.props.match.params.status;
        let station = this.props.match.params.station;
        let borneFilter = null;
        if(status || station){                      
            if (status) {
                borneFilter = Object.assign({}, borneFilter, { alarmType: status.toUpperCase() });
            }        
            if (station) {
                borneFilter = Object.assign({}, borneFilter, { stations: [{ key: station, value: '' }], redirected: true });
            }
        } else  {
            borneFilter = userSessionParamsHandler.getFilter(EQUIPMENTS_TABLE_ID);
        } 
        if(!borneFilter) {
            borneFilter = {sortProperty: {key: 's.implant', value: "DESC"}};
        }
        return borneFilter;
	}

    dataInfobar(filter) {
        return [
            { key: "titleFilter", value: msg('filter.in.progress') },
            { key: "Canaux", value: filter && filter.saleChannels.length > 0 && filter.saleChannels[0].value ? msg("filter.channels.canal") + ": " + 
            ((filter.saleChannels[0].value === 'LOTUS') ?msg('station.contract.schannel.lotus') :filter.saleChannels[0].value ) + ";" : msg("filter.channels.canal") + ": " + msg('filter.channels.all') + ";" },
            { key: "Station", value: filter && filter.stations.length > 0 && filter.stations[0].value ? msg('filter.station') + ": " + filter.stations[0].value + ";" : msg('filter.station') + ": " + msg('filter.stations.all') + ";" },
            { key: "Type bornes", value: filter && filter.borneTypes.length > 0 && filter.borneTypes[0].value ? msg('filter.borne.type') + ": " + filter.borneTypes[0].value + ";" : msg('filter.borne.type') + ": " + msg('filter.bornetypes.all') + ";" },
            { key: "Bornes", value: filter && filter.bornes.length > 0 && filter.bornes[0].value ? msg('filter.bonre') + ": " + filter.bornes[0].value : msg('filter.bonre') + ": " + msg('filter.equipments.all') }
        ]
    }

    typeFiltreAlrme(showAllAlarm, showOnlyOpenedAlarm) {
        if (String(showAllAlarm) === "true") return msg('filter.value.showAllAlarm');
        if (String(showOnlyOpenedAlarm) === "true") return msg('filter.value.showOnlyOpenedAlarm');
        return msg('filter.value.no.alarm')
    }

    typeTransaction(value) {
        if (!value) {
            return '';
        }
        let values = value.toString().split(",");
        if (values.includes("S") && values.includes("P")) {
            return msg('filter.value.sellingFeaturekey');
        } else if (values.includes("S")) {
            return msg('equipements.filter.ventes');
        } else if (values.includes("P")) {
            return msg('equipements.filter.prestations');
        }
        return '';
    }

    getStatusBorne(rowData, column) {
        let status = rowData.borne ? rowData.borne.status : '';
        switch (status) {
            case 'ONLINE':
            case 'STARTED':
            case 'RUNNING':
                return msg('borne.status.online')
            case 'OFFLINE':
                return msg('borne.status.offline')
            default:
                return ' ';
        }

    }

    getSerialNumber(rowData, column) {
        let serialNumber = rowData.borne ? rowData.borne.serialNumber : "";
        if (serialNumber != null && serialNumber.length > 24)
            return <div className="hasTooltip p-ellipsis"> {serialNumber} <span> {serialNumber}  </span></div>;
        else
            return <div> {serialNumber} </div>
    }

    getFirmwareName(rowData, column) {
        let firmwareName = rowData.borne ? rowData.borne.currentFirmwareName : "";
        if (firmwareName != null && firmwareName.length > MAX_LENGTH)
            return <div className="hasTooltip p-ellipsis"> {firmwareName} <span> {firmwareName}  </span></div>;
        else
            return <div> {firmwareName} </div>
    }

    getStationNameAndImplant(rowData){
        let name = rowData.stationName ? rowData.stationName : ""
        let implant = rowData.station ? rowData.station.implant : ""
         let fullname = <div> 
                           <div>{implant}</div>
                           <div>{name}</div>
                        </div>
        if(name && name.length > MAX_LENGTH)
        return <div className="hasTooltip p-ellipsis" > {fullname} <span> {fullname}  </span></div>;
        else
            return <div> {fullname} </div> 
    }

    getLastTransaction(rowData, column) {
        let time = rowData.time ? rowData.time : "";
        if (time != null && time.length > 15)
            return <div className="hasTooltip p-ellipsis"> {time} <span> {time}  </span></div>;
        else
            return <div> {time} </div>
    }

    getOpenedAlarm(rowData, column) {
        let alarm = (rowData.openedAlarm === true ? <div className='count'  alt="Alarms">{rowData.openedAlarmCount}</div> : '');
        let alarmClass
        if (rowData.errors > 0) {
            alarmClass = (rowData.openedAlarm === true ? 'equipment-opened-alarm-errors' : '');
        } else if (rowData.majorWarnings > 0) {
            alarmClass = (rowData.openedAlarm === true ? 'equipment-opened-alarm-major' : '');
        } else if (rowData.minorWarnings > 0) {
            alarmClass = (rowData.openedAlarm === true ? 'equipment-opened-alarm-mineur' : '');
        }
        return <div className='p-grid'><div className='p-col-10'>{rowData.name}</div><div className={'p-col-2 ' + alarmClass}>{alarm}</div></div>;
    }

    onSelection(event) {
        if (event.value) {
            let filter = Object.assign({}, this.state.filter, {
                equipments: [{ key: event.value.id, value: event.value.id }],
                stations: this.state.borneFilter && this.state.borneFilter.stations ? this.state.borneFilter.stations : []
            });
            let serialNumber = event.value.borne && event.value.borne.serialNumber ? event.value.borne.serialNumber : "";
            let equipmentName = event.value.name ? event.value.name : ""
            let stationName = event.value.stationName ? event.value.stationName : ""
            let stationImplant = event.value.station ? event.value.station.implant : ""
            let equipmentFullName = stationImplant + " (" + stationName + ") - " + serialNumber + " (" + equipmentName + ")";
            this.setState({
                equipmentId: event.value.id,
                equipmentFullName: equipmentFullName,                
                openedAlarm: event.value.openedAlarm,
                isNeverTurned: event.value.borne && !event.value.borne.serialNumber && !event.value.transaction,
                filter: filter
            });
        }
    }

    loadFilter(borneFilter) {
        this.props.loadFilter(EQUIPMENTS, borneFilter);
        this.setState({ borneFilter: borneFilter });
    }

    loadFilterStations(filter) {
        this.props.loadFilterStations(EQUIPMENTS, filter);
    }

    loadEquipments(page, pageSize, filter) {
        if(!filter.sortPropreties) {
            filter = this.loadSortedPropreties(filter);
            this.setState({ borneFilter: filter });
        }
        this.props.loadEquipments(page, pageSize, filter);
        progressSpinnerHandler.show(true);
    }

    loadEquipmentsByLabel(quickSearch) {
        let page = 0;
        let pageSize = userSessionParamsHandler.getPageSize(EQUIPMENTS_TABLE_ID);
        this.loadEquipments(page, pageSize, this.state.borneFilter);
    }

    onQuickSearchChange(e){
        let filter = Object.assign({}, this.state.borneFilter);
        filter.label = e.target.value;
        this.setState({ borneFilter: filter });
    }

    applyBorneFilter(filter) {
        this.loadEquipments(0, userSessionParamsHandler.getPageSize(EQUIPMENTS_TABLE_ID), filter);
        defaultActiviteFilter.lastFilterRestTime = new Date().getTime();
        this.setState({ borneFilter: filter, filter: defaultActiviteFilter, equipmentId: null, equipmentName: null });
        this.props.setDataInfoBar(this.dataInfobar(filter));
    }

    onSort = (e, page) => {
        let pageSize = userSessionParamsHandler.getPageSize(EQUIPMENTS_TABLE_ID)
        let filter = userSessionParamsHandler.getFilter(EQUIPMENTS_TABLE_ID);
        let sortDirection = this.state.sortDirection === "ASC" ? "DESC" : "ASC";
        filter.sortProperty = {key: e.sortField, value: sortDirection};
        this.loadEquipments(page, pageSize, filter);        
        this.setState({ sortDirection: sortDirection, borneFilter: filter });
        progressSpinnerHandler.show(true);
    }

    loadEquipmentById(id, page, pageSize, filter) {
        this.props.loadEquipmentById(id, page, pageSize, filter)
    }

    loadSortedPropreties(filter) {
        let lFilter = Object.assign({}, filter);
        let propretiesSort = [];
        propretiesSort.push({ 'key': 'name', 'value': this.state.sortNameDirection });
        propretiesSort.push({ 'key': 'station.implant', 'value': this.state.sortImplantDirection });
        lFilter.sortPropreties = propretiesSort;
        return lFilter;
    }

    redirectToActivityJournal() {
        this.props.history.push('/actitvityJournal?equipmentId=' + this.state.equipmentId);
    }

    showActivityJournal() {
        if (this.state.equipmentId) {
            let lFilter = Object.assign({}, this.state.filter, {startDate: null});
            this.loadEquipmentById(this.state.equipmentId, 0, userSessionParamsHandler.getPageSize(EQUIPMENTS_TABLE_ID), lFilter);
            this.props.history.push('/actitvityJournal?equipmentId=' + this.state.equipmentId);
        }
    }

    showServiceInformation() {
        this.props.loadServiceInformations(this.state.equipmentId);
        this.setState({isServiceInformation: true});
    }

    showOpenAlarms() {
        this.setState({isOpenAlarms: true});
    }

    sendQRCode() {
        this.props.loadSendQrCodeById(this.state.equipmentId);
        progressSpinnerHandler.show(true);
    };

    onOpenAlarmsDialogHide() {
        this.setState({ isOpenAlarms: false });
        this.loadEquipments(0, userSessionParamsHandler.getPageSize(EQUIPMENTS_TABLE_ID), this.state.borneFilter);
        this.loadFilter(this.state.borneFilter)
    }

    render() {
        let authorized = rolesValidator.validate(EQUIPMENTS);
    
        let columnsEquipments = [
            { isColWithBody: false, field: "id", header: "id", sortable: true, className: "visibilityHidden" },
            { isColWithBody: true, field: "station.implant",  header: msg('equipments.station.implant.name'), body: this.getStationNameAndImplant, sortable: true, className: "p-col-2 tableData" },
            { isColWithBody: true, field: "name", header: msg('equipements.station.name'), body: this.getOpenedAlarm, sortable: true, className: "p-col-2 tableData" },
            { isColWithBody: true, field: "borne.serialNumber", header: msg('equipements.borne.serialNumber'), body: this.getSerialNumber, sortable: true, className: "p-col-1 p-ellipsis" },
            { isColWithBody: false, field: "borne.borneType", header: msg('equipements.name'), sortable: true, className: "p-col-1" },
            { isColWithBody: true, field: "borne.currentFirmwareName", header: msg('equipements.borne.currentFirmwareName'), body: this.getFirmwareName, sortable: true, className: "p-col-2" },
            { isColWithBody: true, field: "borne.status", header: msg('equipements.borne.status'), body: this.getStatusBorne, sortable: true, className: "p-col-2" },
            { isColWithBody: false, field: "time", header: msg('equipements.lastPrestation'), sortable: true, className: "p-col-2" }

        ];

        let mesQrCodeVersion = this.props.equipments && this.props.equipments.mesQrCodeVersion ? this.props.equipments.mesQrCodeVersion : "";
        let qrValue = mesQrCodeVersion + ";" + this.state.qrValue;

        let equipmentsOutilsBar = [
            { validate: 'EQUIPMENTS', filename: "Liste des équipments.xlsx", iconSrc: "/assets/ultima/layout/images/cartadis/excel.svg", titleIcone: "Equipements", tooltipTextIcone: msg('equipement.tooltip.export'), urlExport: "equipments/equipments-excel" },
        ]

       let BorneData =  {
            implant: this.state.implant ? this.state.implant : '',
            stationName: this.state.stationName ? this.state.stationName : '',
            sapName: this.state.sapName ? this.state.sapName : '',
            equipmentName: this.state.equipmentName ? this.state.equipmentName : '',
            borneType: this.state.borneType ? this.state.borneType : '',
            equipmentId: this.state.equipmentId ? this.state.equipmentId : '',
            borneIp: this.state.borneIp ? this.state.borneIp : '',
            borneGateWay: this.state.borneGateWay ? this.state.borneGateWay : '',
            borneMask: this.state.borneMask ? this.state.borneMask : '',
            neftisIp: this.state.neftisIp ? this.state.neftisIp : '',
            neftisId: this.state.neftisId ? this.state.neftisId : '',
            hardware: this.state.hardware ? this.state.hardware : '',
            isMulti:  this.state.isMulti,
            washCodeConfiguration: this.state.washCodeConfiguration ? this.state.washCodeConfiguration : '',
            qrValue: qrValue
        };
        let serviceInformation = this.state.equipmentId && this.state.isServiceInformation && (
        <ServiceInformationDialog visible={this.state.isServiceInformation} BorneData={BorneData} 
            onHide={() => this.setState({ isServiceInformation: false })} onClick={() => this.sendQRCode()}/>);

        let openAlarmsDialog = this.state.equipmentId && this.state.openedAlarm && this.state.isOpenAlarms && (
            <OpenAlarmsDialog
                alarms={this.state.isOpenAlarms ? this.props.openAlarmsData : null}
                equipment={{id: this.state.equipmentId, name: this.state.equipmentFullName}}
                visible={this.state.isOpenAlarms} 
                onHide={this.onOpenAlarmsDialogHide} load={this.props.loadOpenAlarms}
                closeAlarm={this.props.loadCloseAlarm} />);

        let ContextMenuContent = null;
        if(this.state.equipmentId) {
            ContextMenuContent = [];
            if(!this.state.isNeverTurned) {
                ContextMenuContent.push({
                    label: msg('equipement.activity.jornal'),
                    icon: 'pi-md-format-list-bulleted',
                    command: this.showActivityJournal
                });
            }
            if(this.state.openedAlarm) {
                ContextMenuContent.push({
                    label: msg('equipment.open.alarms'),
                    icon: 'pi-md-info',
                    command: this.showOpenAlarms
                });
            }
            ContextMenuContent.push({
                label: msg('equipement.service.information'),
                icon: 'pi-md-my-location',
                command: this.showServiceInformation
            });
        }

        let equipments = this.props.equipments && this.props.equipments.equipments ? this.props.equipments.equipments : [];
        let totalPages = this.props.equipments ? this.props.equipments.totalPages : 0;
        let first = this.props.equipments && this.props.equipments.first === true ? this.props.equipments.first : null;
        const tabelEquipments = (
            <TableData data={equipments} titleTable={msg('equipements.title')} totalPages={totalPages} first={first} 
                ContextMenuContent={ContextMenuContent}
                getDataFunction={this.loadEquipments}
                paramnFunctionData={null}
                apply={this.loadEquipmentsByLabel}
                onQuickSearchChange={this.onQuickSearchChange}
                /* TODO note use custom params in common component */
                /* This params are used only by equipment component */
                getDataFunctionActivity={this.loadEquipmentById}
                paramnFunctionDataActivity={this.state.equipmentId}
                location={this.redirectToActivityJournal} 
                showServiceInformation={this.showServiceInformation}
                defaultActiviteFilter={this.state.filter}
                /* Custom end */
                turned={this.state.isNeverTurned} onSort={this.onSort}
                selectionMode={"single"} onSelection={this.onSelection}
                outilsBar={equipmentsOutilsBar}
                filter={this.state.borneFilter} 
                columns={columnsEquipments}
                searchInTable={false} searchByName={true} nameTxtPlaceholder={msg('equipments.quick.search.placeholder')}
                tableId={EQUIPMENTS_TABLE_ID}
            />)
        return authorized && (
            <div className="p-grid commonClass">
                <div className="p-grid p-col-10">
                    <div className="card card-w-title container-size-screen-642-to-1366">
                        <h1>{msg('equipements')}</h1>
                        {tabelEquipments}
                    </div>
                    {serviceInformation}
                    {openAlarmsDialog}
                    <MessageDialog className={'message-dialog warn-message'} visible={this.state.isApiError} title={msg('message.dialog.warn.title')}
                        message={this.state.isApiError && msg(this.props.error.message)}
                        onHide={() => this.setState({ isLignSelected: false, isApiError: false })} />
                    <MessageDialog className={'message-dialog info-message'} visible={this.state.isSent} title={msg('equipment.borne.info')}
                        message={msg('equipment.borne.qrCode.sent')}
                        onHide={() => this.setState({ isSent: false })} />
                </div>
                <div className="p-col-2">
                    <BorneFilter apply={this.applyBorneFilter}
                        reset={this.applyBorneFilter}
                        loadFilter={filter => this.loadFilter(filter)}
                        loadFilterStations={filter => this.loadFilterStations(filter)}
                        filterData={this.props.filterData}
                        filterStations={this.props.filterStations}
                        showChannel={true} showStation={true} showBorneType={true} showBorneSerial={true} showTransactionPeriod={true} 
                        showAlarmType={true} defaultAlarmType={this.state.borneFilter.alarmType}
                        title={msg('equipments.filter.title')}
                        setDataInfoBar={this.props.setDataInfoBar} applyLabel={msg('equipments.filter.apply')}
                        initFilter={this.state.borneFilter} 
                        filterId={EQUIPMENTS_TABLE_ID}/>
                </div>
            </div >
        )
    }
}

export default Equipments
