import React, { Component } from "react";
import { Map, GoogleApiWrapper, Marker, InfoWindow } from "google-maps-react";
import '../common/css/MapContainer.css'
import msg from "../../texts/msg";

class MapContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            markers: [],
            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: {},
            address: ""
        }
        this.onMarkerClick = this.onMarkerClick.bind(this);
        this.onMouseoverMarker = this.onMouseoverMarker.bind(this);
        this.onMapClicked = this.onMapClicked.bind(this);
        this.onInfoWindowClose = this.onInfoWindowClose.bind(this);
    }

    onMarkerClick(props, marker, e) {
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: false
        });
        this.props.onRedirect(marker.id);
    }

    onMapClicked(props) {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            });
        }
    };

    onMouseoverMarker(props, marker, e) {
        if (!this.state.showingInfoWindow || (this.state.activeMarker && marker && marker.id && this.state.activeMarker.id !== marker.id)) {
            this.setState({
                selectedPlace: props,
                activeMarker: marker,
                showingInfoWindow: true
            });
        }
    }

    onInfoWindowClose() {
        this.setState({
            selectedPlace: null,
            showingInfoWindow: false
        });
    }


    render() {
        let stationsMap = this.props.stationsMap ? this.props.stationsMap : null;
        let markers = [];
        //let url = "http://maps.google.com/mapfiles/ms/icons/";
        let url = "/assets/ultima/layout/images/maps/"
       
        if (stationsMap) {
            stationsMap.forEach(function (item) {
                markers.push({
                    id: item.id, name: item.name, implant: item.implant, saleChannel: item.saleChannel,
                    latitude: item.gpsLatitude, longitude: item.gpsLongitude, icon: getIcons(item)
                    , numberOfBorne: item.numberOfBorne
                });
            });
        }

        function getIcons(item) {
            let icon = url + "allBorneOk.png";
            if (!item.oneBorneWithFatalError && !item.frostFree && !item.closeStation && !item.allBorneKo) {
                icon = url + "allBorneOk.png";
            } else {
                if (item.allBorneKo) {
                    icon = url + "allBorneKo.png";
                } else if (item.oneBorneWithFatalError) {
                    icon = url + "oneBorneWithFatalError.png";
                } else {
                    if (item.frostFree) {
                        icon = url + "frostFree.png";
                    }
                    if (item.closeStation) {
                        icon = url + "closeStation.png";
                    }
                }
            }
            return icon;
        }

        let style = {
            position: 'relative',
            width: this.props.width ? this.props.width : '100%',
            height: this.props.height ? this.props.height : '63%'
        };
        let containerStyle = {
            position: 'relative',
            textAlign: 'center',
            width: '100%',
            height: '100%'
        };

        return (
            <Map google={this.props.google}
                style={style} containerStyle={containerStyle}
                zoom={this.props.coordinateDefaultMaps ? this.props.coordinateDefaultMaps.zoomMaps : 12}
                initialCenter={{
                    lat: this.props.coordinateDefaultMaps ? this.props.coordinateDefaultMaps.latStation : 48.851542,
                    lng: this.props.coordinateDefaultMaps ? this.props.coordinateDefaultMaps.lngStation : 2.475907
                }}
                centerAroundCurrentLocation={false}
                /*center={{
                    lat: this.props.coordinateDefaultMaps ? this.props.coordinateDefaultMaps.latStation : 48.851542,
                    lng: this.props.coordinateDefaultMaps ? this.props.coordinateDefaultMaps.lngStation : 2.475907
                }}
                */
                onClick={this.onMapClicked}>
                {markers.map((marker, i) => {
                    let info = <div className="p-grid p-col-12 gm-iw-st-details">
                        <span className="p-col-12">{msg('station.map.info.channel') + ': ' + marker.saleChannel} </span>
                        <span className="p-col-12">{marker.implant + ' (' + marker.name + ')'} </span>
                        <span className="p-col-12">{msg('station.map.info.equipments.count') + ': ' + marker.numberOfBorne} </span>
                        <span className="p-col-12 note">{msg('station.map.info.note')}</span>
                    </div>;
                    return (
                        <Marker
                            onClick={this.onMarkerClick}
                            onMouseover={this.onMouseoverMarker}
                            key={i}
                            id={marker.id}
                            title={marker.name}
                            name={info}
                            position={{ lat: marker.latitude, lng: marker.longitude }}
                            icon={marker.icon}

                        />
                    )
                })}
                <InfoWindow marker={this.state.activeMarker} visible={this.state.showingInfoWindow} onClose={this.onInfoWindowClose}>
                    <div className="p-col-12 gm-iw-content">
                        {this.state.selectedPlace && this.state.selectedPlace.name}
                    </div>
                </InfoWindow>
            </Map>
        );
    }
}
const GoogleMap = GoogleApiWrapper({ apiKey: process.env.REACT_APP_GOOGLE_API_KEY })(MapContainer);
export default GoogleMap;