import {toLocaleDateTimeFormat} from '../../utils/dateTimeUtils';

export const EQUIPMENTS='EQUIPMENTS';
export const EQUIPMENTS_SUCCESS= 'EQUIPMENTS_SUCCESS';
export const EQUIPMENT_BY_ID='EQUIPMENT_BY_ID';
export const EQUIPMENT_BY_ID_SUCCESS= 'EQUIPMENT_BY_ID_SUCCESS';
export const CLOSE_ALARM = 'CLOSE_ALARM';
export const CLOSE_ALARM_SUCCESS= 'CLOSE_ALARM_SUCCESS';
export const QRCODE_SEND_BY_ID = 'QRCODE_SEND_BY_ID';
export const QRCODE_SEND_BY_ID_SUCCESS= 'QRCODE_SEND_BY_ID_SUCCESS';
export const SERVICE_INFORMATIONS='SERVICE_INFORMATIONS';
export const SERVICE_INFORMATIONS_SUCCESS= 'SERVICE_INFORMATIONS_SUCCESS';
export const OPEN_ALARMS='OPEN_ALARMS';
export const OPEN_ALARMS_SUCCESS= 'OPEN_ALARMS_SUCCESS';


export const equipments = (page, pageSize, filter) => {
	return ({
		type: EQUIPMENTS,
		data: {page: page,  pageSize: pageSize, filter: filter},
		errorMessage: null,
		errorStatus: null
	});
};
export const equipmentsSuccess = (r) => {
	return ({
		type: EQUIPMENTS_SUCCESS,
		data: formatDates(r.response),
		errorMessage: null,
		errorStatus: null
	});
};
export const loadServiceInformations = (equipmentId) => {
	return ({
		type: SERVICE_INFORMATIONS,
		data: { equipmentId: equipmentId },
		errorMessage: null,
		errorStatus: null
	});
};
export const loadServiceInformationsSuccess = (r) => {
	return ({
		type: SERVICE_INFORMATIONS_SUCCESS,
		data: r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const loadOpenAlarms = (equipmentId, page, pageSize, filter) => {
	return ({
		type: OPEN_ALARMS,
		data: { equipmentId: equipmentId,  page: page, pageSize: pageSize, filter: filter },
		errorMessage: null,
		errorStatus: null
	});
};
export const loadOpenAlarmsSuccess = (r) => {
	return ({
		type: OPEN_ALARMS_SUCCESS,
		data: r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const equipmentById = (idEquipment, page, pageSize, filter) => { 
	return ({
		type: EQUIPMENT_BY_ID,
		data: {idEquipment: idEquipment, page: page,  pageSize: pageSize, filter: filter},
		errorMessage: null,
		errorStatus: null
	});
};
export const equipmentByIdSuccess = (r) => {
	return ({
		type: EQUIPMENT_BY_ID_SUCCESS,
		data: formatDates(r.response),
		errorMessage: null,
		errorStatus: null
	});
};

export const closeAlarm = (alarmId) => { 
	return ({
		type: CLOSE_ALARM,
		data: {alarmId: alarmId},
		errorMessage: null,
		errorStatus: null
	});
};
export const closeAlarmSuccess = (r) => {
	return ({
		type: CLOSE_ALARM_SUCCESS,
		data: r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const qrCodeSendById = (equipmentId) => { 
	return ({
		type: QRCODE_SEND_BY_ID,
		data: {equipmentId: equipmentId},
		errorMessage: null,
		errorStatus: null
	});
};

export const qrCodeSendByIdSuccess = (r) => {
	return ({
		type: QRCODE_SEND_BY_ID_SUCCESS,
		data: r.response,
		errorMessage: null,
		errorStatus: null
	});
};

function formatDates(response) {
	let data = response && response.equipments ? response.equipments : [];
	data = data.map(e => {
		e.time = toLocaleDateTimeFormat(e.time); 
		return e;
	});	
	response.equipments = data;

	data = response && response.reports ? response.reports : [];
	data = data.map(e => {
		e.time = toLocaleDateTimeFormat(e.time); 
		return e;
	});	
	response.reports = data;
	
	return response;
}