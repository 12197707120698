
import React, { Component } from 'react';
import { InputMask } from 'primereact/components/inputmask/InputMask';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { RadioButton } from 'primereact/radiobutton';
import { Dropdown } from 'primereact/dropdown';
import msg from '../../../texts/msg';

export const PLANNING_OPEN = 'open';
export const PLANNING_CLOSE = 'close';
export const PLANNING_TIMESLOT = 'timeslot';
export const DEFAULTTIMESLOT = {start: "00:00", end: "23:59", closed: false};

export const ALLDAYS = [
    { key: "monday", value: "monday", timeslots: [DEFAULTTIMESLOT]},
    { key: "tuesday", value: "tuesday", timeslots: [DEFAULTTIMESLOT]},
    { key: "wednesday", value: "wednesday", timeslots: [DEFAULTTIMESLOT]},
    { key: "thursday", value: "thursday", timeslots: [DEFAULTTIMESLOT]},
    { key: "friday", value: "friday", timeslots: [DEFAULTTIMESLOT]},
    { key: "saturday", value: "saturday", timeslots: [DEFAULTTIMESLOT]},
    { key: "sunday", value: "sunday", timeslots: [DEFAULTTIMESLOT]}
];

export const TIMETABLES = [
    { key: "default", value: msg("station.timetable.default"), days: ALLDAYS, timeslots: [DEFAULTTIMESLOT], planningType: PLANNING_OPEN},
    { key: "custom", value: msg("station.timetable.custom"), days: ALLDAYS }
];
    
export const getDefaultTimetableRefCopie = () => {
    let lDays = []
    ALLDAYS.forEach(d => {
        lDays.push( {
            key: d.key,
            value: d.value,
            timeslots: d.timeslots = [Object.assign({}, DEFAULTTIMESLOT)]
        });
    });
    return Object.assign({}, TIMETABLES[0], {days : lDays}, {timeslots: [Object.assign({}, DEFAULTTIMESLOT)]});
}

export const getTimetablesRefCopie = () => {
    let lDays = []
    ALLDAYS.forEach(d => {
        lDays.push( {
            key: d.key,
            value: d.value,
            timeslots: d.timeslots = [Object.assign({}, DEFAULTTIMESLOT)]
        });
    });
    let t0 =  Object.assign({}, TIMETABLES[0], {days : lDays}, {timeslots: [Object.assign({}, DEFAULTTIMESLOT)]});
    lDays = [];
    ALLDAYS.forEach(d => {
        lDays.push( {
            key: d.key,
            value: d.value,
            timeslots: d.timeslots = [Object.assign({}, DEFAULTTIMESLOT)]
        });
    });
    let t1 =  Object.assign({}, TIMETABLES[1], {days : lDays}, {timeslots: [Object.assign({}, DEFAULTTIMESLOT)]});
    return [t0, t1];
}

export const getTimetable = (modelTimetable) => {
    let timetable = getDefaultTimetableRefCopie();
    let days = []
    ALLDAYS.forEach(d => {
        days.push( {
            key: d.key,
            value: d.value,
            timeslots: d.timeslots
        });
    });     
    let sTimetable = modelTimetable;    
    if(sTimetable) {
        if(sTimetable.days && Array.isArray(sTimetable.days) && sTimetable.days.length >= 1) {
            sTimetable.days.forEach((d, i) => {
                days[i].key = d.key.toLowerCase();
                days[i].value = d.value.toLowerCase();
                if(d.timeslots && Array.isArray(d.timeslots) && d.timeslots.length >= 1) {
                    days[i].timeslots = [];
                    d.timeslots.forEach(t => {
                        days[i].timeslots.push({
                            start: t.start ? t.start : '00:00',
                            end: t.end ? t.end : '23:59',
                            closed: t.closed
                        });
                    })                        
                }
            });
        }
        let timeslots = [Object.assign({}, DEFAULTTIMESLOT)];
        if(sTimetable.timeslots && Array.isArray(sTimetable.timeslots) && sTimetable.timeslots.length >= 1) {
            timeslots = [];
            let t = sTimetable.timeslots[0];
            timeslots.push({
                start: t.start ? t.start : '00:00',
                end: t.end ? t.end : '23:59',
                closed: t.closed
            });
            if(sTimetable.timeslots.length > 1) {
                t = sTimetable.timeslots[1];
                timeslots.push({
                    start: t.start ? t.start : '00:00',
                    end: t.end ? t.end : '23:59',
                    closed: t.closed
                });
            }
        }
        timetable = {
            key: sTimetable.key.toLowerCase(),
            value: msg('station.timetable.' + sTimetable.key.toLowerCase()),
            planningType: sTimetable.planningType,
            days: days,
            timeslots: timeslots
        }
    }        
    return timetable;
}

export class TimeTable extends Component {

    constructor(props) {
        super(props);
        this.handleTimetableChange = this.handleTimetableChange.bind(this);
        this.handlePlanningTypeChange = this.handlePlanningTypeChange.bind(this);
        this.handleTimeslotStartChange = this.handleTimeslotStartChange.bind(this);
        this.handleTimeslotEndChange = this.handleTimeslotEndChange.bind(this);
        this.handleClosedTimeslotChange = this.handleClosedTimeslotChange.bind(this);
        this.addTimeslot = this.addTimeslot.bind(this);
        this.removeTimeslot = this.removeTimeslot.bind(this);        
    }

    handleTimetableChange(e) {
        let timetable = Object.assign({}, e.target.value); 
        if(timetable.value === "default" && !(timetable.timeslots && timetable.timeslots.length > 0)){
            timetable.timeslots = [Object.assign({}, DEFAULTTIMESLOT)];
        }
        this.props.setTimetableState(timetable);
    }

    handlePlanningTypeChange(e) {
        let timetable = Object.assign({}, this.props.timetable );
        timetable.planningType = e.value;
        if(timetable.planningType === PLANNING_TIMESLOT){
            timetable.timeslots = [Object.assign({}, DEFAULTTIMESLOT)];
        }
        this.props.setTimetableState(timetable);
    }

    handleTimeslotStartChange(e, iday, islot) {
        let v = e.target.value;            
        let time = {hour: '00', minutes: '00'};
        time = this.validateTime(v, time);
        if(!time) {
            return
        }
        let hour = time.hour;
        let minutes = time.minutes;
        let modelTimetable = Object.assign({}, this.props.timetable );
        let end;
        if(!Number.isNaN(iday) && (iday || (iday === 0))) {
            end = modelTimetable.days[iday].timeslots[islot].end;
        } else {
            end = modelTimetable.timeslots[islot].end;
        }
        let t =  end ? end.split(':') : ['23', '59']; 
        let endHour = t.length > 0 ? t[0] : '23';
        let endMinutes = t.length > 1 ? t[1] : '59';
        endHour = Number.parseInt(endHour && !Number.isNaN(endHour)  ? endHour : '23');
        endMinutes = Number.parseInt(endMinutes && !Number.isNaN(endMinutes)  ? endMinutes : '59');
        if(Number.parseInt(hour) > endHour || (Number.parseInt(hour) === endHour && minutes > endMinutes)) {
            return;
        }
        let timeslot =  {start: hour + ':' + minutes, end: end};
        if(!Number.isNaN(iday) && (iday || (iday === 0))) {
            let prevTimeslot  = modelTimetable.days[iday].timeslots[islot];
            modelTimetable.days[iday].timeslots[islot] = {
                start: timeslot.start,
                end: timeslot.end,
                closed: prevTimeslot.closed
            };            
        } else {
            modelTimetable.timeslots[islot] = timeslot;
            modelTimetable.days.forEach(d => {
                let prevTimeslot  = d.timeslots[islot] ? d.timeslots[islot] : Object.assign({}, DEFAULTTIMESLOT);
                d.timeslots[islot] = {
                start: timeslot.start,
                end: timeslot.end,
                closed: prevTimeslot.closed};
            });
        }
        this.props.setTimetableState(modelTimetable);
    }

    handleTimeslotEndChange(e, iday, islot) {
        let v = e.target.value;            
        let time = {hour: '23', minutes: '59'};
        time = this.validateTime(v, time);
        if(!time) {
            return
        }
        let hour = time.hour;
        let minutes = time.minutes;
        let modelTimetable = Object.assign({}, this.props.timetable);
        let start;
        if(!Number.isNaN(iday) && (iday || (iday === 0))) {
            start = modelTimetable.days[iday].timeslots[islot].start;
        }  else {
            start = modelTimetable.timeslots[islot].start;
        }
        let t =  start ? start.split(':') : ['00', '00']; 
        let startHour = t.length > 0 ? t[0] : '00';
        let startMinutes = t.length > 1 ? t[1] : '00';
        startHour = Number.parseInt(startHour && !Number.isNaN(startHour)  ? startHour : '00');
        startMinutes = Number.parseInt(startMinutes && !Number.isNaN(startMinutes)  ? startMinutes : '00');
        let iHour = Number.parseInt(hour);
        let iMinutes = Number.parseInt(minutes);
        if( iHour < startHour || (iHour === startHour && iMinutes < startMinutes)) {
            return;
        }
        if(iday && islot === 0 && modelTimetable.days[iday].timeslots.length === 2){
           let nextStart = modelTimetable.days[iday].timeslots[1].start;
           time = {hour: hour, minutes: minutes};
           time = this.validateTime(nextStart, time);
           let iNextHour = Number.parseInt(time.hour);
            let iNextMinutes = Number.parseInt(time.minutes);
           if(iHour > iNextHour || (iHour === iNextHour && iMinutes > iNextMinutes)) {
                return;
            }
        }
        let timeslot = {start: start, end: hour + ':' + minutes};
        if(!Number.isNaN(iday) && (iday || (iday === 0))) {
            let prevTimeslot  = modelTimetable.days[iday].timeslots[islot];
            modelTimetable.days[iday].timeslots[islot] = {
                start: timeslot.start,
                end: timeslot.end,
                closed: prevTimeslot.closed
            }
        } else {
            modelTimetable.timeslots[islot] = timeslot;
            modelTimetable.days.forEach(d => {
                let prevTimeslot  = d.timeslots[islot] ? d.timeslots[islot] : Object.assign({}, DEFAULTTIMESLOT);
                d.timeslots[islot] = {
                start: timeslot.start,
                end: timeslot.end,
                closed: prevTimeslot.closed};
            });
        }
        this.props.setTimetableState(modelTimetable);
    }

    handleClosedTimeslotChange(e, iday) {
        if(!Number.isNaN(iday) || (iday || (iday === 0))) { 
            let modelTimetable = Object.assign({}, this.props.timetable);
            modelTimetable.days[iday].timeslots[0].closed = e.checked;
            this.props.setTimetableState(modelTimetable);
        }
    }

    isOneSlots(d){
        if(d && d.timeslots && d.timeslots.length === 1){
            let startTime = {hour: '00', minutes: '00'};
            startTime = this.validateTime(d.timeslots[0].start, startTime);
            let endTime = {hour: '23', minutes: '59'};
            endTime = this.validateTime(d.timeslots[0].end, endTime);
            if(startTime && endTime){
                let startHour = Number.parseInt(startTime.hour);
                let endHour = Number.parseInt(endTime.hour);
                if( (startHour < endHour) && (endHour < 23)){
                   return true;
                }
            }            
        }
        return false
    }

    isTowSlots(d){
        return d && d.timeslots && d.timeslots.length === 2;
    }

    addTimeslot(d) {
        if(this.isOneSlots(d)){
            d.timeslots.push(Object.assign({}, {start: d.timeslots[0].end, end: '23:59', closed: false}));
        }
    }

    removeTimeslot(d) {
        if(this.isTowSlots(d)){
            d.timeslots = [d.timeslots[0]];
        }
    }

     
    validateTime = (stringValue, time) => {
        let hour = time && time.hour ? time.hour : '00';
        let minutes = time && time.minutes ? time.minutes : '00';
        if(stringValue) {
            let t = stringValue.split(':');
            if(t.length >= 1){
                hour = t[0];
                if(Number.parseInt(hour) > 23 || Number.parseInt(hour) < 0) {
                    return null;
                }
                if(t.length === 2){
                    minutes = t[1];
                    if(Number.parseInt(minutes) > 59 || Number.parseInt(minutes) < 0) {
                        return null;
                    }
                } else {
                    return null;
                }
            } else {
                return null;
            }            
        } else {
            return null;
        }
        return {hour: hour, minutes : minutes};
    };

    render() {
        let isDefaultTimetable = this.props.timetable.key === "default";
        let timetables = getTimetablesRefCopie();
        return (
        <div className="p-grid form-group p-col-12">
            <div className="p-grid p-col-12" style={{padding: 0, margin: 0}}> 
                <div className="p-col-3">
                    <span className="md-inputfield">
                        <Dropdown options={timetables} optionLabel="value" dataKey="key" value={this.props.timetable}
                            itemTemplate={o => msg('station.timetable.' + o.key)}
                            onChange={e => this.handleTimetableChange(e)}
                            placeholder="Selectionnez" key="timetable" />
                    </span>                                        
                </div>
                <div className="p-col-9">
                {isDefaultTimetable &&
                    <div className="p-grid p-col-12" style={{padding: 0, margin: 0}}>
                        <div className="p-col-4">
                            <RadioButton value={PLANNING_TIMESLOT} name="planningType" inputId="rb1"
                                onChange={e => this.handlePlanningTypeChange(e)} checked={this.props.timetable.planningType === PLANNING_TIMESLOT} />
                        <label htmlFor="rb3" className="p-radiobutton-label">{msg('station.timetable.planning.timeslot')} </label>
                        </div>
                        <div className="p-col-4">
                            <RadioButton value={PLANNING_OPEN} name="planningType" inputId="rb1"
                                onChange={e => this.handlePlanningTypeChange(e)} checked={this.props.timetable.planningType === PLANNING_OPEN} />
                            <label htmlFor="rb1" className="p-radiobutton-label">{msg('station.timetable.planning.open')} </label>
                        </div>
                        <div className="p-col-4">
                            <RadioButton value={PLANNING_CLOSE} name="planningType" inputId="rb2"
                                onChange={e => this.handlePlanningTypeChange(e)} checked={this.props.timetable.planningType === PLANNING_CLOSE} />
                            <label htmlFor="rb2" className="p-radiobutton-label">{msg('station.timetable.planning.close')} </label>
                        </div>
                    </div>}
                </div>
            </div>
            {(!isDefaultTimetable || (isDefaultTimetable && this.props.timetable.planningType === PLANNING_TIMESLOT)) &&
            <div className="p-grid p-col-12" style={{paddingBottom: 0, marginBottom: 0}}> 
                <div className="p-col-3" />
                <div className="p-grid p-col-8" style={{padding: 0, margin: 0}}>
                    <div className="p-col-5"><span>{msg('station.timetable.timeslot.open.hour')}</span></div>
                    <div className="p-col-5"><span>{msg('station.timetable.timeslot.close.hour')}</span></div>
                </div>
                <div className="p-col-1" >
                {!isDefaultTimetable &&
                    <span>{msg('station.timetable.timeslot.closed')}</span>}
                </div>
            </div>}
            <div className="p-grid p-col-12" style={{padding: 0, margin: 0}}> 
                {isDefaultTimetable && this.props.timetable.planningType === PLANNING_TIMESLOT &&
                <div className="p-grid p-col-12" style={{padding: 0, margin: 0}}>
                <div className="p-grid p-col-12">
                    <div className="p-col-3"></div>
                    <div className="p-grid p-col-8" style={{padding: 0, margin: 0}}>
                        <div className="p-col-5">
                            <InputMask value={this.props.timetable.timeslots[0].start} mask="99:99" slotChar="00:00"  key={"startfld0"} 
                                onChange={e => this.handleTimeslotStartChange(e, null, 0)} />
                        </div>
                        <div className="p-col-5">
                            <InputMask value={this.props.timetable.timeslots[0].end} mask="99:99" slotChar="23:59"  key={"endfld0"} 
                                onChange={e => this.handleTimeslotEndChange(e, null, 0)} />
                        </div>
                        <div className="p-col-2 timeslot-btn">
                        <Button icon="pi pi-plus" title={(msg('station.day.timeslot.add.button.title'))} 
                            onClick={e => this.addTimeslot(this.props.timetable)} disabled={!(this.isOneSlots(this.props.timetable))} key={"addBtn0"} />
                    </div>
                    </div>
                    <div className="p-col-1"/>
                </div>
                {(this.props.timetable.timeslots.length > 1) &&
                <div className="p-grid p-col-12">
                    <div className="p-col-3"></div>
                    <div className="p-grid p-col-8" style={{padding: 0, margin: 0}}>
                        <div className="p-col-5">
                            <InputMask value={this.props.timetable.timeslots[1].start} mask="99:99" slotChar="00:00"  key={"startfld1"} 
                                onChange={e => this.handleTimeslotStartChange(e, null, 1)} />
                        </div>
                        <div className="p-col-5">
                            <InputMask value={this.props.timetable.timeslots[1].end} mask="99:99" slotChar="23:59"  key={"endfld1"} 
                                onChange={e => this.handleTimeslotEndChange(e, null, 1)} />
                        </div>
                        <div className="p-col-2 timeslot-btn">
                        <Button icon="pi pi-minus" title={(msg('station.day.timeslot.remove.button.title'))}
                            onClick={e => this.removeTimeslot(this.props.timetable)} disabled={!(this.isTowSlots(this.props.timetable))} key={"removeBtn1"} />
                    </div>
                    </div>                    
                    <div className="p-col-1"/>
                 </div>}
                 </div>
                }
                {(!isDefaultTimetable ||(isDefaultTimetable && this.props.timetable.planningType === PLANNING_TIMESLOT)) &&
                this.props.timetable.days.map((d, i) => {
                    return (
                        <div className="p-grid p-col-12" key={i}>
                            <div className="p-col-3"><span>{msg('station.day.' + d.key)}</span></div>
                            <div className="p-grid p-col-8" style={{padding: 0, margin: 0}}>
                            {d.timeslots.map((t, j) => {
                                return (
                                <div className="p-grid p-col-12" style={{padding: 0, margin: 0}} key={"day-" + i + "-" + j}>
                                    <div className="p-col-5">
                                        <InputMask value={t.start} mask="99:99" slotChar="00:00" readOnly={isDefaultTimetable} disabled={isDefaultTimetable || d.timeslots[0].closed} 
                                            onChange={e => this.handleTimeslotStartChange(e, i, j)} key={"startfld-" + i + "-" + j} />
                                    </div>
                                    <div className="p-col-5">
                                        <InputMask value={t.end} mask="99:99" slotChar="23:59" readOnly={isDefaultTimetable} disabled={isDefaultTimetable || d.timeslots[0].closed}
                                            onChange={e => this.handleTimeslotEndChange(e, i, j)} key={"endfld-" + i + "-" + j} />
                                    </div>
                                    <div className="p-col-2 timeslot-btn">
                                        {j === 0 &&
                                        <Button icon="pi pi-plus" title={(msg('station.day.timeslot.add.button.title'))} 
                                            onClick={e => this.addTimeslot(d)} disabled={isDefaultTimetable || !(this.isOneSlots(d))} key={"addBtn-" + i + "-" + j} />}
                                        {j === 1 && 
                                        <Button icon="pi pi-minus" title={(msg('station.day.timeslot.remove.button.title'))} 
                                            onClick={e => this.removeTimeslot(d)} disabled={isDefaultTimetable || !(this.isTowSlots(d))} key={"removeBtn-" + i + "-" + j} />}
                                    </div>
                                </div>)})}
                            </div>
                            <div className="p-col-1">
                                {!isDefaultTimetable &&
                                <Checkbox value={d.timeslots[0].closed} checked={d.timeslots[0].closed}
                                    onChange={e => this.handleClosedTimeslotChange(e, i)}
                                    name={"closedfld-" + i} id={"closedfld-" + i} inputId={"closedfld-" + i} key={"closedfld-" + i} />}
                            </div>
                        </div>);
                    })}
            </div>
        </div>)
    }
}