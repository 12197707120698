import { ofType} from 'redux-observable';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError} from  'rxjs/operators';
import {PRICEZONE, pricezonesSuccess, PRICEZONE_BY_ID, pricezoneByIdSuccess} from '../actions/pricezone';
import {faillure } from '../actions';
import { authProviderType } from '../AuthProviderType';

export const pricezonesEpic = (epicAction)  => {
	return  epicAction.pipe(
			ofType(PRICEZONE),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlPricezones = apiRootUri + "pricezone";
				const req = ajax({
					  	url: urlPricezones + "/pricezones?page=" + action.data.page+"&pageSize=" + action.data.pageSize,
					  	method: 'GET',				  
						headers: {
							'Content-Type': 'application/json'
						},
						withCredentials: true
					});					
				return req.pipe(
            			map(res => {return pricezonesSuccess(res);}),
            			catchError(res => { return of(faillure(res));})
            		)
			}),
	);
};

export const pricezoneByIdEpic = (epicAction)  => {
	return  epicAction.pipe(
			ofType(PRICEZONE_BY_ID),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlPricezones = apiRootUri + "pricezone";
				const req = ajax({
					  url: urlPricezones + '/' + action.data.id + '?page=' + action.data.page + '&pageSize=' + action.data.pageSize,
					  method: 'POST',					  
					  headers: {
							 'Content-Type': 'application/json'
						 },
					   withCredentials: true,
					   body: action.data.pricezoneId
					});
					
				return req.pipe(
            			map(res => {
							return pricezoneByIdSuccess(res);}),
            			catchError(res => { return of(faillure(res));})
            		)
			}),
	);	
};
