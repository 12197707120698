import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
    rolesValidator,
    ADMINISTRATION, EQUIPMENTS,
    USERS, TRADE, REPORTING, MAINTENANCE, NOTIFICATIONS, CAMPAIGNS,
    SERVER, GROUPS, ROLE, PRICEZONE, COMMISSIONS, 
    FINANCIALMANDATE, FIRMWARE, HARDWARE, ADMINEQUIPMENTS, STATIONS, STATIONGROUPS, TRADE_EXPLOIT, EXPLOITANTSREPORT
} from '../utils/RolesValidator.js';
import msg from '../texts/msg.js';

class CartadisSubmenu extends Component {
    static defaultProps = {
        className: null,
        items: null,
        onMenuItemClick: null,
        onRootItemClick: null,
        root: false,
        layoutMode: null,
        menuActive: false,
        parentMenuItemActive: false
    }
    static propTypes = {
        className: PropTypes.string,
        items: PropTypes.array,
        onMenuItemClick: PropTypes.func,
        onRootItemClick: PropTypes.func,
        root: PropTypes.bool,
        layoutMode: PropTypes.string,
        menuActive: PropTypes.bool,
        parentMenuItemActive: PropTypes.bool
    }
    constructor(props) {
        super(props);
        this.state = {};
    }
    onMenuItemClick(event, item, index) {
        //avoid processing disabled items
        if (item.disabled) {
            event.preventDefault();
            return true;
        }
        if (this.props.root && this.props.onRootItemClick) {
            this.props.onRootItemClick({
                originalEvent: event,
                item: item
            });
        }
        //execute command
        if (item.command) {
            item.command({ originalEvent: event, item: item });
        }
        if (index === this.state.activeIndex)
            this.setState({ activeIndex: null });
        else
            this.setState({ activeIndex: index });
        if (this.props.onMenuItemClick) {
            this.props.onMenuItemClick({
                originalEvent: event,
                item: item
            });
        }
    }
    onMenuItemMouseEnter(index) {
        if (this.props.root && this.props.menuActive && this.isHorizontalOrSlim()) {
            this.setState({ activeIndex: index });
        }
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.parentMenuItemActive === false) {
            return {
                activeIndex: null
            }
        }
        return null;
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.isHorizontalOrSlim() && prevProps.menuActive && !this.props.menuActive) {
            this.setState({ activeIndex: null });
        }
    }
    isHorizontalOrSlim() {
        return (this.props.layoutMode === 'horizontal' || this.props.layoutMode === 'slim');
    }
    renderLinkContent(item) {
        let submenuIcon = item.items && <i className="material-icons submenu-icon">keyboard_arrow_down</i>;
        let badge = item.badge && <span className="menuitem-badge">{item.badge}</span>;
        return (
            <React.Fragment>
                <i className="material-icons">{item.icon}</i>
                <span>{item.label}</span>
                {badge}
                {submenuIcon}
            </React.Fragment>
        );
    }

    renderLink(item, i) {
        let content = this.renderLinkContent(item);

        if (item.to) {
            return (
                <NavLink activeClassName="active-menuitem-routerlink" to={item.to} onClick={(e) => this.onMenuItemClick(e, item, i)} exact
                    target={item.target} onMouseEnter={(e) => this.onMenuItemMouseEnter(i)} className={item.styleClass}>{content}</NavLink>
            )
        }
        else {
            return (
                <a className="ripplelink" href={item.url} onClick={(e) => this.onMenuItemClick(e, item, i)} target={item.target}
                    onMouseEnter={(e) => this.onMenuItemMouseEnter(i)}>
                    {content}
                </a>
            );
        }
    }
    render() {
        var items = this.props.items && this.props.items.map((item, i) => {
            if ((item.features && !rolesValidator.validate(item.features)) || item.hidden === true) {
                return "";
            }
            let active = this.state.activeIndex === i;
            let styleClass = classNames(item.badgeStyleClass, { 'active-menuitem': active });
            let tooltip = this.props.root && <div className="layout-menu-tooltip">
                <div className="layout-menu-tooltip-arrow"></div>
                <div className="layout-menu-tooltip-text">{item.label}</div>
            </div>;

            return (
                <li className={styleClass} key={i}>
                    {this.renderLink(item, i)}
                    {tooltip}
                    <CartadisSubmenu items={item.items} onMenuItemClick={this.props.onMenuItemClick} layoutMode={this.props.layoutMode}
                        menuActive={this.props.menuActive} parentMenuItemActive={active} orientation="vertical"/>
                </li>
            )
        });

        return <ul className={this.props.className}>{items}</ul>;
    }
}
export class CartadisMenu extends Component {
    static defaultProps = {
        model: null,
        onMenuItemClick: null,
        onRootMenuItemClick: null,
        customize: null,
        layoutMode: null,
        active: false
    }
    static propTypes = {
        model: PropTypes.array,
        layoutMode: PropTypes.string,
        onMenuItemClick: PropTypes.func,
        onRootMenuItemClick: PropTypes.func,
        customize: PropTypes.func,
        active: PropTypes.bool
    }
    // changeTheme(theme) {
    //     this.changeStyleSheetUrl('layout-css', theme, 'layout');
    //     this.changeStyleSheetUrl('theme-css', theme, 'theme');
    // }
    // changeStyleSheetUrl(id, value, prefix) {
    //     let element = document.getElementById(id);
    //     let urlTokens = element.getAttribute('href').split('/');
    //     urlTokens[urlTokens.length - 1] = prefix + '-' + value + '.css';
    //     let newURL = urlTokens.join('/');
    //     element.setAttribute('href', newURL);
    // }

    render() {
        const menu = [
            {
                label: msg('menu.commerce'), icon: 'equalizer', features: [TRADE, TRADE_EXPLOIT],
                items: [
                    { label: msg('menu.commerce.dashbord'), icon: 'equalizer', to: '/commerce/dashboard', features: [TRADE]},
                    { label: msg('menu.commerce.analysis'), icon: 'input', to: '/commerce/analysis', features: [TRADE] },
                    { label: msg('menu.commerce.exploit'), icon: 'account_box', to: '/commerce/exploit', features: [TRADE_EXPLOIT]}
                ]
            },
            {
                label: msg('menu.maintenance'), icon: 'build', features: [MAINTENANCE, EQUIPMENTS, NOTIFICATIONS],
                items: [
                    { label: msg('menu.maintenance.dashbord'), icon: 'network_check', to: '/maintenance/dashboard', features: [MAINTENANCE] },
                    { label: msg('menu.maintenance.analysis'), icon: 'input', to: '/maintenance/analysis', features: [MAINTENANCE] },
                    /*{ label: msg('menu.maintenance.logs'), icon: 'grid_on', to: '/maintenance' },
                    { label: msg('menu.maintenance.intervention'), icon: 'security', to: '/maintenance' },
                    { label: msg('menu.maintenance.teleaction'), icon: 'system_update_alt', to: '/maintenance' },*/
                    { label: msg('menu.admin.equipements'), icon: 'dock', to: '/', features: [EQUIPMENTS] },                    
                    { label: msg('menu.maintenance.notification'), icon: 'notifications_active', to: '/notification', features: [NOTIFICATIONS] }
                ]
            },
            {
                label: msg('menu.admin'), icon: 'desktop_mac', features: [ADMINISTRATION, ADMINEQUIPMENTS, USERS, ROLE, GROUPS, PRICEZONE, 
                                                                            COMMISSIONS, FIRMWARE, HARDWARE, CAMPAIGNS],
                items: [                    
                    { label: msg('menu.admin.users.accounts'), icon: 'groups', features: [USERS, GROUPS, ROLE],
                        items: [
                            { label: msg('menu.admin.users'), icon: 'person', to: '/users', features: [USERS] },
                            { label: msg('menu.admin.groups'), icon: 'groups', to: '/groups', features: [GROUPS] },                  
                            { label: msg('menu.admin.roles'), icon: 'recent_actors', to: '/roles',  features: [ROLE] }
                        ]
                    },
                    { label: msg('menu.admin.station'), icon: 'business', features: [STATIONS, ADMINEQUIPMENTS, FIRMWARE, HARDWARE, CAMPAIGNS],
                        items: [                           
                            { label: msg('menu.admin.station'), icon: 'business', to: '/stations', features: [STATIONS]},
                            { label: msg('menu.admin.station.groups'), icon: 'business_center', to: '/stationgroups', features: [STATIONGROUPS]},
                            { label: msg('menu.admin.equipements'), icon: 'dock', to: '/equipment', features: [ADMINEQUIPMENTS]},
                            { label: msg('menu.maintenance.firmware'), icon: 'file_upload', to: '/firmwares', features: [FIRMWARE] },
                            { label: msg('menu.maintenance.hardware'), icon: 'file_upload', to: '/hardwares', features: [HARDWARE] },
                            { label: msg('menu.maintenance.campaign'), icon: 'system_update_alt', to: '/campaign/list', features: [CAMPAIGNS] },
                        ]
                    },
                    { label: msg('menu.admin.articles'), icon: 'local_car_wash', features: [PRICEZONE, COMMISSIONS],
                        items: [
                            { label: msg('menu.admin.pricezone'), icon: 'euro_symbol', to: '/pricezones', features: [PRICEZONE], style :'{color: red' },
                            { label: msg('menu.admin.commission'), icon: 'donut_large', to: '/commissions/list', features: [COMMISSIONS], style :'{color: red' }
                        ]
                    }
                ]
            },
            {
                label: msg('menu.report'), icon: 'assignment', features: [REPORTING, FINANCIALMANDATE, EXPLOITANTSREPORT],
                items: [
                    { label: msg('menu.report.excel'), icon: 'assignment', to: '/reporting/excel', features: [REPORTING] },
                    { label: msg('menu.report.mandat'), icon: 'monetization_on', to: '/reporting/mandate', features: [FINANCIALMANDATE] },
                    { label: msg('menu.report.exploitants'), icon: 'assignment', to: '/reportingExploitant/daily', features: [EXPLOITANTSREPORT] }
                ]
            },
            {
                label: msg('menu.server'), icon: 'settings', features: [SERVER], //Admins Cartadis
                items: [
                    // { label: msg('menu.server.setting'), icon: 'settings', to: '/server/settings' },
                    { label: msg('menu.server.systemevents'), icon: 'grid_on', to: '/server/systemevents' }
                ]
            },
            {
                label: msg('menu.support'), icon: 'phone', features: [EQUIPMENTS, ADMINEQUIPMENTS],
                items: [
                    { label: msg('menu.support.borne'), icon: 'dock', to: '/support/borne' }
                ],
                hidden: !rolesValidator.isCartadisOnly()
            },
            // {
            //     label: 'Themes', icon: 'palette', badge: '6', features: [CUSTOMIZATION],
            //     items: [
            //         { label: 'Indigo - Pink', icon: 'brush', command: (event) => { this.changeTheme('indigo') } },
            //         { label: 'Brown - Green', icon: 'brush', command: (event) => { this.changeTheme('brown') } },
            //         { label: 'Blue - Amber', icon: 'brush', command: (event) => { this.changeTheme('blue') } },
            //         { label: 'Blue Grey - Green', icon: 'brush', command: (event) => { this.changeTheme('blue-grey') } },
            //         { label: 'Dark - Blue', icon: 'brush', command: (event) => { this.changeTheme('dark-blue') } },
            //         { label: 'Dark - Green', icon: 'brush', command: (event) => { this.changeTheme('dark-green') } },
            //         { label: 'Green - Yellow', icon: 'brush', command: (event) => { this.changeTheme('green') } },
            //         { label: 'Purple - Cyan', icon: 'brush', command: (event) => { this.changeTheme('purple-cyan') } },
            //         { label: 'Purple - Amber', icon: 'brush', command: (event) => { this.changeTheme('purple-amber') } },
            //         { label: 'Teal - Lime', icon: 'brush', command: (event) => { this.changeTheme('teal') } },
            //         { label: 'Cyan - Amber', icon: 'brush', command: (event) => { this.changeTheme('cyan') } },
            //         { label: 'Grey - Deep Orange', icon: 'brush', command: (event) => { this.changeTheme('grey') } }
            //     ]
            // },
            // {
            //     label: 'Customization', icon: 'settings_application', features: [CUSTOMIZATION],
            //     items: [
            //         { label: 'Static Menu', icon: 'menu', command: () => this.props.customize({ layoutMode: 'static' }) },
            //         { label: 'Overlay Menu', icon: 'exit_to_app', command: () => this.props.customize({ layoutMode: 'overlay' }) },
            //         { label: 'Slim Menu', icon: 'more_vert', command: () => this.props.customize({ layoutMode: 'slim' }) },
            //         { label: 'Horizontal Menu', icon: 'border_horizontal', command: () => this.props.customize({ layoutMode: 'horizontal' }) },
            //         { label: 'Light Menu', icon: 'label_outline', command: () => this.props.customize({ darkMenu: false }) },
            //         { label: 'Dark Menu', icon: 'label', command: () => this.props.customize({ darkMenu: true }) },
            //         { label: 'Inline Profile', icon: 'contacts', command: () => this.props.customize({ profileMode: 'inline' }) },
            //         { label: 'Top Profile', icon: 'person_pin', command: () => this.props.customize({ profileMode: 'top' }) },
            //     ]
            // },
            {
                label: msg('menu.user.manual'), icon: 'school', command:()=>{ window.open("/assets/resource/Documentation.pdf", "_blank");}
            }
        ];
        return <CartadisSubmenu items={menu} className="ultima-menu ultima-main-menu clearfix"
            menuActive={this.props.active} onRootItemClick={this.props.onRootMenuItemClick}
            onMenuItemClick={this.props.onMenuItemClick} root={true} layoutMode={this.props.layoutMode} parentMenuItemActive={true} />
    }
}