export const LOAD_STATIONS = "LOAD_STATIONS";
export const LOAD_STATIONS_SUCCESS = "LOAD_STATIONS_SUCCESS";
export const LOAD_STATION = "LOAD_STATION";
export const LOAD_STATION_SUCCESS = "LOAD_STATION_SUCCESS";
export const ADD_STATION = "ADD_STATION";
export const ADD_STATION_SUCCESS = "ADD_STATION_SUCCESS";
export const STATION = "STATION";
export const STATION_SUCCESS = "STATION_SUCCESS";
export const STATION_UPDATE = 'STATION_UPDATE';
export const STATION_UPDATE_SUCCESS = 'STATION_UPDATE_SUCCESS';
export const CHANGE_STATION_CONTRACT = 'CHANGE_STATION_CONTRACT';
export const CHANGE_STATION_CONTRACT_SUCCESS = 'CHANGE_STATION_CONTRACT_SUCCESS';
export const STATION_DELETE_BY_ID ='STATION_DELETE_BY_ID';
export const STATION_DELETE_BY_ID_SUCCESS ='STATION_DELETE_BY_ID_SUCCESS';
export const LOAD_STATIONS_MAP = "LOAD_STATIONS_MAP";
export const LOAD_STATIONS_MAP_SUCCESS = "LOAD_STATIONS_MAP_SUCCESS";
export const UPDATE_CONFIG_STATION = "UPDATE_CONFIG_STATION";
export const UPDATE_CONFIG_STATION_SUCCESS = "UPDATE_CONFIG_STATION_SUCCESS";
export const LOAD_STATION_PRICEZONES = "LOAD_STATION_PRICEZONES";
export const LOAD_STATION_PRICEZONES_SUCCESS = "LOAD_STATION_PRICEZONES_SUCCESS";
export const LOAD_STATION_COMMISSIONS = "LOAD_STATION_COMMISSIONS";
export const LOAD_STATION_COMMISSIONS_SUCCESS = "LOAD_STATION_COMMISSIONS_SUCCESS";

export const loadStations = (filter) => {
	return ({
		type: LOAD_STATIONS,
		data: {filter: filter},
		errorMessage: null,
		errorStatus: null
	});
};

export const loadStationsSuccess = (r) => {	
	return ({
		type: LOAD_STATIONS_SUCCESS,
		data: r.response ,
		errorMessage: null,
		errorStatus: null
	});
};

export const addStation = (station) => {
	return ({
		type: ADD_STATION,
		data: { station: station },
		errorMessage: null,
		errorStatus: null
	});
};

export const addStationSuccess = (r) => {
	return ({
		type: ADD_STATION_SUCCESS,
		data: r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const stationUpdate = (stationId, station) => { 
	return ({
		type: STATION_UPDATE,
		data: {stationId:stationId, station: station},
		errorMessage: null,
		errorStatus: null
	});
};

export const stationUpdateSuccess = (r) => {
	return ({
		type: STATION_UPDATE_SUCCESS,
		data: r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const changeStationContract = (stationId) => { 
	return ({
		type: CHANGE_STATION_CONTRACT,
		data: {stationId: stationId},
		errorMessage: null,
		errorStatus: null
	});
};

export const changeStationContractSuccess = (r) => {
	return ({
		type: CHANGE_STATION_CONTRACT_SUCCESS,
		data: r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const stationDeleteById = (stationId) => { 
	return ({
		type: STATION_DELETE_BY_ID,
		data: {stationId: stationId},
		errorMessage: null,
		errorStatus: null
	});
};

export const stationDeleteByIdSuccess = (r) => {
	return ({
		type: STATION_DELETE_BY_ID_SUCCESS,
		data: r.response,
		errorMessage: null,
		errorStatus: null
	});

};

export const station = (implant) => {
	return ({
			type: STATION,
			data: { implant: implant },
			errorMessage: null,
			errorStatus: null
	});
};
export const stationSuccess = (r) => {
	return ({
			type: STATION_SUCCESS,
			data: r.response,
			errorMessage: null,
			errorStatus: null
	});
};

export const loadStationsMap = (feature, filter) => {
	return ({
		type: LOAD_STATIONS_MAP,
		data: {filter: filter, feature: feature},
		errorMessage: null,
		errorStatus: null
	});
};

export const loadStationsMapSuccess = (r) => {
	return ({
		type: LOAD_STATIONS_MAP_SUCCESS,
		data: r.response ,
		errorMessage: null,
		errorStatus: null
	});
};

export const updateConfigStation = (stationId) => {
	return ( {
		type : UPDATE_CONFIG_STATION,
		data : {stationId: stationId},
		errorMessage: null,
		errorStatus: null
	});
};

export const updateConfigStationSuccess = (r) => {
	return ( {
		type : UPDATE_CONFIG_STATION_SUCCESS,
		data : r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const loadStationPriceZones = (page, pageSize) => {
	return ({
		type: LOAD_STATION_PRICEZONES,
		data: { page: page, pageSize: pageSize},
		errorMessage: null,
		errorStatus: null
	});
};
export const loadStationPriceZonesSuccess = (r) => {
	return ({
		type: LOAD_STATION_PRICEZONES_SUCCESS,
		data : r.response ? r.response.pricezones : [],
		errorMessage: null,
		errorStatus: null
	});
};

export const loadStationCommissions = (page, pageSize) => {
	return ( {
		type : LOAD_STATION_COMMISSIONS,
		data : {page: page, pageSize: pageSize},
		errorMessage: null,
		errorStatus: null
	});
};

export const loadStationCommissionsSuccess = (r) => {
	return ( {
		type : LOAD_STATION_COMMISSIONS_SUCCESS,
		data : r.response ? r.response.commissions : [],
		errorMessage: null,
		errorStatus: null
	});
};