class ProgressSpinnerHandler {
    main = {};
    init(main){
        this.main = main;
    }
    
    show(show) {
        if(this.main){
            this.main.showProgress(show);
        }
    }

    isVisible() {
        return this.main && this.main.state && this.main.state.showProgress
    }
}

export const progressSpinnerHandler = new ProgressSpinnerHandler();