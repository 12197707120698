import { LOGIN, LOGOUT, LOGIN_SUCCESS, LOGOUT_SUCCESS, REDIRECT_TO_PROVIDER_SUCCESS, REDIRECT_TO_PROVIDER, REDIRECT_TO_API_SERVER, REDIRECT_TO_API_SERVER_SUCCESS, LOAD_ROLEFEATURES, LOAD_ROLEFEATURES_SUCCESS} from '../actions/login';

const state0 = {data: null, error: null, logedIn: false, logedOut: true};

export const loginReducer = (state = state0, action) => {
	switch (action.type) {
		case LOGIN:
			return {
				state,
				error: null
		};
		case LOGIN_SUCCESS:
			return {
				state,
				data: action.data,
				logedIn: action.logedIn,
				error: null
        };
		default: 
			return state;
	}
};

export const logoutReducer = (state = state0, action) => {
	switch (action.type) {
		case LOGOUT:
			return {
				state,
				'error': null
		};
        case LOGOUT_SUCCESS:
			return {
				state,
				'data': action.data,
				'logedOut': action.logedOut,
				'error': null
		};
		default: 
			return state;
	}
};

export const loadRoleFeaturesReducer = (state = state0, action) => {
	switch (action.type) {
		case LOAD_ROLEFEATURES:
			return {
				state,
				error: null
		};
		case LOAD_ROLEFEATURES_SUCCESS:
			return {
				state,
				data: action.data,
				logedIn: action.logedIn,
				error: null
        };
		default: 
			return state;
	}
};

export const redirectToProviderReducer = (state = state0, action) => {
	switch (action.type) {
		case REDIRECT_TO_PROVIDER:
			return {
				state,
				'error': null
		};
        case REDIRECT_TO_PROVIDER_SUCCESS:
			return {
				state,
				'data': action.data,
				'error': null
		};
		default: 
			return state;
	}
};

export const redirectToApiServerReducer = (state = state0, action) => {
	switch (action.type) {
		case REDIRECT_TO_API_SERVER:
			return {
				state,
				'error': null
		};
        case REDIRECT_TO_API_SERVER_SUCCESS:
			return {
				state,
				'data': action.data,
				'error': null
		};
		default: 
			return state;
	}
};