import { USERS, USER, USER_SUCCESS, USERS_SUCCESS, ADD_USER, ADD_USER_SUCCESS, 
	  LOAD_GROUPS, LOAD_GROUPS_SUCCESS, USER_DELETE_BY_ID_SUCCESS, USER_DELETE_BY_ID,
	  PASSWORD_UPDATE, PASSWORD_UPDATE_SUCCESS, USER_UPDATE, USER_UPDATE_SUCCESS, 
	  MY_PASSWORD_UPDATE, MY_PASSWORD_UPDATE_SUCCESS, GENERATE_BORNE_ACCESS_CODE,
	  GENERATE_BORNE_ACCESS_CODE_SUCCESS } from '../actions/user';

const state0 = {data: null, error: null};

export  const usersReducer = (state = state0, action) => {
	switch (action.type) {
		case USERS:
			return {
				state,
				error: null
		};
		case USERS_SUCCESS:			
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};


export  const userReducer = (state = state0, action) => {
	switch (action.type) {
		case USER:
			return {
				state,
				error: null
		};
		case USER_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};

export  const addUserReducer = (state = state0, action) => {
	switch (action.type) {
		case ADD_USER:
			return {
				state,
				error: null
		};
		case ADD_USER_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};

export const loadGroupsReducer = (state = state0, action) => {
	switch (action.type) {
		case LOAD_GROUPS:
			return {
				state,
				error: null
		};
		case LOAD_GROUPS_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};

export  const userDeleteByIdReducer = (state = state0, action) => {
	switch (action.type) {
		case USER_DELETE_BY_ID:
			return {
				state,
				error: null
		};
		case USER_DELETE_BY_ID_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};
export  const userUpdateReducer = (state = state0, action) => {
	switch (action.type) {
		case USER_UPDATE:
			return {
				state,
				error: null
		};
		case USER_UPDATE_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};

export  const passwordUpdateReducer = (state = state0, action) => {
	switch (action.type) {
		case PASSWORD_UPDATE:
			return {
				state,
				error: null
			};
		case PASSWORD_UPDATE_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
			};
		default: 
			return state;
	}
};

export  const myPasswordUpdateReducer = (state = state0, action) => {
	switch (action.type) {
		case MY_PASSWORD_UPDATE:
			return {
				state,
				error: null
			};
		case MY_PASSWORD_UPDATE_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
			};
		default: 
			return state;
	}
};

export  const generateBorneAccessCodeReducer = (state = state0, action) => {
	switch (action.type) {
		case GENERATE_BORNE_ACCESS_CODE:
			return {
				state,
				error: null
			};
		case GENERATE_BORNE_ACCESS_CODE_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
			};
		default: 
			return state;
	}
};