export const CONFIG_MATERIELS='CONFIG_MATERIELS';
export const CONFIG_MATERIELS_SUCCESS= 'CONFIG_MATERIELS_SUCCESS';
export const CONFIG_MATERIEL='CONFIG_MATERIEL';
export const CONFIG_MATERIEL_SUCCESS= 'CONFIG_MATERIEL_SUCCESS';
export const CONFIG_MATERIEL_DELETE_BY_ID='CONFIG_MATERIEL_DELETE_BY_ID';
export const CONFIG_MATERIEL_DELETE_BY_ID_SUCCESS= 'CONFIG_MATERIEL_DELETE_BY_ID_SUCCESS';


export const configMateriels = (page, pageSize, filter) => {
	return ({
		type: CONFIG_MATERIELS,
		data: {page: page,  pageSize: pageSize, filter: filter},
		errorMessage: null,
		errorStatus: null
	});
};
export const configMaterielsSuccess = (r) => {
	return ({
		type: CONFIG_MATERIELS_SUCCESS,
		data: r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const configMateriel = (hardwareId) => {
	return ({
		type: CONFIG_MATERIEL,
		data: { hardwareId: hardwareId },
		errorMessage: null,
		errorStatus: null
	});
};
export const configMaterielSuccess = (r) => {
	return ({
		type: CONFIG_MATERIEL_SUCCESS,
		data: r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const configMaterielDeleteById = (hardwareId) => { 
	return ({
		type: CONFIG_MATERIEL_DELETE_BY_ID,
		data: {hardwareId: hardwareId},
		errorMessage: null,
		errorStatus: null
	});
};

export const configMaterielDeleteByIdSuccess = (r) => {
	return ({
		type: CONFIG_MATERIEL_DELETE_BY_ID_SUCCESS,
		data: r.response,
		errorMessage: null,
		errorStatus: null
	});
};