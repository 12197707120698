import { ofType} from 'redux-observable';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError} from  'rxjs/operators';
import {GROUPS, groupsSuccess, GROUP_BY_ID, groupByIdSuccess, ADD_GROUP, addGroupSuccess, GROUP, groupSuccess, GROUP_UPDATE, groupUpdateSuccess, GROUP_DELETE_BY_ID, groupDeleteByIdSuccess} from '../actions/group';
import {faillure } from '../actions';
import { authProviderType } from '../AuthProviderType';


export const groupsEpic = (epicAction)  => {
	return  epicAction.pipe(
			ofType(GROUPS),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlGroups = apiRootUri + "groups";
				const req = ajax({
					  	url: urlGroups + "/groups?page=" + action.data.page+"&pageSize=" + action.data.pageSize,
					  	method: 'POST',				  
						headers: {
							'Content-Type': 'application/json'
						},
						withCredentials: true,
						body: action.data.groupFilterDto
					});					
				return req.pipe(
            			map(res => {return groupsSuccess(res);}),
            			catchError(res => { return of(faillure(res));})
            		)
			}),
	);
};

export const groupByIdEpic = (epicAction)  => {
	return  epicAction.pipe(
			ofType(GROUP_BY_ID),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlGroups = apiRootUri + "groups";
				const req = ajax({
					  url: urlGroups + '/' + action.data.id + '?page=' + action.data.page + '&pageSize=' + action.data.pageSize,
					  method: 'POST',					  
					  headers: {
							 'Content-Type': 'application/json'
						 },
					   withCredentials: true,
					   body: action.data.groupFilterDto
					});
					
				return req.pipe(
            			map(res => {
							return groupByIdSuccess(res);}),
            			catchError(res => { return of(faillure(res));})
            		)
			}),
	);	
};

export const addGroupEpic = (epicAction)  => {
	return  epicAction.pipe(
			ofType(ADD_GROUP),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlGroups = apiRootUri + "groups";
				const req = ajax({
					  	url: urlGroups + '/group',
					  	method: 'POST',				  
						headers: {
							'Content-Type': 'application/json'
						},
						withCredentials: true,
						body: action.data.group
					});
					
				return req.pipe(
            			map(res => {
							return addGroupSuccess(res);}),
            			catchError(res => { return of(faillure(res));})
            		)
			}),
	);
	
};

export const groupEpic = (epicAction)  => {
	return  epicAction.pipe(
			ofType(GROUP),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlGroups = apiRootUri + "groups";
				const req = ajax({
					  	url: urlGroups + "/" + action.data.groupId,
					  	method: 'GET',				  
						headers: {
							'Content-Type': 'application/json'
						},
						withCredentials: true,
					});				
				return req.pipe(
            			map(res => {return groupSuccess(res);}),
            			catchError(res => { return of(faillure(res));})
            		)
			}),
	);	
};

export const groupUpdateEpic = (epicAction)  => {
	return  epicAction.pipe(
			ofType(GROUP_UPDATE),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlGroups = apiRootUri + "groups";
				const req = ajax({
						url: urlGroups + "/" + action.data.groupId,
					  	method: 'PUT',
					  	headers: {
							'Content-Type': 'application/json'
						},
						withCredentials: true,
					  	body: action.data.group
					});
				return req.pipe(
            			map(res => { return groupUpdateSuccess(res);}),
            			catchError(res => { return of(faillure(res));})
            		)
			}),
	);
};

export const groupDeleteByIdEpic = (epicAction)  => {
	return  epicAction.pipe(
			ofType(GROUP_DELETE_BY_ID),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlGroups = apiRootUri + "groups";
				const req = ajax({
					url: urlGroups + "/" +action.data.groupId,
					method: 'DELETE',					  
					headers: {
					  'Content-Type': 'application/json'
				  },
				  withCredentials: true,
			 	});
				return req.pipe(
					map(res => {return groupDeleteByIdSuccess(res);}),
					catchError(res => { return of(faillure(res));})
				)
			}),
	);	

};