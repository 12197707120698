import { combineReducers } from 'redux';
import { loginReducer, logoutReducer, redirectToProviderReducer, redirectToApiServerReducer, loadRoleFeaturesReducer } from './login';
import { commerceDashbordReducer, commerceAnalysisReducer, commerceExploitAnalysisReducer } from './commerce';
import {
	loadFilterReducer, loadFilterStationsReducer, loadFilterDodoStationsReducer, loadFilterStationsWithContractsReducer, loadSystemEventsFilterReducer, loadFilterExploitantsReportsReducer
} from './filter';
import {
	equipmentsReducer, equipmentByIdReducer, closeAlarmReducer, qrCodeSendByIdReducer, loadServiceInformationsReducer, loadOpenAlarmsReducer
} from './equipments';
import {
	adminequipmentsReducer, createEquipmentReducer,
	equipmentDeleteByIdReducer, equipmentUpdateReducer, equipmentReducer, updateBorneReducer
} from './adminequipments'
import {
	usersReducer, userReducer, addUserReducer, loadGroupsReducer, userDeleteByIdReducer,
	passwordUpdateReducer, userUpdateReducer, myPasswordUpdateReducer, generateBorneAccessCodeReducer
} from './user';
import { systemEventsReducer } from './server';
import { FAILLURE } from '../actions';
import { LOGOUT_SUCCESS } from '../actions/login';
import {
	notificationsLoadReducer, notificationLoadByIdReducer,
	notificationSendReducer, notificationSendByIdReducer,
	notificationCreateReducer, notificationUpdateReducer, notificationDeleteByIdReducer,
	loadCampaignsReducer, createCampaignReducer, updateCampaignReducer,
	loadFirmwaresReducer, loadHardwaresReducer, loadPriceZonesReducer, loadStationEquipmentsReducer,
	loadCampaignReducer, launchCampaignReducer, deleteCampaignReducer, loadBornesStatusReducer,
	loadBornesAnalysisReducer,
	loadDefaultSubscribersReducer,
	loadCampaignEventsReducer,
} from './maintenance';

import {
	loadStationsReducer, addStationReducer, stationUpdateReducer,
	stationDeleteByIdReducer, stationReducer, loadStationsMapReducer, updateConfigStationReducer, changeStationContractReducer, loadStationPriceZonesReducer, loadStationCommissionsReducer
} from './station'
import { groupsReducer, groupByIdReducer, addGroupReducer, groupReducer, groupUpdateReducer, groupDeleteByIdReducer } from './group';
import { pricezonesReducer, pricezoneByIdReducer } from './pricezone';
import { authorisationsReducer, addRoleReducer, loadRoleReducer, rolesReducer, roleDeleteByIdReducer, roleReducer, roleUpdateReducer } from './role';
import { loadCommissionsReducer, loadCommissionReducer, createCommissionReducer, updateCommissionReducer, deleteCommissionReducer } from './commission';
import { configMaterielsReducer, configMaterielReducer, configMaterielDeleteByIdReducer } from './hardwares'
import {
	loadStationGroupsReducer, loadStationGroupReducer, loadStationGroupStationsReducer,
	createStationGroupReducer, updateStationGroupReducer, deleteStationGroupReducer
} from './stationgroup';
import { firmwareDeleteByIdReducer, firmwareReducer, firmwaresReducer } from './firmwares';
import { checkNetworkSpeedReducer, rebootReducer, getBorneLogsReducer, loadBornesReducer, disassociateBorneReducer } from './borne';
import { onPageSizeChangeReducer, onFilterReducer } from './userSessionParams';
import { toTimestamp } from '../../utils/dateTimeUtils';

const state0 = { data: null, error: null };

const faillureReducer = (state = state0, action) => {
	switch (action.type) {
		case FAILLURE:
			return {
				'data': null,
				'error': {
					'status': action.errorStatus,
					'message': action.errorMessage,
					'source': action.errorSource,
					'timestamp': toTimestamp(action.errorTimestamp),
					'params' : action.errorParams,
					'url': action.errorUrl
				}
			};
		default:
			return state;
	}
};

const combinedReducer = combineReducers(
	{
		commerceDashbord: commerceDashbordReducer,
		commerceAnalysis: commerceAnalysisReducer,
		commerceExploitAnalysis: commerceExploitAnalysisReducer,
		loadFilter: loadFilterReducer,
		loadFilterStations: loadFilterStationsReducer,
		loadFilterStationsWithContracts : loadFilterStationsWithContractsReducer,
		loadFilterDodoStations : loadFilterDodoStationsReducer,
		loadFilterExploitantsReports: loadFilterExploitantsReportsReducer,
		loadSystemEventsFilter: loadSystemEventsFilterReducer,
		equipments: equipmentsReducer,
		equipmentById: equipmentByIdReducer,
		loadBornes: loadBornesReducer,
		disassociateBorne: disassociateBorneReducer,
		loadServiceInformations: loadServiceInformationsReducer,
		loadOpenAlarms: loadOpenAlarmsReducer,
		systemEvents: systemEventsReducer,
		login: loginReducer,
		logout: logoutReducer,
		loadRoleFeatures: loadRoleFeaturesReducer,
		redirectToProvider: redirectToProviderReducer,
		redirectToApiServer: redirectToApiServerReducer,
		faillure: faillureReducer,
		users: usersReducer,
		user: userReducer,
		addUser: addUserReducer,
		loadNotifications: notificationsLoadReducer,
		loadNotificationById: notificationLoadByIdReducer,
		loadDefaultSubscribers: loadDefaultSubscribersReducer,
		sendNotification: notificationSendReducer,
		sendNotificationById: notificationSendByIdReducer,
		createNotification: notificationCreateReducer,
		updateNotification: notificationUpdateReducer,
		deleteNotificationById: notificationDeleteByIdReducer,
		loadGroups: loadGroupsReducer,
		userDeleteById: userDeleteByIdReducer,
		updatePassword: passwordUpdateReducer,
		updateMyPassword: myPasswordUpdateReducer,
		userUpdate: userUpdateReducer,
		generateBorneAccessCode: generateBorneAccessCodeReducer,
		loadCampaigns: loadCampaignsReducer,
		loadCampaign: loadCampaignReducer,
		loadFirmwares: loadFirmwaresReducer,
		loadHardwares: loadHardwaresReducer,
		loadPriceZones: loadPriceZonesReducer,
		loadStationEquipments: loadStationEquipmentsReducer,
		loadCampaignEvents: loadCampaignEventsReducer,
		createCampaign: createCampaignReducer,
		updateCampaign: updateCampaignReducer,
		launchCampaign: launchCampaignReducer,
		deleteCampaign: deleteCampaignReducer,
		loadCommissions: loadCommissionsReducer,
		loadCommission: loadCommissionReducer,
		createCommission: createCommissionReducer,
		updateCommission: updateCommissionReducer,
		deleteCommission: deleteCommissionReducer,
		groups: groupsReducer,
		groupById: groupByIdReducer,
		authorisations: authorisationsReducer,
		addRole: addRoleReducer,
		alarmClose: closeAlarmReducer,
		loadRole: loadRoleReducer,
		addGroup: addGroupReducer,
		group: groupReducer,
		groupUpdate: groupUpdateReducer,
		groupDeleteById: groupDeleteByIdReducer,
		roles: rolesReducer,
		roleDeleteById: roleDeleteByIdReducer,
		role: roleReducer,
		roleUpdate: roleUpdateReducer,
		pricezones: pricezonesReducer,
		pricezoneById: pricezoneByIdReducer,
		adminequipments: adminequipmentsReducer,
		createEquipment: createEquipmentReducer,
		equipmentDeleteById: equipmentDeleteByIdReducer,
		loadBornesStatus: loadBornesStatusReducer,
		loadBornesAnalysis: loadBornesAnalysisReducer,
		equipmentUpdate: equipmentUpdateReducer,
		equipment: equipmentReducer,
		loadStationsMap: loadStationsMapReducer,
		loadStations: loadStationsReducer,
		loadStationPriceZones: loadStationPriceZonesReducer,
		loadStationCommissions: loadStationCommissionsReducer,
		loadStationGroups: loadStationGroupsReducer,
		loadStationGroup: loadStationGroupReducer,
		loadStationGroupStations: loadStationGroupStationsReducer,
		createStationGroup: createStationGroupReducer,
		updateStationGroup: updateStationGroupReducer,
		deleteStationGroup: deleteStationGroupReducer,
		configMateriels: configMaterielsReducer,
		configMateriel: configMaterielReducer,
		configMaterielDeleteById: configMaterielDeleteByIdReducer,
		addStation: addStationReducer,
		stationUpdate: stationUpdateReducer,
		changeStationContract: changeStationContractReducer,
		stationDeleteById: stationDeleteByIdReducer,
		station: stationReducer,
		updateBorne: updateBorneReducer,
		firmwares: firmwaresReducer,
		firmware: firmwareReducer,
		firmwareDeleteById: firmwareDeleteByIdReducer,
		reboot: rebootReducer,
		getBorneLogs: getBorneLogsReducer,
		qrCodeSend: qrCodeSendByIdReducer,
		updateConfigStation: updateConfigStationReducer,
		onPageSizeChange: onPageSizeChangeReducer,
		onFilterChange: onFilterReducer,	
		checkNetworkSpeed: checkNetworkSpeedReducer,	
	}
);
const rootReducer = (state, action) => {
	if (action.type === LOGOUT_SUCCESS) {
		state = {};
		document.location = '/';
	} else if (action.type === FAILLURE && action.errorStatus === 401) {
		//Empty the store, no token in the store.
		if (action.errorStatus === 401) {
			state = {};
		}
	}
	return combinedReducer(state, action);
}
export default rootReducer;