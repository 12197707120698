import React, { Component } from 'react';
import Filter from './Filter';
import { Panel } from 'primereact/panel';
import TableData from '../../utils/TableData';
import { userSessionParamsHandler } from '../../utils/UserSessionParamsHandler';
import { SERVER } from '../../utils/RolesValidator.js';
import { toDefaultDateFormat } from '../../utils/dateTimeUtils';
import msg from '../../texts/msg';
import downloadUploadFilesService from '../../middleware/download-upload-files.service';
import { progressSpinnerHandler } from '../../utils/ProgressSpinnerHandler';

class SystemEvents extends Component {

    constructor(props) {
        super(props);
        this.state = {
            filter: {},
            startDate: new Date(),
            endDate: new Date()
        };
        this.loadSystemEvents = this.loadSystemEvents.bind(this);
        this.searcheBykeyWord = this.searcheBykeyWord.bind(this);
    }

    componentDidMount() {
        progressSpinnerHandler.show(false);
        this.props.loadSystemEventsFilter(SERVER);
    }

    componentDidUpdate(prevProps) {
        if(this.props.data && this.props.data !== prevProps.data){
            progressSpinnerHandler.show(false);
        }
    }

    loadSystemEvents(filter) {
        this.setState({ filter: filter });
        this.props.setDataInfoBar(this.dataInfobar(filter));
        this.props.load(0, userSessionParamsHandler.getPageSize(), filter);
        progressSpinnerHandler.show(true);
    }

    dataInfobar(filter) {
        if (filter != null) {
            let typeEventSystem = filter.typeEventSystem != null ? this.getTypeSystemEvent(filter, null) : null;
            let eventSystem = filter.systemEvent != null || filter.userEvent != null ? this.getSystemEvent(filter, null) : null;
            return [
                { key: "titleFilter", value: msg('filter.in.progress') },
                { key: "startDate", value: msg('filter.date.from').toLowerCase() + "   " + toDefaultDateFormat(new Date(filter.startDate)) },
                { key: "endDate", value: msg('filter.date.to').toLowerCase() + "  " + toDefaultDateFormat(new Date(filter.endDate)) + (typeEventSystem != null ? ";" : "") },
                { key: "Type", value: eventSystem ? typeEventSystem + " - " + eventSystem : typeEventSystem}
            ]
        }
        else return [{ key: "titleFilter", value: " " }]
    }

    getSystemEvent(rowData, column) {
        let systemEvent = rowData.type ? rowData.type : rowData.systemEvent ? rowData.systemEvent : "";
        let userEvent = rowData.type ? rowData.type : rowData.userEvent ? rowData.userEvent : "";
        let type = rowData.type ? rowData.type : rowData.typeEventSystem ? rowData.typeEventSystem : "";
        if(type === 'SYSTEM') {
            return msg('filter.eventsysteme.' + systemEvent);
        } else if(type === 'USER') {
            return msg('filter.eventsysteme.' + userEvent);
        } else {
            return ' '
        }
    }
    getTypeSystemEvent(rowData, column) {
        let type = msg('filter.item.' + (rowData.type ? rowData.type : rowData.typeEventSystem ? rowData.typeEventSystem : "").toLowerCase())
        return <div>{type} </div>
    }
    getCreationDate(rowData, column){
        return <div>{rowData.creationDate} </div>
    }
    getSource(rowData, column) {
        return <div>{msg(rowData.source)} </div>
    }
    getDescription(rowData, column) {
        return <div>{msg(rowData.description)} </div>
    }
    getComment(rowData, column) {
        let message = ''
        let parameters = [];
        if (rowData.parameters) {
            parameters = rowData.parameters.split(';')
        }
        if (!rowData.comments && rowData.parameters) {
            message = parameters[0]
        } else {
            if (parameters.length > 0) {
                let params = {};
                let i = 0;
                for (i = 0; i < parameters.length; i++) {
                    params["p" + i] = parameters[i];
                }
                message = msg(rowData.comments, params)
            } else {
                message = msg(rowData.comments)
            }
        }
        return <div>{message} </div>
    }

    getTargetObject = (rowData) => {
        let item = "";
        let filename = "";
        let exportUrl = "";
        if(rowData.targetObjectId) {
            switch(rowData.type) {
                case "AUTOMATEDFLOWS": {
                    filename = (rowData.source.toUpperCase().startsWith("PAYMENTFLOW") ?
                    msg('paymentflow.stations.anomalies.filename') :
                    msg('synchro.stations.anomalies.filename'))
                    + '.xlsx';
                    exportUrl = (rowData.source.toUpperCase().startsWith("PAYMENTFLOW") ?
                    "server/payment-flow-events-xls/" :
                    "server/synchro-mrnstation-events-xls/")                
                    + rowData.targetObjectId;
                    break;
                }
                case "USER": {
                    if (rowData.description === "user.users.import"){
                        filename =  msg('rejected.users.import.filename') + '.xlsx';
                        exportUrl =  ("server/download-rejected-users-xlsx/")  + rowData.targetObjectId;
                    }
                    break;
                }
                case "SYSTEM": {
                    if (rowData.description && rowData.description.startsWith("campaign" )){
                        filename =  'campaign-report.xlsx';
                        exportUrl =  "campaigns/"  + rowData.targetObjectId + "/report";
                    }
                    break;
                }
                default:
            }
            if(exportUrl && filename) {
                item = (<input id="image" type="image" src={"/assets/ultima/layout/images/cartadis/excel.svg"}
                    style={{ marginBottom: '-5px', height: '25px' }} alt={msg('event.type.target')}
                    onClick={() => { downloadUploadFilesService.getFileData(exportUrl, filename, {});} } />);
            }       
        }
        return item;
    }

    searcheBykeyWord(rechecheObject) {
            let lFilter = Object.assign({}, this.state.filter);
            lFilter.label = rechecheObject.idSearch;
            this.loadSystemEvents(lFilter);
    }

    render() {
        let filter = (<div className="p-col-12"><Filter apply={this.loadSystemEvents} filterData={this.props.systemEventsFilterData} showDate={true} /> </div>);
        let eventsColumns = [
            { isColWithBody: true, field: "creationDate", header: msg('event.type.date'), body: this.getCreationDate, sortable: false},
            { isColWithBody: true, field: "type", header: msg('event.type.type'), body: this.getTypeSystemEvent, sortable: false},
            { isColWithBody: true, field: "source", header: msg('event.type.source'), body: this.getSource, sortable: false},
            { isColWithBody: true, field: "description", header: msg('event.type.description'), body: this.getDescription, sortable: false, className: "p-col-3" },
            { isColWithBody: true, field: "comments", header: msg('event.type.comment'), body: this.getComment, sortable: false, className: "p-col-4" },
            { isColWithBody: true, field: "targetObjectId", header: msg('event.type.target'), body: this.getTargetObject, sortable: false, className: "text-align-center" }
        ];

        let journalOutilsBar = [
            { filename: "journal Système.xlsx", iconSrc: "/assets/ultima/layout/images/cartadis/excel.svg", titleIcone: "journal Système", tooltipTextIcone: msg('event.type.tooltip.export'), urlExport: "server/system-events-xls" }
        ]

        let events = this.props.data && this.props.data.events ? this.props.data.events : [];
        let totalPages = this.props.data && this.props.data.totalPages ? this.props.data.totalPages : 0;
        let eventsTable = (
        <TableData data={events} titleTable={msg('event.type.event.list')} totalPages={totalPages}
            getDataFunction={this.props.load} paramnFunctionData={null}
            selectionMode={"single"} onSelection={this.onSelection} outilsBar={journalOutilsBar}
            columns={eventsColumns} searchInTable={false} filter={this.state.filter} searchByName={true} 
            nameTxtPlaceholder={msg('system.jornal.search')}  apply={this.searcheBykeyWord}
        />);

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card card-w-title system-Events ">
                        <Panel header="Filtre" toggleable={true}  >
                            {filter}
                        </Panel>
                    </div>
                </div>
                <div className="p-col-12">
                    <div className="card card-w-title">
                        <h1>{msg('event.type.system.jornal')}</h1>
                        {eventsTable}
                    </div>
                </div>
            </div>);
    }
}

export default SystemEvents;