import {LOAD_FILTER, LOAD_FILTER_SUCCESS, LOAD_FILTER_STATIONS, LOAD_FILTER_STATIONS_SUCCESS, LOAD_SYSTEM_EVENTS_FILTER, 
	LOAD_SYSTEM_EVENTS_FILTER_SUCCESS, LOAD_FILTER_EXPLOITANTS_REPORTS, LOAD_FILTER_EXPLOITANTS_REPORTS_SUCCESS, 
	LOAD_FILTER_STATIONS_WITH_CONTRACTS,LOAD_FILTER_STATIONS_WITH_CONTRACTS_SUCCESS,
	LOAD_FILTER_DODO_STATIONS, LOAD_FILTER_DODO_STATIONS_SUCCESS
} from '../actions/filter';

const state0 = {data: null, error: null};

export const loadFilterReducer = (state = state0, action) => {
	switch (action.type) {
		case LOAD_FILTER:
			return {
				state,
				error: null
		};
		case LOAD_FILTER_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};

export const loadFilterStationsReducer = (state = state0, action) => {
	switch (action.type) {
		case LOAD_FILTER_STATIONS:
			return {
				state,
				error: null
		};
		case LOAD_FILTER_STATIONS_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};

export const loadFilterStationsWithContractsReducer = (state = state0, action) => {
	switch (action.type) {
		case LOAD_FILTER_STATIONS_WITH_CONTRACTS:
			return {
				state,
				error: null
		};
		case LOAD_FILTER_STATIONS_WITH_CONTRACTS_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};

export const loadFilterDodoStationsReducer = (state = state0, action) => {

	switch (action.type) {
		case LOAD_FILTER_DODO_STATIONS:
			return {
				state,
				error: null
		};
		case LOAD_FILTER_DODO_STATIONS_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default:
			return state;
	}
};

export const loadSystemEventsFilterReducer = (state = state0, action) => {
	switch (action.type) {
		case LOAD_SYSTEM_EVENTS_FILTER:
			return {
				state,
				error: null
		};
		case LOAD_SYSTEM_EVENTS_FILTER_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};

export const loadFilterExploitantsReportsReducer = (state = state0, action) => {
	switch (action.type) {
		case LOAD_FILTER_EXPLOITANTS_REPORTS:
			return {
				state,
				error: null
		};
		case LOAD_FILTER_EXPLOITANTS_REPORTS_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};