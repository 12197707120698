import React, { Component } from 'react'
import { Checkbox } from 'primereact/checkbox';
import TableData from '../../../utils/TableData';
import { rolesValidator, PRICEZONE, READ } from '../../../utils/RolesValidator';
import { userSessionParamsHandler } from '../../../utils/UserSessionParamsHandler';
import UploadService from "../../../middleware/download-upload-files.service";
import { progressSpinnerHandler } from '../../../utils/ProgressSpinnerHandler.js';
import msg from '../../../texts/msg';
import '../../common/css/common.css';
import './pricezone.css';
import { toLocaleDateTimeFormat } from '../../../utils/dateTimeUtils';

export class PriceZones extends Component {
    constructor(props) {
        super(props);
        this.state = {
            first: 0,
            buttonSelectd: false,
            display: false,
            closable: false,
            isLignSelected: false,
            isApiError: false,
            articleFilter: {
                pricezoneId :null
            }
            
        }

        this.loadPriceZones = this.loadPriceZones.bind(this);
        this.onSelection = this.onSelection.bind(this);
        this.loadArticlesByPricezone = this.loadArticlesByPricezone.bind(this);
        this.downloadTargetConfiguration = this.downloadTargetConfiguration.bind(this);

    }

    componentDidMount() {
        const page = 0;
        const pageSize = userSessionParamsHandler.getPageSize();
        this.loadPriceZones(page, pageSize);       
        this.props.setDataInfoBar(null);
    }

    downloadTargetConfiguration() {
        if (this.state.pricezoneId && this.state.buttonSelectd) {
            let configFileName = "PriceZone";
            if (this.state.pricezoneName) {
                configFileName = "PriceZone_" + this.state.pricezoneName + ".json";
            }
            UploadService.getFileData("pricezone/" + this.state.pricezoneId + "/configuration", configFileName).then((response) => {
                const fname = configFileName;
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    if (response.status === "401" || response.status === 401) {
                        this.setState({ buttonSelectd: false, sessionExpired: true });
                    } else if (response.status === 400 || response.status === "400") {
                        this.setState({ isNotDownload: true, buttonSelectd: false });
                    }
                    else {
                        this.setState({ buttonSelectd: false });
                        a.href = url;
                        a.download = fname;
                        a.click();
                    }
                    progressSpinnerHandler.show(false);
                });
            });
        }
    }

    loadPriceZones(page, pageSize) {
        this.props.loadPriceZones(page, pageSize);
    }

    loadArticlesByPricezone(page, pageSize) {
        this.props.loadArticlesByPricezone(this.state.pricezoneId, page, pageSize, this.state.pricezoneId);
    }


    isenabled(rowData) {
        if (rowData.enabled) {
            return (<Checkbox checked={rowData.enabled}></Checkbox>);
        } else {
            return (<Checkbox checked={rowData.enabled}></Checkbox>);
        }
    }

    actionBodyTemplate(rowData) {
        return <div className='p-grid'>
            <div className='p-col-8'>{rowData.service}</div>
            <div className='p-col-2' style={{ width: '15px', height: '15px', backgroundColor: rowData.color, MozBorderRadius: '50%', WebkitBorderRadius: '50%', borderRadius: '50%', textAlign: 'center', marginTop: '10px' }}>
            </div>
        </div>;
    }

    vat(rowData) {
        return (
            <span style={{ fontStyle: 'italic' }}> {rowData.tva + ' %' } </span>
        )
    }

    onSelection(event) {
        let page = 0;
        let pageSize = userSessionParamsHandler.getPageSize();
        if (event.value) {
            this.setState({ pricezoneId: event.value.id });
            this.setState({ pricezoneName: event.value.name });
            this.setState({ buttonSelectd: true });           
            this.props.loadArticlesByPricezone(event.value.id, page, pageSize, event.value.id);
        }
    }

    description(rowData) {
        let description = rowData.comment ? rowData.comment : "";
        if (description != null && description.length > 50)
            return <div className="hasTooltip p-ellipsis"> {description} <span> {description}  </span></div>;
        else
            return <div> {description} </div>
    }

    creationDateBody(row) {
        if (row.creationDate) {
            let creationDate = toLocaleDateTimeFormat(row.creationDate);
            return creationDate.length > 15 ? <div className="hasTooltip p-ellipsis">{creationDate}<span>{creationDate}</span></div> : <div>{creationDate}</div>;
        }
    }

    render() {

        let authorized = rolesValidator.validate([PRICEZONE]);
        let rAuthorized = authorized.toString().includes(READ);

        let pricezonesColumns = [
            { isColWithBody: false, field: "id", header: "id", sortable: true, className: "visibilityHidden" },
            { isColWithBody: false, field: "name", header: msg('pricezone.name'), sortable: false, className: "p-col-2" },
            { isColWithBody: true, field: "comment", header: msg('pricezone.comment'), body: this.description, sortable: false, className: "p-col-3" },
            { isColWithBody: false, field: "version", header: msg('pricezone.version'), sortable: false, className: "p-col-3" },
            { isColWithBody: true, field: "creationDate", header: msg('pricezone.creationDate'), body: this.creationDateBody, sortable: false, className: "p-col-3" },
        ];

        let pricezones = this.props.pricezones && this.props.pricezones.pricezones ? this.props.pricezones.pricezones : [];
        let totalPages = this.props.pricezones && this.props.pricezones.pricezones ? this.props.pricezones.totalPages : 0;
        let ContextMenuContent =
        (this.state.pricezoneId && [
            {
              label: msg('pricezone.button.config.download.tooltip'),
              command: this.downloadTargetConfiguration
            }]
        )
        let pricezonesTable = (
            <TableData data={pricezones} titleTable={msg('pricezone.table.title')} totalPages={totalPages}
            	selectionMode={"single"} selection={this.state.rowSelection} ContextMenuContent={ContextMenuContent}
                onSelection={this.onSelection}
                getDataFunction={this.loadPriceZones} paramnFunctionData={null}
                columns={pricezonesColumns} />);

        // 
        let articlesOutilsBar = [];
        if(this.state.pricezoneId) {
            articlesOutilsBar = [
                {          
                       filename: msg('articles.tooltip.export.xlsx'), iconSrc: "/assets/ultima/layout/images/cartadis/excel.svg", titleIcone:msg('articles.articles'), tooltipTextIcone: msg('articles.tooltip.export'), urlExport: "pricezone/articles-xls"                
               }
           ]
        }
        
        let articlesColumns = [
            { isColWithBody: false, field: "id", header: "id", sortable: true, className: "visibilityHidden" },
            { isColWithBody: true, field: "service", header: msg('article.service'), body: this.actionBodyTemplate, sortable: false, className: "p-col-2" },
            { isColWithBody: true, field: "bblName", header: msg('article.bblName'), sortable: false, className: "p-col-1" },
            { isColWithBody: true, field: "eanCode", header: msg('article.eanCode'), sortable: false, className: "p-col-2" },
            { isColWithBody: true, field: "grCode", header: msg('article.grCode'), sortable: false, className: "p-col-1" },
            { isColWithBody: true, field: "taxIncludedCashPrice", header: msg('article.taxIncludedCashPrice'), sortable: false, className: "p-col-1" },
            { isColWithBody: true, field: "taxIncludedUnitPrice", header: msg('article.taxIncludedUnitPrice'), sortable: false, className: "p-col-1" },
            { isColWithBody: false, field: "reloadValue", header: msg('article.reloadValue'), sortable: false, className: "p-col-1" },
            { isColWithBody: false, field: "relaodPJEGR", header: msg('article.relaodPJEGR'), sortable: false, className: "p-col-1" },
            { isColWithBody: true, field: "tva", header: msg('article.tva'), body: this.vat, sortable: false, className: "p-col-1" },
            { isColWithBody: true, field: "bonus", header: msg('article.bonus'), sortable: false, className: "p-col-1" }

        ];

        let articles = this.props.pricezoneById && this.props.pricezoneById.articles ? this.props.pricezoneById.articles : [];
        totalPages = this.props.pricezoneById ? this.props.pricezoneById.totalPages : 0;
        let first = this.props.pricezoneById && this.props.pricezoneById.first === true ? this.props.pricezoneById.first : null;
        let titleTableArticle = <p>{msg('pricezone.article.title') + " "}  <span style={{ fontWeight: 'bold' }} > {this.state.pricezoneName} </span></p>;
        let datatableArticleByPricezone = (
            <TableData data={articles} titleTable={titleTableArticle} totalPages={totalPages}
                getDataFunction={this.loadArticlesByPricezone} paramnFunctionData={null} first={first}
                outilsBar={articlesOutilsBar} filter={this.state.pricezoneId}
                columns={articlesColumns} searchInTable={false}
            />);


        return rAuthorized && (
            <div className="p-grid commonClass">
                <div className="p-col-12">
                    <div className="p-col-4 p-md-4 item">
                        <div className="p-col-12"><h2 className="titleAbonnement">{msg('pricezone.title')}</h2></div>
                    </div>
                    {pricezonesTable}
                </div>
                <div className="p-col-12">
                    <div className="card card-w-title ContnairPanelFilter"/>
                    <div className="card card-w-title activity ">
                        {datatableArticleByPricezone}
                    </div>
                </div>
            </div >);
    }
}