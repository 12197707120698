import React, { Component } from 'react';
import { Button } from 'primereact/button';
import TableData from '../../../utils/TableData';
import {rolesValidator, COMMISSIONS, CREATE, READ, UPDATE, DELETE } from '../../../utils/RolesValidator';
import { userSessionParamsHandler } from '../../../utils/UserSessionParamsHandler';
import MessageDialog from '../../common/MessageDialog';
import msg from '../../../texts/msg';

const emtyChar = '';

class CommissionList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            buttonSelectd : false,
            commissionId: emtyChar,
            filter : {}
        }

        this.onSelection = this.onSelection.bind(this);
        this.consult = this.consult.bind(this);
        this.delete = this.delete.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
    }

    componentDidMount() {
        this.props.load(0, userSessionParamsHandler.getPageSize());
    }

    componentDidUpdate(prevProps) {
        if (this.checkApiErrors(prevProps)) {
            return;
        }
		if (this.checkReloadOnChange(prevProps)) {
            return;
        }
    }

    checkApiErrors(prevProps) {
        let prevError = prevProps.error && prevProps.error.timestamp;
        let lastError = this.props.error && this.props.error.timestamp;
        let error = (lastError && (!prevError || prevError < lastError));  
        if(error) {
            let isError = this.props.error.message && this.props.error.message.startsWith('error.commissions.') ? true : false;
            this.setState({isApiError: isError});
            return true;
        }
        return false;
    }

    checkReloadOnChange(prevProps) {
        let reload = (this.props.lastChange && (!prevProps.lastChange || prevProps.lastChange < this.props.lastChange));
		if (reload) {
            this.props.load(0, userSessionParamsHandler.getPageSize());
            return true;
		}
        return false;
    }

    onSelection(event) {
        if(event.value){
            this.setState({commissionId: event.value.id, profileName: event.value.name, buttonSelectd: true, deleteButtonSelectd: !event.value.readOnly});
        } else {
            this.setState({buttonSelectd: false, deleteButtonSelectd: false});
        }
    }

    consult() {
        this.props.consult(this.state.commissionId);
    }

    delete() {
        if(this.state.commissionId){
            this.setState({showConfirm: true});
        }
    }

    confirmDelete() {
       this.props.delete(this.state.commissionId);
       this.setState({buttonSelectd: false, deleteButtonSelectd: false});
    }

    name(rowData) {
        let name = rowData.name ? rowData.name : "";
        if (name != null && name.length > 40)
            return <div className="hasTooltip p-ellipsis"> {name} <span> {name}  </span></div>;
        else
            return <div> {name} </div>

    }
    
    description(rowData) {
        let description = rowData.description ? rowData.description : "";
        if (description != null && description.length > 50)
            return <div className="hasTooltip p-ellipsis"> {description} <span> {description}  </span></div>;
        else
            return <div> {description} </div>
    }

    twPrestationRate(rowData) {
        const data = rowData.twPrestationRate.mandate ? rowData.twPrestationRate.value : msg('commission.profile.list.no.mandate');
        return <div> {data} </div>
    }
    grPrestationRate(rowData) {
        const data = rowData.grPrestationRate.mandate ? rowData.grPrestationRate.value : msg('commission.profile.list.no.mandate');
        return <div> {data} </div>
    }
    cbPrestationRate(rowData) {
        const data = rowData.cbPrestationRate.mandate ? rowData.cbPrestationRate.value : msg('commission.profile.list.no.mandate');
        return <div> {data} </div>
    }
    cashPrestationRate(rowData) {
        const data = rowData.cashPrestationRate.mandate ? rowData.cashPrestationRate.value : msg('commission.profile.list.no.mandate');
        return <div> {data} </div>
    }
    twReloadRate(rowData) {
        const data = rowData.twReloadRate.mandate ? rowData.twReloadRate.value : msg('commission.profile.list.no.mandate');
        return <div> {data} </div>
    }

    render() {
        let authorized = rolesValidator.validate([COMMISSIONS]);
        let cAuthorized = authorized.toString().includes(CREATE);
        let rAuthorized = authorized.toString().includes(READ);
        let uAuthorized = authorized.toString().includes(UPDATE);
        let dAuthorized = authorized.toString().includes(DELETE);
        let commissionsColumns = [
            { isColWithBody: false, field: "id", header: "id", sortable: false, className: "visibilityHidden" },
            { isColWithBody: false, field: "readOnly", sortable: false, className: "visibilityHidden" },
            { isColWithBody: true, field: "name", header: msg('commission.profile.list.name'), body: this.name, sortable: false, className: "p-col-2" },
            { isColWithBody: true, field: "description", header: msg('commission.profile.list.description'), body: this.description, sortable: false, className: "p-col-2" },
            { isColWithBody: true, field: "twPrestationRate.value", header: msg('commission.profile.list.twprestation.rate'), body: this.twPrestationRate, sortable: false, className: "p-col-2" },
            { isColWithBody: true, field: "grPrestationRate.value", header: msg('commission.profile.list.grprestation.rate'), body: this.grPrestationRate, sortable: false, className: "p-col-2" },
            { isColWithBody: true, field: "twReloadRate.value", header: msg('commission.profile.list.twreload.rate'), body: this.twReloadRate, sortable: false, className: "p-col-2" },
            { isColWithBody: true, field: "cbPrestationRate.value", header: msg('commission.profile.list.cbprestation.rate'), body: this.cbPrestationRate, sortable: false, className: "p-col-2" },
            { isColWithBody: true, field: "cashPrestationRate.value", header: msg('commission.profile.list.cashprestation.rate'), body: this.cashPrestationRate, sortable: false, className: "p-col-2" }
            ];
            let commissionsOutilsBar = [
                { filename: "Liste des profils de mandat.xlsx", iconSrc: "/assets/ultima/layout/images/cartadis/excel.svg", titleIcone: "Commissions", tooltipTextIcone: msg('commission.tooltip.export'), urlExport: "commissions/commission-xls" }
            ]

        let commissions = this.props.data && this.props.data.commissions ? this.props.data.commissions : [];
        let totalPages = this.props.data && this.props.data.totalPages ? this.props.data.totalPages : 0;
        let commissionsTable = (
            <TableData data={commissions} titleTable={msg('commission.profile.list.title')} totalPages={totalPages}
                getDataFunction={this.props.load} paramnFunctionData={null} outilsBar={commissionsOutilsBar} filter={this.state.filter} 
            	selectionMode={"single"} onSelection={this.onSelection} columns={commissionsColumns} />);
        return rAuthorized && (
        <div className="p-grid commonClass card card-w-title">
            <div className="p-col-12">
                <h2>{msg('commission.profile.title')}</h2>                    
            </div>
            <div className="p-col-12">
                <div className="item p-col-12 p-md-3"/>
                <div className={true ? "btnSelected item" : "btnNotSelected item"}>
                    <Button label={msg('commission.profile.button.create')} tooltip={msg('commission.profile.button.create')} key="create" onClick={this.props.create} disabled={!cAuthorized} />
                </div>
                <div className={this.state.buttonSelectd ? "btnSelected item" : "btnNotSelected item"}>
                    <Button label={msg('commission.profile.button.consult')} tooltip={msg('commission.profile.button.consult')} 
                            key="consult" onClick={this.consult} disabled={!(this.state.buttonSelectd && (uAuthorized || rAuthorized))} />
                </div>
                <div className={this.state.deleteButtonSelectd ? "btnSelected item" : "btnNotSelected item"}>
                    <Button label={msg('commission.profile.button.delete')} tooltip={msg('commission.profile.button.delete')} 
                            key="delete" onClick={this.delete} disabled={!(this.state.buttonSelectd && dAuthorized)} />
                </div>
            </div>
            <div className="p-col-12">
                <div className="">{commissionsTable}</div>
            </div>
            <MessageDialog confirm={true} className={'message-dialog confirm-message'} visible={this.state.showConfirm} title={msg('message.dialog.confirm.title')}
                        message={this.state.showConfirm && msg('commission.profile.delete.confirm.message', {profileName: this.state.profileName})} key="confirm-delete"
                        onConfirm={() => {this.setState({showConfirm: false}); this.confirmDelete()}} onCancel={() => this.setState({showConfirm: false})}/>
            <MessageDialog className={'message-dialog error-message'} visible={this.state.isApiError} title={msg('message.dialog.error.title')}
                message={this.state.isApiError && msg(this.props.error.message)}
                onHide={() => this.setState({ isApiError: false })}/>
        </div>
        );
    }
}

export default CommissionList;