import { REBOOT, REBOOT_SUCCESS, GET_BORNE_LOGS, GET_BORNE_LOGS_SUCCESS, LOAD_BORNES, LOAD_BORNES_SUCCESS,
	TEST_NETWORK_SPEED, TEST_NETWORK_SPEED_SUCCESS, DISASSOCIATE, DISASSOCIATE_SUCCESS} from "../actions/borne";
const state0 = {data: null, error: null};

export const disassociateBorneReducer = (state = state0, action) => {
	switch (action.type) {
		case DISASSOCIATE:
			return {
				state,
				error: null
		};
		case DISASSOCIATE_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};


export const checkNetworkSpeedReducer = (state = state0, action) => {
	switch (action.type) {
		case TEST_NETWORK_SPEED:
			return {
				state,
				error: null
		};
		case TEST_NETWORK_SPEED_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};

export const loadBornesReducer = (state = state0, action) => {
	switch (action.type) {
		case LOAD_BORNES:
			return {
				state,
				error: null
		};
		case LOAD_BORNES_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};

export const rebootReducer = (state = state0, action) => {
	switch (action.type) {
		case REBOOT:
			return {
				state,
				error: null
		};
		case REBOOT_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};

export const getBorneLogsReducer = (state = state0, action) => {
	switch (action.type) {
		case GET_BORNE_LOGS:
			return {
				state,
				error: null
		};
		case GET_BORNE_LOGS_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};