import React, { Component } from 'react'
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import TableData from '../../../utils/TableData';
import { rolesValidator, STATIONS, CREATE, READ, UPDATE, DELETE } from '../../../utils/RolesValidator';
import { userSessionParamsHandler } from '../../../utils/UserSessionParamsHandler';
import msg from '../../../texts/msg';
import MessageDialog from '../../common/MessageDialog';
import { toLocaleDateTimeFormat } from '../../../utils/dateTimeUtils';
import './station.css';
import '../../common/css/common.css';
import { progressSpinnerHandler } from '../../../utils/ProgressSpinnerHandler'

export class Stations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            first: 0,
            buttonSelectd: false,
            isStationSAV: false,
            display: false,
            closable: false,
            isLignSelected: false,
            isApiError: false,
            filter: {},
            sortProprety: { 'key': 'implant', 'value': "ASC" },
            sortDirection: "ASC"
        }
        this.loadStations = this.loadStations.bind(this);
        this.onSelection = this.onSelection.bind(this);
        this.update = this.update.bind(this);
        this.deleteStation = this.deleteStation.bind(this);
        this.onSort = this.onSort.bind(this);
        this.searcheBykeyWord = this.searcheBykeyWord.bind(this);
        this.updateConfigStation = this.updateConfigStation.bind(this);
        this.deleteMessage = this.deleteMessage.bind(this);

    }

    componentDidMount() {
        this.loadStations(0, userSessionParamsHandler.getPageSize(), this.state.filter);
        this.props.setDataInfoBar(null);
    }

    componentDidUpdate(prevProps) {
        let prevError = prevProps.error && prevProps.error.timestamp;
        let lastError = this.props.error && this.props.error.timestamp;
        let error = (lastError && (!prevError || prevError < lastError));
        if (error) {
            progressSpinnerHandler.show(false);
            let isError = this.props.error.message && this.props.error.message.startsWith('error.station.') ? true : false;
            this.setState({ isApiError: isError });
        }
        if(this.props.stations && this.props.stations !== prevProps.stations ){
            progressSpinnerHandler.show(false);
        }
        let prevDelete = prevProps.stationDeleteById && prevProps.stationDeleteById.lastChange;
        let lastDelete = this.props.stationDeleteById && this.props.stationDeleteById.lastChange;
        let reload = (lastDelete && (!prevDelete || prevDelete < lastDelete));
        if (reload) {
            this.loadStations(0, userSessionParamsHandler.getPageSize(), this.state.filter);
        }

        let prevUpdateConfigStation = prevProps.updateConfigStationData.data && prevProps.updateConfigStationData.data.lastChange;
        let lastUpdateConfigStation = this.props.updateConfigStationData.data && this.props.updateConfigStationData.data.lastChange;
        let updated = (lastUpdateConfigStation && (!prevUpdateConfigStation || prevUpdateConfigStation < lastUpdateConfigStation));
        if (updated) {
            this.setState({ isUpdated: true });
        }
    }

    deleteStation() {
        if (this.state.stationId && !this.state.isStationSAV) {
            this.setState({ showConfirm: true });
        } else {
            this.setState({ isLignSelected: true });
        }
    }

    confirmDeleteStation() {
        if (this.state.stationId) {
            this.props.deleteStation(this.state.stationId);
            this.setState({ buttonSelectd: false });
        } else {
            this.setState({ isLignSelected: true });
        }
        this.setState({ buttonSelectd: false });
    }

    update() {
        if (this.state.stationId && this.state.buttonSelectd) {
            this.props.station(this.state.stationImplant);
            this.setState({ buttonSelectd: false });
            this.props.history.push('/station/update');
        } else {
            this.setState({ isLignSelected: true });
        }
    }

    loadStations(page, pageSize, filter) {
        progressSpinnerHandler.show(true); 
        filter.page = page;
        filter.pageSize = pageSize;
        this.props.loadStations(filter);
        this.setState({ filter: filter });
    }

    onSelection(event) {
        if (event.value) {
            this.setState({ stationId: event.value.id });
            this.setState({ stationName: event.value.name });
            this.setState({ stationImplant: event.value.implant });
            this.setState({ stationActive: true });
            this.setState({ buttonSelectd: true, isStationSAV: event.value.implant === "NF000000" });
            this.setState({
                stationWithContract: (event.value.contract != null && event.value.contract.sapCode != null) || event.value.previousContract != null || event.value.nextContract != null
            });
        }
    }

    updateConfigStation() {
        if (this.state.stationId && this.state.stationActive) {
            this.props.updateConfigStation(this.state.stationImplant);
        }
    }

    implant(rowData) {
        let implant = rowData.implant ? rowData.implant : "";
        if (implant != null && implant.length > 15)
            return <div className="hasTooltip p-ellipsis"> {implant} <span> {implant}  </span></div>;
        else
            return <div> {implant} </div>

    }

    name(rowData) {
        let name = rowData.name ? rowData.name : "";
        if (name != null && name.length > 25)
            return <div className="hasTooltip p-ellipsis"> {name} <span> {name}  </span></div>;
        else
            return <div> {name} </div>

    }

    brand(rowData) {
        let brand = rowData.brand ? rowData.brand : "";
        if (brand != null && brand.length > 20)
            return <div className="hasTooltip p-ellipsis"> {brand} <span> {brand}  </span></div>;
        else
            return <div> {brand} </div>
    }

    contractClientName(rowData) {
        let name = rowData.contract.sapName ? rowData.contract.sapName : "";
        if (name && name.length > 20)
            return <div className="hasTooltip p-ellipsis"> {name} <span> {name}  </span></div>;
        else
            return <div> {name} </div>
    }

    batchNumberDate(rowData) {
        let batchNumberDate = (rowData.batchNumberDate && rowData.batchNumber) ? toLocaleDateTimeFormat(rowData.batchNumberDate) + " (" +  rowData.batchNumber + ")" : "";
        if (batchNumberDate != null && batchNumberDate.length > 25)
            return <div className="hasTooltip p-ellipsis"> {batchNumberDate} <span> {batchNumberDate}  </span></div>;
        else
            return <div> {batchNumberDate} </div>
    }

    commission(rowData) {
        let commission = rowData.commission && rowData.commission.key ? rowData.commission.key : "";
        if (commission != null && commission.length > 24)
            return <div className="hasTooltip p-ellipsis"> {commission} <span> {commission}  </span></div>;
        else
            return <div> {commission} </div>

    }

    pricezone(rowData) {
        let pricezone = rowData.pricezone && rowData.pricezone.key ? rowData.pricezone.key : "";
        if (pricezone != null && pricezone.length > 24)
            return <div className="hasTooltip p-ellipsis"> {pricezone} <span> {pricezone}  </span></div>;
        else
            return <div> {pricezone} </div>

    }

    onSort = (e, page) => {
        progressSpinnerHandler.show(true); 
        let filter = Object.assign({}, this.state.filter);
        let sortDirection = this.state.sortDirection === "ASC" ? "DESC" : "ASC";
        filter.sortProperty = {key: e.sortField, value: sortDirection};
        filter.label = this.state.rechecheObject ? this.state.rechecheObject.idSearch : "";
        this.loadStations(page, userSessionParamsHandler.getPageSize(), filter);
        this.setState({sortDirection: sortDirection, filter: filter });
    }

    searcheBykeyWord(rechecheObject) {
        progressSpinnerHandler.show(true); 
        let lFilter = Object.assign({}, this.state.filter);
        this.setState({ rechecheObject: rechecheObject })
        let keyword = rechecheObject.idSearch ? rechecheObject.idSearch : this.state.keyword;
        lFilter.label = keyword;
        this.loadStations(0, userSessionParamsHandler.getPageSize(), lFilter);
    }

    deleteMessage() {
        if (this.state.showConfirm && this.state.stationWithContract)
            return (msg('station.delete.confirm.message.with.contract') + msg('station.delete.confirm.message', { stationName: this.state.stationName }))
        else if (this.state.showConfirm && !this.state.stationWithContract)
            return (msg('station.delete.confirm.message', { stationName: this.state.stationName }))
        else
            return "";
    }

    render() {
        let authorized = rolesValidator.validate([STATIONS]);
        let cAuthorized = authorized.toString().includes(CREATE);
        let rAuthorized = authorized.toString().includes(READ);
        let uAuthorized = authorized.toString().includes(UPDATE);
        let dAuthorized = authorized.toString().includes(DELETE);
        let stationsColumns = [
            { isColWithBody: false, field: "id", header: "id", sortable: true, className: "visibilityHidden" },
            { isColWithBody: true, field: "implant", header: msg('station.implant'), body: this.implant, sortable: true, className: "p-col-2" },
            { isColWithBody: true, field: "name", header: msg('station.name'), body: this.name, sortable: true, className: "p-col-3" },
            { isColWithBody: true, field: "sapName", header: msg('station.client.name'), body: this.contractClientName, sortable: true, className: "p-col-3" },
            { isColWithBody: true, field: "batchNumberDate", header: msg('station.batch.nbr.change.date'), body: this.batchNumberDate, sortable: true, className: "p-col-3" },
            { isColWithBody: true, field: "commission.key", header: msg('station.commission'), body: this.commission, sortable: true, className: "p-col-2" },
            { isColWithBody: true, field: "pricezone.key", header: msg('station.pricezone'), body: this.pricezone, sortable: true, className: "p-col-2" },
        ];

        let stations = this.props.stations && this.props.stations.stations ? this.props.stations.stations : [];
        let totalPages = this.props.stations && this.props.stations.totalPages ? this.props.stations.totalPages : 0;
        let stationsOutilsBar = [
            { filename: "Liste des stations.xlsx", iconSrc: "/assets/ultima/layout/images/cartadis/excel.svg", titleIcone: "stations", tooltipTextIcone: msg('stations.tooltip.export'), urlExport: "stations/stations-excel" }
        ]
        let ContextMenuContent = this.state.stationId && [
                {
                  label: msg('station.update'),
                  command: this.update
                },
                {
                  label: msg('station.delete'),
                  command: this.deleteStation
                },
                {
                  label: msg('station.button.update.config'),
                  command: this.updateConfigStation
                }];
        let stationsTable = (
            <TableData data={stations} titleTable={msg('station.table.title')} totalPages={totalPages} ContextMenuContent={ContextMenuContent}
                selection={this.state.rowSelection}
                selectionMode={"single"} onSelection={this.onSelection} outilsBar={stationsOutilsBar}
                getDataFunction={this.loadStations} paramnFunctionData={null} filter={this.state.filter}
                columns={stationsColumns} searchInTable={false} searchByName={true} nameTxtPlaceholder={msg('station.search.by.name')}
                apply={this.searcheBykeyWord}
                onSort={this.onSort} />);
        return rAuthorized && (
            <div className="card p-grid commonClass">
                <div className="p-col-12">
                    <div className="p-col-4 p-md-4 item">
                        <div className="p-col-12"><h2 className="titleAbonnement">{msg('station.title')}</h2></div>
                    </div>
                    <div className="p-col-8 item buttonPosition">

                        <div className={this.state.buttonSelectd ? "btnSelected item" : "btnNotSelected item "} style={{ marginLeft: '-5px' }}>
                            <Button label={(msg('station.update'))} onClick={this.update} title={(msg('station.update'))} key="update" disabled={!uAuthorized && !rAuthorized} />
                        </div>
                        <div className={this.state.buttonSelectd && !this.state.isStationSAV ? "btnSelected item " : "btnNotSelected item"} >
                            <Button label={(msg('station.delete'))} onClick={() => { this.deleteStation() }} title={(msg('station.delete'))} key="delete" disabled={!dAuthorized} />
                        </div>
                        <div className="item btnSelected">
                            <Link to="/station/create">
                                <Button label={(msg('station.create'))} title={(msg('station.create'))} className="button" key="create" disabled={!cAuthorized} />
                            </Link>
                        </div>

                        <div className={this.state.buttonSelectd ? "btnSelected item " : "btnNotSelected item"}>
                            <Button label={(msg('station.button.update.config'))} title={(msg('station.button.update.config'))}
                                onClick={() => this.updateConfigStation()}
                                key="config_download" disabled={!this.state.stationActive || !uAuthorized}
                            />
                        </div>
                    </div>
                    {stationsTable}
                </div>
                <MessageDialog className={'message-dialog error-message'} visible={this.state.isApiError} title={msg('message.dialog.error.title')}
                    message={this.state.isApiError && msg(this.props.error.message)}
                    onHide={() => this.setState({ isLignSelected: false, isApiError: false })} />
                <MessageDialog confirm={true} className={'message-dialog confirm-message'} visible={this.state.showConfirm && !this.state.isStationSAV} title={msg('message.dialog.confirm.title')}
                    message={this.deleteMessage()} key="confirm-delete"
                    onConfirm={() => { this.setState({ showConfirm: false }); this.confirmDeleteStation() }} onCancel={() => this.setState({ showConfirm: false })} />

                <MessageDialog className={'message-dialog info-message'} visible={this.state.isUpdated} title={msg('station.config.info')}
                    message={msg('station.config.updated', { stationName: this.state.stationName, stationImplant: this.state.stationImplant })}
                    onHide={() => this.setState({ isUpdated: false })} />
            </div >);
    }
}