
import { ADD_GROUP, ADD_GROUP_SUCCESS, GROUP, GROUPS, GROUPS_SUCCESS, GROUP_BY_ID, GROUP_BY_ID_SUCCESS, GROUP_SUCCESS, GROUP_UPDATE, GROUP_UPDATE_SUCCESS, GROUP_DELETE_BY_ID, GROUP_DELETE_BY_ID_SUCCESS } from "../actions/group";


const state0 = { data: null, error: null };

export const groupsReducer = (state = state0, action) => {
	switch (action.type) {
		case GROUPS:
			return {
				state,
				error: null
			};
		case GROUPS_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
			};
		default:
			return state;
	}
};

export const groupByIdReducer = (state = state0, action) => {
	switch (action.type) {
		case GROUP_BY_ID:
			return {
				state,
				error: null
			};
		case GROUP_BY_ID_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
			};
		default:
			return state;
	}
};

export const addGroupReducer = (state = state0, action) => {
	switch (action.type) {
		case ADD_GROUP:
			return {
				state,
				error: null
			};
		case ADD_GROUP_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
			};
		default:
			return state;
	}
};


export const groupReducer = (state = state0, action) => {
	switch (action.type) {
		case GROUP:
		case GROUP_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
			};
		default:
			return state;
	}
};

export const groupUpdateReducer = (state = state0, action) => {
	switch (action.type) {
		case GROUP_UPDATE:
			return {
				state,
				error: null
			};
		case GROUP_UPDATE_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
			};
		default:
			return state;
	}
};

export const groupDeleteByIdReducer = (state = state0, action) => {
	switch (action.type) {
		case GROUP_DELETE_BY_ID:
			return {
				state,
				error: null
			};
		case GROUP_DELETE_BY_ID_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
			};
		default:
			return state;
	}
};
