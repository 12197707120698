import { COMMERCE_DASHBORD, COMMERCE_DASHBORD_SUCCESS, COMMERCE_ANALYSIS, COMMERCE_ANALYSIS_SUCCESS,
			COMMERCE_EXPLOIT_ANALYSIS, COMMERCE_EXPLOIT_ANALYSIS_SUCCESS } from '../actions/commerce';

const state0 = {'data': null, 'error': null};

export const commerceDashbordReducer = (state = state0, action) => {
	switch (action.type) {
		case COMMERCE_DASHBORD:
			return {
				state,
				'error': null
		};
		case COMMERCE_DASHBORD_SUCCESS:
			return {
				state,
				'data': action.data,
				'error': null
		};
		default: 
			return state;
	}
};


export const commerceAnalysisReducer = (state = state0, action) => {
	switch (action.type) {
		case COMMERCE_ANALYSIS:
			return {
				state,
				'error': null
		};
		case COMMERCE_ANALYSIS_SUCCESS:
			return {
				state,
				'data': action.data,
				'error': null
		};
		default: 
			return state;
	}
};

export const commerceExploitAnalysisReducer = (state = state0, action) => {
	switch (action.type) {
		case COMMERCE_EXPLOIT_ANALYSIS:
			return {
				state,
				'error': null
		};
		case COMMERCE_EXPLOIT_ANALYSIS_SUCCESS:
			return {
				state,
				'data': action.data,
				'error': null
		};
		default: 
			return state;
	}
};
