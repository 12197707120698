import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Fieldset } from 'primereact/fieldset';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import TableData from '../../../utils/TableData';
import { STATIONGROUPS, rolesValidator, CREATE, READ, UPDATE, DELETE } from '../../../utils/RolesValidator';
import { userSessionParamsHandler } from '../../../utils/UserSessionParamsHandler';
import msg from '../../../texts/msg';
import MessageDialog from '../../common/MessageDialog';
import { progressSpinnerHandler } from '../../../utils/ProgressSpinnerHandler'


const LIST_MODE = 0;
const CREATION_MODE = 1;
const CONSULT_MODE = 2;
const companyTypes = [
    { key: msg('group.companyName.cartadis'), value: 'cartadis' },
    { key: msg('group.companyName.total'), value: 'total' },
    { key: msg('group.companyName.other'), value: 'other' }
];

class StationGroup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isApiError:false,
            renderMode: LIST_MODE,
            buttonSelected: false,
            deleteButtonSelected: false,
            stationButtonSelected: false,
            selectedStations: [],
            selectedGroups: [],
            stationGroup: {
                id: '',
                name: '',
                description: '',
                editable: true
            },
            stationGroupsFilter: {
            },
            stationsFilter: {
            },
            sortDirection: "ASC",
            stationSortDirection: "ASC"
        }
        this.load = this.load.bind(this);
        this.loadStations = this.loadStations.bind(this);
        this.onSelection = this.onSelection.bind(this);
        this.onStationsSelection = this.onStationsSelection.bind(this);
        this.onGroupsSelection = this.onGroupsSelection.bind(this);
        this.create = this.create.bind(this);
        this.update = this.update.bind(this);
        this.delete = this.delete.bind(this);
        this.validate = this.validate.bind(this);
        this.cancel = this.cancel.bind(this);
        this.updateStation = this.updateStation.bind(this);
        this.searcheBykeyWord = this.searcheBykeyWord.bind(this);
    }
    componentDidMount() {
        this.load(0, userSessionParamsHandler.getPageSize(), this.state.stationGroupsFilter);
        this.props.setDataInfoBar(null);
    }

    componentDidUpdate(prevProps) {
        if(this.props.stationGroupsData && this.props.stationGroupsData !== prevProps.stationGroupsData){
            progressSpinnerHandler.show(false);
        }
        if(this.props.stationsData && this.props.stationsData !== prevProps.stationsData){
            progressSpinnerHandler.show(false);
        }
        if (this.checkApiErrors(prevProps)) {
            return;
        }
        if (this.checkReloadOnChange(prevProps)) {
            return;
        }
        this.initStationGroup(prevProps);
    }

    checkApiErrors(prevProps) {
        let prevError = prevProps.error && prevProps.error.timestamp;
        let lastError = this.props.error && this.props.error.timestamp;
        let error = (lastError && (!prevError || prevError < lastError));
        if (error && this.state.selectedGroups.length !== 0) {
            let isError = this.props.error.message && this.props.error.message.startsWith('error.stationgroups.') ?
                true : false;
            this.setState({ isApiError: isError, message: this.props.error.message});
            return true;
        }
        return false;
    }

    checkReloadOnChange(prevProps) {
        let prevChange = prevProps.createStationGroupData ? prevProps.createStationGroupData.lastChange : null;
        let lastChange = this.props.createStationGroupData ? this.props.createStationGroupData.lastChange : null;
        let reload = (lastChange && (!prevChange || prevChange < lastChange));
        if (!reload) {
            prevChange = prevProps.updateStationGroupData ? prevProps.updateStationGroupData.lastChange : null;
            lastChange = this.props.updateStationGroupData ? this.props.updateStationGroupData.lastChange : null;
            reload = (lastChange && (!prevChange || prevChange < lastChange));
        }
        if (!reload) {
            prevChange = prevProps.deleteStationGroupData ? prevProps.deleteStationGroupData.lastChange : null;
            lastChange = this.props.deleteStationGroupData ? this.props.deleteStationGroupData.lastChange : null;
            reload = (lastChange && (!prevChange || prevChange < lastChange));
        }
        if (reload) {
            this.load(0, userSessionParamsHandler.getPageSize(), this.state.stationGroupsFilter);
            this.setState({
                renderMode: LIST_MODE,
                stations: [],
                selectedGroups: [],
                selectedStations: [],
                stationsTotalPages: 0
            });
            return true;
        }
        return false;
    }

    initStationGroup(prevProps) {
        let prevChange = prevProps.stationGroupData ? prevProps.stationGroupData.lastChange : null;
        let lastChange = this.props.stationGroupData ? this.props.stationGroupData.lastChange : null;
        let change = (lastChange && (!prevChange || prevChange < lastChange));
        if (change) {
            let stationGroupId = this.state.renderMode === CONSULT_MODE ? this.props.stationGroupData.id : '';
            let company = this.props.stationGroupData.company;
            let lStationGroup = {
                id: stationGroupId,
                name: this.props.stationGroupData.name,
                description: this.props.stationGroupData.description,
                editable: this.props.stationGroupData.editable,
                companyType: company ? {key: msg('group.companyName.' + company), value: company} : {},
                stations: this.props.stationGroupData.stations
            }
            let selectedGroups = [];
            if (stationGroupId) {
                let stationGroups = this.props.stationGroupsData && this.props.stationGroupsData.stationGroups ?
                    this.props.stationGroupsData.stationGroups : [];
                if (Array.isArray(stationGroups)) {
                    let i = 0;
                    for (i = 0; i < stationGroups.length; i++) {
                        if (stationGroups[i].id === stationGroupId) {
                            selectedGroups.push(stationGroups[i]);
                            break;
                        }
                    }
                }
            }
            let filter = { groups: [this.state.stationGroupId] }
            this.setState({
                stationGroup: lStationGroup,
                selectedGroups: selectedGroups,
                stationsFilter: filter
            });
            this.loadStations(0, userSessionParamsHandler.getPageSize(), filter);
        }

        prevChange = prevProps.stationsData ? prevProps.stationsData.lastChange : null;
        lastChange = this.props.stationsData ? this.props.stationsData.lastChange : null;
        change = (lastChange && (!prevChange || prevChange < lastChange));
        if (change) {
            let stations = this.props.stationsData && this.props.stationsData.stations ? this.props.stationsData.stations : [];
            let stationsTotalPages = this.props.stationsData && this.props.stationsData.totalPages ? this.props.stationsData.totalPages : 0;
            let stationsTotalElements = this.props.stationsData && this.props.stationsData.totalElements ? this.props.stationsData.totalElements : 0;
            let selectedStations = this.state.selectedStations && Array.isArray(this.state.selectedStations) ? [...this.state.selectedStations] : [];
            if (this.state.stationGroup && this.state.stationGroup && this.state.stationGroup.stations) {
                if (stations && Array.isArray(stations)) {
                    stations.forEach(s => {
                        if (this.state.stationGroup.stations.includes(s.id)) { selectedStations.push(s) }
                    });
                }
            }
            this.setState({
                stations: stations,
                selectedStations: selectedStations,
                stationsTotalPages: stationsTotalPages,
                stationsTotalElements: stationsTotalElements
            });
        }
    }

    load(page, pageSize, filter) {
        progressSpinnerHandler.show(true); 
        filter.feature = STATIONGROUPS;
        filter.page = page;
        filter.pageSize = pageSize;
        this.props.loadStationGroups(filter);
        this.setState({stationGroupsFilter: filter});
    }

    loadStationGroup() {
        this.props.loadStationGroup(this.state.stationGroupId);
    }

    loadStations(page, pageSize, filter) {
        progressSpinnerHandler.show(true); 
        filter.feature = STATIONGROUPS;
        this.props.loadStations(filter, page, pageSize);
        this.setState({stationsFilter: filter});
    }

    onSelection(event) {
        if (event.value) {
            this.setState({
                selectedGroups: event.value,
                stationGroupId: event.value.id,
                stationGroupName: event.value.name,
                buttonSelected: true, deleteButtonSelected: event.value.editable
            });
            if (event.value.id) {
                let filter = { groups: [event.value.id] }
                this.loadStations(0, userSessionParamsHandler.getPageSize(), filter);
                this.setState({ stationsFilter: filter });
            }
        } else {
            this.setState({ buttonSelected: false, deleteButtonSelected: false });
        }
    }

    onGroupsSelection(event) {
        if (this.state.renderMode === LIST_MODE) {
            return;
        }
        let lSelectedGroups = event.value;
        if (lSelectedGroups && Array.isArray(lSelectedGroups)) {
            if(lSelectedGroups.length === 0){
                let filter = { groups: lSelectedGroups }
                this.setState({ stationsFilter: filter, selectedGroups: lSelectedGroups, stations : [], stationsTotalPages : 0 });
            } else {
                let groups = [];
                lSelectedGroups.forEach(g => groups.push(g.id));
                let filter = { groups: groups }
                this.loadStations(0, userSessionParamsHandler.getPageSize(), filter);
                this.setState({ stationsFilter: filter, selectedGroups: lSelectedGroups });
            }


        }
    }

    onStationsSelection(event) {
        let lStations = event.value;
        this.setState({ selectedStations: lStations });        
        if (this.state.renderMode === LIST_MODE) {
            if (event.value) {
                this.setState({ stationButtonSelected: true });
            } else {
                this.setState({ stationButtonSelected: false });
            }
            return;
        }        
        if (lStations && (Array.isArray(lStations) && lStations.length > 0)) {
            this.setState({ stationsError: false });
        }
    }

    create() {
        let filter = { groups: [] };
        let emptyStationGroup = {
            id: '',
            name: '',
            description: '',
            editable: true
        };
        let data = this.props.stationGroupsData;
        let stationGroups = data && data.stationGroups ? data.stationGroups : [];
        let preSelectedGroups = [];
        if (Array.isArray(stationGroups)) {
            let i = 0;
            for (i = 0; i < stationGroups.length; i++) {
                if (!stationGroups[i].editable) {
                    preSelectedGroups.push(stationGroups[i]);
                    filter = { groups: [stationGroups[i].id] };
                    this.loadStations(0, userSessionParamsHandler.getPageSize(), filter);
                    break;
                }
            }
        }
        this.setState({
            renderMode: CREATION_MODE,
            stationGroup: emptyStationGroup,
            stationsFilter: filter,
            selectedGroups: preSelectedGroups,
            stations: [],
            stationsTotalPages: 0,
            selectedStations: [],
        });
    }

    update() {
        this.setState({
            renderMode: CONSULT_MODE,
            selectedGroups: [],
            stations: [],
            stationsTotalPages: 0,
            selectedStations: [],
        });
        this.loadStationGroup();
    }

    validate() {
        let valid = true;
        if (!this.state.stationGroup.name || !this.state.stationGroup.name.trim()) {
            this.setState({ nameInputError: true });
            valid = false;
        } else {
            this.setState({ nameInputError: false });
        }
        if (!this.state.selectedGroups || this.state.selectedGroups.length === 0) {
            this.setState({ selectedGroupsError: true});
            valid = false;
        } else {
            this.setState({ selectedGroupsError: false });
        }
        if (!this.state.selectedStations || this.state.selectedStations.length === 0) {
            this.setState({ stationsError: true});
            valid = false;
        } else {
            this.setState({ stationsError: false });
        }
        if(this.isCompanyUser(0) && !(this.state.stationGroup.companyType && this.state.stationGroup.companyType.value)){
            this.setState({ companyInputError: true });
            valid = false;
        } else {
            this.setState({ companyInputError: false });
        }
        if (valid) {
            let lStations = [];
            this.state.selectedStations.forEach(s => lStations.push(s.id));
            let lStationGroup = {
                id: this.state.renderMode === CONSULT_MODE ? this.state.stationGroup.id : '',
                name: this.state.stationGroup.name,
                description: this.state.stationGroup.description,
                company: this.state.stationGroup.companyType && this.state.stationGroup.companyType.value ? this.state.stationGroup.companyType.value : '',
                editable: this.state.stationGroup.editable,
                stations: lStations
            }
            if (this.state.renderMode === CREATION_MODE) {
                this.props.createStationGroup(lStationGroup);
            }
            if (this.state.renderMode === CONSULT_MODE) {
                this.props.updateStationGroup(lStationGroup);
            }
        } else {
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        }
    }

    delete() {
        this.setState({ showConfirm: true });
    }

    confirmDelete() {
        this.props.deleteStationGroup(this.state.stationGroupId);
    }

    cancel() {
        this.setState({ renderMode: LIST_MODE, stationsError: false, isApiError: false});
    }

    isEditionMode() {
        return this.state.renderMode === CREATION_MODE || this.state.renderMode === CONSULT_MODE
    }

    groupeName(rowData) {
        let name = rowData.name ? rowData.name : "";
        if (name != null && name.length > 80)
            return <div className="hasTooltip p-ellipsis"> {name} <span> {name}  </span></div>;
        else
            return <div> {name} </div>

    }

    description(rowData) {
        let description = rowData.description ? rowData.description : "";
        if (description != null && description.length > 80)
            return <div className="hasTooltip p-ellipsis"> {description} <span> {description}  </span></div>;
        else
            return <div> {description} </div>
    }

    groupCompany(row){
        return row.company ? msg('group.companyName.' + row.company)  : "";
    }

    stationName(rowData) {
        let name = rowData.name ? rowData.name : "";
        if (name != null && name.length > 50)
            return <div className="hasTooltip p-ellipsis"> {name} <span> {name}  </span></div>;
        else
            return <div> {name} </div>

    }

    brand(rowData) {
        let brand = rowData.brand ? rowData.brand : "";
        if (brand != null && brand.length > 25)
            return <div className="hasTooltip p-ellipsis"> {brand} <span> {brand}  </span></div>;
        else
            return <div> {brand} </div>
    }

    getStationGroupsColumns() {
        let stationGroupsColumns = [
            { isColWithBody: false, field: "id", header: "id", sortable: false, className: "visibilityHidden" },
            { isColWithBody: true, field: "name", header: msg('station.groups.name'), body: this.groupeName, sortable: true, className: "p-col-4" },
            { isColWithBody: true, field: "description", header: msg('station.groups.description'), body: this.description, sortable: true, className: "p-col-6" },
            { isColWithBody: true, field: "company", header: msg('station.groups.company'), body: this.groupCompany, sortable: true, className: "p-col-2" }
        ]
        if (this.isEditionMode()) {
            stationGroupsColumns.unshift({ isColWithBody: false, selectionMode: "multiple", className: "p-col-1" });
        }
        return stationGroupsColumns;
    }

    getStationsColumns() {
        let stationsColumns = [
            { isColWithBody: false, field: "id", header: "id", sortable: false, className: "visibilityHidden" },
            { isColWithBody: false, field: "implant", header: msg('station.group.stations.implant'), sortable: true, className: "p-col-3" },
            { isColWithBody: true, field: "name", header: msg('station.group.stations.name'), body: this.stationName, sortable: true, className: "p-col-5" },
            { isColWithBody: true, field: "brand", header: msg('station.group.stations.brand'), body: this.brand, sortable: true, className: "p-col-3" }
        ]
        if (this.isEditionMode()) {
            stationsColumns.unshift({ isColWithBody: false, selectionMode: "multiple", className: "p-col-1" });
        }
        return stationsColumns;
    }

    getStationsTitle(groupName, count) {
        return groupName && count  ? 
            msg('station.group.stations.list.title.count.group', {p0: groupName, p1: count}) : 
            msg('station.group.stations.list.title') ;
    }

    updateStation() {
        let station =  this.state.selectedStations && !Array.isArray(this.state.selectedStations) ? this.state.selectedStations : null
        if(station) {
            this.props.loadStation(station.implant);
            this.props.history.push('/station/update');
        }
    }

    isCompanyUser(i){
        let lCompanyTypes = rolesValidator.getCompanyTypes();
        return lCompanyTypes && lCompanyTypes.length > 0 && lCompanyTypes.includes(companyTypes[i].value);
    }

    onSort = (e, page) => {
        let filter = Object.assign({}, this.state.stationGroupsFilter);
        let sortDirection = this.state.sortDirection === "ASC" ? "DESC" : "ASC";
        filter.sortProperty = {key: e.sortField, value: sortDirection};
        this.load(page, userSessionParamsHandler.getPageSize(), filter);
        this.setState({sortDirection: sortDirection, stationGroupsFilter: filter });
    }
    
    onStationsSort = (e, page) => {
        let filter = Object.assign({}, this.state.stationsFilter);
        let stationSortDirection = this.state.stationSortDirection === "ASC" ? "DESC" : "ASC";
        filter.sortProperty = {key: e.sortField, value: stationSortDirection};
        this.setState({stationSortDirection: stationSortDirection, filter: filter });
        this.loadStations(page, userSessionParamsHandler.getPageSize(), filter);
        this.setState({ stationsFilter: filter });
    }

    searcheBykeyWord(o) {
        let lFilter = Object.assign({}, this.state.stationsFilter);
        let keyword = o.idSearch ? o.idSearch : "";
        lFilter.label = keyword;
        this.loadStations(0, userSessionParamsHandler.getPageSize(), lFilter);
    }

    render() {
        let authorized = rolesValidator.validate([STATIONGROUPS]);
        let cAuthorized = authorized.toString().includes(CREATE);
        let rAuthorized = authorized.toString().includes(READ);
        let uAuthorized = authorized.toString().includes(UPDATE);
        let dAuthorized = authorized.toString().includes(DELETE);
        let data = this.props.stationGroupsData;
        let stationGroups = data && data.stationGroups ? data.stationGroups : [];
        let totalPages = data && data.totalPages ? data.totalPages : 0;
        let stations = this.state.stations ? this.state.stations : [];
        let stationsTotalPages = this.state.stationsTotalPages ? this.state.stationsTotalPages : 0;
        let stationsTotalElements = this.state.stationsTotalElements ? this.state.stationsTotalElements : 0;
        let selectedGroupName = !this.isEditionMode() && this.state.selectedGroups ? this.state.selectedGroups.name : '';
        let selectedStations = this.state.selectedStations ? this.state.selectedStations : [];
        let stationGroupsColumns = this.getStationGroupsColumns();
        let stationsColumns = this.getStationsColumns();
        let stationGroupsTable = (
            <div className="p-col-12">
            <TableData data={stationGroups} titleTable={this.isEditionMode() ? msg('station.groups.filtred.list.title') : msg('station.groups.list.title')}
                dataKey="id" selection={this.state.selectedGroups} totalPages={totalPages}
                getDataFunction={this.load} paramnFunctionData={null} filter={this.state.stationGroupsFilter} 
                onSelection={this.isEditionMode() ? this.onGroupsSelection : this.onSelection}
                columns={stationGroupsColumns} selectionMode={this.isEditionMode() ? "" : "single"}
                onSort={this.onSort}
            />
            </div>);
        let stationsTable = (
            <div className="p-col-12">
                 <h1>{msg('station.groups.filtred.list.description.title')}</h1>
            <TableData data={stations} titleTable={this.getStationsTitle(selectedGroupName, stationsTotalElements)}
                columns={stationsColumns} totalPages={stationsTotalPages}
                loading={!(this.state.selectedGroups && this.state.selectedGroups.length === 0)}
                searchByName={true} nameTxtPlaceholder={msg('station.groups.search.by.name')}
                dataKey="id" selection={selectedStations}
                getDataFunction={this.loadStations} paramnFunctionData={null} filter={this.state.stationsFilter}
                onSelection={this.onStationsSelection} selectionMode={this.isEditionMode() ? "" : "single"}
                onSort={this.onStationsSort}
                apply={this.searcheBykeyWord}
            />
            </div>);

        return rAuthorized && (
            <div className="p-grid commonClass card card-w-title">
                <div className="p-col-12">
                    <h2>{msg('station.groups.title')}</h2>
                </div>
                {this.isEditionMode() &&
                    <div className="card card-w-title p-col-12">
                        <div className="p-grid form-group p-col-12">
                            <div className="p-grid p-col-6">
                                <div className="p-grid p-col-3">
                                    <div className="p-col-10"><label>{msg('station.group.form.input.name')}</label></div>
                                    <div className="p-col-1"><span className="obligatoire">*</span></div>
                                    <div className="p-col-1"></div>
                                </div>
                                <div className="p-col-9">
                                    <span className="md-inputfield">
                                        <InputText value={this.state.stationGroup.name} className="inputClass" id="name" placeholder={msg('station.group.form.input.name')}
                                            size="30" maxLength="50"
                                            onChange={e => this.setState({ stationGroup: Object.assign({}, this.state.stationGroup, { name: e.target.value }), nameInputError: false })}
                                            key="name" readOnly={!this.state.stationGroup.editable} />
                                    </span>
                                    {this.state.nameInputError && <Message severity="error" text={msg('station.group.form.input.name.required')} key="name" />}
                                </div>
                            </div>
                            {this.isCompanyUser(0) && 
                            <div className="p-grid p-col-6">
                                <div className="p-grid p-col-3">
                                    <div className="p-col-10"><label>{msg('station.group.form.input.company')}</label></div>
                                    <div className="p-col-1"><span className="obligatoire">*</span></div>
                                    <div className="p-col-1"></div>
                                </div>
                                <div className="p-col-6">
                                    <span className="md-inputfield">
                                        <Dropdown options={companyTypes} optionLabel="key" value={this.state.stationGroup.companyType} placeholder={msg('station.group.form.input.company.select')}
                                            onChange={e => this.setState({stationGroup: Object.assign({}, this.state.stationGroup, { companyType: e.target.value })})}/>
                                    </span>
                                    {this.state.companyInputError && <Message severity="error" text={msg('station.group.form.input.company.required')} key="company" />}
                                </div>
                            </div>}
                            <div className="p-grid p-col-6">
                                <div className="p-grid p-col-3">
                                    <div className="p-col-11"><label>{msg('station.group.form.input.description')}</label></div>
                                    <div className="p-col-1"></div>
                                </div>
                                <div className="p-col-9">
                                    <span className="md-inputfield">
                                        <InputText value={this.state.stationGroup.description} className="inputClass" id="description" placeholder={msg('station.group.form.input.description')}
                                            size="50" maxLength="75"
                                            onChange={e => this.setState({ stationGroup: Object.assign({}, this.state.stationGroup, { description: e.target.value }) })}
                                            key="name" readOnly={!this.state.stationGroup.editable} />
                                    </span>
                                </div>
                            </div>
                            {this.state.selectedGroupsError && <div className=" p-col-7">
                            <Message severity="error" text={msg('station.group.form.list.group.of.stations.required')} key="stations" />
                            </div>}
                            {!this.state.selectedGroupsError && this.state.stationsError && <div className=" p-col-7">
                            <Message severity="error" text={msg('station.group.form.list.stations.required')} key="stations" />
                            </div>}
                        </div>
                    </div>}
                {!this.isEditionMode() &&
                    <div className="p-col-12">
                        <div className="item p-col-12 p-md-3" />
                        <div className="btnSelected item">
                            <Button label={msg('station.groups.button.create')} tooltip={msg('station.groups.button.create')}
                                key="create" onClick={this.create} disabled={!cAuthorized} />
                        </div>
                        <div className={this.state.deleteButtonSelected ? "btnSelected item" : "btnNotSelected item"}>
                            <Button label={msg('station.groups.button.update')} tooltip={msg('station.groups.button.update')}
                                key="update" onClick={this.update} disabled={!(this.state.deleteButtonSelected && uAuthorized)} />
                        </div>
                        <div className={this.state.deleteButtonSelected ? "btnSelected item" : "btnNotSelected item"}>
                            <Button label={msg('station.groups.button.delete')} tooltip={msg('station.groups.button.delete')}
                                key="delete" onClick={this.delete} disabled={!(this.state.deleteButtonSelected && dAuthorized)} />
                        </div>
                    </div>}
                <Fieldset className="p-col-12 card card-w-title" toggleable={true}>
                    {stationGroupsTable}
                </Fieldset>
                {/*(!this.isEditionMode() && this.state.stationButtonSelected) &&
                <div className="p-col-12">
                    <div className="item p-col-12 p-md-6" />
                    <div className={this.state.stationButtonSelected ? "btnSelected item" : "btnNotSelected item"}>
                        <Button label={msg('station.groups.button.update')} tooltip={msg('station.groups.button.update')}
                            key="updateStation" onClick={this.updateStation} disabled={this.state.stationButtonSelected ? "" : "disabled"} />
                    </div>
                </div>*/}
                <Fieldset className="p-col-12 card card-w-title" toggleable={true}>
                    {stationsTable}
                </Fieldset>
                {this.isEditionMode() &&
                    <div className="p-grid p-col-12">
                        <div className="p-grid p-col-9">
                        </div>
                        <div className="p-grid p-col-3">
                            <Button label={msg('station.groups.form.button.validate')} title={msg('station.groups.form.button.validate')}
                                key="validate" disabled={!(this.state.stationGroup.editable && (uAuthorized || cAuthorized))}
                                onClick={this.validate} />
                            <Button label={msg('station.groups.form.button.cancel')} title={msg('station.groups.form.button.cancel')}
                                key="cancel" onClick={this.cancel} />
                        </div>
                    </div>
                }
                <MessageDialog confirm={true} className={'message-dialog confirm-message'} visible={this.state.showConfirm} title={msg('message.dialog.confirm.title')}
                    message={this.state.showConfirm && msg('station.group.delete.confirm.message', { stationGroupName: this.state.stationGroupName })} key="confirm-delete"
                    onConfirm={() => { this.setState({ showConfirm: false }); this.confirmDelete() }} onCancel={() => this.setState({ showConfirm: false })} />
                
                <MessageDialog className={'message-dialog error-message'} visible={this.state.isApiError} title={msg('message.dialog.error.title')}
                    message={msg(this.state.message, { stationGroupName: this.state.stationGroupName }) }
                    onHide={() => this.setState({ isApiError: false, message: '' })} />
            </div>);
    }
}

export default StationGroup;