import React, {Component} from 'react';
import {rolesValidator, ADMINISTRATION, EQUIPMENTS, STATIONS, USERS, ROLE, GROUPS} from '../../utils/RolesValidator.js';


export class Administration extends Component {
	
    render() { 

		let authorized = rolesValidator.validate([ADMINISTRATION, EQUIPMENTS, STATIONS, USERS, ROLE, GROUPS]);     	    	

		return authorized && 
			(
				<div className="p-grid">
					<div className="p-col-12">
						<div className="card">
							<h1>Administration Page TOTAL</h1>
							<ul>
								<li>Articles
									<ul>
										<li>Visualisation</li>
										<li>Zone de prix</li>
									</ul>
								</li>
								<li>Campagnes</li>
								<li>Utilisateurs</li>
								<li>Stations
									<ul>
									<li>Edition</li>
									<li>Equipements</li>
									<li>Suppression Borne</li>
									</ul>
								</li>
							<li>Taux mandats</li>

							</ul>
						</div>
					</div>
				</div>
			);
    }
}