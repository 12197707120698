import React, { Component } from "react";
import { Link } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Chips } from "primereact/chips";
import { Message } from "primereact/message";
import { MultiSelect } from "primereact/multiselect";
import MessageDialog from "../../common/MessageDialog";
import { validateEmails } from "./validateEmails";
import { AutoComplete } from "primereact/autocomplete";
import {
  rolesValidator,
  NOTIFICATIONS,
  CREATE,
} from "../../../utils/RolesValidator.js";
import msg from "../../../texts/msg";
import { EMAIL_VALID_TEXT } from "../../../utils/RegexPatterns";
import "../../common/css/common.css";
import "./Notification.css";
const ALL_STATIONS = {
  key: msg("subscription.maintenance.all.stations"),
  value: "",
};
const EMPTYCHAR = "";
const USER = "USER";
const SYSTEM = "SYSTEM";
const BORNE = "BORNE";
const AUTOMATEDFLOWS = "AUTOMATEDFLOWS";
const API = "API";
export class NotificationCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSending: false,
      isName: false,
      isModifiable: true,
      isValid: false,
      stations: [],
      abonnement: {
        eventBorne: false,
        id: null,
        name: EMPTYCHAR,
        description: EMPTYCHAR,
        destinataires: [],
        eventBornes: [],
        eventSystemesType: [],
        eventSystemesSubTypeUser: [],
        eventSystemesSubTypeSystem: [],
        eventSystemesSubTypeFlows: [],
        criticites: [],
        stations: [ALL_STATIONS],
      },
      query: EMPTYCHAR,
    };
    this.eventSystemes = [
      { label: msg("filter.item.borne"), value: BORNE },
      { label: msg("filter.item.automatedflows"), value: AUTOMATEDFLOWS },
      { label: msg("filter.item.user"), value: USER },
      { label: msg("filter.item.system"), value: SYSTEM },
      { label: msg("filter.item.api"), value: API },

    ];
    this.userEvents = [
      {
        label: msg("filter.eventsysteme.connection.user"),
        value: "CONNECTION",
      },
      { label: msg("filter.eventsysteme.error"), value: "USER_ERROR" },
      { label: msg("filter.eventsysteme.other"), value: "USER_OTHER" },
    ];
    this.systemEvents = [
      { label: msg("filter.eventsysteme.campagne"), value: "CAMPAIGN" },
      { label: msg("filter.eventsysteme.fiscale"), value: "FISCALE" },
      { label: msg("filter.eventsysteme.startup"), value: "SERVER_STARTUP" },
    ];
    this.automatedFlowsEvents = [
      { label: msg("filter.eventsysteme.posWashAdmin"), value: "POS" },
      { label: msg("filter.eventsysteme.standalone"), value: "STANDALONE" },
      { label: msg("filter.eventsysteme.importPriceZone"), value: "PRICEZONE" },
      { label: msg("filter.eventsysteme.importMRN"), value: "MRN" },
      { label: msg("filter.eventsysteme.exportBBL"), value: "BBL" },
      { label: msg("filter.eventsysteme.paymentFlow"), value: "PAYMENTFLOW" },
      { label: msg("filter.eventsysteme.report"), value: "REPORT" },
    ];
    this.criticites = [
      { label: msg("subscription.criticality.information"), value: "INFO" },
      {
        label: msg("subscription.criticality.minoravertissement"),
        value: "MINOR_WARNING",
      },
      {
        label: msg("subscription.criticality.avertissement"),
        value: "MAJOR_WARNING",
      },
      { label: msg("subscription.criticality.erreur"), value: "ERROR" },
    ];
    this.eventBornes = [
      { label: msg("type.periph.gantry"), value: "GANTRY" },
      { label: msg("type.periph.bank.card.reader"), value: "BANK_CARD_READER" },
      { label: msg("type.periph.user.card.reader"), value: "USER_CARD_READER" },
      { label: msg("type.periph.bill.reader"), value: "BILL_READER" },
      { label: msg("type.periph.coin.acceptor"), value: "COIN_ACCEPTOR" },
      { label: msg("type.periph.hopper"), value: "HOPPER" },
      { label: msg("type.periph.card.dispenser"), value: "CARD_DISPENSER" },
      { label: msg("type.periph.printer"), value: "PRINTER" },
      { label: msg("type.periph.coin.changer"), value: "COIN_CHANGER" },
      { label: msg("type.periph.terminal.it.self"), value: "TERMINAL_ITSELF" },
      { label: msg("type.periph.scan.code.reader"), value: "SCAN_CODE_READER" },
      { label: msg("type.periph.relay.board"), value: "RELAY_BOARD" },
      { label: msg("type.periph.network"), value: "NETWORK" },
      { label: msg("type.periph.jetWash"), value: "JET_WASH" },
      { label: msg("type.periph.unknown"), value: "UNKNOWN" },
      { label: msg("type.periph.led.controler"), value: "LED_CONTROLER" },
      { label: msg("type.periph.light.sensor"), value: "LIGHT_SENSOR" },
    ];
    this.doOnSave = this.doOnSave.bind(this);
    this.send = this.send.bind(this);
    this.filterStations = this.filterStations.bind(this);
    this.selectStations = this.selectStations.bind(this);
    this.includes = this.includes.bind(this);
  }

  doOnSave() {
    if (!this.state.abonnement.name || !this.state.abonnement.name.trim()) {
      this.setState({ isName: true });
    } else {
      if (!this.state.isSending) {
        this.setState({ showWarn: true });
      } else if (validateEmails(this)) {
        this.props.createNotification(this.state.abonnement);
        this.setState({ isSending: false });
        this.props.history.push("/notification");
      }
    }
  }

  componentDidMount() {
    this.props.setDataInfoBar(null);
    this.props.loadSystemEventsFilter(NOTIFICATIONS);
    this.loadStations(this.state.query);
  }

  componentDidUpdate(prevProps) {
    let subscribersData = this.props.subscribersData
      ? this.props.subscribersData
      : null;
    if (
      !prevProps.subscribersData &&
      subscribersData &&
      this.state.isModifiable
    ) {
      this.setState({
        abonnement: Object.assign({}, this.state.abonnement, {
          destinataires: subscribersData.destinataires
            ? subscribersData.destinataires
            : [],
          eventBorne: subscribersData.eventBorne,
        }),
      });
    }
    let prevChange =
      prevProps.stationsData && prevProps.stationsData.lastChange;
    let lastChange =
      this.props.stationsData && this.props.stationsData.lastChange;
    let change = lastChange && (!prevChange || prevChange < lastChange);
    if (change) {
      let stations = [ALL_STATIONS];
      this.props.stationsData.stations.forEach((s) =>
        stations.push({ key: s.implant + "(" + s.name + ")", value: s.implant })
      );
      this.setState({ filteredStations: stations });
    }
  }

  send() {
    if (validateEmails(this) && this.validateText()) {
      this.props.sendNotification(this.state.abonnement);
      this.setState({ isSending: true });
    }
  }

  validateText() {
    let valid = EMAIL_VALID_TEXT.test(this.state.abonnement.name);
    if (!valid) {
      this.setState({ isInvalidText: true });
    }
    return valid;
  }

  filterStations(event, isDropdownClick) {
    let query = event.query.toLowerCase();
    if (!isDropdownClick) {
      if (!query || query === this.state.query) {
        return;
      }
    } else if (query && query === this.state.query) {
      return;
    }
    this.loadStations(isDropdownClick ? this.state.query : query);
    this.setState({ query: query });
  }

  loadStations(query) {
    let filter = { label: query, page: 0, pageSize: 10 };
    this.props.loadStations(NOTIFICATIONS, filter);
  }

  selectStations(event) {
    let lStations = event.target.value;
    if (lStations && lStations.length > 1) {
      lStations = lStations.filter((s) => s.value);
    }
    this.setState({
      isModifiable: false,
      abonnement: Object.assign({}, this.state.abonnement, {
        stations: lStations,
      }),
    });
  }

  includes(array, element) {
    let exist = false;
    array.forEach((e) => {
      if (e === element) {
        exist = true;
      }
    });
    return exist;
  }

  render() {
    let authorized = rolesValidator.validate(NOTIFICATIONS);
    let cAuthorized = authorized.toString().includes(CREATE);
    let abonnement = this.state.abonnement;
    let eventsSystemType = [
      { key: msg("subscription.maintenance.all.categories"), value: "" },
    ];

    if (
      this.props.systemEventsFilterData &&
      this.props.systemEventsFilterData.systemEventTypes
    ) {
      this.props.systemEventsFilterData.systemEventTypes.forEach((e) =>
        eventsSystemType.push({
          key: msg("filter.item." + e.key.toLowerCase()),
          value: e.key,
        })
      );
    }
    return (
      cAuthorized && (
        <div className="card commonClass">
          <div className="card card-w-title p-col-12">
            <div className="p-grid form-group p-col-12">
              {
                <div className="p-grid p-col-6">
                  <div className="p-grid p-col-3">
                    <div className="p-col-5">
                      <label>{msg("group.fields.name")}</label>
                    </div>
                    <div className="">
                      <span className="obligatoire">*</span>
                    </div>
                    <div className="p-col-1"></div>
                  </div>
                  <div className="p-col-9">
                    <span className="md-inputfield">
                      <InputText
                        className="inputClass"
                        id="name"
                        type="text"
                        placeholder={msg(
                          "subscription.maintenance.description.name"
                        )}
                        size="30"
                        maxLength="30"
                        value={this.state.abonnement.name}
                        onChange={(e) =>
                          this.setState({
                            isModifiable: false,
                            isName: false,
                            isInvalidText: false,
                            abonnement: Object.assign(
                              {},
                              this.state.abonnement,
                              { name: e.target.value }
                            ),
                          })
                        }
                      />
                    </span>
                    {this.state.isName && (
                      <Message
                        severity="error"
                        text={msg("group.input.name.required")}
                        key="name"
                      />
                    )}
                    {this.state.isInvalidText && (
                      <Message
                        severity="error"
                        text={msg("subscription.maintenance.invalidtext")}
                        key="nameTxt"
                      />
                    )}
                  </div>
                </div>
              }
              {<div className="p-grid p-col-6"></div>}
            </div>
            <div className="p-grid form-group p-col-12">
              {
                <div className="p-grid p-col-6">
                  <div className="p-grid p-col-3">
                    <div className="p-col-8">
                      {" "}
                      <label>{msg("group.fields.description")}</label>
                    </div>
                  </div>
                  <div className="p-col-9">
                    <span className="md-inputfield">
                      <InputText
                        className="inputClass"
                        id="description"
                        type="text"
                        placeholder={msg(
                          "subscription.maintenance.description"
                        )}
                        size="60"
                        value={this.state.abonnement.description}
                        onChange={(e) =>
                          this.setState({
                            isModifiable: false,
                            abonnement: Object.assign(
                              {},
                              this.state.abonnement,
                              { description: e.target.value }
                            ),
                          })
                        }
                      />
                    </span>
                  </div>
                </div>
              }
            </div>
          </div>
          <br />
          <div className="p-grid  p-col-12">
            <div className="p-grid p-col-3">
              <div className="p-col-5">
                <h3>{msg("subscription.maintenance.recipien")} </h3>
              </div>
              <div className="p-col-1"></div>
              <div className="p-col-1">
                <span className="obligatoire">*</span>
              </div>
            </div>
            <div className="p-grid p-col-2">
              <Button
                type="button"
                onClick={this.send}
                icon="pi-md-send"
                className="but"
                tooltip={msg("notification.send.email")}
                style={{ marginTop: "11px", float: "left" }}
                disabled={!cAuthorized}
              />
              <span style={{ marginTop: "18px" }}>
                {msg("subscription.maintenance.button.send")}
              </span>
            </div>
            <div className="p-grid p-col-8">
              <span></span>
            </div>
            <div className="p-grid p-col-12">
              <span className="infos">
                {msg("subscription.maintenance.entry.message")}{" "}
              </span>
            </div>
          </div>
          <div className="card card-w-title p-col-12">
            <div className="p-col-9">
              {abonnement.destinataires && (
                <Chips
                  value={abonnement.destinataires}
                  onChange={(e) =>
                    this.setState({
                      isModifiable: false,
                      abonnement: Object.assign({}, this.state.abonnement, {
                        destinataires: e.target.value,
                      }),
                    })
                  }
                ></Chips>
              )}
              {this.state.isDestinataires && (
                <span className="error">
                  {msg("subscription.maintenance.required")}
                </span>
              )}
              {this.state.error && (
                <Message severity="error" text={this.state.error} key="name" />
              )}
            </div>
          </div>
          <h3>{msg("subscription.maintenance.source.events")}</h3>
          <div className="card card-w-title p-col-12">
            <br />
            <div className="p-grid p-col-12">
              <label htmlFor="acSimple">
                {msg("subscription.maintenance.stations")}
              </label>
              <div className="p-col-12" style={{ marginBottom: "10px" }}>
                <span className="infos">
                  {msg("subscription.maintenance.select.message.station")}
                </span>
                <br></br>
                <AutoComplete
                  placeholder={msg("subscription.maintenance.all.stations")}
                  minLength={0}
                  size={60}
                  dropdown={true}
                  multiple={true}
                  delay={100}
                  field="key"
                  value={this.state.abonnement.stations}
                  suggestions={this.state.filteredStations}
                  completeMethod={this.filterStations}
                  onDropdownClick={(e) => this.filterStations(e, true)}
                  onChange={this.selectStations}
                  id="stons"
                  key="stationsKey"
                />
              </div>
            </div>
            {!this.state.abonnement.eventBorne && (
              <div className="p-grid p-col-12">
                <label htmlFor="acAdvanced">
                  {msg("subscription.maintenance.system.events")}
                </label>
                <div className="p-grid p-col-12">
                  <div className="p-col-12">
                    <div>
                      <span className="infos">
                        {msg("subscription.maintenance.select.message.system")}
                      </span>
                    </div>
                    <br></br>
                    <div className="p-grid p-col-12">
                      <div className="p-grid p-col-12">
                        <div className="p-col-2">
                          <span>
                            {msg("subscription.maintenance.category.label")}
                          </span>
                        </div>
                        <div className="p-col-10 autocomplete">
                          <MultiSelect
                            value={this.state.abonnement.eventSystemesType}
                            options={this.eventSystemes}
                            onChange={(e) =>
                              this.setState({
                                abonnement: Object.assign(
                                  {},
                                  this.state.abonnement,
                                  { eventSystemesType: e.target.value }
                                ),
                              })
                            }
                            style={{ minWidth: "50%" }}
                            placeholder={msg(
                              "subscription.maintenance.all.categories"
                            )}
                            selectedItemsLabel={ this.state.abonnement.eventSystemesType.length + " " 
                              + (this.state.abonnement.eventSystemesType.length === this.eventSystemes.length ? 
                              msg("subscription.maintenance.all.subcategories") : msg("subscription.maintenance.selected.subcategories"))}
                            maxSelectedLabels={this.eventSystemes.length < 10 ? this.eventSystemes.length - 1 : 10}
                          />
                        </div>
                      </div>

                      <div
                        className=" p-grid p-col-12"
                        style={{ marginLeft: "1px" }}
                      >
                        <div className="p-grid  p-col-12">
                          {this.state.abonnement.eventSystemesType &&
                            this.includes(
                              this.state.abonnement.eventSystemesType,
                              USER
                            ) && (
                              <div className="p-grid p-col-4 ">
                                <div className="p-col-3">
                                  <br />
                                  <br />
                                  <span>
                                    {msg(
                                      "subscription.maintenance.subCategory.user.label"
                                    )}
                                  </span>
                                </div>
                                <div className="p-col-6">
                                  <br />
                                  <br />
                                  <MultiSelect
                                    value={
                                      this.state.abonnement
                                        .eventSystemesSubTypeUser
                                    }
                                    options={this.userEvents}
                                    onChange={(e) =>
                                      this.setState({
                                        abonnement: Object.assign(
                                          {},
                                          this.state.abonnement,
                                          {
                                            eventSystemesSubTypeUser:
                                              e.target.value,
                                          }
                                        ),
                                      })
                                    }
                                    style={{ minWidth: "50%" }}
                                    placeholder={msg(
                                      "subscription.maintenance.all.subcategories"
                                    )}
                                    selectedItemsLabel={ this.state.abonnement.eventSystemesSubTypeUser.length + " " 
                                      + (this.state.abonnement.eventSystemesSubTypeUser.length === this.userEvents.length ? 
                                      msg("subscription.maintenance.all.subcategories") : msg("subscription.maintenance.selected.subcategories"))}
                                    maxSelectedLabels={this.userEvents.length < 10 ? this.userEvents.length - 1 : 10}
                                  />
                                </div>
                                <div className=" p-col-3"></div>
                              </div>
                            )}
                          {this.state.abonnement.eventSystemesType &&
                            this.includes(
                              this.state.abonnement.eventSystemesType,
                              SYSTEM
                            ) && (
                              <div className="p-grid p-col-4 ">
                                <div className="p-col-3">
                                  <br />
                                  <br />
                                  <span>
                                    {msg(
                                      "subscription.maintenance.subCategory.system.label"
                                    )}
                                  </span>
                                </div>
                                <div className="p-col-6">
                                  <br />
                                  <br />
                                  <MultiSelect
                                    value={
                                      this.state.abonnement
                                        .eventSystemesSubTypeSystem
                                    }
                                    options={this.systemEvents}
                                    onChange={(e) =>
                                      this.setState({
                                        abonnement: Object.assign(
                                          {},
                                          this.state.abonnement,
                                          {
                                            eventSystemesSubTypeSystem:
                                              e.target.value,
                                          }
                                        ),
                                      })
                                    }
                                    style={{ minWidth: "50%" }}
                                    placeholder={msg(
                                      "subscription.maintenance.all.subcategories"
                                    )}
                                    selectedItemsLabel={ this.state.abonnement.eventSystemesSubTypeSystem.length + " " 
                                      + (this.state.abonnement.eventSystemesSubTypeSystem.length === this.systemEvents.length ? 
                                      msg("subscription.maintenance.all.subcategories") : msg("subscription.maintenance.selected.subcategories"))}
                                    maxSelectedLabels={this.systemEvents.length < 10 ? this.systemEvents.length - 1 : 10}
                                  />
                                </div>
                                <div className="p-col-3"></div>
                              </div>
                            )}
                          {this.state.abonnement.eventSystemesType &&
                            this.includes(
                              this.state.abonnement.eventSystemesType,
                              AUTOMATEDFLOWS
                            ) && (
                              <div className="p-grid p-col-4 ">
                                <div className="p-col-4">
                                  <br />
                                  <br />
                                  <span>
                                    {msg(
                                      "subscription.maintenance.subCategory.automatedflows.label"
                                    )}
                                  </span>
                                </div>
                                <div className="p-col-6">
                                  <br />
                                  <br />
                                  <MultiSelect
                                    value={
                                      this.state.abonnement
                                        .eventSystemesSubTypeFlows
                                    }
                                    options={this.automatedFlowsEvents}
                                    onChange={(e) =>
                                      this.setState({
                                        abonnement: Object.assign(
                                          {},
                                          this.state.abonnement,
                                          {
                                            eventSystemesSubTypeFlows:
                                              e.target.value,
                                          }
                                        ),
                                      })
                                    }
                                    style={{ minWidth: "50%" }}
                                    placeholder={msg(
                                      "subscription.maintenance.all.subcategories"
                                    )}
                                    selectedItemsLabel={ this.state.abonnement.eventSystemesSubTypeFlows.length + " " 
                                      + (this.state.abonnement.eventSystemesSubTypeFlows.length === this.automatedFlowsEvents.length ? 
                                      msg("subscription.maintenance.all.subcategories") : msg("subscription.maintenance.selected.subcategories"))}
                                    maxSelectedLabels={this.automatedFlowsEvents.length < 10 ? this.automatedFlowsEvents.length - 1 : 10}
                                  />
                                </div>
                                <div className=" p-col-2"></div>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {this.state.abonnement.eventBorne && (
              <div className="p-grid p-col-12">
                <label htmlFor="acSimple">
                  {msg("subscription.maintenance.terminal.events")}
                </label>
                <div className="p-col-12">
                  <span className="infos">
                    {msg("subscription.maintenance.select.terminals.message")}
                  </span>
                  <MultiSelect
                    id="eventBornes"
                    value={this.state.abonnement.eventBornes}
                    options={this.eventBornes}
                    onChange={(e) =>
                      this.setState({
                        isModifiable: false,
                        abonnement: Object.assign({}, this.state.abonnement, {
                          eventBornes: e.target.value,
                          eventBorne: true,
                        }),
                      })
                    }
                    style={{ minWidth: "85%" }}
                    placeholder={msg("subscription.maintenance.evnements")}
                    selectedItemsLabel={ abonnement.eventBornes.length + " " 
                      + (abonnement.eventBornes.length === this.eventBornes.length ? 
                      msg("subscription.maintenance.evnements") : msg("subscription.maintenance.selected.evnements"))}
                    maxSelectedLabels={this.eventBornes.length < 10 ? this.eventBornes.length - 1 : 10}
                    onKeyUp={(e) => {
                      if (e.keyCode === 13) this.doOnSave();
                    }}
                  />
                </div>
                {this.state.iseventBornes && (
                  <span className="error">
                    {msg("subscription.maintenance.required")}
                  </span>
                )}
              </div>
            )}
            {this.state.abonnement.eventBorne && (
              <div className="p-grid p-col-12">
                <label htmlFor="acAdvanced">
                  {msg("subscription.maintenance.criticality.lavel")}
                </label>
                <div className="p-col-12">
                  <span className="infos">
                    {msg("subscription.maintenance.select.criticality.message")}
                  </span>
                  <MultiSelect
                    id="criticites"
                    value={this.state.abonnement.criticites}
                    options={this.criticites}
                    onChange={(e) =>
                      this.setState({
                        isModifiable: false,
                        abonnement: Object.assign({}, this.state.abonnement, {
                          criticites: e.target.value,
                        }),
                      })
                    }
                    style={{ minWidth: "85%" }}
                    placeholder={msg("subscription.maintenance.levels")}
                    selectedItemsLabel={ abonnement.criticites.length + " " 
                      + (abonnement.criticites.length === this.criticites.length ? 
                      msg("subscription.maintenance.levels") : msg("subscription.maintenance.selected.levels"))}
                    maxSelectedLabels={this.criticites.length < 10 ? this.criticites.length - 1 : 10}
                    onKeyUp={(e) => {
                      this.doOnSave();
                    }}                    
                  />
                </div>
                {this.state.iscriticites && (
                  <span className="error">
                    {msg("subscription.maintenance.required")}
                  </span>
                )}
              </div>
            )}
          </div>
          <br />
          <div className="p-grid p-col-12">
            <div className="p-grid p-col-9"></div>
            <div className="p-grid p-col-3">
              <Button
                label={msg("subscription.maintenance.validate")}
                title={msg("subscription.maintenance.validate")}
                onClick={this.doOnSave}
                className="button"
                disabled={!cAuthorized}
              />
              <Link to="/notification">
                <Button
                  label={msg("subscription.maintenance.cancel")}
                  className="button"
                />
              </Link>
            </div>
          </div>
          <br />
          <MessageDialog
            className={"message-dialog warn-message"}
            visible={this.state.showWarn}
            title={msg("message.dialog.warn.title")}
            message={
              this.state.showWarn &&
              msg("subscription.maintenance.send.mail.message")
            }
            key="warn-select"
            onHide={() => {
              this.setState({ showWarn: false });
            }}
          />
        </div>
      )
    );
  }
}
export default NotificationCreate;
