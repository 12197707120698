import { Fragment, Component } from "react";
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Calendar } from 'primereact/calendar';
import { Message } from 'primereact/message';
import { Dropdown } from 'primereact/dropdown';
import { AutoComplete } from 'primereact/autocomplete';
import msg from "../../../texts/msg";

export const POSTotalEnergies = {key: 'POSTotalEnergies', label: msg('station.contract.application.lot.number.postotalenergies')};
export const WashAdmin = {key: 'WashAdmin', label: msg('station.contract.application.lot.number.washadmin')};
const applicationLotNumbers = [POSTotalEnergies, WashAdmin];

export class Contract extends Component {

    render() {
        
        return <Fragment>
            {this.props.visible && <div className="p-col-12">
                <div className="p-grid form-group p-col-12">
                    <div className="p-grid p-col-6">
                        <div className="p-grid p-col-6">
                            <Checkbox value={this.props.contract.contractCicd}
                                onChange={e => { if (!this.props.readOnly) { this.props.onCicdChange(e) } }}
                                checked={this.props.contract.contractCicd} readOnly={this.props.readOnly} style={{marginLeft: '8px'}} />
                            <label>{msg('station.contract.cicd')}</label>
                        </div>
                    </div>
                    <div className="p-grid p-col-6">                        
                        <div className="p-grid p-col-6"><label>{msg('station.contract.application.lot.number')}</label></div>                        
                        <div className="p-grid p-col-6">
                            <Dropdown value={this.props.contract.applicationLotNumber} options={applicationLotNumbers}
                                itemTemplate={o => this.props.optionTemplate(o, POSTotalEnergies.label)}
                                onChange={e => { if (!this.props.readOnly) { this.props.onApplicationLotNumberChange(e) } }}
                                dataKey="key" optionLabel="label" disabled={this.props.readOnly}
                                placeholder={POSTotalEnergies.label} className="p-sm-12 p-md-4" />
                        </div>
                    </div>
                </div>
                <div className="p-grid form-group p-col-12">
                    {<div className="p-grid p-col-6">
                        <div className="p-grid p-col-6">
                            <div className="p-col-6"><label>{msg('station.fields.contract.startDate')}</label></div>
                            <div className=""><span className="obligatoire">*</span></div>
                        </div>
                        <div className="p-col-6">
                            <Calendar dateFormat='dd/mm/yy' selectOtherMonths={true} hideOnDateTimeSelect={true}
                                value={this.props.contract.contractStart}
                                readOnlyInput={this.props.readOnly} readOnly={this.props.readOnly} disabled={this.props.readOnly}
                                onChange={e => { if (!this.props.readOnly) { this.props.onStartChange(e) } }}
                                locale={this.props.locale}
                            />
                            {this.props.errors && !this.props.errors.isStartDateValid && <Message severity="error" text={msg('station.fields.contract.startDate.required')} />}
                            <p><small> {msg('station.fields.contract.format.date')} </small></p>

                        </div>
                    </div>}
                    {<div className="p-grid p-col-6">
                        <div className="p-grid p-col-6">
                            <div className="p-col-6"><label>{msg('station.fields.contract.endDate')}</label></div>
                            <div className=""><span className="obligatoire">*</span></div>
                        </div>
                        <div className="p-col-6">
                            <Calendar dateFormat='dd/mm/yy' selectOtherMonths={true} hideOnDateTimeSelect={true}
                                value={this.props.contract.contractEnd}
                                readOnlyInput={this.props.readOnly} readOnly={this.props.readOnly} disabled={this.props.readOnly}
                                onChange={e => { if (!this.props.readOnly) { this.props.onEndChange(e) } }}
                                locale={this.props.locale}
                            />
                            {this.props.errors && !this.props.errors.isEndDateValid && <Message severity="error" text={msg('station.fields.contract.endDate.required')} />}
                            {this.props.errors && !this.props.errors.isEndDateAftrStartDate && <Message severity="error" text={msg('station.fields.contract.endDate.after.startDate')} />}
                            <p><small> {msg('station.fields.contract.format.date')} </small></p>
                        </div>
                    </div>}
                </div>
                <div className="p-grid form-group p-col-12">
                    {<div className="p-grid p-col-6">
                        <div className="p-grid p-col-6">
                            <div className="p-col-6"> <label>{msg('station.fields.contract.saleChannel')}</label></div>
                            <div className=""><span className="obligatoire">*</span></div>
                        </div>
                        <div className="p-col-6">
                            <Dropdown value={this.props.contract.saleChannel} options={this.props.saleChannels}
                                itemTemplate={o => this.props.optionTemplate(o, msg('filter.channels.all'))}
                                onChange={e => { if (!this.props.readOnly) { this.props.onSaleChannelChange(e) } }}
                                dataKey="value" optionLabel="key" disabled={this.props.readOnly}
                                placeholder={msg('filter.channels.all')} className="p-sm-12 p-md-4" />
                            {this.props.errors && this.props.errors.isSaleChannel && <Message severity="error" text={msg('station.fields.contract.saleChannels.required')} key="startDate" />}
                        </div>
                    </div>}
                    {<div className="p-grid p-col-6">
                        <div className="p-grid p-col-6">
                            <div className="p-col-6"><label>{msg('station.fields.contract.user')}</label></div>
                        </div>
                        <div className="p-col-6">
                            <AutoComplete minLength={0} id="users" size={30} dropdown={true} multiple={false} field="value"
                                suggestions={this.props.filteredUsers} value={this.props.contract.user} delay={100}
                                completeMethod={this.props.filterUser} onDropdownClick={e => this.props.filterUser(e, true)}
                                onChange={e => { if (!this.props.readOnly) { this.props.onUserChange(e) } }} disabled={this.props.readOnly}
                            />
                        </div>
                    </div>}
                </div>
                <div className="p-grid form-group p-col-12">
                    {<div className="p-grid p-col-6">
                        <div className="p-grid p-col-6">
                            <div className="p-col-6"><label>{msg('station.fields.customer.code')}</label></div>
                            <div className=""><span className="obligatoire">*</span></div>
                        </div>
                        <div className="p-col-6">
                            <InputText value={this.props.contract.sapCode}
                                onChange={e => { if (!this.props.readOnly) { this.props.onSapCodeChange(e) } }}
                                maxLength="255" readOnly={this.props.readOnly} disabled={this.props.readOnly} />
                            {this.props.errors && this.props.errors.issapCode && <Message severity="error" text={msg('station.input.sapCode.required')} key="sapName" />}
                            {this.props.errors && this.props.errors.isSapCodeFormat && <Message severity="error" text={msg('station.input.sapCode.format.invalid')} key="sapFormatName" />}
                        </div>
                    </div>}
                    {<div className="p-grid p-col-6">
                        <div className="p-grid p-col-6">
                            <div className="p-col-6"><label>{msg('station.fields.customer.name')}</label></div>
                            <div className=""><span className="obligatoire">*</span></div>
                        </div>
                        <div className="p-col-6">
                            <InputText value={this.props.contract.sapName}
                                onChange={e => { if (!this.props.readOnly) { this.props.onSapNameChange(e) } }}
                                maxLength="255" readOnly={this.props.readOnly} disabled={this.props.readOnly} />
                            {this.props.errors && this.props.errors.issapName && <Message severity="error" text={msg('station.input.sapName.required')} key="sapName" />}
                        </div>
                    </div>}
                </div>
                <div className="p-grid form-group p-col-12">
                    {<div className="p-grid p-col-6">
                        <div className="p-grid p-col-6">
                            <div className="p-col-8"> <label>{msg('station.fields.contract.comment')}</label></div>
                        </div>
                        <div className="p-col-6">
                            <span className="md-inputfield">
                                <InputText className="inputClass" type="text" size="50" value={this.props.contract.comment ? this.props.contract.comment : ''}
                                    onChange={e => { if (!this.props.readOnly) { this.props.onCommentChange(e) } }}
                                    readOnly={this.props.readOnly} />
                            </span>

                        </div>
                    </div>}
                </div>
            </div>}
        </Fragment>
    }

}