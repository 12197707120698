import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { AutoComplete } from 'primereact/autocomplete';
import { Message } from 'primereact/message';
import { TabView, TabPanel } from 'primereact/tabview';
import MessageDialog from '../../common/MessageDialog';
import msg from '../../../texts/msg';
import { GPS_LATITUDE, GPS_LONGITUDE } from '../../../utils/RegexPatterns';
import { STATIONS, STATIONGROUPS, rolesValidator, UPDATE, READ } from '../../../utils/RolesValidator.js';
import { getCalendarLocale } from '../../../texts/locales/calendar-locale';
import { validateInputText, updateInputNumberFormat,isValidateInputNumber } from '../../../utils/validateInputData';
import { IP_PATTERN, NUMBER_PATTERN} from '../../../utils/RegexPatterns';
import { toLocaleDateTimeFormat, toLocaleDateTime } from '../../../utils/dateTimeUtils';
import { capitalizeFirstLetter } from '../../../utils/validateInputData';
import {TimeTable, getDefaultTimetableRefCopie, getTimetable} from './TimeTable';
import {languages, saleChannels} from '../../../utils/CommonData';
import { Contract, POSTotalEnergies, WashAdmin } from './Contract';
import './station.css';
import '../../common/css/common.css';


const STATION_ORIGIN_MRN_CREATION = "FROMMRN";
const ARRAY_STATUS_STATIONS = [ {key : "closed", value: msg("station.status.closed")},
                                {key : "open", value: msg("station.status.open")},
                                {key : "exceptionally_closed", value: msg("station.status.exceptionally.closed")}
                              ]

export class UpdateStation extends Component {

    constructor(props) {
        super(props);
        let sDate = new Date();
        sDate.setDate(1);
        let eDate = new Date();

        this.state = {
            isEndDateAftrStartDate: true,
            isEndDateValid: true,
            isStartDateValid: true,
            locale: getCalendarLocale(),

            filter: {},
            station: {
                id: null,
                name: "",
                brand: "",
                implant: "",
                comment: "",
                gpsLatitude: '',
                gpsLongitude: '',
                ticketFooter: "",
                ticketHeader: "",
                language: {},
                timezone: { key: msg('station.timezone'), value: 1 },
                priceZone: {},
                commission: {},
                contract: {
                    saleChannel: {key: '', value: ''},
		            contractCicd: false,
		            contractCicdDate: null,
		            contractStart: sDate,
		            contractEnd: eDate,
		            comment: '',
		            sapCode: '',
		            sapName: '',
                    user: {key: '', value: ''},
                    applicationLotNumber: POSTotalEnergies
                },
                lockPriceZone: false,
                useCoinSuction: false,
                coinSuctionDuration: 5,
                voipServer: '',
                inetPingTest: '',
                timetable: getDefaultTimetableRefCopie(),
                address: null,
                updateDate: undefined,
                status: undefined,
                originType: undefined,
                batchNumber: undefined,
                batchNumberDate: undefined,
                batchNumberReceptionDate: undefined
            },
            address: {
                street: undefined,
                postCode: undefined,
                city: undefined
            },
            userFilter: {
            },
            query: ''
        }
        this.doOnSave = this.doOnSave.bind(this);
        this.updateStation = this.updateStation.bind(this);
        this.fieldsControl = this.fieldsControl.bind(this);
        this.validateFields = this.validateFields.bind(this);
        this.optionTemplate = this.optionTemplate.bind(this);
        this.filterUser = this.filterUser.bind(this);
        this.handleSaleChannelChange = this.handleSaleChannelChange.bind(this);
        this.onContractCicdChange = this.onContractCicdChange.bind(this);
        this.onApplicationLotNumberChange = this.onApplicationLotNumberChange.bind(this);
        this.onContractStartChange = this.onContractStartChange.bind(this);
        this.onContractEndChange = this.onContractEndChange.bind(this);
        this.onContractUserChange = this.onContractUserChange.bind(this);
        this.onContractSapCodeChange = this.onContractSapCodeChange.bind(this);
        this.onContractSapNameChange = this.onContractSapNameChange.bind(this);
        this.onContractCommentChange = this.onContractCommentChange.bind(this);
        this.filterStationGroups = this.filterStationGroups.bind(this);
        this.unselectStationGroup = this.unselectStationGroup.bind(this);
        this.confirmContractChangeDialog = this.confirmContractChangeDialog.bind(this);
        this.confirmContractChange = this.confirmContractChange.bind(this);
        this.setTimetableState = this.setTimetableState.bind(this);
    }

    findTextFromArr = (arr, str) =>{
        let result = "";
        var element = {};

        if((str !== null) && (str !== undefined))
        {
           element = arr.find(element => element.key === str);
            if(element === null)
            {
               result = msg("common.undefined.status");
            }else{
                result = element.value;
            }
        } else{
            result = msg("common.undefined.status");
        }
     return result;
    }

    handleInputText(v, n, ticket_name )
    {
      const ticketHeader = "ticket_hdr";
      let charNbrLimitation = 30;
      const breakLinePos = 31;
      const char= "\n";
      let space = 0;
      let lines = v.split(char);

      for(let i = 0; i < lines.length; i++)
        {
          if (lines[i].length <= charNbrLimitation) continue;
          let j = 0; space = charNbrLimitation;
          while (j++ <= charNbrLimitation)
               {
                 if (lines[i].charAt(j) === ' ') space = j;
               }

          lines[i + 1] = lines[i].substring(space + 1) + (lines[i + 1] || "");
          lines[i] = lines[i].substring(0, space);
        }
      if(lines.length <= n)
        {
          v= lines.slice(0, breakLinePos).join(char);

          if(ticket_name.valueOf() === ticketHeader)
          {
          this.setState({ isModifiable: false, station: Object.assign({}, this.state.station, { ticketHeader: v }) })
          } else
          {
          this.setState({ isModifiable: false, station: Object.assign({}, this.state.station, { ticketFooter: v }) })
          }
        }
    }
    fieldsControl() {
        if (!this.state.station.name || !this.state.station.name.trim()) {
            this.setState({ isName: true });
        } else {
            this.setState({ isName: false });
        }
        if (!this.state.station.brand || !this.state.station.brand.trim()) {
            this.setState({ isBrand: true });
        } else {
            this.setState({ isBrand: false });
        }
        if (!this.state.station.implant || !this.state.station.implant.trim()) {
            this.setState({ isImplant: true })
        } else {
            this.setState({ isImplant: false });
        }
        if (!this.state.station.priceZone || !this.state.station.priceZone.value
            || !this.state.station.priceZone.value.toString().trim()) {
            this.setState({ isPriceZone: true });
        } else {
            this.setState({ isPriceZone: false });
        }
        if (!this.state.station.commission || !this.state.station.commission.key
            || !this.state.station.commission.key.toString().trim()) {
            this.setState({ isCommissionRate: true });
        } else {
            this.setState({ isCommissionRate: false });
        }

        if (!this.state.station.contract.saleChannel || !this.state.station.contract.saleChannel.value
            || !this.state.station.contract.saleChannel.value.trim()) {
            this.setState({ isSaleChannel: true })
        } else {
            this.setState({ isSaleChannel: false });
        }
        if (!this.state.station.contract.sapCode || !this.state.station.contract.sapCode.trim()) {
            this.setState({ issapCode: true, isSapCodeFormat: false })
        } else {
            this.setState({ issapCode: false });
            this.setState({ isSapCodeFormat: !isValidateInputNumber(this.state.station.contract.sapCode) })
        }
        if (!this.state.station.contract.sapName || !this.state.station.contract.sapName.trim()) {
            this.setState({ issapName: true })
        } else {
            this.setState({ issapName: false });
        }
        if (!this.state.station.contract.contractStart || !this.state.station.contract.contractStart.toString().trim()) {
            this.setState({ isStartDateValid: false })
        } else {
            this.setState({ isStartDateValid: true });
        }
        if (!this.state.station.contract.contractEnd || !this.state.station.contract.contractEnd.toString().trim()) {
            this.setState({ isEndDateValid: false })
        } else {
            this.setState({ isEndDateValid: true });
        }

    }

    validateFields() {
        let fields = [this.state.station.name, this.state.station.brand, this.state.station.implant, this.state.station.priceZone && this.state.station.priceZone.value,
            this.state.station.commission && this.state.station.commission.key];
        if (!validateInputText(fields)) {
            return false;
        }
        fields = [this.state.station.contract.saleChannel && this.state.station.contract.saleChannel.value
            , this.state.station.contract.sapName, this.state.station.contract.sapCode, this.state.station.contract.contractStart, this.state.station.contract.contractEnd, this.state.station.contract.sapCode];
        if (!validateInputText(fields)) {
            return false;
        }

        return isValidateInputNumber(this.state.station.contract.sapCode)
    }

    componentDidMount() {
        this.props.loadCommissions(0, 100000);
        this.props.loadPriceZones(0, 100000);
        let filter = { label: this.state.query, page: 0, pageSize: 10, feature: STATIONGROUPS};
        this.props.loadStationGroups(filter);
    }

    componentDidUpdate(prevProps, nextProps) {
        let prevError = prevProps.error && prevProps.error.timestamp;
        let lastError = this.props.error && this.props.error.timestamp;
        let error = (lastError && (!prevError || prevError < lastError));
        if (error) {
            let isError = this.props.error.message && this.props.error.message.startsWith('error.station.') ? true : false;
            this.setState({ isApiError: isError });
        }
        let loaded = (this.props.stationData && prevProps.stationData !== this.props.stationData )
        if (loaded) {
            let station = {
                id: this.props.stationData.id,
                name: this.props.stationData.name,
                brand: this.props.stationData.brand,
                implant: this.props.stationData.implant,
                comment: this.props.stationData.comment ? this.props.stationData.comment : '',
                gpsLatitude: this.props.stationData.gpsLatitude,
                gpsLongitude: this.props.stationData.gpsLongitude,
                timezone: { key: msg('station.timezone'), value: 1 },
                ticketFooter: this.props.stationData.ticketFooter ? this.props.stationData.ticketFooter : '',
                ticketHeader: this.props.stationData.ticketHeader ? this.props.stationData.ticketHeader : '',
                language: this.props.stationData.language,
                priceZone: this.props.stationData.pricezone,
                commission: this.props.stationData.commission,
                contract: this.props.stationData.contract  ? {
                    saleChannel: this.props.stationData.contract.saleChannel,
                    contractCicd: this.props.stationData.contract.contractCicd,
                    applicationLotNumber: this.props.stationData.contract.applicationLotNumber && 
                                                this.props.stationData.contract.applicationLotNumber.key === WashAdmin.key  ? WashAdmin : POSTotalEnergies,
		            contractCicdDate: this.props.stationData.contract.contractCicdDate,
                    contractStart: this.props.stationData.contract.contractStart ? 
                        toLocaleDateTime(this.props.stationData.contract.contractStart) : this.state.station.contract.contractStart,
                    contractEnd: this.props.stationData.contract.contractEnd ? 
                        toLocaleDateTime(this.props.stationData.contract.contractEnd) : this.state.station.contract.contractEnd,
		            comment: this.props.stationData.contract.commen ? this.props.stationData.contract.comment: '',
		            sapCode: this.props.stationData.contract.sapCode ? this.props.stationData.contract.sapCode : '',
		            sapName: this.props.stationData.contract.sapName  ? this.props.stationData.contract.sapName : '',
		            user: this.props.stationData.contract.user
                } : this.state.station.contract,
                nextContract: this.props.stationData.nextContract ? {
                    saleChannel: this.props.stationData.nextContract.saleChannel,
                    contractCicd: this.props.stationData.nextContract.contractCicd,
                    applicationLotNumber: this.props.stationData.contract.applicationLotNumber && 
                                                this.props.stationData.contract.applicationLotNumber.key === WashAdmin.key  ? WashAdmin : POSTotalEnergies,
		            contractCicdDate: this.props.stationData.nextContract.contractCicdDate,
                    contractStart: this.props.stationData.nextContract.contractStart ? 
                        toLocaleDateTime(this.props.stationData.nextContract.contractStart) : this.state.station.nextContract.contractStart,
                    contractEnd: this.props.stationData.nextContract.contractEnd ? 
                        toLocaleDateTime(this.props.stationData.nextContract.contractEnd) : this.state.station.nextContract.contractEnd,
		            comment:  this.props.stationData.nextContract.comment,
		            sapCode: this.props.stationData.nextContract.sapCode,
		            sapName: this.props.stationData.nextContract.sapName,
		            user: this.props.stationData.nextContract.user
                } : null,
                previousContract: this.props.stationData.previousContract ? {
                    saleChannel: this.props.stationData.previousContract.saleChannel,
                    contractCicd: this.props.stationData.previousContract.contractCicd,
                    applicationLotNumber: this.props.stationData.previousContract.applicationLotNumber && 
                                                this.props.stationData.previousContract.applicationLotNumber.key === WashAdmin.key  ? WashAdmin : POSTotalEnergies,
		            contractCicdDate: this.props.stationData.previousContract.contractCicdDate,
                    contractStart: this.props.stationData.previousContract.contractStart ? 
                        toLocaleDateTime(this.props.stationData.previousContract.contractStart) : this.state.station.previousContract.contractStart,
                    contractEnd: this.props.stationData.previousContract.contractEnd ? 
                        toLocaleDateTime(this.props.stationData.previousContract.contractEnd) : this.state.station.previousContract.contractEnd,
		            comment:  this.props.stationData.previousContract.comment,
		            sapCode: this.props.stationData.previousContract.sapCode,
		            sapName: this.props.stationData.previousContract.sapName,
		            user: this.props.stationData.previousContract.user
                } : null,
                useCoinSuction: this.props.stationData.useCoinSuction,
                coinSuctionDuration: this.props.stationData.coinSuctionDuration,
                voipServer: this.props.stationData.voipServer ? this.props.stationData.voipServer : '',
                inetPingTest: this.props.stationData.inetPingTest ? this.props.stationData.inetPingTest : this.state.station.inetPingTest,
                timetable: getTimetable(this.props.stationData.timetable),
                stationGroups: this.props.stationData.stationGroups ? this.props.stationData.stationGroups : [],
                updateDate: this.props.stationData.updateDate ? toLocaleDateTimeFormat(this.props.stationData.updateDate) : null,
                creationDate: this.props.stationData.creationDate ? toLocaleDateTimeFormat(this.props.stationData.creationDate) : null,
                status: this.props.stationData.status,
                originType:(STATION_ORIGIN_MRN_CREATION === this.props.stationData.originType)? msg("station.origin.mrn.creation") : msg("station.origin.local.creation"),
                address: this.props.stationData.address,
                batchNumber: this.props.stationData.batchNumber? this.props.stationData.batchNumber : msg("common.undefined.status") ,
                batchNumberDate: this.props.stationData.batchNumberDate ? toLocaleDateTimeFormat( this.props.stationData.batchNumberDate) : msg("common.undefined.status"),
                batchNumberReceptionDate:  this.props.stationData.batchNumberReceptionDate ? toLocaleDateTimeFormat(this.props.stationData.batchNumberReceptionDate) : msg("common.undefined.status")
            }
            this.setState({ 
                station: station,
                lockPriceZone: this.props.stationData.lockPriceZone, 
            });
        }

        let prevChangeContract = prevProps.changeContractData && prevProps.changeContractData.lastChange;
        let lastChangeContract = this.props.changeContractData && this.props.changeContractData.lastChange;
        let contractChanged = (lastChangeContract && (!prevChangeContract || prevChangeContract < lastChangeContract));
        if(contractChanged){
            this.props.loadStation(this.state.station.implant);
        }

        let prevCreate = prevProps.updateStationData && prevProps.updateStationData.lastChange;
        let lastCreate = this.props.updateStationData && this.props.updateStationData.lastChange;
        let created = (lastCreate && (!prevCreate || prevCreate < lastCreate))
        if (created) {
            this.props.history.push('/stations');
        }
        let prevChange = prevProps.users && prevProps.users.lastChange;
        let lastChange = this.props.users && this.props.users.lastChange;
        let change = (lastChange && (!prevChange || prevChange < lastChange))
        if (change) {
            let users = []
            this.props.users.users.forEach(u => users.push({ key: u.id, value: u.name }));
            this.setState({ filteredUsers: users });
        }
        prevChange = prevProps.stationGroupsData && prevProps.stationGroupsData.lastChange;
        lastChange = this.props.stationGroupsData && this.props.stationGroupsData.lastChange;
        change = (lastChange && (!prevChange || prevChange < lastChange))
        if (change) {
            let stationGroups = [];
            let stationGroupIds = [];
            if (this.state.station.stationGroups) {
                this.state.station.stationGroups.forEach(g => { if (g.id) { stationGroupIds.push(g.id) } });
            }
            this.props.stationGroupsData.stationGroups.forEach(
                g => { if (!stationGroupIds.includes(g.id)) { stationGroups.push(g) } }
            );
            this.setState({ filteredStationGroups: stationGroups });
        }
    }

    doOnSave() {
        let valid = true;
        if (!this.validateFields()) {
            this.fieldsControl();
        } else {
            this.setState({ isImplant: false, isBrand: false, isName: false, isPriceZone: false});

            if (this.state.station.gpsLatitude || this.state.station.gpsLongitude) {
                if (this.state.station.gpsLatitude && !GPS_LATITUDE.test(this.state.station.gpsLatitude)) {
                    this.setState({ gpsLatitudeNotValid: true });
                    valid = false;
                } else {
                    this.setState({ gpsLatitudeNotValid: false });
                }

                if (this.state.station.gpsLongitude && !GPS_LONGITUDE.test(this.state.station.gpsLongitude)) {
                    this.setState({ gpsLongitudeNotValid: true });
                    valid = false;
                } else {
                    this.setState({ gpsLongitudeNotValid: false });
                }
            }

            if (this.state.station.useCoinSuction && this.state.station.coinSuctionDuration && !NUMBER_PATTERN.test(this.state.station.coinSuctionDuration)) {
                this.setState({ coinSeductionNotValid: true });
                valid = false;
            } else {
                this.setState({ coinSeductionNotValid: false });
            }
    
            if (this.state.station.voipServer && !IP_PATTERN.test(this.state.station.voipServer)) {
                this.setState({ ipNotValid: true });
                valid = false;
            } else {
                this.setState({ ipNotValid: false });
            }
    
            if (this.state.station.inetPingTest && !IP_PATTERN.test(this.state.station.inetPingTest)) {
                this.setState({ inetPingTestNotValid: true });
                valid = false;
            } else {
                this.setState({ inetPingTestNotValid: false });
            }
            if (this.state.station.contract.contractEnd.getTime() < this.state.station.contract.contractStart.getTime()) {
                this.setState({ isEndDateAftrStartDate: false });
                valid = false;
            }

            if (valid) {
                this.updateStation();
            }
        }
    }

    updateStation() {
        let sapCode = this.state.station.contract.sapCode ? this.state.station.contract.sapCode : '';
        let o = updateInputNumberFormat(sapCode);
        let station = Object.assign({}, this.state.station, {
            contract: Object.assign({}, this.state.station.contract, {sapCode: o.formattedCode})});
            if(!station.contract.user) {
            station.contract.user = {key: '', value: ''};
        }
        this.props.updateStation(station.id, station);
    }

    optionTemplate(option, emptyValueReplace) {
        return (
            <div>
                <span style={{ color: 'black', visibility: 'visible' }}>
                    {!option.key ? emptyValueReplace : option.key}
                </span>
            </div>
        );
    }

    filterUser(event, isDropdownClick) {
        let query = event.query.toLowerCase();
        if (!isDropdownClick) {
            if (!query || query === this.state.query) {
                this.setState({ query: query });
                return;
            }
        } else if (query && (query === this.state.query)) {
            return;
        }
        let label = isDropdownClick ? this.state.query : query
        let lFilter = Object.assign({}, this.state.userFilter);
        lFilter.label = label;
        this.props.loadUsers(0, 10, lFilter);
        this.setState({ query: query });
    }

    filterStationGroups(event, isDropdownClick) {
        let query = event.query.toLowerCase();
        if (!isDropdownClick) {
            if (!query || query === this.state.query) {
                this.setState({ query: query });
                return;
            }
        } else if (query && (query === this.state.query)) {
            return;
        }
        let filter = {
            label: isDropdownClick ? this.state.query : query,
            page: 0,
            pageSize: 10,
            feature: STATIONGROUPS
        };
        this.props.loadStationGroups(filter);
        this.setState({ query: query });
    }

    unselectStationGroup(event) {
        let unselectGroup = event.value;
        if (unselectGroup && this.state.station.stationGroups) {
            let stationGroups = [];
            this.state.station.stationGroups.forEach(g => { if (g.id !== unselectGroup.id) stationGroups.push(g) });
            this.setState({
                query: '',
                filteredStationGroups: null,
                station: Object.assign({}, this.state.station, { stationGroups: stationGroups })
            });
            let filter = { label: '', page: 0, pageSize: 10 };
            this.props.loadStationGroups(filter);
        }
    }
    
    onContractCicdChange(e) {
        this.setState({station: Object.assign({}, this.state.station, {contract: Object.assign({}, this.state.station.contract, {contractCicd: e.checked})}) })
    }

    onApplicationLotNumberChange(e) {
        this.setState({station: Object.assign({}, this.state.station, {contract: Object.assign({}, this.state.station.contract, {applicationLotNumber: e.target.value})})});
    }

    onContractStartChange(e) {
        this.setState({station: Object.assign({}, this.state.station, {contract: Object.assign({}, this.state.station.contract, {contractStart: e.value})}) });
    }
    
    onContractEndChange(e) {
        this.setState({station: Object.assign({}, this.state.station, {contract: Object.assign({}, this.state.station.contract, {contractEnd: e.value})}) });
    }

    onContractUserChange(e) {
        this.setState({station: Object.assign({}, this.state.station, {contract: Object.assign({}, this.state.station.contract, {user: e.target.value})}) });
    }

    onContractSapCodeChange(e) {
        this.setState({station: Object.assign({}, this.state.station, {contract: Object.assign({}, this.state.station.contract, {sapCode: e.target.value})}) });
    }

    onContractSapNameChange(e) {
        this.setState({station: Object.assign({}, this.state.station, {contract: Object.assign({}, this.state.station.contract, {sapName: e.target.value})}) });
    }

    onContractCommentChange(e) {
        this.setState({station: Object.assign({}, this.state.station, {contract: Object.assign({}, this.state.station.contract, {comment: e.target.value})}) });
    }

    handleSaleChannelChange(e) {
        this.setState({station: Object.assign({}, this.state.station, {contract: Object.assign({}, this.state.station.contract, {saleChannel: e.target.value, contractCicd: e.target.value.value === 'CODO'})})});
    }

    setTimetableState(timetable) {
        this.setState({station: Object.assign({}, this.state.station, {timetable: timetable })});
    }

    isContractChangeable() {
        let currentDate = new Date().getTime();
        return this.state.station.contract && this.state.station.nextContract
            && this.state.station.contract.saleChannel
            && (this.state.station.contract.saleChannel.value === 'LOTUS' || this.state.station.contract.applicationLotNumber.key === WashAdmin.key)
            && this.state.station.contract.contractEnd
            && this.state.station.contract.contractEnd.getTime() <= currentDate
            && this.state.station.nextContract.contractStart
            && this.state.station.nextContract.contractStart.getTime() <= currentDate;
    }
    confirmContractChangeDialog(e) {
        this.setState({
            isShowConfirm: true,
            confirmMessage: msg('station.change.contract.confirm')
        });
    }

    confirmContractChange(e) {
        if(this.isContractChangeable()){
            this.props.changeContract(this.state.station.id);
            this.setState({ confirmMessage: '', isShowConfirm: false });
        }        
    }

    render() {
        let authorized = rolesValidator.validate([STATIONS]);
        let rAuthorized = authorized.toString().includes(READ);
        let uAuthorized = authorized.toString().includes(UPDATE);
        let commissionsTemporaire = this.props.commissionsData ? this.props.commissionsData : [];
        let pricezonesTemporaire = this.props.priceZonesData ? this.props.priceZonesData : [];
        let commissions = [];
        let pricezones = [];

        if (commissionsTemporaire.length !== 0) {
            commissionsTemporaire.forEach(e => commissions.push({ key: e.name, value: e.id }));
        }
        if (pricezonesTemporaire.length !== 0) {
            pricezonesTemporaire.forEach(e => pricezones.push({ 
                id: e.id,
                key: e.name + "_" + e.version,
                name: e.name,
                value: e.id.toString(),
                label : e.name + " ( " + e.version + " )",                
                version: e.version.toString()}));
        }

        const timezones = [
            { key: msg('station.timezone'), value: 1 }
        ];

        let gpsLatitude = this.state.station.gpsLatitude ? this.state.station.gpsLatitude : '';
        let gpsLongitude = this.state.station.gpsLongitude ? this.state.station.gpsLongitude : '';

        return rAuthorized && (
            <div className="card commonClass">
                <TabView>
                    <TabPanel header={msg('station.identity')}>
                        <div className="test p-col-12">
                            <h3 style={{ marginTop: '.5em' }}>{msg('station.identity')}</h3>
                        </div>
                        <div className="card card-w-title p-col-12">
                            <div className="p-grid form-group p-col-12">
                                {<div className="p-grid p-col-6">
                                    <div className="p-grid p-col-3">
                                        <div className="p-col-8"><label>{msg('station.fields.implant')}</label></div>
                                        <div className=""><span className="obligatoire">*</span></div>
                                    </div>
                                    <div className="p-col-9">
                                        <span className="md-inputfield">
                                            <InputText value={this.state.station.implant} onChange={e => this.setState({ isModifiable: false, station: Object.assign({}, this.state.station, { implant: e.target.value }) })} disabled maxLength="255" />
                                        </span>
                                        {this.state.implantNotValid && <Message severity="error" text={msg('station.message.not.valid.text')} key="startTimeError" />}
                                        {this.state.isImplant && <Message severity="error" text={msg('station.input.implant.required')} key="name" />}
                                    </div>
                                </div>}.
                                {<div className="p-grid p-col-6">
                                    <div className="p-grid p-col-3">
                                        <div className="p-col-8"><label>{msg('station.fields.name')}</label></div>
                                        <div className=""><span className="obligatoire">*</span></div>
                                    </div>
                                    <div className="p-col-9">
                                        <span className="md-inputfield">
                                            <InputText value={this.state.station.name} onChange={e => this.setState({ isModifiable: false, station: Object.assign({}, this.state.station, { name: e.target.value }) })} disabled maxLength="255" />
                                        </span>
                                        {this.state.nameNotValid && <Message severity="error" text={msg('station.message.not.valid.text')} key="startTimeError" />}
                                        {this.state.isName && <Message severity="error" text={msg('station.input.name.required')} key="name" />}
                                    </div>
                                </div>}
                            </div>
                            <div className="p-grid form-group p-col-12">
                                {<div className="p-grid p-col-6">
                                    <div className="p-grid p-col-3">
                                        <div className="p-col-8"> <label>{msg('station.fields.brand')}</label></div>
                                        <div className=""><span className="obligatoire">*</span></div>
                                    </div>
                                    <div className="p-col-9">
                                        <span className="md-inputfield">
                                            <InputText value={this.state.station.brand} onChange={e => this.setState({ isModifiable: false, station: Object.assign({}, this.state.station, { brand: e.target.value }) })} maxLength="255" />
                                        </span>
                                        {this.state.brandNotValid && <Message severity="error" text={msg('station.message.not.valid.text')} key="startTimeError" />}
                                        {this.state.isBrand && <Message severity="error" text={msg('station.input.brand.required')} key="lastname" />}
                                    </div>
                                </div>}
                                {<div className="p-grid p-col-6">
                                    <div className="p-grid p-col-3">
                                        <div className="p-col-8"> <label>{msg('station.fields.comment')}</label></div>
                                    </div>
                                    <div className="p-col-9">
                                        <span className="md-inputfield">
                                            <InputText className="inputClass" type="text" size="60" value={this.state.station.comment} onChange={e => this.setState({ isModifiable: false, station: Object.assign({}, this.state.station, { comment: e.target.value }) })} />
                                        </span>
                                        {this.state.commentNotValid && <Message severity="error" text={msg('station.message.not.valid.text')} key="startTimeError" />}
                                    </div>
                                </div>}
                            </div>
                            <div className="p-grid form-group p-col-12">
                                {pricezones && <div className="p-grid p-col-6">
                                    <div className="p-grid p-col-3">
                                        <div className="p-col-8"><label>{msg('station.fields.pricezone')}</label></div>
                                        <div className=""><span className="obligatoire">*</span></div>
                                    </div>
                                    <div className="p-col-9">
                                        <span className="md-inputfield md-inputfield-fill">
                                            <Dropdown options={pricezones} value={this.state.station.priceZone}
                                                optionLabel="label" dataKey="key" disabled={this.state.lockPriceZone}
                                                onChange={e => this.setState({ isModifiable: false, station: Object.assign({}, this.state.station, { priceZone: e.target.value }) })}
                                                placeholder="Selectionnez" key="pricezones" style={{ width: 'auto' }}
                                            />
                                        </span>
                                        {this.state.isPriceZone && <Message severity="error" text={msg('station.input.pricezone.required')} key="name" />}
                                    </div>
                                    {this.state.lockPriceZone && 
                                    <div className="p-col-12 config-desc"><span>{msg('station.pricezone.update.campaign.note')}</span></div>}
                                </div>}
                                {commissions && <div className="p-grid p-col-6">
                                    <div className="p-grid p-col-3">
                                        <div className="p-col-8"> <label>{msg('station.fields.commission')}</label></div>
                                        <div className=""><span className="obligatoire">*</span></div>
                                    </div>
                                    <div className="p-col-9">
                                        <span className="md-inputfield md-inputfield-fill">
                                            <Dropdown value={this.state.station.commission} options={commissions}
                                                onChange={e => this.setState({ isModifiable: false, station: Object.assign({}, this.state.station, { commission: e.target.value }) })}
                                                optionLabel="key" placeholder="Selectionnez" style={{ width: 'auto' }} />
                                                   {this.state.isCommissionRate && <Message severity="error" text={msg('station.input.commission.rate.required')} key="commissionRate" />} 
                                        </span>
                                    </div>
                                </div>}
                            </div>
                        </div>
                        <br />
                        <div className="test p-col-12">
                            <h3 style={{ marginTop: '.5em', marginBottom: '.5em' }}>{msg('Contrats')}</h3>
                        </div>
                        <div className="card card-w-title p-col-12 contracts-card">
                        <div className="p-grid form-group p-col-12 contracts-view">
                        <TabView className="p-col-12" >
                        {
                        <TabPanel header={msg('station.contract')}>
                        <Contract visible={true}
                            title={msg('station.contract')}
                            contract={this.state.station.contract}
                            nextContract={this.state.station.nextContract}
                            onCicdChange={this.onContractCicdChange}
                            onApplicationLotNumberChange={this.onApplicationLotNumberChange}
                            onApplicationLotNumberChange1={this.onApplicationLotNumberChange1}
                            onStartChange={this.onContractStartChange}
                            onEndChange={this.onContractEndChange}
                            onSaleChannelChange={this.handleSaleChannelChange}
                            onUserChange={this.onContractUserChange}
                            onSapCodeChange={this.onContractSapCodeChange}
                            onSapNameChange={this.onContractSapNameChange}
                            onCommentChange={this.onContractCommentChange}
                            saleChannels={saleChannels}
                            filteredUsers={this.state.filteredUsers}
                            filterUser={this.filterUser}
                            optionTemplate={this.optionTemplate}
                            errors={{
                                isStartDateValid: this.state.isStartDateValid,
                                isEndDateValid: this.state.isEndDateValid,
                                isEndDateAftrStartDate: this.state.isEndDateAftrStartDate,
                                isSaleChannel: this.state.isSaleChannel,
                                issapCode: this.state.issapCode,
                                isSapCodeFormat: this.state.isSapCodeFormat,
                                issapName: this.state.issapName
                            }}
                            locale={this.state.locale}
                            readOnly={false} />
                            </TabPanel>}
                            <TabPanel header={msg('station.next.contract')}>
                        <Contract visible={this.state.station.nextContract && true}
                            title={msg('station.next.contract')}
                            contract={this.state.station.nextContract}
                            saleChannels={saleChannels}
                            filteredUsers={this.state.filteredUsers}
                            filterUser={this.filterUser}
                            optionTemplate={this.optionTemplate}
                            locale={this.state.locale}
                            readOnly={true} />
                            </TabPanel>
                            <TabPanel header={msg('station.previous.contract')}>
                        <Contract visible={this.state.station.previousContract && true}
                            title={msg('station.previous.contract')}
                            contract={this.state.station.previousContract}
                            saleChannels={saleChannels}
                            filteredUsers={this.state.filteredUsers}
                            filterUser={this.filterUser}
                            optionTemplate={this.optionTemplate}
                            locale={this.state.locale}
                            readOnly={true} />
                            </TabPanel>
                        </TabView>
                        </div>
                        </div>
                        <br />
                        <div className="test p-col-12">
                            <h3 style={{ marginTop: '.5em' }}>{msg('station.ticket')}</h3>
                        </div>
                        <div className="card card-w-title p-col-12">
                            <div className="p-grid form-group p-col-12">
                                {<div className="p-grid p-col-6">
                                    <div className="p-grid p-col-3">
                                        <div className="p-col-12"> <label>{msg('station.fields.tickethdr')}</label></div>
                                    </div>
                                    <div className="p-col-9">
                                        <span className="md-inputfield">
                                            <InputTextarea rows={8} cols={28} maxLength={250} autoResize={true}  value={this.state.station.ticketHeader}
                                            onChange={e => this.handleInputText(e.target.value, 8,"ticket_hdr")} />
                                        </span>
                                        {this.state.hdrNotValid && <Message severity="error" text={msg('station.message.not.valid.text')} key="startTimeError" />}
                                    </div>
                                </div>}
                                {<div className="p-grid p-col-6">
                                    <div className="p-grid p-col-3">
                                        <div className="p-col-12"><label>{msg('station.fields.ticketftr')}</label></div>
                                    </div>
                                    <div className="p-col-9">
                                        <span className="md-inputfield">
                                            <InputTextarea rows={3} cols={28} maxLength={100}  autoResize={true} value={this.state.station.ticketFooter}
                                             onChange={e => this.handleInputText(e.target.value,3,"ticket_ftr")} />
                                        </span>
                                        {this.state.ftrNotValid && <Message severity="error" text={msg('station.message.not.valid.text')} key="startTimeError" />}
                                    </div>
                                </div>}
                            </div>
                        </div>
                        <br />
                        <div className="test p-col-12">
                            <h3 style={{ marginTop: '.5em' }}>{msg('station.divers')}</h3>
                        </div>
                        <div className="card card-w-title p-col-12">
                            <div className="p-grid form-group p-col-12">
                                {<div className="p-grid p-col-6">
                                    <div className="p-grid p-col-3">
                                        <div className="p-col-8"><label>{msg('station.fields.language')}</label></div>
                                    </div>
                                    <div className="p-col-9">
                                        <span className="md-inputfield md-inputfield-fill">
                                            <Dropdown value={this.state.station.language} options={languages}
                                                onChange={e => this.setState({ isModifiable: false, station: Object.assign({}, this.state.station, { language: e.target.value }) })}
                                                optionLabel="key" placeholder="Selectionnez" style={{ width: 'auto' }} />
                                        </span>
                                    </div>
                                </div>}
                                {<div className="p-grid p-col-6">
                                    <div className="p-grid p-col-3">
                                        <div className="p-col-12"> <label>{msg('station.fields.timezone')}</label></div>
                                    </div>
                                    <div className="p-col-9">
                                        <span className="md-inputfield md-inputfield-fill">
                                            <Dropdown value={this.state.station.timezone} options={timezones}
                                                onChange={e => this.setState({ isModifiable: false, station: Object.assign({}, this.state.station, { timezone: e.target.value }) })}
                                                optionLabel="key" placeholder="Selectionnez" style={{ minWidth: '60%' }} disabled />
                                        </span>
                                    </div>
                                </div>}
                            </div>
                            <div className="p-grid form-group p-col-12">
                                {<div className="p-grid p-col-6">
                                    <div className="p-grid p-col-3">
                                        <div className="p-col-8"><label>{msg('station.fields.gpsLatitude')}</label></div>
                                    </div>
                                    <div className="p-col-9">
                                        <span className="md-inputfield">
                                            <InputText value={gpsLatitude} onChange={e => this.setState({ isModifiable: false, station: Object.assign({}, this.state.station, { gpsLatitude: e.target.value }) })} />
                                        </span>
                                        {this.state.gpsLatitudeNotValid && <Message severity="error" text={msg('station.message.not.valid.latitude')} key="startTimeError" />}
                                    </div>
                                </div>}
                                {<div className="p-grid p-col-6">
                                    <div className="p-grid p-col-3">
                                        <div className="p-col-8"> <label>{msg('station.fields.gpsLongitude')}</label></div>
                                    </div>
                                    <div className="p-col-9">
                                        <span className="md-inputfield">
                                            <InputText value={gpsLongitude} onChange={e => this.setState({ isModifiable: false, station: Object.assign({}, this.state.station, { gpsLongitude: e.target.value }) })} />
                                        </span>
                                        {this.state.gpsLongitudeNotValid && <Message severity="error" text={msg('station.message.not.valid.longitude')} key="startTimeError" />}
                                    </div>
                                </div>}
                            </div>
                        </div>
                        <br />
                        <div className="test p-col-12">
                            <h3 style={{ marginTop: '.5em' }}>{msg('group.stations.group')}</h3>
                        </div>
                        <div className="card card-w-title p-col-12">
                            <br />
                            <div className="form-group p-col-12">
                                <div className="p-grid p-col-12">
                                    <div className="p-col-12" style={{ marginBottom: '10px' }}>
                                        <span className="infos">{msg('group.stations.group.select.message')}</span>
                                        <br></br>
                                        <br></br>
                                        <AutoComplete minLength={0} placeholder={msg('group.stations.group.select.all')} id="stations" size={60} dropdown={true} multiple={true} field="name"
                                            suggestions={this.state.filteredStationGroups} value={this.state.station.stationGroups} delay={100}
                                            completeMethod={this.filterStationGroups} onDropdownClick={e => this.filterStationGroups(e, true)}
                                            onChange={e => this.setState({ filteredStationGroups: null, isModifiable: false, station: Object.assign({}, this.state.station, { stationGroups: e.target.value }) })}
                                            onUnselect={this.unselectStationGroup}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                    </TabPanel>
                    <TabPanel header={msg('station.advanced.settings')}>
                        <div className="test p-col-12">
                            <h3 style={{ marginTop: '.5em' }}>{msg('station.advanced.settings')}</h3>
                        </div>
                        <div className="card card-w-title p-col-12">
                            <div className="p-grid form-group p-col-12">
                                {<div className="p-grid p-col-6">
                                    <div className="p-grid p-col-5">
                                        <div className="p-col-12"><label>{msg('station.advanced.settings.useCoinSuction')}</label></div>
                                    </div>
                                    <div className="p-col-7">
                                        <span className="md-inputfield">
                                            <Checkbox value={this.state.station.useCoinSuction} onChange={e => this.setState({ station: Object.assign({}, this.state.station, { useCoinSuction: e.checked }) })}
                                                checked={this.state.station.useCoinSuction}></Checkbox>
                                        </span>
                                    </div>
                                </div>}
                                {this.state.station.useCoinSuction && <div className="p-grid p-col-6">
                                    <div className="p-grid p-col-5">
                                        <div className="p-col-12"><label>{msg('station.advanced.settings.coinSuctionDuration')}</label></div>
                                    </div>
                                    <div className="p-col-7">
                                        <span className="md-inputfield">
                                            <InputText value={this.state.station.coinSuctionDuration} onChange={e => this.setState({ station: Object.assign({}, this.state.station, { coinSuctionDuration: e.target.value }) })}
                                            />
                                        </span>
                                        {this.state.coinSeductionNotValid && <Message severity="error" text={msg('equipment.message.not.valid.number')} key="startTimeError" />}
                                    </div>
                                </div>}
                            </div>
                            <div className="p-grid form-group p-col-12">
                                {<div className="p-grid p-col-6">
                                    <div className="p-grid p-col-5">
                                        <div className="p-col-12"><label>{msg('station.advanced.settings.voip.ip')}</label></div>
                                    </div>
                                    <div className="p-col-7">
                                        <span className="md-inputfield">
                                            <InputText value={this.state.station.voipServer} onChange={e => this.setState({ station: Object.assign({}, this.state.station, { voipServer: e.target.value }) })} />
                                        </span>
                                        {this.state.ipNotValid && <Message severity="error" text={msg('equipment.message.not.valid.ip')} key="startTimeError" />}
                                    </div>
                                </div>}
                                {<div className="p-grid p-col-6">
                                    <div className="p-grid p-col-5">
                                        <div className="p-col-12"><label>{msg('station.advanced.settings.voip.inetPingTest')}</label></div>
                                    </div>
                                    <div className="p-col-7">
                                        <span className="md-inputfield">
                                            <InputText value={this.state.station.inetPingTest} onChange={e => this.setState({ station: Object.assign({}, this.state.station, { inetPingTest: e.target.value }) })} />
                                        </span>
                                        {this.state.inetPingTestNotValid && <Message severity="error" text={msg('equipment.message.not.valid.ip')} key="startTimeError" />}
                                    </div>
                                </div>}
                            </div>
                        </div>
                        <br />
                        <div className="test p-col-12">
                            <h3 style={{ marginTop: '.5em' }}>{msg('station.advanced.settings.timetable')}</h3>
                        </div>
                        <div className="card card-w-title p-col-12">
                            <TimeTable timetable={this.state.station.timetable}  setTimetableState={this.setTimetableState} />
                        </div>
                </TabPanel>

                <TabPanel header ={msg('station.information')}>
                    <div className='card card-w-title p-col-12'>
                        <div className= 'p-grid from-group p-col-12'>
                            <div className= 'p-grid from-group p-col-12'>
                                <div className='p-grid  p-col-7'>
                                   <div className='p-col-3'><label><strong>{msg('station.adresse')}</strong></label>
                                   </div>
                                   {this.state.station.address && 
                                    <div className='p-col-5'>
                                         <div className = 'p-col-12' style={{ marginLeft: "-8px", marginTop: "-8px"}}>
                                              <span>{capitalizeFirstLetter(this.state.station.address.street)}
                                              </span>
                                         </div>
                                         <div className = 'p-col-12'style={{ marginLeft: "-8px",  marginTop: "-10px"}} >
                                              <span>{this.state.station.address.postCode + " " + capitalizeFirstLetter(this.state.station.address.city)}
                                              </span>
                                         </div>
                                   </div>
                                    } 
                                    {!this.state.station.address &&
                                      <div className='p-col-5'>
                                           <div className = 'p-col-12' style={{ marginLeft: "-8px", marginTop: "-8px" }}>
                                                <span>{msg('common.undefined.status')}
                                                </span>
                                           </div>
                                      </div>
                                    }
                                </div>
                                   <div className='p-grid  p-col-5'>
                                        <div className='p-grid p-col-9'>
                                             <div className='p-col-4'><label><strong>{msg('equipement.Characteristics.update.date')}</strong></label>
                                             </div>
                                             <div className='p-col-4' style={{ marginLeft: "-20px" }}>
                                                 <span>{this.state.station.updateDate || this.state.station.creationDate ? 
                                                 (this.state.station.updateDate ? this.state.station.updateDate : this.state.station.creationDate) : msg("common.undefined.status")}
                                                 </span>
                                             </div>
                                        </div>
                                    </div>
                              </div>
                            <div className='p-grid p-col-7'>
                                 <div className='p-col-3'><label><strong>{msg('station.status')}</strong></label>
                                 </div>
                                 <div className='p-col-5'>
                                      { <span>{this.findTextFromArr(ARRAY_STATUS_STATIONS, this.state.station.status)}
                                      </span> }
                                 </div>
                            </div>
                            <div className='p-grid  p-col-7'>
                                 <div className='p-col-3'><label><strong>{msg('station.origin.creation')}</strong></label>
                                 </div>
                                 <div className='p-col-5'>
                                      <span>{this.state.station.originType}
                                      </span>
                                 </div>
                            </div>
                            <div className='p-grid  p-col-7'>
                                 <div className='p-col-3'><label><strong>{msg('station.batchNumber')}</strong></label>
                                 </div>
                                 <div className='p-col-5'>
                                      <span>{this.state.station.batchNumber}
                                      </span>
                                 </div>
                            </div>
                            <div className='p-grid  p-col-7'>
                                 <div className='p-col-3'><label><strong>{msg('station.batchNumberDate')}</strong></label>
                                 </div>
                                 <div className='p-col-5'>
                                 { <span>{this.state.station.batchNumberDate}
                                      </span> }
                                 </div>
                            </div>
                            <div className='p-grid  p-col-7'>
                                 <div className='p-col-3'><label><strong>{msg('station.batchNumberReceptionDate')}</strong></label>
                                 </div>
                                 <div className='p-col-5'>
                                      { <span>{this.state.station.batchNumberReceptionDate}
                                      </span> }
                                 </div>
                            </div>
                        </div>
                    </div>
                </TabPanel>
            </TabView>
            <div className="p-grid p-col-12">
                <div className="p-grid p-col-9"/>
                    <div className="p-grid p-col-3">
                        <Button label={(msg('station.validate'))} title={(msg('station.validate'))} onClick={this.doOnSave} className="button" disabled={!uAuthorized} />
                        <Link to="/stations">
                            <Button label={(msg('station.reset'))} className="button" />
                        </Link>
                    </div>
                </div>
                <MessageDialog className={'message-dialog error-message'} visible={this.state.isApiError} title={msg('message.dialog.error.title')}
                        message={this.state.isApiError && msg(this.props.error.message)}
                        onHide={() => this.setState({ isLignSelected: false, isApiError: false })} />
                <MessageDialog className={'message-dialog info-message'} visible={this.state.isShowConfirm} title={msg('message.dialog.confirm.title')}
                        confirm={this.state.isShowConfirm} message={this.state.isShowConfirm && msg(this.state.confirmMessage)}
                        onConfirm={this.confirmContractChange}
                        onCancel={() => this.setState({ confirmMessage: '', isShowConfirm: false })}
                        onHide={() => this.setState({ confirmMessage: '', isShowConfirm: false })} />
            </div>
        );
    }
}
export default UpdateStation;