import {toLocaleDateTimeFormat} from '../../utils/dateTimeUtils';

export const ADMINEQUIPMENTS='ADMINEQUIPMENTS';
export const ADMINEQUIPMENTS_SUCCESS= 'ADMINEQUIPMENTS_SUCCESS';
export const CREATE_EQUIPMENT_SUCCESS= 'CREATE_EQUIPMENT_SUCCESS';
export const CREATE_EQUIPMENT= 'CREATE_EQUIPMENT';
export const EQUIPMENT_DELETE_BY_ID = 'EQUIPMENT_DELETE_BY_ID';
export const EQUIPMENT_DELETE_BY_ID_SUCCESS = 'EQUIPMENT_DELETE_BY_ID_SUCCESS';
export const EQUIPMENT='EQUIPMENT';
export const EQUIPMENT_SUCCESS= 'EQUIPMENT_SUCCESS';
export const EQUIPMENT_UPDATE = 'EQUIPMENT_UPDATE';
export const EQUIPMENT_UPDATE_SUCCESS = 'EQUIPMENT_UPDATE_SUCCESS';
export const UPDATE_BORNE = 'UPDATE_BORNE';
export const UPDATE_BORNE_SUCCESS = 'UPDATE_BORNE_SUCCESS';


export const adminequipments = (page, pageSize, filter) => {
	return ({
		type: ADMINEQUIPMENTS,
		data: {page: page,  pageSize: pageSize, filter: filter},
		errorMessage: null,
		errorStatus: null
	});
};

export const adminequipmentsSuccess = (r) => {
	return ({
		type: ADMINEQUIPMENTS_SUCCESS,
		data: formatDates(r.response),
		errorMessage: null,
		errorStatus: null
	});
};

export const createEquipment = (equipment) => {
	return ( {
		type : CREATE_EQUIPMENT,
		data : {equipment: equipment},
		errorMessage: null,
		errorStatus: null
	});
};

export const createEquipmentSuccess = (r) => {
	return ( {
		type : CREATE_EQUIPMENT_SUCCESS,
		data : r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const equipmentDeleteById = (equipmentId) => { 
	return ({
		type: EQUIPMENT_DELETE_BY_ID,
		data: {equipmentId: equipmentId},
		errorMessage: null,
		errorStatus: null
	});
};

export const equipmentDeleteByIdSuccess = (r) => {
	return ({
		type: EQUIPMENT_DELETE_BY_ID_SUCCESS,
		data: r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const equipment = (equipmentId) => {
	return ({
		type: EQUIPMENT,
		data: { equipmentId: equipmentId },
		errorMessage: null,
		errorStatus: null
	});
};
export const equipmentSuccess = (r) => {
	return ({
		type: EQUIPMENT_SUCCESS,
		data: r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const equipmentUpdate = (equipmentId, equipment) => { 
	return ({
		type: EQUIPMENT_UPDATE,
		data: {equipmentId: equipmentId, equipment: equipment},
		errorMessage: null,
		errorStatus: null
	});
};

export const equipmentUpdateSuccess = (r) => {
	return ({
		type: EQUIPMENT_UPDATE_SUCCESS,
		data: r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const updateBorne = (equipmentId) => {
	return ( {
		type : UPDATE_BORNE,
		data : {equipmentId: equipmentId},
		errorMessage: null,
		errorStatus: null
	});
};

export const updateBorneSuccess = (r) => {
	return ( {
		type : UPDATE_BORNE_SUCCESS,
		data : r.response,
		errorMessage: null,
		errorStatus: null
	});
};

function formatDates(response) {
	let data = response && response.equipments ? response.equipments : [];
	data = data.map(e => {
		e.time = toLocaleDateTimeFormat(e.time); 
		return e;
	});	
	response.equipments = data;

	data = response && response.reports ? response.reports : [];
	data = data.map(e => {
		e.time = toLocaleDateTimeFormat(e.time); 
		return e;
	});	
	response.reports = data;
	
	return response;
}