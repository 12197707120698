export const LOAD_STATION_GROUPS = "LOAD_STATION_GROUPS";
export const LOAD_STATION_GROUPS_SUCCESS = "LOAD_STATION_GROUPS_SUCCESS";
export const LOAD_STATION_GROUP = "LOAD_STATION_GROUP";
export const LOAD_STATION_GROUP_SUCCESS = "LOAD_STATION_GROUP_SUCCESS";
export const LOAD_STATION_GROUP_STATIONS = "LOAD_STATION_GROUP_STATIONS";
export const LOAD_STATION_GROUP_STATIONS_SUCCESS = "LOAD_STATION_GROUP_STATIONS_SUCCESS";
export const CREATE_STATION_GROUP = "CREATE_STATION_GROUP";
export const CREATE_STATION_GROUP_SUCCESS = "CREATE_STATION_GROUP_SUCCESS";
export const UPDATE_STATION_GROUP = "UPDATE_STATION_GROUP";
export const UPDATE_STATION_GROUP_SUCCESS = "UPDATE_STATION_GROUP_SUCCESS";
export const DELETE_STATION_GROUP = "DELETE_STATION_GROUP";
export const DELETE_STATION_GROUP_SUCCESS = "DELETE_STATION_GROUP_SUCCESS";

export const loadStationGroups = (filter) => { 
	return ({
		type: LOAD_STATION_GROUPS,
		data: {filter: filter},
		errorMessage: null,
		errorStatus: null
	});
};

export const loadStationGroupsSuccess = (r) => {
	return ({
		type: LOAD_STATION_GROUPS_SUCCESS,
		data: r.response ,
		errorMessage: null,
		errorStatus: null
	});
};

export const loadStationGroup = (id) => { 
	return ({
		type: LOAD_STATION_GROUP,
		data: {id: id},
		errorMessage: null,
		errorStatus: null
	});
};

export const loadStationGroupSuccess = (r) => {
	return ({
		type: LOAD_STATION_GROUP_SUCCESS,
		data: r.response ,
		errorMessage: null,
		errorStatus: null
	});
};

export const loadStationGroupStations = (filter, page, pageSize) => { 
	return ({
		type: LOAD_STATION_GROUP_STATIONS,
		data: {filter: filter, page: page, pageSize: pageSize},
		errorMessage: null,
		errorStatus: null
	});
};

export const loadStationGroupStationsSuccess = (r) => {
	return ({
		type: LOAD_STATION_GROUP_STATIONS_SUCCESS,
		data: r.response ,
		errorMessage: null,
		errorStatus: null
	});
};

export const createStationGroup = (stationGroup) => { 
	return ({
		type: CREATE_STATION_GROUP,
		data: {stationGroup: stationGroup},
		errorMessage: null,
		errorStatus: null
	});
};

export const createStationGroupSuccess = (r) => {
	return ({
		type: CREATE_STATION_GROUP_SUCCESS,
		data: r.response ,
		errorMessage: null,
		errorStatus: null
	});
};

export const updateStationGroup = (stationGroup) => { 
	return ({
		type: UPDATE_STATION_GROUP,
		data: {stationGroup: stationGroup},
		errorMessage: null,
		errorStatus: null
	});
};

export const updateStationGroupSuccess = (r) => {
	return ({
		type: UPDATE_STATION_GROUP_SUCCESS,
		data: r.response ,
		errorMessage: null,
		errorStatus: null
	});
};

export const deleteStationGroup = (id) => { 
	return ({
		type: DELETE_STATION_GROUP,
		data: {id: id},
		errorMessage: null,
		errorStatus: null
	});
};

export const deleteStationGroupSuccess = (r) => {
	return ({
		type: DELETE_STATION_GROUP_SUCCESS,
		data: r.response ,
		errorMessage: null,
		errorStatus: null
	});
};