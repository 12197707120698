import React, { Component } from 'react';
import { Chart } from 'primereact/chart';
import msg from '../../../texts/msg.js';
import { colorUtlis } from '../../../utils/ColorUtlis';

class CampaignReportChart extends Component {

    constructor(props) {
        super(props);
        this.buildChartModel = this.buildChartModel.bind(this);
    }

    buildChartModel() {
        let chartLabels = [];
        let chartData = [];
        let backgroundColorData = [];
        if(this.props.campaign.progressions && Array.isArray(this.props.campaign.progressions)) {
            this.props.campaign.progressions.forEach(e => {
                let item = {label: msg('campaign.report.status.' + e.label.toLowerCase()), value: e.count + ' (' + e.value.toFixed(2) + '%)'};
                chartLabels.push(item);
                chartData.push(e.value);
                backgroundColorData.push(colorUtlis.colorByStatus(e.label));
            });            
        }
        let chartModel =  {labels: chartLabels, data: chartData, backgroundColors: backgroundColorData}
        return chartModel;
    }

    render() {
        let chartModelData = this.buildChartModel();
        let chartModel = {
            labels: chartModelData.labels,
            datasets: [{
                data: chartModelData.data,
                backgroundColor: chartModelData.backgroundColors,
                hoverBackgroundColor: chartModelData.backgroundColors
            }]
        };
        let options = {
            title: {display: false, text: "Progression"},
            legend: {display: false, position: 'bottom', align: 'start', labels: {}},
            tooltips: {
                callbacks: {label: (tooltipItem, data) => (" " + data.labels[tooltipItem.index].label + " " + data.labels[tooltipItem.index].value)}
            },
            animation: {duration: 0},
            events: ['mousemove']
        };
        return (
            <div className="p-col-12 p-grid campaign-chart">
                <div className="p-col-7">
                    <Chart type={"pie"} data={chartModel} options={options} />
                </div>
                <div className="p-col-5 p-grid legend">
                    {
                        chartModelData.labels.map((item, i) => {
                            return (
                            <div className="p-col-12 p-grid" key={i}>
                                <div className="p-col-5" style={{color: chartModelData.backgroundColors[i], marginRight: '5px'}}>{item.label}</div>
                                <div className="p-col-5" style={{marginLeft: '5px'}}>{item.value}</div>
                            </div>)
                        })
                    }
                </div>         
            </div>
        )
    }

}

export default CampaignReportChart;