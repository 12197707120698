export const LOAD_BORNES = "LOAD_BORNES";
export const LOAD_BORNES_SUCCESS = "LOAD_BORNES_SUCCESS";
export const REBOOT = 'REBOOT';
export const REBOOT_SUCCESS = 'REBOOT_SUCCESS';
export const GET_BORNE_LOGS = 'GET_BORNE_LOGS';
export const GET_BORNE_LOGS_SUCCESS = 'GET_BORNE_LOGS_SUCCESS';
export const TEST_NETWORK_SPEED = 'TEST_NETWORK_SPEED';
export const TEST_NETWORK_SPEED_SUCCESS = 'TEST_NETWORK_SPEED_SUCCESS';
export const DISASSOCIATE = "LOAD_DISASSOCIATE";
export const DISASSOCIATE_SUCCESS = "LOAD_DISASSOCIATE_SUCCESS";


export const disassociateBorne = (equipmentId, borneId) => {
	return ({
		type: DISASSOCIATE,
		data: {equipmentId: equipmentId, borneId: borneId},
		errorMessage: null,
		errorStatus: null
	});
};

export const disassociateBorneSuccess = (r) => {
	return ({
		type: DISASSOCIATE_SUCCESS,
		data: r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const loadBornes = (filter) => {
	return ( {
		type : LOAD_BORNES,
		data : {filter: filter},
		errorMessage: null,
		errorStatus: null
	});
};

export const loadBornesSuccess = (r) => {
	return ( {
		type : LOAD_BORNES_SUCCESS,
		data : r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const checkNetworkSpeedSuccess = (r) => {
	return ( {
		type : TEST_NETWORK_SPEED_SUCCESS,
		data : r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const checkNetworkSpeed = (borneId, borne) => {
	return ( {
		type : TEST_NETWORK_SPEED,
		data : {borneId: borneId, borne: borne},
		errorMessage: null,
		errorStatus: null
	});
};

export const reboot= (borneId, borne) => {
	return ( {
		type : REBOOT,
		data : {borneId: borneId, borne: borne},
		errorMessage: null,
		errorStatus: null
	});
};

export const rebootSuccess = (r) => {
	return ( {
		type : REBOOT_SUCCESS,
		data : r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const getBorneLogs = (borneId, borne) => {
	return ( {
		type : GET_BORNE_LOGS,
		data : {borneId: borneId, borne: borne},
		errorMessage: null,
		errorStatus: null
	});
};

export const getBorneLogsSuccess = (r) => {
	return ( {
		type : GET_BORNE_LOGS_SUCCESS,
		data : r.response,
		errorMessage: null,
		errorStatus: null
	});
};