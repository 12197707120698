import React, { Component } from 'react'
import { InputText } from 'primereact/inputtext';
import { ProgressBar } from 'primereact/progressbar';
import { Dropdown } from 'primereact/dropdown';
import { Message } from 'primereact/message';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import UploadService from "../../../middleware/download-upload-files.service"
import MessageDialog from '../../common/MessageDialog';
import { rolesValidator, HARDWARE, READ, UPDATE } from '../../../utils/RolesValidator';
import msg from '../../../texts/msg';
import { progressSpinnerHandler } from '../../../utils/ProgressSpinnerHandler';
import '../../common/css/common.css';
import { Link } from 'react-router-dom';

export class UpdateHardware extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFiles: undefined,
            currentFile: undefined,
            progress: 0,
            message: "",
            fileInfos: [],
            hardwareId: null,
            name: '',
            description: '',
            isNotFile: false,
            isNotype: false,
            isDone: false,
            isPatternError: false,
            borneType: '',
            isNameValid: false,
            sessionExpired: false,
            isModifiable: true,
            isInitialized: false,
            isConfig: false
        };
    }

    componentDidMount() {
        this.props.setDataInfoBar([{}]);
    }

    onFileChange = event => {
        this.setState({ selectedFile: event.files[0] });
        this.setState({ name: event.files[0].name });
        this.setState({ progress: 0 });
    };

    onFileUpload = () => {
        let valid = true;
        let currentFile = this.state.selectedFile;
        this.setState({
            progress: 0,
            currentFile: currentFile,
        });

        if (!this.state.borneType.value) {
            this.setState({ isNotype: true });
            valid = false;
        } else {
            this.setState({ isNotype: false });
        }

        if (valid)
            UploadService.upload(currentFile, this.state.description, this.state.borneType.value, this.state.hardwareId, (event) => {
                this.setState({ progress: Math.round((100 * event.loaded) / event.total), });
            }).then((response) => {
                this.setState({ borneType: {}, currentFile: undefined, description: '', type: {} });
                this.setState({ isDone: true });
                this.setState({ progress: 0, currentFile: null });
                this.props.history.push('/hardwares');
            }).catch((error) => {
                if (error.response.status === "401" || error.response.status === 401) {
                    this.setState({ sessionExpired: true });

                } else {
                    this.setState({ isError: true, isPatternError: true, message: error.response.data.message });
                    this.setState({ progress: 0, currentFile: null });
                }
            });
    };

    handleErrorChange(e) {
        this.setState({ isDone: false, isError: false, isNameValid: false, selectedFile: null, message: null })
        this.setState({ currentFile: undefined });
        if (this.state.sessionExpired) {
            this.setState({ sessionExpired: false });
            this.props.history.push('/hardwares');
        }
    }

    componentDidUpdate(prevProps) {
        if (!this.state.isInitialized && this.state.isModifiable && this.props.configMateriel && this.props.configMateriel.id) {
            let borne = this.props.configMateriel.name ? this.props.configMateriel.name.split('_')[0] : "";
            let borneType = { key: msg('equipment.type.' + borne.toLowerCase()), value: borne };
            this.setState({
                description: this.props.configMateriel.comment,
                hardwareId: this.props.configMateriel.id,
                borneType: borneType,
                isInitialized: true,
                isConfig: this.props.configMateriel.config
            });
        }

        let prevError = prevProps.error && prevProps.error.timestamp;
        let lastError = this.props.error && this.props.error.timestamp;
        let error = (lastError && (!prevError || prevError < lastError));
        if (error) {
            let isError = this.props.error.message && this.props.error.message.startsWith('error.config.borne') ? true : false;
            this.setState({ isApiError: isError });
        }
    }

    downloadTargetConfiguration() {
        let configFileName = "hardware";
        if (this.state.hardwareId) {
            configFileName = this.state.borneType.value + "_" + configFileName + "_" + this.state.hardwareId + ".json";
        }
        UploadService.getFileData("hardware/" + this.state.hardwareId + "/configuration", configFileName, null)
            .then((response) => {
                const fname = configFileName;
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    if (response.status === "401" || response.status === 401) {
                        this.setState({ buttonSelectd: false, sessionExpired: true });
                    } else if (response.status === 400 || response.status === "400") {
                        this.setState({ isNotDownload: true, buttonSelectd: false });
                    }
                    else {
                        this.setState({ buttonSelectd: false });
                        a.href = url;
                        a.download = fname;
                        a.click();
                    }
                    progressSpinnerHandler.show(false);
                });
            });
    }

    render() {
        const {
            currentFile,
            progress,
        } = this.state;

        const bornTypes = [
            { key: msg('equipment.type.tl16'), value: 'TL16' },
            { key: msg('equipment.type.tl17'), value: 'TL17' },
            { key: msg('equipment.type.tl18'), value: 'TL18' },
            { key: msg('equipment.type.tk2'), value: 'TK2' }
        ];

       
        let hardwareName;
        if (this.state.hardwareId) {
            hardwareName = this.state.borneType.value + "_hardware_" + this.state.hardwareId;         
        }

        let authorized = rolesValidator.validate([HARDWARE]);
        let rAuthorized = authorized.toString().includes(READ);
        let uAuthorized = authorized.toString().includes(UPDATE);
        return rAuthorized && (
            <div className="commonClass roleClass">
                <div className="test p-grid p-col-12" style={{ marginLeft: '1px' }}>
                    <div className="p-grid p-col-6">
                        <h4 className="p-col-5" style={{ marginTop: '-.2em' }}>{msg('hardware.fields.title')}</h4>
                        <h4 className="p-col-2" style={{ marginTop: '-.2em' }}>{hardwareName}</h4>
                    </div>
                    <div className="p-grid p-col-6" style={{ marginTop: '-10px' }}>
                        <div className="p-grid p-col-4">
                        </div>
                        <div className="p-grid p-col-5">
                        </div>
                        <div className="p-grid p-col-1">
                            <ol className="tooltip">
                                <input id="image" type="image" src="/assets/ultima/layout/images/cartadis/download.svg" style={{ marginTop: '-10px', marginLeft: '100px', height: '30px' }}
                                    alt={(msg('hardware.button.config.download'))}
                                    tooltip={msg('hardware.button.config.download.tooltip')}
                                    onClick={() => this.downloadTargetConfiguration()} />
                                {<span className="tooltiptext">{msg('hardware.button.config.download.tooltip')}</span>}
                            </ol>
                        </div>
                    </div>
                </div>
                {currentFile && (<div className="test p-col-12">
                    <ProgressBar value={progress}></ProgressBar>
                </div>)}
                <div className="card card-w-title p-col-12">
                    <div className="p-grid form-group p-col-12">
                        {<div className="p-grid form-group p-col-12">
                            <div className="p-grid form-group p-col-12">
                                {<div className="p-grid p-col-6">
                                    <div className="p-grid p-col-5">
                                        <div className="p-col-6"> <label>{msg('equipment.fields.borneType')}</label></div>
                                        <div className="p-col-1"></div>
                                        <div className=""><span className="obligatoire">*</span></div>
                                    </div>
                                    <div className="p-col-4">
                                        <span className="md-inputfield">
                                            <Dropdown options={bornTypes} optionLabel="key" value={this.state.borneType}
                                                selectedItemsLabel={this.state.borneType.length + " " + msg('group.selected.items')}
                                                onChange={e => this.setState({ isModifiable: false, borneType: e.target.value })}
                                                placeholder="Selectionnez" key="firmwares" disabled
                                            />
                                        </span>
                                        {this.state.isNotype && <Message severity="error" text={msg('equipment.input.borneType.required')} key="name" />}
                                    </div>
                                </div>}
                                {<div className="p-grid p-col-6">
                                    <div className="p-grid p-col-3">
                                        <div className="p-col-6"> <label>{msg('firmware.fields.description')}</label></div>
                                    </div>
                                    <div className="p-col-9">
                                        <span className="md-inputfield">
                                            <InputText className="inputClass" value={this.state.description} disabled={this.state.isConfig} onChange={e => this.setState({ isModifiable: false, description: e.target.value })} placeholder="Description" type="text" size="60" />
                                        </span>
                                    </div>
                                </div>}
                            </div>

                            <div className="p-grid form-group p-col-12">
                                {<div className="p-grid p-col-6">
                                    <div className="p-grid p-col-6">
                                        <div className="p-col-12"> <label>{msg('hardware.fields.upload')}</label></div>
                                    </div>
                                    <div className="p-col-6">
                                        <span className="md-inputfield">
                                            <FileUpload mode="basic" name="rr" chooseLabel={msg('hardware.fields.upload.button.label')} disabled={this.state.isConfig} onSelect={this.onFileChange} accept=".json" maxFileSize={9000000000}
                                                emptyTemplate={<p className="p-m-0"></p>} />
                                        </span>
                                        {this.state.isNotFile && <Message severity="error" text={msg('config.input.file.required')} key="name" />}
                                    </div>
                                </div>}
                                {<div className="p-col-12">
                                    {<span className="infos">{msg('hardware.upload.file.config.confirm.message')}</span>}
                                </div>}
                            </div>
                        </div>}
                    </div>
                </div>
                <br></br>
                <br />
                <div className="p-grid p-col-12">
                    <div className="p-grid p-col-9">
                    </div>
                    <div className="p-grid p-col-3">
                        <Button label={(msg('user.validate'))} title={(msg('user.validate'))}
                            onClick={this.onFileUpload}
                            className="button" disabled={!uAuthorized}/>
                        <Link to="/hardwares">
                            <Button label={(msg('user.reset'))} className="button" />
                        </Link>
                    </div>
                </div>
                <br />
                <div className="alert alert-light" role="alert">
                    <MessageDialog className={'message-dialog error-message'} visible={this.state.isError || this.state.isDone || this.state.sessionExpired} title={msg('firmware.fields.upload.title.error')}
                        message={(this.state.isError && this.state.message)
                            || (this.state.isDone && msg("firmware.fields.upload.done"))
                            || (this.state.sessionExpired && msg("login.unauthorized.internalauthenticationserviceexception"))}
                        onHide={e => this.handleErrorChange(e)} onCancel={e => this.handleErrorChange(e)} />

                    <MessageDialog className={'message-dialog warn-message'} visible={this.state.isNotDownload} title={msg('message.dialog.warn.title')}
                        message={this.state.isNotDownload && msg('error.config.borne.target.configuration.cannot.be.build')}
                        onHide={() => this.setState({ isNotDownload: false })} />
                </div>
                <br />
            </div>);
    }
}
export default UpdateHardware;
