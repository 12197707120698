import React, { Component } from 'react';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';

import msg from '../../texts/msg';
import { getCalendarLocale } from '../../texts/locales/calendar-locale';

class Filter extends Component {

    constructor(props) {
        super(props);
        let sDate = new Date();
        sDate.setDate(1);
        this.state = {
            locale: getCalendarLocale(),
            startDate: sDate,
            endDate: new Date(),
            typeEventSystem: '',
            subTypeEventSystem: ''
        }
        this.apply = this.apply.bind(this);
    }

    componentDidMount() {
        this.apply();
    }

    apply() {
        this.props.apply(this.buildFilter());
    }

    buildFilter() {
        //If the calendar time enabled remove this
        if (this.state.startDate && this.state.endDate) {
            let today = new Date();
            this.state.startDate.setHours(today.getHours());
            this.state.startDate.setMinutes(today.getMinutes());
            this.state.endDate.setHours(today.getHours());
            this.state.endDate.setMinutes(today.getMinutes());
        }
        return {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            typeEventSystem: this.state.typeEventSystem,
            subTypeEventSystem: this.state.subTypeEventSystem.value
        };
    }

    render() {
        let today = new Date();
        let eventsSystem = [{label: msg('filter.item.select') , value: ""}];
        if(this.props.filterData && this.props.filterData.systemEventTypes){
            this.props.filterData.systemEventTypes.forEach(e => eventsSystem.push({label: msg('filter.item.'+e.key.toLowerCase()) , value: e.key}));
        }
        const userEvents = [
            { key: msg('filter.item.select'), value: "" },
            { key: msg('filter.eventsysteme.connection.user'), value: "CONNECTION" },
            { key: msg('filter.eventsysteme.error'), value: "USER_ERROR" },
            { key: msg('filter.eventsysteme.other'), value: "USER_OTHER" },
        ];

        const systemEvents = [
            { key: msg('filter.item.select'), value: "" },
            { key: msg('filter.eventsysteme.campagne'), value: "CAMPAIGN" },
            { key: msg('filter.eventsysteme.fiscale'), value: "FISCALE" },
            { key: msg('filter.eventsysteme.startup'), value: "SERVER_STARTUP" }
        ];

        const automatedFlowsEvents = [
            { key: msg('filter.item.select'), value: "" },
            { key: msg('filter.eventsysteme.posWashAdmin'), value: "POS" },
            { key: msg('filter.eventsysteme.standalone'), value: "STANDALONE"},
            { key: msg('filter.eventsysteme.importPriceZone'), value: "PRICEZONE" },
            { key: msg('filter.eventsysteme.importMRN'), value: "MRN" },
            { key: msg('filter.eventsysteme.exportBBL'), value: "BBL" },
            { key: msg('filter.eventsysteme.paymentFlow'), value: "PAYMENTFLOW"},
            { key: msg('filter.eventsysteme.report'), value: "REPORT"}
        ];

        return (
            <div className="p-grid filter">
                {this.props.showDate && (<div className="p-col-12">
                    <div className="p-grid">
                        <div className="p-col-2 p-md-2"><span>{msg('filter.date.from')}</span></div>
                        <div className="p-col-3 p-md-3">
                            <Calendar dateFormat='dd/mm/yy' showIcon={false} selectOtherMonths={true} hideOnDateTimeSelect={true}
                                value={this.state.startDate} maxDate={this.state.endDate}
                                onChange={(e) => this.setState({ startDate: e.value })} readOnlyInput={true} locale={this.state.locale} />
                        </div>
                        <div className="p-col-2 p-md-2 " ><span>{msg('filter.date.to')}</span></div>
                        <div className="p-col-3 p-md-3">
                            <Calendar dateFormat='dd/mm/yy' showIcon={false} selectOtherMonths={true} hideOnDateTimeSelect={true}
                                value={this.state.endDate} minDate={this.state.startDate} maxDate={today}
                                onChange={(e) => this.setState({ endDate: e.value })} readOnlyInput={true} locale={this.state.locale} />
                        </div>
                        <div className="p-col-2 p-md-2 "><span></span></div>
                        <br></br>
                        <br></br>
                        <div className="p-col-2 p-md-2 "><span>{msg('filter.type.journalisation')}</span></div>
                        <div className="p-col-3 p-md-3">
                            <Dropdown value={this.state.typeEventSystem}
                                options={eventsSystem}
                                onChange={(e) => { this.setState({ typeEventSystem: e.value, subTypeEventSystem: ''}) }}
                                placeholder={msg('filter.item.select')} />
                        </div>
                        <br></br>
                        <br></br>
                        {this.state.typeEventSystem === 'USER' &&
                            <div className="p-col-2 p-md-2 "><span>{msg('filter.type.user.action')}</span></div>
                        }
                        {this.state.typeEventSystem === 'USER' &&
                            <div className="p-col-3 p-md-3">
                                <Dropdown value={this.state.subTypeEventSystem}
                                    options={userEvents}
                                    onChange={(e) => { this.setState({ subTypeEventSystem: e.value }) }}
                                    placeholder={msg('filter.item.select')} optionLabel="key" />
                            </div>
                        }
                        {this.state.typeEventSystem === 'SYSTEM' &&
                            <div className="p-col-2 p-md-2 "><span>{msg('filter.type.systeme.event')}</span></div>
                        }
                        {this.state.typeEventSystem === 'SYSTEM' &&
                            <div className="p-col-3 p-md-3">
                                <Dropdown value={this.state.subTypeEventSystem}
                                    options={systemEvents}
                                    onChange={(e) => { this.setState({ subTypeEventSystem: e.value }) }}
                                    placeholder={msg('filter.item.select')} optionLabel="key" />
                            </div>
                        }
                        {(this.state.typeEventSystem === 'AUTOMATEDFLOWS') &&
                            <div className="p-col-2 p-md-2 "><span>{msg('filter.type.systeme.event')}</span></div>
                        }
                        {(this.state.typeEventSystem === 'AUTOMATEDFLOWS') &&
                            <div className="p-col-3 p-md-3">
                                <Dropdown value={this.state.subTypeEventSystem}
                                    options={automatedFlowsEvents}
                                    onChange={(e) => { this.setState({ subTypeEventSystem: e.value }) }}
                                    placeholder={msg('filter.item.select')} optionLabel="key" />
                            </div>
                        }
                        <div className="p-col-12 apply-btn">
                            <Button label={msg('filter.apply')} title={msg('filter.apply')} onClick={this.apply} />
                        </div>
                    </div>
                </div>)}
            </div>);
    }
}

export default Filter;