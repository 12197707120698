import React, { Component } from 'react';
import CampaignList from './CampaignList';
import CampaignForm from './CampaignForm';
import { LIST_MODE, CREATION_MODE, DUPLICATE_MODE, CONSULT_MODE, PLANNING_MODE} from './campaignRenderModes';
import { rolesValidator, CAMPAIGNS, READ } from '../../../utils/RolesValidator';
import '../../common/css/common.css';
import msg from '../../../texts/msg';
import { progressSpinnerHandler } from '../../../utils/ProgressSpinnerHandler';

class Campaign extends Component {

    constructor(props) {
        super(props);
        this.state = {
            submenu: this.props.match.params.submenu,
            buttonSelectd : true,
            renderMode: LIST_MODE
        }
        this.create = this.create.bind(this);
        this.duplicate = this.duplicate.bind(this);
        this.consult = this.consult.bind(this);        
        this.validate = this.validate.bind(this);
        this.cancel = this.cancel.bind(this);
        this.launch = this.launch.bind(this);
        this.delete = this.delete.bind(this);
        this.loadCampaigns = this.loadCampaigns.bind(this);
        this.loadCampaign = this.loadCampaign.bind(this);
        this.loadFirmwares = this.loadFirmwares.bind(this);
        this.loadHardwares = this.loadHardwares.bind(this);
        this.loadPriceZones = this.loadPriceZones.bind(this);
        this.loadStations = this.loadStations.bind(this);
        this.loadCampaignEvents = this.loadCampaignEvents.bind(this);        
        this.renderList = this.renderList.bind(this);
    }

    componentDidMount() {
        progressSpinnerHandler.show(true); 
        let titleData = [{}];
        if(!this.props.borneAutoConfigEnabled) {
            titleData = [{key: 'borneAutoConfig', value: msg('campaign.borne.auto.config.disabled')}];
        }
        this.props.setDataInfoBar(titleData);
    }

    componentDidUpdate(prevProps) {
        if(this.props.match.params.submenu !== prevProps.match.params.submenu) {
            this.setState({submenu: this.props.match.params.submenu});
            if(this.props.match.params.submenu === 'list') {
                this.setState({renderMode: LIST_MODE});
            }
        }
        if(this.props.loadCampaignsData  && this.props.loadCampaignsData  !== prevProps.loadCampaignsData){
            progressSpinnerHandler.show(false);
        }
    }

    create() {
        this.setState({renderMode: CREATION_MODE});
        this.props.history.push('/campaign/form');
    }

    duplicate(campaignId) {
        this.setState({renderMode: DUPLICATE_MODE, campaignId: campaignId});
        this.props.history.push('/campaign/form');
    }

    consult(campaignId) {
        this.setState({renderMode: CONSULT_MODE, campaignId: campaignId});
        this.props.history.push('/campaign/form');
    }

    cancel() {
        this.setState({renderMode: LIST_MODE});
    }

    loadCampaign(campaignId, planning) {
        this.props.loadCampaign(campaignId, planning);
    }

    loadCampaigns(page , pageSize) {
        progressSpinnerHandler.show(true); 
        this.props.loadCampaigns(page, pageSize);
    }

    loadFirmwares(borneType) {
        this.props.loadFirmwares(borneType);
    }

    loadHardwares(borneType) {
        this.props.loadHardwares(borneType);
    }

    loadPriceZones() {
        this.props.loadPriceZones();
    }

    loadStations(campaignId, borneType, stationLevel, planning, filter, page, pageSize) {
        this.props.loadStationEquipments(campaignId, borneType, stationLevel, planning, filter, page, pageSize);
    }

    loadCampaignEvents(campaignId) {
        this.props.loadCampaignEvents(campaignId);
    }

    validate(campaign) {
        if(this.state.renderMode === CREATION_MODE || this.state.renderMode === DUPLICATE_MODE) {
            this.props.createCampaign(campaign);
        } else if (this.state.renderMode === CONSULT_MODE) {
            this.props.updateCampaign(campaign);
        } else if(this.state.renderMode === PLANNING_MODE){
            this.props.launchCampaign(campaign);
        }
    }

    renderList() {
        this.setState({renderMode: LIST_MODE});
    }

    launch(campaignId) {       
        this.setState({renderMode: PLANNING_MODE, campaignId: campaignId});
        this.props.history.push('/campaign/form'); 
    }

    delete(campaignId) {
        this.props.deleteCampaign(campaignId);
        this.setState({renderMode: LIST_MODE});
    }

    getLastChange() {
        let lastChange0 = this.props.createCampaignData && this.props.createCampaignData.data ? this.props.createCampaignData.data.lastChange : null;
        let lastChange1 = this.props.updateCampaignData && this.props.updateCampaignData.data && this.props.updateCampaignData.data.lastChange && 
                    (this.props.updateCampaignData.data.lastChange > lastChange0) ? this.props.updateCampaignData.data.lastChange : lastChange0;
        let lastChange2 = this.props.deleteCampaignData && this.props.deleteCampaignData.data && this.props.deleteCampaignData.data.lastChange && 
                    (this.props.deleteCampaignData.data.lastChange > lastChange1) ? this.props.deleteCampaignData.data.lastChange : lastChange1;
        let lastChange3 = this.props.launchCampaignData && this.props.launchCampaignData.data && this.props.launchCampaignData.data.lastChange && 
                    (this.props.launchCampaignData.data.lastChange > lastChange2) ? this.props.launchCampaignData.data.lastChange : lastChange2;
        return lastChange3;
    }

    render() {
        let authorized = rolesValidator.validate([CAMPAIGNS]);
        let rAuthorized = authorized.toString().includes(READ);
        let firmwares = this.props.loadFirmwaresData && this.props.loadFirmwaresData.data ? this.props.loadFirmwaresData.data : [];
        let hardwares = this.props.loadHardwaresData && this.props.loadHardwaresData.data ? this.props.loadHardwaresData.data : [];
        let priceZones = this.props.loadPriceZonesData && this.props.loadPriceZonesData.data ? this.props.loadPriceZonesData.data : [];
        let stationsData = this.props.loadStationEquipmentsData && this.props.loadStationEquipmentsData.data ? this.props.loadStationEquipmentsData.data : null;
        let campaigns = this.props.loadCampaignsData && this.props.loadCampaignsData.data ? this.props.loadCampaignsData.data : [];
        let campaign = this.props.loadCampaignData && this.props.loadCampaignData.data ? this.props.loadCampaignData.data : null;
        let campaignEvents = this.props.campaignEventsData && this.props.campaignEventsData.data ? this.props.campaignEventsData.data : [];
        let lastChange = this.getLastChange();        
        let campaignMode = <CampaignList create={this.create} duplicate={this.duplicate} consult={this.consult}
                                load={this.loadCampaigns} launch={this.launch} delete={this.delete}
                                    data={campaigns} lastChange={lastChange} error={this.props.error} />;
        let formModes = [CREATION_MODE, DUPLICATE_MODE, CONSULT_MODE, PLANNING_MODE];
        if(this.state.renderMode && formModes.includes(this.state.renderMode)){            
            let lmode = this.state.renderMode === CREATION_MODE ? null : 
                    ( this.state.renderMode === DUPLICATE_MODE ? DUPLICATE_MODE : CONSULT_MODE);
            let lplanning = this.state.renderMode === PLANNING_MODE;
            let lcampaignId = this.state.renderMode === CREATION_MODE ? null : this.state.campaignId;
            let lcampaign = this.state.renderMode === CREATION_MODE ? null : campaign;
            campaignMode = <CampaignForm validate={this.validate} cancel={this.cancel}
                                    mode={lmode} planning={lplanning} campaignId={lcampaignId}
                                    loadCampaign={this.loadCampaign} campaign={lcampaign}
                                    loadFirmwares={this.loadFirmwares} firmwares={firmwares}
                                    loadHardwares={this.loadHardwares} hardwares={hardwares}
                                    loadPriceZones={this.loadPriceZones} priceZones={priceZones}
                                    loadStations={this.loadStations} stationsData={stationsData} 
                                    loadCampaignEvents={this.loadCampaignEvents} campaignEvents={campaignEvents} error={this.props.error} 
                                    lastChange={lastChange} renderList={this.renderList}
                                    history={this.props.history} loadEquipmentById={this.props.loadEquipmentById}/>;
        }
        
        return rAuthorized && campaignMode;
    }
}
export default Campaign;