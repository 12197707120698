import { LOAD_STATION_GROUP, LOAD_STATION_GROUP_SUCCESS, LOAD_STATION_GROUPS, LOAD_STATION_GROUPS_SUCCESS,
	LOAD_STATION_GROUP_STATIONS, LOAD_STATION_GROUP_STATIONS_SUCCESS, CREATE_STATION_GROUP, CREATE_STATION_GROUP_SUCCESS,
	UPDATE_STATION_GROUP, UPDATE_STATION_GROUP_SUCCESS, DELETE_STATION_GROUP, DELETE_STATION_GROUP_SUCCESS } from '../actions/stationgroup';

const state0 = {data: null, error: null};

export const loadStationGroupsReducer = (state = state0, action) => {
	switch (action.type) {
		case LOAD_STATION_GROUPS:
			return {
				state,
				error: null
		};
		case LOAD_STATION_GROUPS_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};

export const loadStationGroupReducer = (state = state0, action) => {
	switch (action.type) {
		case LOAD_STATION_GROUP:
			return {
				state,
				error: null
		};
		case LOAD_STATION_GROUP_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};

export const loadStationGroupStationsReducer = (state = state0, action) => {
	switch (action.type) {
		case LOAD_STATION_GROUP_STATIONS:
			return {
				state,
				error: null
		};
		case LOAD_STATION_GROUP_STATIONS_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};

export const createStationGroupReducer = (state = state0, action) => {
	switch (action.type) {
		case CREATE_STATION_GROUP:
			return {
				state,
				error: null
		};
		case CREATE_STATION_GROUP_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};

export const updateStationGroupReducer = (state = state0, action) => {
	switch (action.type) {
		case UPDATE_STATION_GROUP:
			return {
				state,
				error: null
		};
		case UPDATE_STATION_GROUP_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};

export const deleteStationGroupReducer = (state = state0, action) => {
	switch (action.type) {
		case DELETE_STATION_GROUP:
			return {
				state,
				error: null
		};
		case DELETE_STATION_GROUP_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};