import React, {Component, Fragment} from 'react';
import {InputText} from 'primereact/inputtext';
import {Password} from 'primereact/password';
import {Button} from 'primereact/button';
import msg from '../../texts/msg';
import { toTimestamp } from '../../utils/dateTimeUtils';
import { progressSpinnerHandler } from '../../utils/ProgressSpinnerHandler';
import { authProviderType } from '../../middleware/AuthProviderType';

export class LoginPage extends Component {
	
	constructor(props) {
        super(props);
        this.state = {
        		username: '',
				password: '',
				messageError: null,
				loginError: null,
        }
		this.doOnLogin = this.doOnLogin.bind(this);
		this.onRedirectToProvider = this.onRedirectToProvider.bind(this);
	}

	componentDidUpdate(prevProps) {
		let prevTime = prevProps.loginError ? toTimestamp(prevProps.loginError.timestamp) : 0;
		let lastTime = this.props.loginError ? toTimestamp(this.props.loginError.timestamp) : 0;
		let lastChange = lastTime - prevTime > 0;
		if(lastChange) {
			if(this.props.loginError && (
				   !(this.props.loginError.message && this.props.loginError.message.startsWith('ajax') && this.props.loginError.status !== 0 )
				&& !(this.props.loginError.url && this.props.loginError.url.endsWith('oauthlogout') && this.props.loginError.status === 0)
				&& !(this.props.loginError.url && this.props.loginError.url.endsWith('rolefeatures') && this.props.loginError.status === 401))) {
				this.setState({loginError: this.props.loginError, messageError: null});
			} else {
				this.setState({loginError: null, messageError: null});
			}
			progressSpinnerHandler.show(false);
		}
	}

	doOnLogin() {
		if(this.validateLoginPasswword()){		
			progressSpinnerHandler.show(true);
			this.props.onLogin(this.state.username, this.state.password);
		}
	}

	onRedirectToProvider(authRegistrationID) {
		progressSpinnerHandler.show(true);
		this.props.redirectToProvider(authRegistrationID);
	}

	validateLoginPasswword() {
		let valid = (this.state.username && this.state.username.trim() && this.state.username.length >= 5)
			&& (this.state.password && this.state.password.trim() && this.state.password.length >= 5)
		this.setState({messageError: !valid ? msg('login.unauthorized.badcredentialsexception') : null, loginError: null});		
		return valid;
	}
		  

    render() {
		let classErrorLogin = '';
		let classErrorMessage = 'errorMessageHide' ;
		let messageError = "";
		if ((this.state.loginError || this.state.messageError)){ 
			messageError = this.state.messageError ? this.state.messageError : msg('login.server.error');
			if(this.state.loginError && this.state.loginError.status === 401){
				messageError = msg('login.' + this.state.loginError.message, {'p0': this.state.loginError.params});
			}
			classErrorLogin= 'p-inputtext p-error';
			classErrorMessage= 'errorMessageShow';
		}
        return !this.props.logedIn && (
            <div className="commonClass p-grid">
				<div className="p-col-12 p-md-4" style={{paddingRight: '0'}}/>
				<div className="p-col-12 p-md-4 p-grid card" style={{paddingRight: '0'}}>
					<div className={"p-col-12 p-grid"} style={{paddingRight: '0', textAlign: 'center'}}>
						<div className="p-col-12">
							<h1 style={{lineHeight: '30px'}}>
								<span style={{ display: 'inline', fontSize:'2rem', fontWeight:'bold' }}>{msg("login.welcome")} </span>
								<span style={{ display: 'inline', fontSize:'2rem', fontWeight:'bold', color:'#e4032e' }}>WashAdmin</span>
							</h1>
						</div>
					</div>
					{ !authProviderType.isDbAuthDisabled() &&
					<div className={"p-col-12 p-grid"} style={{paddingRight: '0'}}>
						<div className="p-col-12" id="Div2ErrorMessage" style={{minHeight: '40px'}}>
							<label className={classErrorMessage}>{messageError}</label>
						</div>
						<div className="p-col-12"><label htmlFor="login">{msg('login.login')} <span className="obligatoire">*</span></label></div>
						<div className="p-col-12">
							<InputText className={classErrorLogin + " p-col-12"} id="login" type="text" maxLength="255" value={this.state.username} 
										onChange={(e) => {this.setState({username: e.target.value}) }} 
										onKeyUp={(e) => {if(e.keyCode === 13) this.doOnLogin(); e.target.className = 'p-inputtext p-component'}}/>
						</div>
						<div className="p-col-12"><label htmlFor="login">{msg('login.password')} <span className="obligatoire">*</span></label></div>
						<div className="p-col-12">
							<Password id="loginPwd" className={classErrorLogin + " p-col-12"} maxLength="30"
										onChange={(e) => this.setState({password: e.target.value})} feedback={false}
										onKeyUp={(e) => {if(e.keyCode === 13) this.doOnLogin(); e.target.className = 'p-inputtext p-component'} }/>
						</div>
						<div className="p-col-12"/>
						<div className="p-col-12">
							<Button id="loginButon" label={msg('login.button')} title={msg('login.button')} onClick={this.doOnLogin} style={{width: '100%', minHeight: '40px'}}/>
						</div>
						<div className="p-col-12"/>
					</div>}
					{ !authProviderType.isOidcAuthDisabled() &&
					<Fragment>
					<div className={"p-col-12 p-grid"} style={{paddingRight: '0'}}>
						<hr className={"p-col-11"} style={{padding:'0', color: '#e4032e'}}/>
						{!authProviderType.isOidcAuth1Disabled() &&
						<Fragment>
							<div className="p-col-12"/>						
							<div className="p-col-12">
								<Button id="teConnectRedirectButon" label={msg('login.button.totalenergies.connect')} title={msg('login.button.totalenergies.connect')}
									onClick={e => this.onRedirectToProvider(authProviderType.getAuthRegistrationID1())}
									style={{width: '100%', minHeight: '40px', backgroundColor: 'transparent', color: authProviderType.isOidcAuth1Disabled() ? 'grey' : 'black'}}
									disabled={authProviderType.isOidcAuth1Disabled()} />
							</div>
						</Fragment>}
						{!authProviderType.isOidcAuth2Disabled() && 
						<Fragment>
							<div className="p-col-12"/>
							<div className="p-col-12">
								<Button id="digitalPassRedirectButon" label={msg('login.button.digital.pass')} title={msg('login.button.digital.pass')}
									onClick={e => this.onRedirectToProvider(authProviderType.getAuthRegistrationID2())}
									style={{width: '100%', minHeight: '40px', backgroundColor: 'transparent', color: authProviderType.isOidcAuth2Disabled() ? 'grey' : 'black'}} 
									disabled={authProviderType.isOidcAuth2Disabled()} />
							</div>
						</Fragment>}
						<div className="p-col-12"/>
					</div>
					</Fragment>}
				</div>
				<div className="p-col-12 p-md-4" style={{paddingRight: '0'}}/>
			</div>
        );
    }
}