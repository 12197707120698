import { ofType} from 'redux-observable';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError} from  'rxjs/operators';
import { usersSuccess, userSuccess, addUserSuccess, loadGroupsSuccess, userDeleteByIdSuccess, 
	passwordUpdateSuccess, myPasswordUpdateSuccess, userUpdateSuccess, generateBorneAccessCodeSuccess, GENERATE_BORNE_ACCESS_CODE } from '../actions/user';
import {USERS, USER, USER_DELETE_BY_ID, PASSWORD_UPDATE, MY_PASSWORD_UPDATE, USER_UPDATE,
	ADD_USER, LOAD_GROUPS} from '../actions/user';
import {faillure } from '../actions';
import { authProviderType } from '../AuthProviderType';

export const usersEpic = (epicAction)  => {
	return  epicAction.pipe(
			ofType(USERS),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlUsers = apiRootUri + "users/";
				const req = ajax({
						url: urlUsers + "filter"
							+ "?page=" + action.data.page +"&pageSize=" + action.data.pageSize,
					  	method: 'POST',				  
						headers: {
							'Content-Type': 'application/json'
						},
						withCredentials: true,
						body: action.data.userFilter
					});					
				return req.pipe(
            			map(res => {return usersSuccess(res);}),
            			catchError(res => { return of(faillure(res));})
            		)
			}),
	);
};


export const userEpic = (epicAction)  => {
	return  epicAction.pipe(
			ofType(USER),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlUsers = apiRootUri + "users/";
				const req = ajax({
					  	url: urlUsers + action.data.userId,
					  	method: 'GET',				  
						headers: {
							'Content-Type': 'application/json'
						},
						withCredentials: true
					});				
				return req.pipe(
            			map(res => {return userSuccess(res);}),
            			catchError(res => { return of(faillure(res));})
            		)
			}),
	);	
};

export const addUserEpic = (epicAction)  => {
	return  epicAction.pipe(
			ofType(ADD_USER),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlUsers = apiRootUri + "users/";
				const req = ajax({
					  	url: urlUsers,
					  	method: 'POST',				  
						headers: {
							'Content-Type': 'application/json'
						},
						withCredentials: true,
						body: action.data.user
					});
					
				return req.pipe(
            			map(res => {
							return addUserSuccess(res);}),
            			catchError(res => { return of(faillure(res));})
            		)
			}),
	);
	
};

export const loadGroupsEpic = (epicAction) => {
	return  epicAction.pipe(
			ofType(LOAD_GROUPS),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlUsers = apiRootUri + "users/";
				const req = ajax({
						url: urlUsers + "groups",
						method: 'GET',
						withCredentials: true
					  });
				return req.pipe(
            			map(res => { return loadGroupsSuccess(res); }),
            			catchError(res => { return of(faillure(res));})
            		)
			}),
	);	
};

export const userDeleteByIdEpic = (epicAction)  => {
	return  epicAction.pipe(
			ofType(USER_DELETE_BY_ID),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlUsers = apiRootUri + "users/";
				const req = ajax({
					url: urlUsers + action.data.userId,
					method: 'DELETE',					  
					headers: {
					  'Content-Type': 'application/json'
				  },
				  withCredentials: true
			 	});
				return req.pipe(
					map(res => {return userDeleteByIdSuccess(res);}),
					catchError(res => { return of(faillure(res));})
				)
			}),
	);	
};

export const userUpdateEpic = (epicAction)  => {
	return  epicAction.pipe(
			ofType(USER_UPDATE),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlUsers = apiRootUri + "users/";
				const req = ajax({
						url: urlUsers + action.data.userId,
					  	method: 'PUT',
					  	headers: {
							'Content-Type': 'application/json'
						},
						withCredentials: true,
					  	body: action.data.user
					});
				return req.pipe(
            			map(res => { return userUpdateSuccess(res);}),
            			catchError(res => { return of(faillure(res));})
            		)
			}),
	);
};

export const passwordUpdateEpic = (epicAction)  => {
	return  epicAction.pipe(
			ofType(PASSWORD_UPDATE),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlUsers = apiRootUri + "users/";
				const req = ajax({
						url: urlUsers + action.data.userId + "/password",
					  	method: 'PUT',
					  	headers: {
							'Content-Type': 'application/json'
						},
						withCredentials: true,
					  	body: action.data.password
					});
				return req.pipe(
            			map(res => { return passwordUpdateSuccess(res);}),
            			catchError(res => { return of(faillure(res));})
            		)
			}),
	);
};

export const myPasswordUpdateEpic = (epicAction)  => {
	return  epicAction.pipe(
			ofType(MY_PASSWORD_UPDATE),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlUsers = apiRootUri + "users/";
				const req = ajax({
						url: urlUsers + "mypassword/update",
					  	method: 'PUT',
					  	headers: {
							'Content-Type': 'application/json'
						},
						withCredentials: true,
					  	body: action.data.password
					});
				return req.pipe(
            			map(res => { return myPasswordUpdateSuccess(res);}),
            			catchError(res => { return of(faillure(res));})
            		)
			}),
	);
};

export const generateBorneAccessCodeEpic = (epicAction) => {
	return  epicAction.pipe(
	ofType(GENERATE_BORNE_ACCESS_CODE),
	mergeMap(action => {
		let apiRootUri = authProviderType.getApiRootUri();
		let urlUsers = apiRootUri + "users/";
		const req = ajax({
			url: urlUsers + "borneaccess/generate",
			method: 'GET',
			withCredentials: true
		});
		return req.pipe(map(res => generateBorneAccessCodeSuccess(res)), catchError(res => of(faillure(res))));
	}));	
};