class AuthProviderType {

    authType = 'cartadis';

    setAuthType(type) {
        this.authType = type;
    }

    getAuthType() {
        return this.authType;
    }
    getApiRootUri() {
        return process.env.REACT_APP_REST_API;
    }

    getApiDomainUri() {
        return process.env.REACT_APP_DOMAIN;
    }

    isDbAuthDisabled() {
        if(process.env.REACT_APP_DB_AUTH_DISABLED === true || process.env.REACT_APP_DB_AUTH_DISABLED === 'true'){
            return true;
        }
        return false;
    }
    
    isOidcAuth1Disabled() {
        if(process.env.REACT_APP_OIDC_AUTH1_DISABLED === true || process.env.REACT_APP_OIDC_AUTH1_DISABLED === 'true'){
            return true;
        }
        return false;
    }

    isOidcAuth2Disabled() {
        if(process.env.REACT_APP_OIDC_AUTH2_DISABLED === true || process.env.REACT_APP_OIDC_AUTH2_DISABLED === 'true'){
            return true;
        }
        return false;
    }


    isOidcAuthDisabled() {
        return this.isOidcAuth1Disabled() && this.isOidcAuth2Disabled();
    }

    getAuthRegistrationID1(){
        return process.env.REACT_APP_OIDC_REGISTRATION_ID1;
    }
    
    getAuthRegistrationID2(){
        return process.env.REACT_APP_OIDC_REGISTRATION_ID2;
    }
}

export const  authProviderType  =  new AuthProviderType();