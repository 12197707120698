import msg from '../texts/msg';

export const languages = [
  { key: msg('station.fr'), value: "fra" },
  { key: msg('station.en'), value: "eng" },
  { key: msg('station.de'), value: "deu" },
];

export const saleChannels = [
  {key: msg('station.contract.schannel.coco'), value : 'COCO'},
  {key: msg('station.contract.schannel.codo'), value : 'CODO',},
  {key: msg('station.contract.schannel.dodo'), value : 'DODO', },
  {key: msg('station.contract.schannel.lotus'), value : 'LOTUS', }
];