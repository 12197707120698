import { ofType} from 'redux-observable';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError} from  'rxjs/operators';
import {firmwaresSuccess, firmwareSuccess, firmwareDeleteByIdSuccess} from '../actions/firmwares';
import {FIRMWARES, FIRMWARE, FIRMWARE_DELETE_BY_ID} from '../actions/firmwares';
import {faillure } from '../actions';
import { authProviderType } from '../AuthProviderType';

export const firmwaresEpic = (epicAction)  => {
	return  epicAction.pipe(
			ofType(FIRMWARES),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlFirmwares = apiRootUri + "firmware";
				const req = ajax({
					  	url: urlFirmwares + "/firmwares?page=" + action.data.page+"&pageSize=" + action.data.pageSize,
					  	method: 'POST',				  
						headers: {
							'Content-Type': 'application/json'
						},
						withCredentials: true,
						body: action.data.filter
					});					
				return req.pipe(
            			map(res => {return firmwaresSuccess(res);}),
            			catchError(res => { return of(faillure(res));})
            		)
			}),
	);
};

export const firmwareEpic = (epicAction)  => {
	return  epicAction.pipe(
			ofType(FIRMWARE),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlFirmwares = apiRootUri + "firmware";
				const req = ajax({
					  	url: urlFirmwares + "/" + action.data.firmwareId,
					  	method: 'GET',				  
						headers: {
							'Content-Type': 'application/json'
						},
						withCredentials: true,
					});				
				return req.pipe(
            			map(res => {return firmwareSuccess(res);}),
            			catchError(res => { return of(faillure(res));})
            		)
			}),
	);	
};

export const firmwareDeleteByIdEpic = (epicAction)  => {
	return  epicAction.pipe(
			ofType(FIRMWARE_DELETE_BY_ID),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlFirmwares = apiRootUri + "firmware";
				const req = ajax({
					url: urlFirmwares + "/" +action.data.firmwareId,
					method: 'DELETE',					  
					headers: {
					  'Content-Type': 'application/json'					   
				  },
				  withCredentials: true,
			 	});
				return req.pipe(
					map(res => {return firmwareDeleteByIdSuccess(res);}),
					catchError(res => { return of(faillure(res));})
				)
			}),
	);	

};