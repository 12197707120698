import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';
import ActivityType from './ActivityType';
import { formatDateTime } from '../../../utils/dateTimeUtils';
import msg from '../../../texts/msg';

export default class Activity extends Component {

	render() {

		let datime = formatDateTime(this.props.refresh ? this.props.refresh :  new Date());
		let dayBeforeDateTime = this.props.dayBefore ? formatDateTime(new Date(new Date().getTime() - 24*60*60*1000)) : '';
		
		return (
			<div className=" dashboard p-sm-12 p-md-3 p-lg-3 p-large-screen p-smal-screen p-extra-small-screen  activity">
				<Fieldset className="p-col-12" legend={this.props.legend} toggleable={true}>
					<ActivityPeriod className="p-col-12" refresh={this.props.refresh} refreshTime={datime}
						dayBefore={dayBeforeDateTime.date} load={this.props.load} />
					<ActivityPrestation data={this.props.data} evol={this.props.evol} />
					<ActivityVente data={this.props.data} evol={this.props.evol} />
				</Fieldset>
			</div>);
	}
}

class ActivityPeriod extends Component {
	render() {
		let today = formatDateTime(new Date());
		let refreshLabel = this.props.refresh && ( today.date === this.props.refreshTime.date ? msg('today.refresh.at', { time : this.props.refreshTime.time})
		: msg('last.day.refresh', { datetime : this.props.refreshTime.date + ' ' + this.props.refreshTime.time}));
		return (
			<div className="p-grid  p-col-12 period"  >
				<div className="p-col-10 detail-container">
					<span className="detail">
						{refreshLabel}
						{this.props.dayBefore}
					</span>
				</div>
				<div className="p-col-2 period-refresh">
					{this.props.refresh && (<Button icon="pi-md-refresh" onClick={this.props.load} />)}
				</div>
			</div>
		);
	}

}

class ActivityPrestation extends Component {
	
	render() {

		let evol = this.props.evol && this.props.data && this.props.data.prestEvolution;
		let amount = this.props.data && this.props.data.prestations ? this.props.data.prestations.amount : 0.00;

		let prestationTypeItems = this.props.data &&  this.props.data.prestations ? this.props.data.prestations.typeItems : null;
		let prestationTypeTitle = msg('type.prest.chart.title');
		if(prestationTypeItems){
			for(let e in prestationTypeItems){
				prestationTypeItems[e].label = msg(prestationTypeItems[e].label);
			}
		}

		let programTypeItems = this.props.data &&  this.props.data.prestations ? this.props.data.prestations.programItems : null;
		let programTypeTitle = msg('programs.chart.title');

		let charts = [
			{items: prestationTypeItems, type: "doughnut", title: prestationTypeTitle}, 
			{items: programTypeItems, type: "pie", title: programTypeTitle}
		];

		return (
			<div className="activityPrestation p-col-12"> 
				<ActivityType  className ="p-col-12" type="prest" icon="local-car-wash" title={msg('featurekey')} amount={amount} evol={evol} charts={charts} />
			</div>
		);
	}

}

class ActivityVente extends Component {
	render() {

		let evol = this.props.evol && this.props.data && this.props.data.venteEvolution;
		let amount = this.props.data && this.props.data.ventes ? this.props.data.ventes.amount : 0.00;

		let paymentItems = this.props.data &&  this.props.data.ventes ? this.props.data.ventes.paymentItems : null;
		let paymentTitle = msg('payment.chart.title');

		let typeProductItems = this.props.data && this.props.data.ventes ? this.props.data.ventes.typeItems : null;
		let typeProductTitle = msg('type.products.chart.title');

		let charts = [
			{items: paymentItems, type: "doughnut", title: paymentTitle}, 
			{items: typeProductItems, type: "doughnut", title: typeProductTitle}
		];

		return (
			<div className="activityVente p-col-12"> 
				<ActivityType className ="activityVente" type="vente" activityImageSrc="/assets/ultima/layout/images/cartadis/Vente_blanc.svg" title={msg('sellingkey')} amount={amount} evol={evol} charts={charts} />
			</div>
		);
	}
}