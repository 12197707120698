import { ofType} from 'redux-observable';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError} from  'rxjs/operators';
import { TEST_NETWORK_SPEED, REBOOT, rebootSuccess, checkNetworkSpeedSuccess, GET_BORNE_LOGS, getBorneLogsSuccess, LOAD_BORNES, loadBornesSuccess
,DISASSOCIATE, disassociateBorneSuccess  } from '../actions/borne';
import {faillure } from '../actions';
import { authProviderType } from '../AuthProviderType';

export const disassociateBorneEpic = (epicAction)  => {
	return  epicAction.pipe(
		ofType(DISASSOCIATE),
		mergeMap((action) => {
			let apiRootUri = authProviderType.getApiRootUri();
			let urlBorne = apiRootUri + "equipments";
			const req = ajax({
				url: urlBorne + '/' + action.data.equipmentId + '/' + action.data.borneId + '/disassociateBorne',
				method: 'POST',					  
				headers: {'Content-Type': 'application/json'},
				withCredentials: true,
				body: action.data.filter
			});
			return req.pipe(map(res => disassociateBorneSuccess(res)), catchError(res => of(faillure(res))))
		})
	);
};

export const loadBornesEpic = (epicAction)  => {
	return  epicAction.pipe(
		ofType(LOAD_BORNES),
		mergeMap((action) => {
			let apiRootUri = authProviderType.getApiRootUri();
			let urlBorne = apiRootUri + "equipments";
			const req = ajax({
				url: urlBorne + '/bornes',
				method: 'POST',					  
				headers: {'Content-Type': 'application/json'},
				withCredentials: true,
				body: action.data.filter
			});
			return req.pipe(map(res => loadBornesSuccess(res)), catchError(res => of(faillure(res))))
		})
	);
};

export const checkNetworkSpeedEpic = (epicAction)  => {
	return  epicAction.pipe(
		ofType(TEST_NETWORK_SPEED),
		mergeMap((action) => {
			let apiRootUri = authProviderType.getApiRootUri();
			let urlBorne = apiRootUri + "equipments";
			const req = ajax({
				url: urlBorne + '/' + action.data.borneId + '/checkNetworkSpeed',
				method: 'POST',					  
				headers: {'Content-Type': 'application/json'},
				withCredentials: true,
				body: action.data.borne
			});
			return req.pipe(map(res => checkNetworkSpeedSuccess(res)), catchError(res => of(faillure(res))))
		})
	);
};


export const rebootBorneEpic = (epicAction)  => {
	return  epicAction.pipe(
		ofType(REBOOT),
		mergeMap((action) => {
			let apiRootUri = authProviderType.getApiRootUri();
			let urlBorne = apiRootUri + "equipments";
			const req = ajax({
				url: urlBorne + '/' + action.data.borneId + '/reboot',
				method: 'POST',					  
				headers: {'Content-Type': 'application/json'},
				withCredentials: true,
				body: action.data.borne
			});
			return req.pipe(map(res => rebootSuccess(res)), catchError(res => of(faillure(res))))
		})
	);
};

export const getBorneLogsEpic = (epicAction)  => {
	return  epicAction.pipe(
		ofType(GET_BORNE_LOGS),
		mergeMap((action) => {
			let apiRootUri = authProviderType.getApiRootUri();
			let urlBorne = apiRootUri + "equipments";
			const req = ajax({
				url: urlBorne + '/' + action.data.borneId + '/logs',
				method: 'POST',					  
				headers: {'Content-Type': 'application/json'},
				withCredentials: true,
				body: action.data.borne
			});
			return req.pipe(map(res => getBorneLogsSuccess(res)), catchError(res => of(faillure(res))))
		})
	);
};