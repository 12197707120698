
import { LOAD_STATIONS, LOAD_STATIONS_SUCCESS, ADD_STATION, ADD_STATION_SUCCESS,
	LOAD_STATIONS_MAP, LOAD_STATIONS_MAP_SUCCESS, STATION, STATION_SUCCESS,
	STATION_DELETE_BY_ID, STATION_DELETE_BY_ID_SUCCESS, 
	STATION_UPDATE, STATION_UPDATE_SUCCESS, UPDATE_CONFIG_STATION, UPDATE_CONFIG_STATION_SUCCESS, CHANGE_STATION_CONTRACT, CHANGE_STATION_CONTRACT_SUCCESS, LOAD_STATION_PRICEZONES, LOAD_STATION_PRICEZONES_SUCCESS, LOAD_STATION_COMMISSIONS, LOAD_STATION_COMMISSIONS_SUCCESS} from '../actions/station';

const state0 = {data: null, error: null};

export const loadStationsReducer = (state = state0, action) => {
	switch (action.type) {
		case LOAD_STATIONS:
			return {
				state,
				error: null
		};
		case LOAD_STATIONS_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};

export const addStationReducer = (state = state0, action) => {
	switch (action.type) {
		case ADD_STATION:
			return {
				state,
				error: null
			};
		case ADD_STATION_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
			};
		default:
			return state;
	}
};

export const stationUpdateReducer = (state = state0, action) => {
	switch (action.type) {
		case STATION_UPDATE:
			return {
				state,
				error: null
			};
		case STATION_UPDATE_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
			};
		default:
			return state;
	}
};

export const changeStationContractReducer = (state = state0, action) => {
	switch (action.type) {
		case CHANGE_STATION_CONTRACT:
			return {
				state,
				error: null
			};
		case CHANGE_STATION_CONTRACT_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
			};
		default:
			return state;
	}
};

export const stationDeleteByIdReducer = (state = state0, action) => {
	switch (action.type) {
		case STATION_DELETE_BY_ID:
			return {
				state,
				error: null
			};
		case STATION_DELETE_BY_ID_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
			};
		default:
			return state;
	}
};

export const stationReducer = (state = state0, action) => {
	switch (action.type) {
			case STATION:
			case STATION_SUCCESS:
					return {
							state,
							data: action.data,
							error: null
					};
			default:
					return state;
	}
};

export const loadStationsMapReducer = (state = state0, action) => {
	switch (action.type) {
		case LOAD_STATIONS_MAP:
			return {
				state,
				error: null
		};
		case LOAD_STATIONS_MAP_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};

export const updateConfigStationReducer = (state = state0, action) => {
	switch (action.type) {
		case UPDATE_CONFIG_STATION:
			return {
				state,
				error: null
		};
		case UPDATE_CONFIG_STATION_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};

export const loadStationPriceZonesReducer = (state = state0, action) => {
	switch (action.type) {
		case LOAD_STATION_PRICEZONES:
			return {
				state,
				error: null
		};
		case LOAD_STATION_PRICEZONES_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};

export const loadStationCommissionsReducer = (state = state0, action) => {
	switch (action.type) {
		case LOAD_STATION_COMMISSIONS:
			return {
				state,
				error: null
		};
		case LOAD_STATION_COMMISSIONS_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};