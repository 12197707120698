import React, { Component } from 'react';
import QRCode from 'qrcode.react';
import msg from '../../../texts/msg';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { EQUIPMENTS, UPDATE, rolesValidator } from '../../../utils/RolesValidator';

const BORNE_TL16 = "TL16";

class ServiceInformationDialog extends Component {

    render() {
        let authorized = rolesValidator.validate(EQUIPMENTS);
        let uAuthorized = authorized.toString().includes(UPDATE);
        let footer = (
            <div>
                <Button label={msg('message.dialog.button.send')} onClick={this.props.onClick} icon="pi pi-check" disabled={!uAuthorized} />
                <Button label={msg('message.dialog.button.ok')} onClick={this.props.onHide} />
            </div>);
        let header = (<h4>{msg('equipment.commissioning.info')}</h4>);
        let posFirstCol = "p-col-5", posSecondCol = "p-col-7";
        let content =  (
            <div className="p-grid p-col-12">
                <div className="p-grid p-md-9 p-col-12">
                    <div className={posFirstCol} >
                        <label>{msg('equipment.qrcode.implant')} :</label>
                    </div>
                    <div className={posSecondCol} >
                        {this.props.BorneData.implant}
                    </div>
                    <div className={posFirstCol} style={{ marginTop: "-7px" }}>
                        <label>{msg('equipment.qrcode.stationName')} :</label>
                    </div>
                    <div className={posSecondCol} style={{ marginTop: "-7px" }}>
                        {this.props.BorneData.stationName}
                    </div>
                    <div className={posFirstCol} style={{ marginTop: "-7px" }}>
                        <label>{msg('equipment.qrcode.sapName')} :</label>
                    </div>
                    <div className={posSecondCol} style={{ marginTop: "-7px" }}>
                        {this.props.BorneData.sapName}
                    </div>
                    <div>
                    <br/><br/>
                    </div>
                    <div className={posFirstCol} style={{ marginTop: "-7px" }}>
                        <label>{msg('equipment.qrcode.name')} :</label>
                    </div>
                    <div className={posSecondCol} style={{ marginTop: "-7px" }}>
                        {this.props.BorneData.equipmentName}
                    </div>
                    <div className={posFirstCol} style={{ marginTop: "-7px" }} >
                        <label>{msg('equipment.qrcode.borneType')} :</label>
                    </div>
                    <div className={posSecondCol} style={{ marginTop: "-7px" }}>
                        {this.props.BorneData.borneType}
                    </div>
                    <div className={posFirstCol} style={{ marginTop: "-7px" }}>
                        <label>{msg('equipment.identity.update.id')} </label>
                    </div>
                    <div className={posSecondCol} style={{ marginTop: "-7px" }}>
                        {this.props.BorneData.equipmentId}
                    </div>
                    <div>
                    <br/><br/>
                    </div>
                    <div className={posFirstCol} style={{ marginTop: "-7px" }}>
                        <label>{msg('equipment.configuration.borne.ip')} :</label>
                    </div>
                    <div className={posSecondCol} style={{ marginTop: "-7px" }}>
                        {this.props.BorneData.borneIp}
                    </div>
                    <div className={posFirstCol} style={{ marginTop: "-7px" }}>
                        <label>{msg('equipment.qrcode.mask')} :</label>
                    </div>
                    <div className={posSecondCol} style={{ marginTop: "-7px" }}>
                        {this.props.BorneData.borneMask}
                    </div>
                    <div className={posFirstCol} style={{ marginTop: "-7px" }}>
                        <label>{msg('equipment.configuration.borne.gatewat')} :</label>
                    </div>
                    <div className={posSecondCol} style={{ marginTop: "-7px" }}>
                        {this.props.BorneData.borneGateWay}
                    </div>
                    <div>
                    <br/><br/>
                    </div>
                    <div className={posFirstCol}  style={{ marginTop: "-7px" }}>
                        <label>{msg('equipment.configuration.borne.neftisIp')} :</label>
                    </div>           
                    <div className={posSecondCol} style={{ marginTop: "-7px" }}>
                        {this.props.BorneData.neftisIp}
                    </div>
                    <div className={posFirstCol}  style={{ marginTop: "-7px" }}>
                        <label>{msg('equipment.configuration.borne.neftisStationId')} :</label>
                    </div>
                    <div className={posSecondCol} style={{ marginTop: "-7px" }}>
                        { this.props.BorneData.implant + this.props.BorneData.neftisId}
                    </div>
                    <div>
                    <br/><br/>
                    </div>
                    <div className={posFirstCol}  style={{ marginTop: "-7px" }}>
                        <label>{msg('user.equipment.mail.qrcode.configuration.hardware.name')} </label>
                    </div>
                    <div className={posSecondCol} style={{ marginTop: "-7px" }}>
                        {this.props.BorneData.hardware}
                    </div>
                    {(this.props.BorneData.borneType === BORNE_TL16) &&
                    <div className={posFirstCol}  style={{ marginTop: "-7px" }}>
                    <label>{msg('equipment.configuration.borne.tl16')} </label>
                    </div>}
                   {(this.props.BorneData.isMulti !== undefined) && (this.props.BorneData.isMulti) &&
                    <div className={posSecondCol} style={{ marginTop: "-7px" }}>
                        <label>{msg('equipment.configuration.borne.tl16.multiUser')} </label>
                    </div>
                   }
                    {(this.props.BorneData.isMulti !== undefined) && (!this.props.BorneData.isMulti) &&
                    <div className={posSecondCol} style={{ marginTop: "-7px" }}>
                        <label>{msg('equipment.configuration.borne.tl16.singleUser')} </label>
                    </div> }
                    <div className={posFirstCol}  style={{ marginTop: "-7px" }}>
                        <label>{msg('equipment.configuration.washCode')} :</label>
                    </div>
                    <div className={posSecondCol} style={{ marginTop: "-7px" }}>
                        {this.props.BorneData.washCodeConfiguration}
                    </div>
                </div>
                <div className="p-md-3 p-col-12">
                    <QRCode
                        id="qr-gen"
                        style={{ marginTop: "-12px" }}
                        value={this.props.BorneData.qrValue}
                        size={200}
                        level={"H"}
                        includeMargin={true}/>
                </div>
            </div>
        );  
        
       return  <Dialog className="p-md-7 p-col-12" visible={this.props.visible} modal={false} closable={false}
                    onHide={this.props.onHide} header={header} footer={footer}
                    //style={{ width: "45%" }}
                    >
            {content}
        </Dialog>;
    }
}

export default ServiceInformationDialog;