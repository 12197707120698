import {EQUIPMENTS,EQUIPMENTS_SUCCESS, EQUIPMENT_BY_ID, EQUIPMENT_BY_ID_SUCCESS, CLOSE_ALARM, CLOSE_ALARM_SUCCESS, 
	QRCODE_SEND_BY_ID, QRCODE_SEND_BY_ID_SUCCESS, SERVICE_INFORMATIONS, SERVICE_INFORMATIONS_SUCCESS, OPEN_ALARMS, OPEN_ALARMS_SUCCESS} from '../actions/equipments';

const state0 = {data: null, error: null};

export  const equipmentsReducer = (state = state0, action) => {
	switch (action.type) {
		case EQUIPMENTS:
			return {
				state,
				error: null
		};
		case EQUIPMENTS_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};
export  const equipmentByIdReducer = (state = state0, action) => {
	switch (action.type) {
		case EQUIPMENT_BY_ID:
			return {
				state,
				error: null
		};
		case EQUIPMENT_BY_ID_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};
export  const loadServiceInformationsReducer = (state = state0, action) => {
	switch (action.type) {
		case SERVICE_INFORMATIONS:
			return {
				state,
				error: null
		};
		case SERVICE_INFORMATIONS_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};

export  const loadOpenAlarmsReducer = (state = state0, action) => {
	switch (action.type) {
		case OPEN_ALARMS:
			return {
				state,
				error: null
		};
		case OPEN_ALARMS_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};

export  const closeAlarmReducer = (state = state0, action) => {
	switch (action.type) {
		case CLOSE_ALARM:
			return {
				state,
				error: null
		};
		case CLOSE_ALARM_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};

export  const qrCodeSendByIdReducer = (state = state0, action) => {
	switch (action.type) {
		case QRCODE_SEND_BY_ID:
			return {
				state,
				error: null
		};
		case QRCODE_SEND_BY_ID_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};