import { ofType} from 'redux-observable';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError} from  'rxjs/operators';
import { loadStationGroupSuccess, loadStationGroupsSuccess, loadStationGroupStationsSuccess, 
    createStationGroupSuccess, updateStationGroupSuccess, deleteStationGroupSuccess } from '../actions/stationgroup';
import { LOAD_STATION_GROUP, LOAD_STATION_GROUPS, LOAD_STATION_GROUP_STATIONS, 
    CREATE_STATION_GROUP, UPDATE_STATION_GROUP, DELETE_STATION_GROUP } from '../actions/stationgroup';
import { faillure } from '../actions';
import { authProviderType } from '../AuthProviderType';

export const loadStationGroupsEpic = (epicAction) => {
	return  epicAction.pipe(
        ofType(LOAD_STATION_GROUPS),
        mergeMap(action => {
            let apiRootUri = authProviderType.getApiRootUri();
			let urlStationGroup = apiRootUri + 'stationgroups';
            const req = ajax({
                url: urlStationGroup + '/filter',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
                body: action.data.filter
            });
            return req.pipe(
                map(res => loadStationGroupsSuccess(res)),
                catchError(res => of(faillure(res)))
            )
        })
	);	
};

export const loadStationGroupEpic = (epicAction) => {
	return  epicAction.pipe(
        ofType(LOAD_STATION_GROUP),
        mergeMap(action => {
            let apiRootUri = authProviderType.getApiRootUri();
			let urlStationGroup = apiRootUri + 'stationgroups';
            const req = ajax({
                url: urlStationGroup + '/' + action.data.id,
                method: 'GET',
                withCredentials: true
            });
            return req.pipe(
                map(res => loadStationGroupSuccess(res)),
                catchError(res => of(faillure(res)))
            )
        })
	);	
};

export const loadStationGroupStationsEpic = (epicAction) => {
	return  epicAction.pipe(
        ofType(LOAD_STATION_GROUP_STATIONS),
        mergeMap(action => {
            let apiRootUri = authProviderType.getApiRootUri();
			let urlStationGroup = apiRootUri + 'stationgroups';
            const req = ajax({
                url: urlStationGroup + '/stations?page=' + action.data.page + '&pageSize=' + action.data.pageSize,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
                body: action.data.filter
            });
            return req.pipe(
                map(res => loadStationGroupStationsSuccess(res)),
                catchError(res => of(faillure(res)))
            )
        })
	);	
};

export const createStationGroupEpic = (epicAction) => {
	return  epicAction.pipe(
        ofType(CREATE_STATION_GROUP),
        mergeMap(action => {
            let apiRootUri = authProviderType.getApiRootUri();
			let urlStationGroup = apiRootUri + 'stationgroups';
            const req = ajax({
                url: urlStationGroup,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
                body: action.data.stationGroup
            });
            return req.pipe(
                map(res => createStationGroupSuccess(res)),
                catchError(res => of(faillure(res)))
            )
        })
	);	
};

export const updateStationGroupEpic = (epicAction) => {
	return  epicAction.pipe(
        ofType(UPDATE_STATION_GROUP),
        mergeMap(action => {
            let apiRootUri = authProviderType.getApiRootUri();
			let urlStationGroup = apiRootUri + 'stationgroups';
            const req = ajax({
                url: urlStationGroup + '/' + action.data.stationGroup.id,
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
                body: action.data.stationGroup
            });
            return req.pipe(
                map(res => updateStationGroupSuccess(res)),
                catchError(res => of(faillure(res)))
            )
        })
	);	
};

export const deleteStationGroupEpic = (epicAction) => {
	return  epicAction.pipe(
        ofType(DELETE_STATION_GROUP),
        mergeMap(action => {
            let apiRootUri = authProviderType.getApiRootUri();
			let urlStationGroup = apiRootUri + 'stationgroups';
            const req = ajax({
                url: urlStationGroup + '/' + action.data.id,
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            });
            return req.pipe(
                map(res => deleteStationGroupSuccess(res)),
                catchError(res => of(faillure(res)))
            )
        })
	);	
};