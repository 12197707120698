/* Component pour afficher la liste des équipements et les transactions attachées a chaque équipement  */

import React, { Component } from 'react'
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SettingsAccessibilityIcon from '@material-ui/icons/Launch';
import VisibilityIcon from '@material-ui/icons/FormatListBulleted';
import GetAppIcon from '@material-ui/icons/GetApp';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ReportIcon from '@material-ui/icons/Info'
import MenuList from '@material-ui/core/MenuList';
import BorneInformationReport from './BorneInformationReport';
import BorneActivityFilter from './BorneActivityFilter';
import { rolesValidator, EQUIPMENTS, TELEACTIONS, UPDATE } from '../../../utils/RolesValidator.js';
import { checkDuration } from '../../../utils/validateInputData';
import TableData from '../../../utils/TableData.js';
import { toDefaultDateFormat, toLocaleDateTimeFormat } from '../../../utils/dateTimeUtils';
import MessageDialog from '../../common/MessageDialog';
import { progressSpinnerHandler } from '../../../utils/ProgressSpinnerHandler';
import UploadService from "../../../middleware/download-upload-files.service";
import { userSessionParamsHandler } from '../../../utils/UserSessionParamsHandler.js';
import { getCalendarLocale } from '../../../texts/locales/calendar-locale.js';
import msg from '../../../texts/msg';
import '../../common/css/common.css';

let TICKET_STATE = "ticketState";
let COMMAND_STATE = "commandState";
let REPORT_TICKET_URL = 'equipments/ticket-report';
const EMPTYCHAR = '';
const BORNE = 'BORNE';
const TPE = 'TPE';
const MAJOR_VERSION = 11;
const DOT = '.';
let sDate = new Date();
sDate.setDate(1);
sDate.setHours(0);
sDate.setMinutes(0);
sDate.setSeconds(0);
let eDate = new Date();
eDate.setHours(23);
eDate.setMinutes(59);
eDate.setSeconds(59);
export let defaultActiviteFilter = {
    startDate: sDate,
    endDate: eDate,
    equipments: [],
    transactionTypes: ['P', 'S'],
    showAllAlarm: true,
    showOnlyOpenedAlarm: false,
    mandated: false,
    butonRadioChecked: 'all',
    commandsTypes: ['GET_LOGS', 'GET_KERNEL_LOGS', 'GET_STARTUP_REPORT', 'SPEED_TEST', 'CONFIG_ACQUITTED'],
    meanOfPaymentTypes: [],
    eventTypes: [],
    timestamp: new Date().getTime(),
    sortDirection: 'DESC'
};
export class ActivityJornal extends Component {

    constructor(props) {
        super(props);
        let sDate = new Date();
        sDate.setDate(1);
        sDate.setHours(0);
        sDate.setMinutes(0);
        sDate.setSeconds(0);
        let eDate = new Date();
        eDate.setHours(23);
        eDate.setMinutes(59);
        eDate.setSeconds(59);
        let lDate = new Date();
        lDate.setHours(23);
        lDate.setMinutes(59);
        lDate.setSeconds(59);
        this.state = {
            transactionNumber : null,
            transactionState:{},
            isApiError:false,
            locale: getCalendarLocale(),
            first: 0,
            userExist: false,
            tieredItems: "test",
            display: false,
            closable: false,
            filter: defaultActiviteFilter,
            isInitialized: false,
            equipmentId: this.getEquipmentIdParam(),
            isAsPossible: true,
            isAtOnce: false,
            journalSelected: true,
            teleactionSelected: false,
            commissiongSelected: false,
            maxEndDate: eDate,
            implantAndNameStation:"",
            typeLog: { key: msg('equipment.borne.log.type.log'), value: false },
            currentFirmwareName: EMPTYCHAR,
            borne: {
                borneId: null,
                serialNumber: null,
                rebootType: "smart",
                logStartDate: sDate,
                logEndDate: lDate,
                peripheral: BORNE
            },
            test: { date: null },
            sortDirection: 'DESC'
        }
        this.onSelection = this.onSelection.bind(this);
        this.loadEquipmentById = this.loadEquipmentById.bind(this);
        this.applyActiviteFilter = this.applyActiviteFilter.bind(this);
        this.onSelectionItem = this.onSelectionItem.bind(this);
        this.handleRboutBorneClick = this.handleRboutBorneClick.bind(this);
        this.handleLogClick = this.handleLogClick.bind(this);
        this.handleTestNetworkClick = this.handleTestNetworkClick.bind(this);
        this.reboot = this.reboot.bind(this);
        this.clickJournalSelected = this.clickJournalSelected.bind(this);
        this.clickArrowBackSelected = this.clickArrowBackSelected.bind(this);
        this.clickTeleActionSelected = this.clickTeleActionSelected.bind(this);
        this.clickCommissiongSelected = this.clickCommissiongSelected.bind(this);
        this.getErreurMessage = this.getErreurMessage.bind(this);
        this.onSort = this.onSort.bind(this);
        this.checkNetworkSpeed = this.checkNetworkSpeed.bind(this);
        this.buildInfoDialogParam = this.buildInfoDialogParam.bind(this);
        this.reinitParameters = this.reinitParameters.bind(this);
        this.handleRebootTPEClick = this.handleRebootTPEClick.bind(this);
        this.isFirmwareVersionValid = this.isFirmwareVersionValid.bind(this);
        this.computePaidAmountHTMainArticle = this.computePaidAmountHTMainArticle.bind(this);
    }

    componentDidMount() {
        progressSpinnerHandler.show(true);
    }

    componentDidUpdate(prevProps) {
        let prevClose = prevProps.closeAlarmData && prevProps.closeAlarmData.lastChange;
        let lastClose = this.props.closeAlarmData && this.props.closeAlarmData.lastChange;
        let id = this.props.equipmentById && this.props.equipmentById.equipmentId ? this.props.equipmentById.equipmentId : null;
        let equipmentName = this.props.equipmentById && this.props.equipmentById.equipmentName ? this.props.equipmentById.equipmentName : null;
        let borneType = this.props.equipmentById && this.props.equipmentById.borneType ? this.props.equipmentById.borneType : null;
        let borneId = this.props.equipmentById && this.props.equipmentById.borneId ? this.props.equipmentById.borneId : null;
        let serialNumber = this.props.equipmentById && this.props.equipmentById.serialNumber ? this.props.equipmentById.serialNumber : EMPTYCHAR;
        let currentFirmwareName = this.props.equipmentById && this.props.equipmentById.borneCharacteristics  &&  
        this.props.equipmentById.borneCharacteristics.currentFirmwareName ? this.props.equipmentById.borneCharacteristics.currentFirmwareName : EMPTYCHAR;

        if ((lastClose && (!prevClose || prevClose < lastClose))) {
            let page = 0;
            let pageSize = userSessionParamsHandler.getPageSize();
            this.props.loadEquipmentById(this.state.equipmentId, page, pageSize, this.state.filter);
            progressSpinnerHandler.show(true);
        }
        let prevLastChange = prevProps.equipmentById && prevProps.equipmentById.lastChange;
        let lastChange = this.props.equipmentById && this.props.equipmentById.lastChange;
        if ((lastChange && (!prevLastChange || prevLastChange < lastChange))) {
            progressSpinnerHandler.show(false);
        }
        if (!this.state.isInitialized && id) {                       
            let filter = Object.assign(
                {}, this.state.filter,
                {
                    equipments: [{ key: id, value: id }]
                });

            let eDate = new Date();
            let periodStartDays = this.props.equipmentById.periodStartDays;
            eDate.setDate(filter.endDate.getDate() - periodStartDays);
            eDate.setHours(23);
            eDate.setMinutes(59);
            eDate.setSeconds(59);
            filter.startDate = eDate;
            let implantAndNameStation =  this.props.equipmentById.borneCharacteristics ?  ( this.props.equipmentById.borneCharacteristics.implant
                                             + " - " + this.props.equipmentById.borneCharacteristics.stationName) : EMPTYCHAR;
            this.setState({
                isInitialized: true,
                equipmentId: id,
                equipmentName: equipmentName,
                borneType: borneType,
                implantAndNameStation:implantAndNameStation,
                currentFirmwareName : currentFirmwareName,
                borne: {
                    borneId: borneId,
                    serialNumber: serialNumber,
                    rebootType: "smart",
                    logStartDate: this.state.borne.logStartDate,
                    logEndDate: this.state.borne.logEndDate
                },
                filter: filter
            });
            this.props.setDataInfoBar(this.dataInfobar(filter, equipmentName, implantAndNameStation));
        }

        let prevError = prevProps.error && prevProps.error.timestamp;
        let lastError = this.props.error && this.props.error.timestamp;
        let error = (lastError && (!prevError || prevError < lastError));
        if (error) {
            let isError = this.props.error.message && this.props.error.message.startsWith('error.borne.') ? true : false;
            this.setState({ isApiError: isError});
            progressSpinnerHandler.show(false);
        }

        let prevReboot = prevProps.rebootData.data && prevProps.rebootData.data.lastChange;
        let lastReboot = this.props.rebootData.data && this.props.rebootData.data.lastChange;
        let rebooted = (lastReboot && (!prevReboot || prevReboot < lastReboot));
        if (rebooted) {
            if(this.state.borne.peripheral === BORNE){
                this.setState({ isBorneRebooted: true });
            } else {
                this.setState({ isTPERebooted: true });
            }
        }
        let prevBorneLogs = prevProps.borneLogsData.data && prevProps.borneLogsData.data.lastChange;
        let lastBorneLogs = this.props.borneLogsData.data && this.props.borneLogsData.data.lastChange;
        let isLogRequested = (lastBorneLogs && (!prevBorneLogs || prevBorneLogs < lastBorneLogs));
        if (isLogRequested) {
            this.setState({ isLogRequested: true });
        }
        let prevCheckNetworkSpeed = prevProps.checkNetworkSpeedData.data && prevProps.checkNetworkSpeedData.data.lastChange;
        let lastPrevCheckNetworkSpeed = this.props.checkNetworkSpeedData.data && this.props.checkNetworkSpeedData.data.lastChange;
        let isCheckNetworkSpeed = (lastPrevCheckNetworkSpeed && (!prevCheckNetworkSpeed || prevCheckNetworkSpeed < lastPrevCheckNetworkSpeed));
        if (isCheckNetworkSpeed) {
            this.setState({ isCheckNetworkSpeed: true });
        }   
    }

    getEquipmentIdParam() {
        let id = document.location.search;
        if(id && id.includes('?equipmentId=')){
            id = id.replace('?equipmentId=', '');
            if(id.includes('&')){
                id = id.substring(0, id.indexOf('&'));
            }
            return id;
        }
        return null;
    }

    isFirmwareVersionValid(){
        if(!this.state.currentFirmwareName){
            return false;
        }
        let name = this.state.currentFirmwareName;
        let version = name.substring(name.indexOf(DOT) + 1)
        let majorVersion = version.substring(0, version.indexOf(DOT))
        return (majorVersion > MAJOR_VERSION);
    }
    onSelectionItem(event) {
        let commandState = this.selectCommand(event);
        this.setState(commandState);
        let alarmState = this.selectAlarm(event);
        this.setState(alarmState);
        let transactionState = this.selectTransaction(event);
        this.setState({transactionState: transactionState});
    }
    computePaidAmountHTMainArticle(articlePriceTTC, articlePriceHT, paidAmountTTC){
        let paidAmountHT = 0;
        let _articlePriceHT = Number(articlePriceHT.replace(",", "."));
        let _articlePriceTTC = Number(articlePriceTTC.replace(",", "."));
        let _paidAmountTTC = Number(paidAmountTTC.replace(",", "."));

        if((this.state.borneType === "TL18") || (this.state.borneType === "TK2") ){
            paidAmountHT = Number(articlePriceHT.replace(",", ".")) ;
        } else {
        if(_paidAmountTTC > _articlePriceTTC){
            paidAmountHT = Math.round( (_articlePriceHT * (_paidAmountTTC / _articlePriceTTC)) *100)/100;
        } else  if(_paidAmountTTC < _articlePriceTTC){
            paidAmountHT = Math.round( (_articlePriceHT / (_articlePriceTTC / _paidAmountTTC)) *100)/100;
        } else {
            paidAmountHT = Number(articlePriceHT.replace(",", ".")) ;
        }
    }
        return paidAmountHT.toString();
    }
    selectTransaction(event) {
        let filter = {};
        if (event.value && event.value.report && event.value.report.type === "TRANSACTION" ) {
            let transactionNumber = event.value.report.borneTransacId;
            filter.equipmentId = this.state.equipmentId;
            filter.transactionNumber = transactionNumber;
            filter.creationDate = event.value.timestamp;
            this.setState({
                transactionNumber : transactionNumber
            })
        } else {
            this.setState({
                transactionNumber : null
            })
        }
        return filter;
    }

    selectCommand(event) {

        let commandId = null;
        let typeCommand = null;
        let contenPath = null;
        if (event.value != null && event.value.report && event.value.report.type === "COMMAND" && event.value.report.contenPath) {
            let error = this.getErreurMessage(event.value.report.returnCode);
            if (!error) {
                commandId = event.value.report.id;
                typeCommand = event.value.report.typeCommand;
                contenPath = event.value.report.contenPath;
                this.getTypeCommand(typeCommand);
                this.setState({ commandId: commandId, typeCommand: typeCommand, contenPath: contenPath });
            }
        }
        return { commandId: commandId, typeCommand: typeCommand, contenPath: contenPath };
    }

    selectAlarm(event) {
        let alarmId = null;
        let alarmDescription = null;
        if (event.value && event.value.typeDescription === "OPEN_ALARM" && event.value.report && event.value.report.id) {
            alarmId = event.value.report.id;
            alarmDescription = event.value.report.description;
        }
        return { alarmId: alarmId, alarmeDescription: alarmDescription };
    }
    confirmCloseAlarm() {
        if (this.state.alarmId) {
            this.props.closeAlarm(this.state.alarmId);
        }
    }

    onSelection(event) {
        if (event.value != null) {
            let filter = Object.assign(
                {}, this.state.filter,
                {
                    equipments: [{ key: event.value.id, value: event.value.id }],
                    stations: this.state.borneFilter && this.state.borneFilter.stations ? this.state.borneFilter.stations : []
                });
            this.setState({
                equipmentId: event.value.id,
                equipmentName: event.value.name,
                filter: filter
            });
            this.props.setDataInfoBar(this.dataInfobar(filter, event.value.name));
        }
    }

    loadEquipmentById(id, page, pageSize, filter) { 
        let lFilter = Object.assign({}, this.state.filter);
        lFilter.sortDirection = this.state.sortDirection;
        this.props.loadEquipmentById(id, page, pageSize, lFilter);
        progressSpinnerHandler.show(true);
    }


    dataInfobar(filter, equipmentName, implantAndNameStation) {
        return [
            { key: "implantAndNameStation", value: implantAndNameStation ? implantAndNameStation : "" },
            { key: "equipmentName", value: equipmentName ? msg('filter.value.equipment') + equipmentName + ";" : "" },
            { key: "titleFilter", value: msg('filter.in.progress') },
            { key: "startDate", value: msg('filter.date.from').toLowerCase() + " " + toDefaultDateFormat(new Date(filter.startDate)) + " " },
            { key: "endDate", value: msg('filter.date.to').toLowerCase() + " " + toDefaultDateFormat(new Date(filter.endDate)) + ";  " },
            { key: "transactionTypes", value: this.typeTransaction(filter.transactionTypes) + ";" },
            { key: "mandated", value: (String(filter.mandated) === "true") ? msg('filter.value.isMandated') + ";" : "" },
            { key: "alarm", value: this.typeFiltreAlrme(filter.showAllAlarm, filter.showOnlyOpenedAlarm) + " " },

        ]
    }

    dataPartialInfobar(filter, equipmentName, implantAndNameStation) {
        return [
            { key: "implantAndNameStation", value: implantAndNameStation ? implantAndNameStation : "" },
            { key: "equipmentName", value: equipmentName ? msg('filter.value.equipment') + equipmentName : "" },
        ]
    }

    typeFiltreAlrme(showAllAlarm, showOnlyOpenedAlarm) {
        if (String(showAllAlarm) === "true") return msg('filter.value.showAllAlarm');
        if (String(showOnlyOpenedAlarm) === "true") return msg('filter.value.showOnlyOpenedAlarm');
        return msg('filter.value.no.alarm')
    }

    typeTransaction(value) {
        if (!value) {
            return '';
        }
        let values = value.toString().split(",");
        if (values.includes("S") && values.includes("P")) {
            return msg('filter.value.sellingFeaturekey');
        } else if (values.includes("S")) {
            return msg('equipements.filter.ventes');
        } else if (values.includes("P")) {
            return msg('equipements.filter.prestations');
        }
        return '';
    }


    applyActiviteFilter(filter) {
        this.props.setDataInfoBar(this.dataInfobar(filter, this.state.equipmentName));
        let lfilter = Object.assign(
            {}, filter,
            {
                equipments: [{ key: this.state.equipmentId, value: this.state.equipmentId }],
                stations: this.state.borneFilter && this.state.borneFilter.stations ? this.state.borneFilter.stations : []
            });
            lfilter.sortDirection = this.state.sortDirection;
        this.setState({ filter: lfilter });
        let page = 0;
        let pageSize = userSessionParamsHandler.getPageSize();
        this.props.loadEquipmentById(this.state.equipmentId, page, pageSize, lfilter);
        progressSpinnerHandler.show(true);
    }

    handleDownloadClick(reportType) {
        switch(reportType) {
            case COMMAND_STATE:
                    let values = this.state.contenPath.split("/");
                    const fileName = values[values.length - 1];
                    UploadService.getFileCommandData("equipments/" + this.state.equipmentId + "/" + this.state.commandId + "/" + this.state.typeCommand + "/commandeResponse", this.state.typeCommand, this.props.authorization).then((response) => {
                        response.blob().then(blob => {
                            let url = window.URL.createObjectURL(blob);
                            let a = document.createElement('a');
            
                            if (response.status === 401) {
                                progressSpinnerHandler.show(false);
                                alert(msg('login.unauthorized.internalauthenticationserviceexception'));
                            } else if (response.status === 400) {
                                progressSpinnerHandler.show(false);
                                this.setState({ isDownloadError: true });
                            }
                            else {
                                this.setState({ buttonSelectd: false });
                                a.href = url;
                                a.download = fileName;
                                a.click();
                                progressSpinnerHandler.show(false);
                            }
                        });
                    });
                break;
            case TICKET_STATE: 
            let filename = msg('report.ticket.generation') + " " + this.state.equipmentId + "-" + this.state.transactionNumber + '.pdf';
            UploadService.getFileData(REPORT_TICKET_URL, filename, this.state.transactionState);
            break;
            default:    
        }
    }

    getTypeCommand(typeCommand) {
        if (typeCommand === 'GET_LOGS') {
            this.setState({ messageDownload: "borne.commande.download.log.message" });
        } else if (typeCommand === 'GET_KERNEL_LOGS') {
            this.setState({ messageDownload: "borne.commande.download.log.kernel.message" });
        } else if (typeCommand === 'GET_STARTUP_REPORT') {
            this.setState({ messageDownload: "borne.commande.download.startup.report.message" });
        }
    }

    getTypeCommandMessage(typeCommand) {
        if (typeCommand === 'GET_LOGS') {
            this.setState({ typeCommandMessage: "equipements.activity.log" });
        } else if (typeCommand === 'GET_KERNEL_LOGS') {
            this.setState({ typeCommandMessage: "equipements.activity.log.kernel" });
        } else if (typeCommand === 'GET_STARTUP_REPORT') {
            this.setState({ typeCommandMessage: "borne.commande.resppnse.commissioning.report" });
        }
    }

    getErreurMessage(errorMessage) {
        let responseError = false;
        if (errorMessage !== 0) {
            responseError = true;
        }
        return responseError;
    }

    getDetailReport(rowData) {
        let receptionDate;
        let mopType;
        let descMsg;
        let details;
        let codeDetails;
        let batchNumber;
        let transactionNumber;
        let responseError = false;
        if(!rowData.report){
            return <div className="p-col-12"></div>;
        }
        switch (rowData.report.type) {
            case 'COMMAND':

                if (rowData.report.returnCode !== 0) {
                    responseError = true;
                }

                return (
                    <div className="p-col-12">
                        <ul style={{ marginLeft: '-45px', marginTop: '-25px', marginBottom: '0px' }}>
                            {<li style={{ 'listStyle': 'none' }}>
                                <p> <span>{msg('borne.commande.resppnse')}</span><span style={{ fontWeight: 'bolder' }}>{msg('equipements.activity.type.command.message.' + rowData.report.typeCommand.toLowerCase())}</span> </p>
                            </li>}

                            {rowData.report.contenPath && !responseError &&
                                <li style={{ 'listStyle': 'none' }}>
                                    <div style={{ marginTop: '5px' }}>
                                        <GetAppIcon fontSize="small" style={{ marginBottom: '-4px', color: "#8B8B89" }} />
                                        {<span style={{ fontStyle: 'italic', marginLeft: '5px' }}>{msg('borne.commande.resppnse.message.download')}</span>}
                                    </div>
                                </li>}

                            {!rowData.report.contenPath && !responseError && !rowData.report.content &&
                                <li style={{ 'listStyle': 'none' }}>
                                    <div>
                                        <span style={{ fontStyle: 'italic' }}>
                                            {msg('borne.commande.response.log.not.found')}
                                        </span>
                                    </div>
                                </li>}

                            {responseError &&
                                <li style={{ 'listStyle': 'none' }}>
                                    <span style={{ fontStyle: 'italic' }}> {msg('borne.commande.resppnse.error')}  {rowData.report.error} </span>
                                </li>}

                            {<li style={{ fontStyle: 'italic', 'listStyle': 'none' }}>
                                <div className="coin-detail" style={{ marginTop: '5px' }}>
                                    <Panel header={msg('transaction.details')} toggleable={true} collapsed={true}>
                                        <div style={{ marginTop: '-10px' }}>
                                            <div className="">
                                                <span style={{ fontStyle: 'italic' }}> {msg('borne.commande.resppnse.number') + " " + rowData.report.borneCommandId} </span>
                                            </div>
                                            {rowData.report.content && <div className="">
                                                <span style={{ fontStyle: 'italic' }}> {msg('borne.commande.resppnse.details') + " " + rowData.report.content} </span>
                                            </div>}
                                        </div>
                                    </Panel>
                                </div>
                            </li>}
                        </ul>
                    </div>
                );
            case 'ALARM':
                return (
                    <div className="p-col-12">
                        <ul style={{ marginLeft: '-45px', marginTop: '-25px', marginBottom: '0px' }}>
                            <li style={{ 'listStyle': 'none' }}>
                                <p> <span>{rowData.report.peripheralDesc} :</span > <span style={{ fontWeight: 'bolder' }}> {rowData.report.description} </span> </p>
                            </li>
                            {
                                rowData.report.openDate &&
                                (<li style={{ 'listStyle': 'none' }}>
                                    <span style={{ fontStyle: 'italic' }}> {rowData.report.duration ? msg('alarm.duration') + ' : ' + rowData.report.duration : msg('alarm.started') + ' :' + rowData.report.openDate} </span>
                                </li>)
                            }
                            {
                                rowData.report.openDate && rowData.report.duration &&
                                (<li style={{ 'listStyle': 'none' }}>
                                    <span style={{ fontStyle: 'italic' }}> {msg('alarm.start.date') + ' : ' + toLocaleDateTimeFormat(rowData.report.openDate)} </span>
                                </li>)
                            }
                            {rowData.report.comment &&
                                (<li style={{ 'listStyle': 'none', marginBottom: '-12px' }}>
                                    <span style={{ fontStyle: 'italic' }}> {msg('transaction.alarm.comment')} : {rowData.report.comment} </span>
                                </li>)
                            }
                        </ul>
                    </div>
                );
            case 'TRANSACTION':
            default:
                let program = "";
                let progOptions = null;
                if (rowData.report.basketArticles && rowData.report.basketArticles.length > 0) {
                    let mains = rowData.report.basketArticles.filter(b => !b.option);
                    if (mains && mains.length > 0) {
                        program = mains[0].articleName;
                    }
                    let options = rowData.report.basketArticles.filter(b => b.option);
                    if (options && options.length > 0) {
                        program += " ";
                        program += msg('equipment.transaction.prog.withoption.title');
                        progOptions = options.map(o => o.articleName).toString().replaceAll(",", ", ");
                        progOptions = msg('equipment.transaction.prog.options') + " : " + progOptions;
                    }
                }
                mopType = rowData.report.meanOfPayment.type.trim();
                if (mopType === 'HAPPY_HOUR') {
                    descMsg = msg('transaction.meanOfPayment.type.happy.hour.code');
                    details = descMsg;
                } else if (mopType === 'PAID_CODE') {
                    descMsg = msg('transaction.meanOfPayment.type.paid.code');
                    details = descMsg + "  (" + rowData.report.meanOfPayment.codeValue + ")";
                } else if (mopType === 'FREE_CODE') {
                    descMsg = msg('transaction.meanOfPayment.type.free.code');
                    details = descMsg + "  (" + rowData.report.meanOfPayment.codeValue + ")";
                } else if (mopType === 'LICENSE_PLATE') {
                    descMsg = msg('transaction.meanOfPayment.type.license.plate');
                    details = rowData.report.meanOfPayment.codeValue ? descMsg + "  (" + rowData.report.meanOfPayment.codeValue + ")" : "";
                } else if (mopType === 'MAINTENANCE') {
                    descMsg = msg('transaction.meanOfPayment.type.maintenance');
                    details = "";
                } else {
                    descMsg = msg('transaction.meanOfPayment.type.' + rowData.report.meanOfPayment.type);
                    details = rowData.report.meanOfPayment.cardId ? descMsg + "  (" + rowData.report.meanOfPayment.cardId + ")" : "";
                    if (rowData.report.basketArticles && rowData.report.basketArticles.length > 0 && rowData.report.basketArticles[0].code
                        && rowData.report.basketArticles[0].code.toLowerCase() !== "undefined") {
                        codeDetails = msg('equipment.transaction.detail.code.achat') + " (" + rowData.report.basketArticles[0].code + ")";
                    }
                }
                return (
                    receptionDate = toLocaleDateTimeFormat(rowData.report.receptionDate),
                    batchNumber = rowData.report.batchNumber ? rowData.report.batchNumber : '',
                    transactionNumber = rowData.report.borneTransacId,
                    progOptions,
                    <div className="p-grid">
                        <div className="p-col-8">
                            <ul style={{ marginLeft: '-39px', marginTop: '-1px', marginBottom: '-20px' }}>
                                <li style={{ 'display': 'inline-block', 'listStyle': 'none' }}>
                                    {program}
                                </li>
                                <li style={{ fontStyle: 'italic', 'listStyle': 'none' }}>{
                                    mopType === 'CASH' ?
                                        <div className="coin-detail" >
                                            {msg('transaction.meanOfPayment.type.cash')}
                                            <Panel header={msg('transaction.details')} toggleable={true} collapsed={true}>
                                                <div style={{ marginTop: '-10px' }}>
                                                    <div>
                                                        <span style={{ fontStyle: 'italic' }}> {msg('transaction.receptionDate') + ' : ' + receptionDate} </span>
                                                    </div>
                                                    <div>
                                                        <span style={{ fontStyle: 'italic' }}> {msg('transaction.batch.number') + ' : ' + batchNumber} </span>
                                                    </div>
                                                    <div>
                                                        <span style={{ fontStyle: 'italic' }}> {msg('transaction.number.transaction') + ' : ' + transactionNumber} </span>
                                                    </div>
                                                    {rowData.report.meanOfPayment.loyaltyId &&
                                                        <div>
                                                            {msg('transaction.meanOfPayment.loyaltyId') + ":  (" + rowData.report.meanOfPayment.loyaltyId + ")"}
                                                        </div>
                                                    }
                                                    <div>
                                                        {rowData.coinInbox && rowData.coinInbox.map((coin, key) => (
                                                            <div key={key}>{coin}</div>
                                                        ))}
                                                        {rowData.coinIntube && rowData.coinIntube.map((coin, key) => (
                                                            <div key={key}>{coin}</div>
                                                        ))}
                                                        {rowData.coinReturned && rowData.coinReturned.map((coin, key) => (
                                                            <div key={key}>{coin}</div>
                                                        ))}
                                                        {rowData.bills && rowData.bills.map((bill, key) => (
                                                            <div key={key}>{bill}</div>
                                                        ))}
                                                    </div>
                                                    {rowData.report.programUsage && rowData.report.programUsage.length !== 0 && <div className="" >
                                                        <span style={{ fontStyle: 'italic' }}> {msg('transaction.usage.hp') + ' : '} </span>
                                                    </div>}
                                                    {
                                                        rowData.report.programUsage &&
                                                        rowData.report.programUsage.map((program, i) => {
                                                            return (
                                                                <ul key={i}>
                                                                    <li key={i} style={{ display: 'inline' }}>
                                                                        <span style={{ fontStyle: 'italic' }}>{program.programName && program.programName.toLowerCase() !== "undefined" ? program.programName : msg('transaction.usage.duration')}</span> :
                                                                        <span style={{ fontStyle: 'italic' }}>{checkDuration(program.durationString)}</span>
                                                                    </li>
                                                                </ul>
                                                            );
                                                        })}
                                                </div>
                                            </Panel>
                                        </div>
                                        :
                                        <div className="coin-detail">
                                            {descMsg}
                                            <Panel header={msg('transaction.details')} toggleable={true} collapsed={true}>
                                                <div style={{ marginTop: '-10px' }}>
                                                    <div>
                                                        <span style={{ fontStyle: 'italic' }}> {msg('transaction.receptionDate') + ' : ' + receptionDate} </span>
                                                    </div>
                                                    <div>
                                                    	<span style={{ fontStyle: 'italic' }}> {msg('transaction.batch.number') + ' : ' + batchNumber} </span>
                                                    </div>
                                                    <div>
                                                        <span style={{ fontStyle: 'italic' }}> {msg('transaction.number.transaction') + ' : ' + transactionNumber} </span>
                                                    </div>
                                                    {rowData.report.meanOfPayment.loyaltyId &&
                                                        <div className="">
                                                            {msg('transaction.meanOfPayment.loyaltyId') + ":  (" + rowData.report.meanOfPayment.loyaltyId + ")"}
                                                        </div>
                                                    }
                                                    <div>
                                                        {details}
                                                    </div>
                                                    <div>
                                                        {codeDetails}
                                                    </div>
                                                    {progOptions &&
                                                        <div>
                                                            <span style={{ fontStyle: 'italic' }}> {progOptions} </span>
                                                        </div>
                                                    }
                                                    {rowData.pspreference &&
                                                        <div>
                                                            {msg('transaction.pspreference')} : {rowData.pspreference}
                                                        </div>
                                                    }

                                                    {rowData.report.programUsage && rowData.report.programUsage.length !== 0 && <div className="" >
                                                        <span style={{ fontStyle: 'italic' }}> {msg('transaction.usage.hp') + ' : '} </span>
                                                    </div>}
                                                    {
                                                        rowData.report.programUsage &&
                                                        rowData.report.programUsage.map((program, i) => {
                                                            return (
                                                                <ul key={i}>
                                                                    <li key={i} style={{ display: 'inline' }}>
                                                                        <span style={{ fontStyle: 'italic' }}>{program.programName && program.programName.toLowerCase() !== "undefined" ? program.programName : msg('transaction.usage.duration')}</span> :
                                                                        <span style={{ fontStyle: 'italic' }}>{checkDuration(program.durationString)}</span>
                                                                    </li>
                                                                </ul>

                                                            )
                                                        })}
                                                </div>
                                            </Panel>
                                        </div>
                                }
                                </li>
                            </ul>
                        </div>
                        <div className="p-col-4 ">
                            <ul style={{ marginTop: '-1px', marginBottom: '0' }}>
                                <li style={{ fontWeight: 'bolder', listStyle: 'none' }}>{rowData.report.meanOfPayment.paidAmout} {rowData.report.meanOfPayment.paidUnit}</li>
                                <li style={{ fontWeight: 'bolder', listStyle: 'none' }}>{rowData.typeDescription + ((rowData.report.mandate === true || rowData.report.mandate === 'true') ? (rowData.report.transactionType === 'P' ? ' commissionnée' : ' sous mandat') : '')}</li>
                            </ul>
                        </div>
                    </div>);
        }
    }


    getTransactionTypeWithIcons(rowData, column) {
        switch (rowData.typeDescription) {
            case 'Prestation':
                return (
                    <img className="typeDescriptionIcon" src="/assets/ultima/layout/images/cartadis/Prestation_vert.svg" width="40" alt="Prestation" />
                );
            case 'Vente':
                return (
                    <img className="typeDescriptionIcon" src="/assets/ultima/layout/images/cartadis/Vente_bleu.svg" width="40" alt="Vente" />
                );
            case 'Prestation/Vente':
                return (
                    <img className="typeDescriptionIcon" src="/assets/ultima/layout/images/cartadis/Prestation_et_Vente.svg" width="40" alt="Prestation/Vente" />
                );
            case 'Command':
                return (
                    <img className="typeDescriptionIcon" src="/assets/ultima/layout/images/cartadis/launch.svg" width="40" alt="Command" />
                );
            default:
            	let criticality = rowData.report && rowData.report.criticality && rowData.report.criticality.toLowerCase();
            criticality = rowData.report && rowData.report.endDate ? 'closed' : criticality;
            let icon = (criticality === 'info' ? (rowData.report && rowData.report.peripheral=== "TERMINAL_ITSELF" && rowData.report.eventTypeValue === 270 ? "pi-md-sync" : "pi-md-info") 
            : "pi-md-error");
            return (
                <span className={criticality} style={{ marginLeft: '-2px' }} > <i className={icon} /> </span>
            );
        }
    }
    handleRebootTPEClick() {
        this.setState({ rebootTPE: true, log: false, checkNetworkSpeed : false, rebootBorne: false, siRebootTPERejected: false });
    }

    handleRboutBorneClick() {
        this.setState({ rebootBorne: true, log: false, checkNetworkSpeed : false, rebootTPE : false, siRebootTPERejected: false });
    }

    handleLogClick() {
        this.setState({ rebootBorne: false, log: true, checkNetworkSpeed: false, rebootTPE : false, siRebootTPERejected: false });
    }

    handleTestNetworkClick() {
        this.setState({ rebootBorne: false, log: false, checkNetworkSpeed: true, rebootTPE : false, siRebootTPERejected: false });
    }

    handleLogStartDateChange(e) { 
        if (e.value) {
            let dStart = new Date(e.value);
            if (dStart && !Number.isNaN(dStart.valueOf())) {
                dStart.setHours(0);
                dStart.setMinutes(0);
                dStart.setSeconds(0);
                this.setState({ borne: Object.assign({}, this.state.borne, { logStartDate: dStart }) })
                if (this.state.borne.logEndDate && this.state.borne.logEndDate.getTime() < dStart.getTime()) {
                    let dEnd = new Date(e.value);
                    dEnd.setHours(23);
                    dEnd.setMinutes(59);
                    dEnd.setSeconds(59);
                    this.setState({ borne: Object.assign({}, this.state.borne, { logEndDate: dEnd }) })
                }
            }
        }
    }

    handleLogEndDateChange(e) {
        if (e.value) {
            let dEnd = new Date(e.value);
            if (dEnd && !Number.isNaN(dEnd.valueOf())) {
                dEnd.setHours(23);
                dEnd.setMinutes(59);
                dEnd.setSeconds(59);
                this.setState({ borne: Object.assign({}, this.state.borne, { logEndDate: dEnd }) })
                if (this.state.borne.logStartDate && this.state.borne.logStartDate.getTime() > dEnd.getTime()) {
                    let dStart = new Date(e.value);
                    dStart.setHours(0);
                    dStart.setMinutes(0);
                    dStart.setSeconds(0);
                    this.setState({ borne: Object.assign({}, this.state.borne, { logStartDate: dStart }) })
                }
            }
        }
    }

    handleLogTypeClick(e) {
        this.setState({ isKernelLog: e.value.value, typeLog: e.value, borne: Object.assign({}, this.state.borne, { kernelLogs: e.value.value }) });
    }

    checkNetworkSpeed(){
        if (this.state.borne.serialNumber && this.state.borne.borneId) {
            this.props.checkNetworkSpeed(this.state.borne.borneId, this.state.borne);
        }
    }
    reboot() {
        if(this.state.rebootBorne){
            if (this.state.borne.serialNumber && this.state.borne.borneId) {
                let obj = Object.assign({}, this.state.borne);
                obj.peripheral = BORNE;
                this.setState({ borne: Object.assign({}, this.state.borne, { peripheral: BORNE }) })
                this.props.reboot(this.state.borne.borneId, obj);
            }
        }else if(this.state.rebootTPE){
            if(this.isFirmwareVersionValid()){
                if (this.state.borne.serialNumber && this.state.borne.borneId) {
                    let obj = Object.assign({}, this.state.borne);
                    obj.peripheral = TPE;
                    this.setState({ borne: Object.assign({}, this.state.borne, { peripheral: TPE }) })
                    this.props.reboot(this.state.borne.borneId, obj);
                }
            }else {
                this.setState({siRebootTPERejected : true });
            }
        }
    }

    getBorneLogs() {
        if (this.state.borne.serialNumber && this.state.borne.borneId) {
            this.props.getBorneLogs(this.state.borne.borneId, this.state.borne);
        }
    }

    clickJournalSelected() {
        this.setState({ journalSelected: true, teleactionSelected: false, commissiongSelected: false, active: false });
        this.props.setDataInfoBar(this.dataInfobar(this.state.filter, this.state.equipmentName, this.state.implantAndNameStation));

    }

    clickArrowBackSelected() {
        this.props.history.push('/');
    }

    clickTeleActionSelected() {
        this.setState({ teleactionSelected: true, journalSelected: false, commissiongSelected: false, active: true });
        this.props.setDataInfoBar(this.dataPartialInfobar(this.state.filter, this.state.equipmentName, this.state.implantAndNameStation));
    }

    clickCommissiongSelected() {
        this.setState({ journalSelected: false, teleactionSelected: false, commissiongSelected: true, active: false });
        this.props.setDataInfoBar(this.dataPartialInfobar(this.state.filter, this.state.equipmentName, this.state.implantAndNameStation));
    }

    onSort = (e, page) => {
        let lFilter = Object.assign({}, this.state.filter);
        let sortDirection = this.state.sortDirection === "DESC" ? "ASC" : "DESC";
        lFilter.sortDirection = sortDirection;
        let pageSize = userSessionParamsHandler.getPageSize();
        this.props.loadEquipmentById(this.state.equipmentId, page, pageSize, lFilter);
        this.setState({ sortDirection: sortDirection, filter: lFilter});
        progressSpinnerHandler.show(true);
    }

    reinitParameters(e){
        this.setState({ notLog: false, isBorneRebooted: false, isLogRequested : false, isCheckNetworkSpeed: false, 
                        isLignSelected: false, isApiError: false, isDownloadError: false, isTPERebooted :false, siRebootTPERejected: false})
    }

    buildInfoDialogParam(equipmentName){
        let parameters = {
            visibility: false,
            title: EMPTYCHAR,
            message: EMPTYCHAR,
            className : EMPTYCHAR
        }
        if(this.state.siRebootTPERejected){
            parameters.visibility = true;
            parameters.className =  "message-dialog warn-message";
            parameters.title = msg("equipment.borne.warning");
            parameters.message = msg('equipment.borne.reboot.rejected', {serialNumber: this.state.borne.serialNumber })
        } else if (this.state.isApiError || this.state.isDownloadError){
            parameters.visibility = true;
            parameters.title=msg('message.dialog.error.title');
            parameters.message = (this.state.isApiError && msg(this.props.error.message, { borneName: equipmentName, serialNumber: this.state.borne.serialNumber })) 
            || (this.state.isDownloadError && msg('user.equipment.download.command.file.not.found'))
        } else {
        parameters.className =  "message-dialog info-message";
        parameters.title = msg('equipment.borne.info');
        if(this.state.notLog){
            parameters.visibility = true;
            parameters.message = msg('equipment.borne.functionality.inprogres');
        }else if(this.state.isBorneRebooted){
            parameters.visibility = true;
            parameters.message = msg('equipment.borne.reboot.borne', { borneName: equipmentName, serialNumber: this.state.borne.serialNumber })
        }else if(this.state.isTPERebooted){
            parameters.visibility = true;
            parameters.message = msg('equipment.borne.reboot.tpe', { borneName: equipmentName, serialNumber: this.state.borne.serialNumber })
        }else if (this.state.isLogRequested){
            parameters.visibility = true;
            parameters.message = msg('equipment.borne.logs', { borneName: equipmentName, serialNumber: this.state.borne.serialNumber })
        } else if(this.state.isCheckNetworkSpeed){
            parameters.visibility = true;
            parameters.message = msg('equipment.borne.test.network.speed', {borneName: equipmentName, serialNumber: this.state.borne.serialNumber })
        }
    }

        return parameters;
    }
    render() {
        let authorized = rolesValidator.validate([EQUIPMENTS]);
        let uAuthorized = authorized.toString().includes(UPDATE);
        let authorizedTeleaction = uAuthorized && authorized && rolesValidator.validate([TELEACTIONS]);
        let lFilter = Object.assign({}, this.state.filter);
        lFilter.sortDirection = this.state.sortDirection;
        let periodStartDays = this.state.equipmentId && this.props.equipmentById ? this.props.equipmentById.periodStartDays : null;
        let activiteFilter = (<div className="p-col-12">
            <BorneActivityFilter apply={this.applyActiviteFilter} filter={this.state.filter}
            showDate={true} borneType={this.state.borneType} periodStartDays={periodStartDays} readOnly={false} />
        </div>);
        let reports = this.state.equipmentId && this.props.equipmentById && this.props.equipmentById.reports ? this.props.equipmentById.reports : [];
        let serialNumber = this.state.equipmentId && this.props.equipmentById && this.props.equipmentById.serialNumber ? this.props.equipmentById.serialNumber : null;
        let equipmentName = this.state.equipmentId && this.props.equipmentById && this.props.equipmentById.equipmentName ? this.props.equipmentById.equipmentName : ""
        let lastReportStartup = this.state.equipmentId && this.props.equipmentById && this.props.equipmentById.lastReportStartup ? this.props.equipmentById.lastReportStartup : null;
        let borneCharacteristics = this.state.equipmentId && this.props.equipmentById && this.props.equipmentById.borneCharacteristics ? this.props.equipmentById.borneCharacteristics : null;
        let equipmentId = this.props.equipmentById && this.props.equipmentById.equipmentId ? this.props.equipmentById.equipmentId : null;
        let totalPages = this.props.equipmentById ? this.props.equipmentById.totalPages : 0;
        let first = this.props.equipmentById && this.props.equipmentById.first === true ? this.props.equipmentById.first : null;
        let contenPath = this.props.equipmentById && this.props.equipmentById.lastPathReportStartup ? this.props.equipmentById.lastPathReportStartup : null;
        let parameters = this.buildInfoDialogParam(equipmentName);
        let services = this.state.equipmentId && this.props.equipmentById ? this.props.equipmentById.services : null;
        let timetable = this.state.equipmentId && this.props.equipmentById ? this.props.equipmentById.timetable : null;

        // Journal d'activité de l'équipement 
        let columnsTransaction = [
            { isColWithBody: false, field: "id", header: "id", sortable: false, className: "visibilityHidden" },
            { isColWithBody: false, field: "equipmentId", header: "equipmentId", sortable: false, className: "visibilityHidden" },
            { isColWithBody: false, field: "report.criticality", header: "criticality", sortable: false, className: "visibilityHidden" },
            { isColWithBody: false, field: "equipmentName", header: msg('transaction.nameEquipment'), sortable: false, className: "p-col-2" },
            { isColWithBody: true, field: "typeDescription", header: msg('transaction.type'), body: this.getTransactionTypeWithIcons, sortable: false, className: "p-col-1 typeColumn " },
            { isColWithBody: false, field: "time", header: msg('equipements.borne.date'), sortable: true, className: "p-col-2 dateColumn" },
            { isColWithBody: true, field: "report", header: msg('transaction.basketArticles'), body: this.getDetailReport, sortable: false, className: "p-col-7" }

        ]

        let closedAlarm = (
            <MessageDialog confirm={true} className={'alarm confirm-message'} visible={this.state.showConfirm} title={msg('message.dialog.confirm.title')}
                message={this.state.showConfirm && msg('alarm.close.confirm.message', { alarmeDescription: this.state.alarmeDescription })} key="confirm-close"
                onConfirm={() => { this.setState({ showConfirm: false }); this.confirmCloseAlarm() }} onCancel={() => this.setState({ showConfirm: false })} />
        );

        let titleTableTransaction = <p>{msg('transaction.title') + " "}  <span style={{ fontWeight: 'bold' }} > {equipmentName} </span></p>;

        const urlExportXlsTransaction = this.props.equipmentById && ("equipments/reportTransaction");
        const urlExportXlsAlarme = this.props.equipmentById && ("equipments/" + this.props.equipmentById.equipmentId + "/alarms-excel");
        let transactionOutilsBar = this.state.equipmentId && [
            { filename: "transaction.xlsx", iconSrc: "/assets/ultima/layout/images/cartadis/excel.svg", titleIcone: "Transactions", tooltipTextIcone: msg('transaction.tooltip.export'), urlExport: urlExportXlsTransaction },
            { filename: "alarmes.xlsx", iconSrc: "/assets/ultima/layout/images/cartadis/excel.svg", titleIcone: "Alarmes", tooltipTextIcone: msg('alarm.tooltip.export'), urlExport: urlExportXlsAlarme }
        ]
            let ContextMenuContent = [];
            if(this.state.alarmId && uAuthorized){
                ContextMenuContent =  [ {
                    label: msg('equipements.close.alarm.context.menu'),
                    icon: 'pi pi-fw pi-times',
                    command: () => this.setState({showConfirm : true})
                }
               ]
            } else if (this.state.commandId){
                ContextMenuContent = [ {
                    label: msg(this.state.messageDownload),
                    icon: 'pi-md-get-app',
                    command: () => this.handleDownloadClick(COMMAND_STATE)
                }
               ]
            } else if (this.state.transactionNumber) {
                ContextMenuContent = [ {
                    label: msg('equipment.generation.ticket'),
                    icon: 'pi-md-get-app',
                    command: () => this.handleDownloadClick(TICKET_STATE)
                }
               ]
            } else {
                ContextMenuContent = undefined
            }

        let datatablebyId = (
            <TableData data={reports} titleTable={titleTableTransaction} totalPages={totalPages}
                 ContextMenuContent={ContextMenuContent} 
                getDataFunction={this.loadEquipmentById} paramnFunctionData={this.state.equipmentId} 
                first={first}
                selectionMode={"single"} onSelection={this.onSelectionItem} ActivityJornal={true}
                onContextMenu={this.onContextMenu} onContextMenuSelection={this.onContextMenuSelection} onSelectionChange={this.onTest}
                columns={columnsTransaction} searchInTable={false} outilsBar={transactionOutilsBar} filter={this.state.filter} onSort={this.onSort}
            />
        );

        const logsType = [
            { key: msg('equipment.borne.log.type.log'), value: false },
            { key: msg('equipment.borne.log.type.karnelLog'), value: true },
        ];

        return authorized && (
            <div className="p-grid commonClass">
                <div className="card card-w-title  p-grid p-col-12">
                    <div className="p-col-3 activityMenu activity-analysis" style={{ width: "270px" }}>
                        <MenuList style={{ position: "fixed" }}>
                            {<MenuItem tooltip="{tooltipInfo}" style={this.state.arrowBack ? { background: "#ebe8ee", width: "100%" } : {}}
                                onClick={this.clickArrowBackSelected} >
                                <ListItemIcon style={{ marginLeft: "-15px" }} className="tooltip">
                                    <ArrowBackIcon fontSize="small" style={{
                                        color: "#ffffff",
                                        background: "#8B8B89",
                                        borderRadius: "50%",
                                        width: "20px",
                                        height: "20px",
                                        border: "1px"
                                    }} />
                                </ListItemIcon>
                                {<ListItemText className="tooltiptextArrowBack"
                                    style={{ marginLeft: "-25px" }}
                                    primary={msg('equipment.list.reset')} />}
                            </MenuItem>}
                            {<MenuItem style={this.state.journalSelected ? { background: "#ebe8ee", width: "100%" } : {}}
                                onClick={this.clickJournalSelected}>
                                <ListItemIcon style={{ marginLeft: "-15px" }}>
                                    <VisibilityIcon fontSize="small" style={this.state.journalSelected ? { color: "#1565C0" } : {}} />
                                </ListItemIcon>
                                <ListItemText style={this.state.journalSelected ? { marginLeft: "-25px", color: "#1565C0" } : { marginLeft: "-25px" }} primary={msg('equipements.activity.jornal')} />
                            </MenuItem>}
                            {authorizedTeleaction && serialNumber &&
                                <MenuItem style={this.state.teleactionSelected ? { background: "#ebe8ee", width: "100%" } : {}}
                                    onClick={this.clickTeleActionSelected}>
                                    <ListItemIcon style={{ marginLeft: "-15px" }}>
                                        <SettingsAccessibilityIcon fontSize="small" style={this.state.teleactionSelected ? { color: "#1565C0" } : {}} />
                                    </ListItemIcon>
                                    <ListItemText style={this.state.teleactionSelected ? { marginLeft: "-25px", color: "#1565C0" } : { marginLeft: "-25px" }} primary={msg('equipements.activity.teleaction')} />
                                </MenuItem>}
                            {<MenuItem style={this.state.commissiongSelected ? { background: "#ebe8ee", width: "100%" } : {}}
                                onClick={this.clickCommissiongSelected}>
                                <ListItemIcon style={{ marginLeft: "-15px" }}>
                                    <ReportIcon fontSize="small" style={this.state.commissiongSelected ? { color: "#1565C0" } : {}} />
                                </ListItemIcon>
                                <ListItemText style={this.state.commissiongSelected ? { marginLeft: "-25px", color: "#1565C0" } : { marginLeft: "-25px" }} primary={msg('equipements.activity.commissioning')} />
                            </MenuItem>}
                        </MenuList>
                    </div>
                    <div className="p-col-9 activity-analysis" style={{ width: "70%" }}>
                        {this.state.journalSelected && <div className="ContnairPanelFilter">
                            <Panel header="Filtre" toggleable={true} collapsed={true}>
                                {activiteFilter}
                            </Panel>
                        </div>}
                        <br></br>
                        {this.state.journalSelected && <div className="p-col-12" style={{ height: "100%" }}>
                            {closedAlarm}
                            {datatablebyId}
                        </div>}
                        {this.state.teleactionSelected && <div className="p-col-12" style={{ height: "100%" }}>
                            <div className="p-grid card" style={{ height: "100%" }}>
                                {<div className="p-grid p-col-12">
                                    <div className="p-grid p-col-12">
                                        <h1>{msg('equipements.activity.teleaction')}</h1>
                                    </div>
                                    <div className="p-grid p-col-12 p-md-3 export-button">
                                        <div className="p-col-12" >
                                            <img id="rebot" className={"payments " + (this.state.rebootBorne ? "buttonSelected" : "buttonNotSelected")} type="image" alt="payments"
                                                src="/assets/ultima/layout/images/cartadis/restart_alt.svg"
                                                onClick={() => this.handleRboutBorneClick()}
                                                key='showbornefilter' />
                                        </div>
                                        <div className="p-col-12" style={{ height: '58.9px' }}><span>{msg('equipements.activity.rebot')}</span></div>
                                    </div>
                                    <div className="p-grid p-col-12 p-md-3 export-button">
                                    <div className="p-col-12" >
                                            <img id="rebot" className={"payments " + (this.state.rebootTPE ? "buttonSelected" : "buttonNotSelected")} type="image" alt="payments"
                                                src="/assets/ultima/layout/images/cartadis/restart_alt.svg"
                                                onClick={() => this.handleRebootTPEClick()}
                                                key='showbornefilter' />
                                        </div>
                                        <div className="p-col-12" style={{ height: '58.9px' }}><span>{msg('equipements.activity.reboot.tpe')}</span></div>
                                    </div>
                                    <div className="p-grid p-col-12 p-md-3 export-button">
                                        <div className="p-col-12" >
                                            <img id="imagePaiment" className={"payments " + (this.state.log ? "buttonSelected" : "buttonNotSelected")} type="image" alt="payments"
                                                src="/assets/ultima/layout/images/cartadis/archive.svg" style={{ color: 'red' }}
                                                onClick={() => this.handleLogClick()}
                                                key='showbornefilter' />
                                        </div>
                                        <div className="p-col-12" style={{ height: '63px' }}><span>{msg('equipements.activity.log')}</span></div>
                                    </div>
                                    <div className="p-grid p-col-12 p-md-3 export-button">
                                        <div className="p-col-12" >
                                            <img id="imageNetworkSpeed" className={"payments " + (this.state.checkNetworkSpeed ? "buttonSelected" : "buttonNotSelected")} type="image" alt="checkNetworkSpeed"
                                                src="/assets/ultima/layout/images/cartadis/command.svg" style={{ color: 'red' }}
                                                onClick={() => this.handleTestNetworkClick()}
                                                key='showbornefilter' />
                                        </div>
                                        <div className="p-col-12" style={{ height: '63px' }}><span>{msg('equipements.activity.test.network.speed')}</span></div>
                                    </div>
                                </div>}
                                {this.state.checkNetworkSpeed && <div className="p-grid form-group p-col-12">
                                    <div className="p-grid p-col-12">
                                        <div className="p-col-10">
                                        </div>
                                        <div className="p-col-2">
                                            <Button label={(msg('equipment.test.network.ask'))} className="button" onClick={() => this.checkNetworkSpeed()} />
                                        </div>
                                    </div>
                                </div>}
                                {this.state.log && <div className="p-grid form-group p-col-12">
                                    <div className="p-grid form-group p-col-12">
                                        {<div className="p-grid p-col-6">
                                            <div className="p-grid p-col-6">
                                                <div className="p-col-12"> <label>{msg('equipements.activity.log.type')}</label></div>
                                            </div>
                                            <div className="p-col-6">
                                                <Dropdown value={this.state.typeLog} options={logsType}
                                                    onChange={(e) => this.handleLogTypeClick(e)}
                                                    optionLabel="key"
                                                />
                                            </div>
                                        </div>}
                                        {<div className="p-grid p-col-4">
                                        </div>}
                                        {!this.state.isKernelLog && <div className="p-grid p-col-6">
                                            <div className="p-grid p-col-6">
                                                <div className="p-col-12"><label>{msg('equipements.activity.startDate')}</label></div>
                                            </div>
                                            <div className="p-col-6">
                                                <Calendar dateFormat='dd/mm/yy' selectOtherMonths={true} hideOnDateTimeSelect={true}
                                                    showTime={false} hourFormat={'24'} value={this.state.borne.logStartDate} maxDate={this.state.borne.logEndDate}
                                                    onChange={(e) => this.handleLogStartDateChange(e)}
                                                    readOnlyInput={true} locale={this.state.locale} />
                                            </div>
                                        </div>}
                                        {!this.state.isKernelLog && <div className="p-grid p-col-6">
                                            <div className="p-grid p-col-6">
                                                <div className="p-col-12"><label>{msg('equipements.activity.endDate')}</label></div>
                                            </div>
                                            <div className="p-col-6">
                                                <Calendar dateFormat='dd/mm/yy' showIcon={false} selectOtherMonths={true} hideOnDateTimeSelect={true}
                                                    showTime={false} hourFormat={'24'} value={this.state.borne.logEndDate} minDate={this.state.borne.logStartDate}
                                                    onChange={(e) => this.handleLogEndDateChange(e)} maxDate={this.state.maxEndDate}
                                                    readOnlyInput={true} locale={this.state.locale} />
                                            </div>
                                        </div>}
                                    </div>
                                    <div className="p-grid p-col-12">
                                        <div className="p-col-10" />
                                        <div className="p-col-2">
                                            <Button label={(msg('equipment.logs.valid'))} className="button" onClick={() => this.getBorneLogs()} />
                                        </div>
                                    </div>
                                </div>}
                                {(this.state.rebootBorne || this.state.rebootTPE) && <div className="p-grid form-group p-col-12">
                                    {<div className="p-grid p-col-6">
                                        <div className="p-grid p-col-6">
                                            <div className="p-col-12"><label>{msg('equipements.activity.asPossible')}</label></div>
                                        </div>
                                        <div className="p-col-6">
                                            <Checkbox value={this.state.isAsPossible} onChange={e => this.setState({ isAtOnce: false, isAsPossible: true, borne: Object.assign({}, this.state.borne, { rebootType: "smart" }) })}
                                                checked={this.state.isAsPossible} />
                                        </div>
                                    </div>}
                                    {<div className="p-grid p-col-6">
                                        <div className="p-grid p-col-6">
                                            <div className="p-col-12"><label>{msg('equipements.activity.atOnce')}</label></div>
                                        </div>
                                        <div className="p-col-6">
                                            <Checkbox value={this.state.isAtOnce} onChange={e => this.setState({ isAtOnce: true, isAsPossible: false, borne: Object.assign({}, this.state.borne, { rebootType: "force" }) })}
                                                checked={this.state.isAtOnce} />
                                        </div>
                                    </div>}
                                    <div className="p-grid p-col-12">
                                        <div className="p-col-10">
                                        </div>
                                        <div className="p-col-2">
                                            <Button label={(msg('equipment.rebot.valid'))} className="button" onClick={() => this.reboot()} />
                                        </div>
                                    </div>
                                </div>}
                            </div>
                        </div>}
                        {this.state.commissiongSelected && <div className="p-col-12" style={{ height: "100%" }}>
                            <div className="p-grid card" style={{ height: "100%" }}>
                                {<div className="p-grid p-col-12">
                                    <div className="p-col-2">
                                        <h1 style={{ fontWeight: 'bold' }}>{msg('equipements.activity.commissioning')}</h1>
                                    </div>
                                    <BorneInformationReport serialNumber={serialNumber} equipmentName={equipmentName} equipmentId={equipmentId} lastReportStartup={lastReportStartup} contenPath={contenPath} borneCharacteristics={borneCharacteristics}
                                        services={services} timetable={timetable}
                                    />
                                </div>}
                            </div>
                        </div>}
                    </div>

                </div>

                <div style={{ whiteSpace: 'pre-line'}}>
                <MessageDialog  className={parameters.className} visible={parameters.visibility} title={parameters.title}
                        message={parameters.message}
                        onHide={e => this.reinitParameters(e)}/>
                </div>

            </div >
        )
    }
}

export default ActivityJornal
