import { toLocaleDateTimeFormat } from '../../utils/dateTimeUtils';

export const USERS = 'USERS';
export const USERS_SUCCESS = 'USERS_SUCCESS';

export const USER = 'USER';
export const USER_SUCCESS = 'USER_SUCCESS';

export const ADD_USER = 'ADD_USER';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';

export const USER_BY_ID = 'USER_BY_ID';
export const USER_BY_ID_SUCCESS = 'USER_BY_ID_SUCCESS';

export const LOAD_GROUPS = 'LOAD_GROUPS';
export const LOAD_GROUPS_SUCCESS = 'LOAD_GROUPS_SUCCESS';

export const USER_DELETE_BY_ID ='USER_DELETE_BY_ID';
export const USER_DELETE_BY_ID_SUCCESS ='USER_DELETE_BY_ID_SUCCESS';

export const PASSWORD_UPDATE ='PASSWORD_UPDATE';
export const PASSWORD_UPDATE_SUCCESS ='PASSWORD_UPDATE_SUCCESS';

export const MY_PASSWORD_UPDATE ='MY_PASSWORD_UPDATE';
export const MY_PASSWORD_UPDATE_SUCCESS ='MY_PASSWORD_UPDATE_SUCCESS';

export const USER_UPDATE ='USER_UPDATE';
export const USER_UPDATE_SUCCESS ='USER_UPDATE_SUCCESS';

export const GENERATE_BORNE_ACCESS_CODE ='GENERATE_BORNE_ACCESS_CODE';
export const GENERATE_BORNE_ACCESS_CODE_SUCCESS ='GENERATE_BORNE_ACCESS_CODE_SUCCESS';

export const users = (page, pageSize, userFilter) => {
	return ({
		type: USERS,
		data: { page: page, pageSize: pageSize, userFilter : userFilter},
		errorMessage: null,
		errorStatus: null
	});
};
export const usersSuccess = (r) => {
	return ({
		type: USERS_SUCCESS,
		data: formatDates(r.response),
		errorMessage: null,
		errorStatus: null
	});
};

export const user = (userId) => {
	return ({
		type: USER,
		data: { userId: userId },
		errorMessage: null,
		errorStatus: null
	});
};
export const userSuccess = (r) => {
	return ({
		type: USER_SUCCESS,
		data: r.response,
		errorMessage: null,
		errorStatus: null
	});
};


export const addUser = (user) => {
	return ({
		type: ADD_USER,
		data: { user: user },
		errorMessage: null,
		errorStatus: null
	});
};

export const addUserSuccess = (r) => {
	return ({
		type: ADD_USER_SUCCESS,
		data: r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const loadGroups = () => {
	return ({
		type: LOAD_GROUPS,
		data: {  },
		errorMessage: null,
		errorStatus: null
	});
};

export const loadGroupsSuccess = (r) => {
	return ({
		type: LOAD_GROUPS_SUCCESS,
		data: r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const userDeleteById = (userId) => { 
	return ({
		type: USER_DELETE_BY_ID,
		data: {userId: userId},
		errorMessage: null,
		errorStatus: null
	});
};

export const userDeleteByIdSuccess = (r) => {
	return ({
		type: USER_DELETE_BY_ID_SUCCESS,
		data: r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const userUpdate = (userId, user) => { 
	return ({
		type: USER_UPDATE,
		data: {userId: userId, user: user},
		errorMessage: null,
		errorStatus: null
	});
};

export const userUpdateSuccess = (r) => {
	return ({
		type: USER_UPDATE_SUCCESS,
		data: r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const passwordUpdate = (userId, password) => { 
	return ({
		type: PASSWORD_UPDATE,
		data: {userId: userId, password: password},
		errorMessage: null,
		errorStatus: null
	});
};

export const passwordUpdateSuccess = (r) => {
	return ({
		type: PASSWORD_UPDATE_SUCCESS,
		data: r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const myPasswordUpdate = (password) => { 
	return ({
		type: MY_PASSWORD_UPDATE,
		data: {password: password},
		errorMessage: null,
		errorStatus: null
	});
};

export const myPasswordUpdateSuccess = (r) => {
	return ({
		type: MY_PASSWORD_UPDATE_SUCCESS,
		data: r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const generateBorneAccessCode = () => {
	return ({
		type: GENERATE_BORNE_ACCESS_CODE,
		data: {},
		errorMessage: null,
		errorStatus: null
	});
};
export const generateBorneAccessCodeSuccess = (r) => {
	return ({
		type: GENERATE_BORNE_ACCESS_CODE_SUCCESS,
		data: r.response,
		errorMessage: null,
		errorStatus: null
	});
};

function formatDates(response) {
	let data = response && response.users ? response.users : [];
	data = data.map(e => {
		e.lastConnectionDate = toLocaleDateTimeFormat(e.lastConnectionDate);
		return e;
	});
	response.users = data;
	return response;
}