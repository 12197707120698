import { LOAD_COMMISSIONS, LOAD_COMMISSIONS_SUCCESS, LOAD_COMMISSION, LOAD_COMMISSION_SUCCESS,
     CREATE_COMMISSION, CREATE_COMMISSION_SUCCESS, UPDATE_COMMISSION, UPDATE_COMMISSION_SUCCESS, 
     DELETE_COMMISSION, DELETE_COMMISSION_SUCCESS } from "../actions/commission";

const state0 = {data: null, error: null};

export const loadCommissionsReducer = (state = state0, action) => {
	switch (action.type) {
		case LOAD_COMMISSIONS:
			return {
				state,
				error: null
		};
		case LOAD_COMMISSIONS_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};

export const loadCommissionReducer = (state = state0, action) => {
	switch (action.type) {
		case LOAD_COMMISSION:
			return {
				state,
				error: null
		};
		case LOAD_COMMISSION_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};

export const createCommissionReducer = (state = state0, action) => {
	switch (action.type) {
		case CREATE_COMMISSION:
			return {
				state,
				error: null
		};
		case CREATE_COMMISSION_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};

export const updateCommissionReducer = (state = state0, action) => {
	switch (action.type) {
		case UPDATE_COMMISSION:
			return {
				state,
				error: null
		};
		case UPDATE_COMMISSION_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};

export const deleteCommissionReducer = (state = state0, action) => {
	switch (action.type) {
		case DELETE_COMMISSION:
			return {
				state,
				error: null
		};
		case DELETE_COMMISSION_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};