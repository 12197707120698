import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError} from  'rxjs/operators';
import { ofType } from 'redux-observable';

import {
	loadFilterSuccess, loadFilterStationsSuccess, loadFilterStationsWithContractsSuccess, loadSystemEventsFilterSuccess, LOAD_FILTER, LOAD_FILTER_STATIONS,
	LOAD_SYSTEM_EVENTS_FILTER, LOAD_FILTER_EXPLOITANTS_REPORTS, loadFilterExploitantsReportsSuccess, LOAD_FILTER_STATIONS_WITH_CONTRACTS,
	LOAD_FILTER_DODO_STATIONS, loadFilterDodoStationsSuccess
} from '../actions/filter';
import {faillure } from '../actions';
import { authProviderType } from '../AuthProviderType';

export const loadFilterEpic = (epicAction)  => {
	return  epicAction.pipe(
			ofType(LOAD_FILTER),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				const req = ajax({
					url: apiRootUri + action.data.feature + '/common-filter',
					method: 'POST',					  
					headers: {
						'Content-Type': 'application/json'
					},
					withCredentials: true,
					body: action.data.filter
				});				
				return req.pipe(					   
            		map(res => { return loadFilterSuccess(res); }),
            		catchError(res => { return of(faillure(res));})
            	)
		}),
	);
};

export const loadFilterStationsEpic = (epicAction)  => {
	return  epicAction.pipe(
		ofType(LOAD_FILTER_STATIONS),
		mergeMap((action) => {
		let apiRootUri = authProviderType.getApiRootUri();
		const req = ajax({
			url: apiRootUri + action.data.feature + '/common-filter/stations',
			method: 'POST',					  
			headers: {
				'Content-Type': 'application/json'
			},
			withCredentials: true,
			body: action.data.filter
		});				
		return req.pipe(map(res => loadFilterStationsSuccess(res)), catchError(res => of(faillure(res))));
	}));
};

export const loadFilterStationsWithContractsEpic = (epicAction)  => {
	return  epicAction.pipe(
		ofType(LOAD_FILTER_STATIONS_WITH_CONTRACTS),
		mergeMap((action) => {
		let apiRootUri = authProviderType.getApiRootUri();
		const req = ajax({
			url: apiRootUri + action.data.feature + '/common-filter/stations-with-contracts',
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			withCredentials: true,
			body: action.data.filter
		});
		return req.pipe(map(res => loadFilterStationsWithContractsSuccess(res)), catchError(res => of(faillure(res))));
	}));
};

export const loadSystemEventsFilterEpic = (epicAction)  => {
	return  epicAction.pipe(
			ofType(LOAD_SYSTEM_EVENTS_FILTER),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				const req = ajax({
					url: apiRootUri + action.data.feature + '/common-filter/system-events',
					method: 'GET',					  
					headers: {
						'Content-Type': 'application/json'
					},
					withCredentials: true
				});				
				return req.pipe(					   
            		map(res => { return loadSystemEventsFilterSuccess(res); }),
            		catchError(res => { return of(faillure(res));})
            	)
		}),
	);
};


export const loadFilterExploitantsReportsEpic = (epicAction)  => {

	return  epicAction.pipe(
		ofType(LOAD_FILTER_EXPLOITANTS_REPORTS),
		mergeMap((action) => {
		let apiRootUri = authProviderType.getApiRootUri();
		const req = ajax({
			url: apiRootUri + action.data.feature + '/filter-exploitantsReports',
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			withCredentials: true,
			body: action.data.filter
		});
		return req.pipe(map(res => loadFilterExploitantsReportsSuccess(res)), catchError(res => of(faillure(res))));
	}));
};

export const loadFilterDodoStationsEpic = (epicAction) => {

	return epicAction.pipe(
		ofType(LOAD_FILTER_DODO_STATIONS),
		mergeMap((action) => {
			let apiRootUri = authProviderType.getApiRootUri();
			const req = ajax({
				url: apiRootUri + action.data.feature + '/common-filter/dodo-stations',
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				withCredentials: true,
				body: action.data.filter
			});

			return req.pipe(map(res => loadFilterDodoStationsSuccess(res)), catchError(res => of(faillure(res))));
		})
	)
};