import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import { Checkbox } from 'primereact/checkbox';
import { Password } from 'primereact/password';
import { Dropdown } from 'primereact/dropdown';
import { Message } from 'primereact/message';
import { Dialog } from 'primereact/dialog';
import { RadioButton } from 'primereact/radiobutton';
import QRCode from 'qrcode.react';
import { validatePassword } from './validatePassword';
import { EMAIL_PATTERN } from '../../../utils/RegexPatterns';
import msg from '../../../texts/msg';
import { rolesValidator, USERS, USERS_BORNEACCESS, CREATE, ADMINISTRATION } from '../../../utils/RolesValidator';
import MessageDialog from '../../common/MessageDialog';
import { validateInputText } from '../../../utils/validateInputData';
import '../../common/css/common.css';
import './User.css';

const emtyChar = '';
const CARTADIS_COMPANY_TYPE = "cartadis";
const REF_BORNEACCESS_LEVELS = [
    {key: msg("user.borne.access.level.empty"), value: emtyChar},
	{key: msg("user.borne.access.level.admin"), value: 1},
	{key: msg("user.borne.access.level.technic"), value: 2},
	{key: msg("user.borne.access.level.operator"), value: 4}
];
const ADMIN_BORNEACCESS_LEVEL = REF_BORNEACCESS_LEVELS[1];
const AUTHENTICATION_MODE_DEFAULT = msg("user.creation.authentication.mode.sso");
const AUTHENTICATION_MODE_WASHADMIN = msg("user.creation.authentication.mode.washadmin");
const AUTHENTICATION_MODES = [
	{key: AUTHENTICATION_MODE_DEFAULT, value: 1 },
	{key: AUTHENTICATION_MODE_WASHADMIN, value: 2 },
];

export class CreationUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: false,
            isfirstName: false,
            islastName: false,
            isemail: false,
            isidentifiant: false,
            isprofil: false,
            ispassword: false,
            ispasswordConfirm: false,
            groups: [],
            isModifiable: true,
            isSaved: false,
            closable: false,
            isAssociated: false,
            addStation: false,
            isMatchPassword: false,
            isRegesValid: false,
            isBorneAccessLevel:false,
            user: {
                login: emtyChar,
                firstname: emtyChar,
                lastname: emtyChar,
                email: emtyChar,
                comment: emtyChar,
                groups: [],
                enabled: true,
                borneAccessEnabled: false,
                borneAccessLevel: REF_BORNEACCESS_LEVELS[0],
                borneAccessCode: emtyChar,
                borneAccessEmailSend: false,
                authenticationMode: AUTHENTICATION_MODE_DEFAULT
            },
            password: {
                password: emtyChar,
                confirmPassword: emtyChar
            },
            borneAccessLevels: REF_BORNEACCESS_LEVELS.slice()
        }
        this.doOnSave = this.doOnSave.bind(this);
        this.addUsers = this.addUsers.bind(this);
        this.fieldsControl = this.fieldsControl.bind(this);
        this.validateFields = this.validateFields.bind(this);
        this.association = this.association.bind(this);
        this.addStation = this.addStation.bind(this);
        this.generateAccessCode = this.generateAccessCode.bind(this);        
        this.handleBorneAccessChange = this.handleBorneAccessChange.bind(this);
        this.handleBorneAccessEmailSendChange = this.handleBorneAccessEmailSendChange.bind(this);
        this.handleBorneAccessLevelChange = this.handleBorneAccessLevelChange.bind(this);
        this.handleGroupChange = this.handleGroupChange.bind(this);
        this.handleAuthChange = this.handleAuthChange.bind(this);
    }

    componentDidMount() {
        this.props.loadGroups();
        this.props.setDataInfoBar(null);
        this.generateAccessCode();
    }

    componentDidUpdate(prevProps, nextProps) {
        let prevError = prevProps.error && prevProps.error.timestamp;
        let lastError = this.props.error && this.props.error.timestamp;
        let error = (lastError && (!prevError || prevError < lastError));
        if (error) {
            let isError = this.props.error.message && this.props.error.message.startsWith('error.users.') ? true : false;
            this.setState({ isApiError: isError, message: this.props.error.message});
        }
        if(this.props.groupsData && this.props.groupsData !== prevProps.groupsData && this.props.groupsData.groups.length === 1){
            this.setState({user: Object.assign({}, this.state.user, {groups: [this.props.groupsData.groups[0]]})});
        }
        let prevCreate = prevProps.addUser && prevProps.addUser.lastChange;
        let lastCreate = this.props.addUser && this.props.addUser.lastChange;
        let created = (lastCreate && (!prevCreate || prevCreate < lastCreate))
        if (created) {
            this.props.history.push('/users');
        }
        let prevGenerate = prevProps.borneAccessCodeData && prevProps.borneAccessCodeData.generationDate;
        let lastGenerate = this.props.borneAccessCodeData && this.props.borneAccessCodeData.generationDate;
        let generate = (lastGenerate && (!prevGenerate || prevGenerate < lastGenerate))
        if (generate) {
            this.setState({ user: Object.assign({}, this.state.user, { borneAccessCode: this.props.borneAccessCodeData.code })});
        }

    }

    applyGroupAccessLevel(lBorneAccessEnabled, lBorneAccessLevel, lGroups){
        let limitedLevels =  REF_BORNEACCESS_LEVELS.slice();
        let limitedLevel = lBorneAccessLevel;
        if(lBorneAccessEnabled && lGroups.length > 0){
            let llGroups = lGroups.filter(g => g.companyType !== CARTADIS_COMPANY_TYPE);
            if(llGroups.length > 0){
                limitedLevels =  REF_BORNEACCESS_LEVELS.filter(l => l.value !== ADMIN_BORNEACCESS_LEVEL.value);
                if(limitedLevels.length > 0){
                    if(lBorneAccessLevel.value === ADMIN_BORNEACCESS_LEVEL.value){
                        limitedLevel = limitedLevels[0];
                    }
                }
            }
        }
        this.setState({isModifiable: false, borneAccessLevels: limitedLevels, 
            user: Object.assign({}, this.state.user, { borneAccessEnabled: lBorneAccessEnabled, borneAccessLevel: limitedLevel, groups: lGroups}) });
    }

    addUsers() {
        let user = Object.assign({}, this.state.user, { borneAccessLevel: this.state.user.borneAccessLevel.value, login: this.state.user.login.trim()});
        this.props.addUsers({ user: user, password: this.state.password, authenticationMode: this.state.user.authenticationMode });
    }

    fieldsControl() {
        if (!this.state.user.firstname || !this.state.user.firstname.trim()) {
            this.setState({ isfirstName: true });
        } else {
            this.setState({ isfirstName: false });
        }
        if (!this.state.user.lastname || !this.state.user.lastname.trim()) {
            this.setState({ islastName: true });
        } else {
            this.setState({ islastName: false });
        }
        if (!this.state.user.email || !this.state.user.email.trim()) {
            this.setState({ isemailErr: true })
        } else {
            this.setState({ isemailErr: false });
        }
        if (!this.state.user.login || !this.state.user.login.trim()) {
            this.setState({ isidentifiant: true });
        } else {
            this.setState({ isidentifiant: false });
        }
        if (this.state.user.login && this.state.user.login.trim().length < 5) {
            this.setState({ notValidIdentifiant: true });
        } else {
            this.setState({ notValidIdentifiant: false });
        }
        if (!this.state.user.groups || this.state.user.groups.length === 0) {
            this.setState({ isprofil: true });
        } else {
            this.setState({ isprofil: false });
        }
        if (this.state.user.authenticationMode === AUTHENTICATION_MODE_WASHADMIN && (!this.state.password.password || this.state.password.password.trim().length < 8)) {
            this.setState({ ispassword: true });
        } else {
            this.setState({ ispassword: false });
        }
        if (this.state.user.authenticationMode === AUTHENTICATION_MODE_WASHADMIN && (!this.state.password.confirmPassword || this.state.password.confirmPassword.trim().length < 8)) {
            this.setState({ ispasswordConfirm: true });
        } else {
            this.setState({ ispasswordConfirm: false });
        }
    }

    validateFields() {
        let fields = [this.state.user.firstname, this.state.user.lastname, this.state.user.email];
        if (this.state.user.authenticationMode === AUTHENTICATION_MODE_WASHADMIN)  {
            fields.push(
                this.state.password.password && this.state.password.password,
                this.state.password.password && this.state.password.confirmPassword
            )
        }
        return validateInputText(fields) &&
        this.state.user.login.trim().length >= 5 && this.state.user.groups.length > 0
        && ((
            this.state.password.password.length === this.state.password.confirmPassword.length
            && this.state.password.password.length >= 8
            && this.state.user.authenticationMode === AUTHENTICATION_MODE_WASHADMIN)
        || this.state.user.authenticationMode === AUTHENTICATION_MODE_DEFAULT);
    }

    doOnSave() {
        let valid = true;
        if (!this.validateFields()) {
            this.fieldsControl();
            valid = false;
        }
        if (this.state.user.authenticationMode === AUTHENTICATION_MODE_WASHADMIN && this.state.password.password !== this.state.password.confirmPassword) {
            this.setState({ isMatchPassword: true });
            valid = false;
        } else if (this.state.user.authenticationMode === AUTHENTICATION_MODE_WASHADMIN && !validatePassword(this.state.password.password)) {
            this.setState({ isRegesValid: true });
            valid = false;
        }
        if (!EMAIL_PATTERN.test(this.state.user.email)) {
            this.setState({ isemailvalidErr: true });
            valid = false;
        } else {
            this.setState({ isemailvalidErr: false });
        }
        if(this.state.user.borneAccessEnabled && !validateInputText(this.state.user.borneAccessLevel.value)){
            this.setState({ isBorneAccessLevel: true });
            valid = false;
         } else {
             this.setState({ isBorneAccessLevel: false });
         }
        if (valid) {
            this.addUsers();
        }
    }

    association() {
        this.setState({ isAssociated: false });
        this.setState({ addStation: false });
        this.props.history.push('/users');
    }

    addStation() {
        this.setState({ addStation: false });
        this.setState({ isAssociated: true });

    }

    generateAccessCode() {
        if(rolesValidator.validate(USERS_BORNEACCESS)){
            this.props.generateBorneAccessCode();
        }        
    }

    handleBorneAccessChange(e) {    
        if(e.checked === true){
            this.generateAccessCode();
        }
        this.applyGroupAccessLevel(e.checked, this.state.user.borneAccessLevel, this.state.user.groups);
    }

    handleBorneAccessEmailSendChange(e) {    
        this.setState({ user: Object.assign({}, this.state.user, { borneAccessEmailSend: e.checked })});
    }

    handleBorneAccessLevelChange(e) {        
        this.applyGroupAccessLevel(this.state.user.borneAccessEnabled, e.target.value, this.state.user.groups);
        
    }

    handleGroupChange(e) {        
        let lGroups = [e.target.value];
        this.applyGroupAccessLevel(this.state.user.borneAccessEnabled, this.state.user.borneAccessLevel, lGroups);
    }

    handleAuthChange(e) {
        this.setState({ user: Object.assign({}, this.state.user, { authenticationMode: e.value })});
    }

    passwordFooter() {
        return (
            <div>
                <Button label="Ok" icon="pi pi-check" onClick={() => this.setState({ isMatchPassword: false, isRegesValid: false })} />
            </div>
        );
    }
    renderFooter() {
        return (
            <div>
                <Button label="Ok" icon="pi pi-check" onClick={this.association} />
            </div>
        );
    }

    stationFooter() {
        return (
            <div>
                <Button label={msg('user.yes')} icon="pi pi-check" onClick={this.addStation} />
                <Button label={msg('user.no')} icon="pi pi-check" onClick={this.association} />
            </div>
        );
    }

    render() {
        let authorized = rolesValidator.validate(USERS);
        let cAuthorized = authorized.toString().includes(CREATE);
        let borneAccessAuthorized = rolesValidator.validate(USERS_BORNEACCESS);
        let isAdminGroup = this.props.adminUser.groupAdmin || rolesValidator.validate([ADMINISTRATION]);
        let groupInfos = this.props.groupsData ? this.props.groupsData : null;

        let regesPasswordValid = (
            <Dialog visible={this.state.isRegesValid} style={{ width: '50%' }} modal={false} onHide={() => this.setState({ isRegesValid: false })} closable={this.state.closable}
                footer={this.passwordFooter()}>
                <h3>{msg('user.control.reges.password')}</h3>
            </Dialog>
        )
        let passwordNotMatch = (
            <Dialog visible={this.state.isMatchPassword} style={{ width: '40%' }} modal={false} onHide={() => this.setState({ isMatchPassword: false })} closable={this.state.closable}
                footer={this.passwordFooter()}>
                <h3>{msg('user.password.not.match')}</h3>
            </Dialog>
        );

        let addStation = (
            <Dialog visible={this.state.addStation} style={{ width: '30%' }} modal={false} onHide={() => this.setState({ addStation: false })} closable={this.state.closable}
                footer={this.stationFooter()}>
                <h3>{msg('user.station.add')}</h3>
            </Dialog>
        );

        let association = (
            <Dialog visible={this.state.isAssociated} style={{ width: '30%' }} modal={false} onHide={() => this.setState({ isLignSelected: false })} closable={this.state.closable}
                footer={this.renderFooter()}>
                <h3>{msg('user.station.not.implemented')}</h3>
            </Dialog>
        );

        return cAuthorized && (
            <div className="card users commonClass">
                {regesPasswordValid}
                {passwordNotMatch}
                {addStation}
                {association}
                <div className="test p-col-12">
                    <h3 style={{ marginTop: '.5em' }}>{msg('user.identity')}</h3>
                </div>
                <div className="card card-w-title p-col-12">
                    <div className="p-grid form-group p-col-12">
                        {<div className="p-grid p-col-6">
                            <div className="p-grid p-col-2">
                                <div className="p-col-5"><label>{msg('user.fields.firstname')}</label></div>
                                <div className="p-col-1"></div>
                                <div className=""><span className="obligatoire">*</span></div>
                            </div>
                            
                            <div className="p-col-10">
                                <span className="md-inputfield">
                                    <InputText value={this.state.user.firstname} onChange={e => this.setState({ user: Object.assign({}, this.state.user, { firstname: e.target.value }) })} maxLength="255"/>
                                </span>
                                {this.state.isfirstName && <Message severity="error" text={msg('user.input.firstname.required')} key="firstname" />}
                            </div>
                        </div>}
                        {<div className="p-grid p-col-6">
                            <div className="p-grid p-col-3">
                                <div className="p-col-5"> <label>{msg('user.fields.lastname')}</label></div>
                                <div className="p-col-1"></div>
                                <div className=""><span className="obligatoire">*</span></div>
                            </div>
                            <div className="p-col-8">
                                <span className="md-inputfield">
                                    <InputText value={this.state.user.lastname} onChange={e => this.setState({ user: Object.assign({}, this.state.user, { lastname: e.target.value }) })} maxLength="255"/>
                                </span>
                                {this.state.islastName && <Message severity="error" text={msg('user.input.lastname.required')} key="lastname" />}
                            </div>
                        </div>}
                    </div>
                    <div className="p-grid form-group p-col-12">
                        {<div className="p-grid p-col-6">
                            <div className="p-grid p-col-2">
                                <div className="p-col-6"> <label>{msg('user.fields.email')}</label></div>
                                <div className="p-col-1"></div>
                                <div className=""><span className="obligatoire">*</span></div>
                            </div>
                            <div className="p-col-10">
                                <span className="md-inputfield">
                                    <InputText value={this.state.user.email} onChange={e => this.setState({ user: Object.assign({}, this.state.user, { email: e.target.value }) })} maxLength="255"/>
                                </span>
                                {this.state.isemailErr && <Message severity="error" text={msg('user.input.email.required')} key="emailErr" />}
                                {this.state.isemailvalidErr && <Message severity="error" text={msg('user.input.email.invalid')} key="emailvalidErr" />}
                            </div>
                        </div>}
                        {<div className="p-grid p-col-6">
                            <div className="p-grid p-col-3">
                                <div className="p-col-8"> <label>{msg('group.fields.description')}</label></div>
                            </div>
                            <div className="p-col-9">
                                <span className="md-inputfield">
                                <InputText className="inputClass" id="float-input" placeholder="Description" type="text" size="60" value={this.state.user.comment} onChange={e => this.setState({ user: Object.assign({}, this.state.user, { comment: e.target.value }) })} />
                                </span>
                            </div>
                        </div>}                      
                    </div>
                </div>
                <div className="test p-col-12">
                    <div className="p-grid">
                        <div className="p-col-4">
                            <h3 style={{ marginTop: '.5em' }}>{msg('user.connection')}</h3>
                        </div>
                        <div className="p-grid p-col-2" style={{ marginTop: '.3em' }}>
                            <div className="p-col-3"><label>{msg('user.fields.actif')} </label></div>
                            <div className="p-col-8"><Checkbox onChange={e => this.setState({ user: Object.assign({}, this.state.user, { enabled: e.checked }) })}
                                value={this.state.user.enabled} checked={this.state.user.enabled}></Checkbox></div>
                        </div>
                    </div>
                </div>
                <div className="card card-w-title p-col-12">
                    <div className="p-grid form-group p-col-12">
                        {<div className="p-grid p-col-6">
                            <div className="p-grid p-col-3">
                                <div className="p-col-7"> <label>{msg('user.fields.identifiant')}</label></div>
                                <div className="p-col-1"></div>
                                <div className=""><span className="obligatoire">*</span></div>
                            </div>
                            <div className="p-col-9">
                                <span className="md-inputfield">
                                    <InputText value={this.state.user.login} onChange={e => this.setState({ user: Object.assign({}, this.state.user, { login: e.target.value }) })} minLength="5" maxLength="255" />
                                </span>
                                {this.state.isidentifiant && <Message severity="error" text={msg('user.input.login.required')} key="login" />}
                                {this.state.notValidIdentifiant && <Message severity="error" text={msg('user.input.login.size')} key="loginSize" />}
                            </div>
                        </div>}
                        {groupInfos && <div className="p-grid p-col-6">
                            <div className="p-grid p-col-3">
                                <div className="p-col-7"> <label>{msg('user.fields.profil')}</label></div>                              
                                <div className=""><span className="obligatoire">*</span></div>
                            </div>
                            <div className="p-col-9">
                                <span className="md-inputfield md-inputfield-fill">
                                    <Dropdown value={this.state.user.groups[0]} options={this.props.groupsData.groups}
                                        onChange={e => this.handleGroupChange(e)}
                                        optionLabel="name" dataKey="id" placeholder="Selectionnez" style={{ minWidth: '60%' }} disabled={this.props.groupsData.groups.length === 1 ? true : false} />
                                </span>
                                {this.state.isprofil && <Message severity="error" text={msg('user.input.group.required')} key="group" />}
                            </div>
                            <div className="p-grid p-col-3">
                                <div className="p-col-12"> <label>{msg('user.fields.group.admin')}</label></div>
                            </div>
                            <div className="p-col-9">
                                <span className="md-inputfield md-inputfield-fill">
                                    <Checkbox onChange={e => this.setState({ user: Object.assign({}, this.state.user, { groupAdmin: e.checked }) })} value={this.state.user.groupAdmin} checked={this.state.user.groupAdmin} />
                                </span>
                            </div>
                        </div>}
                    </div>
                </div>
                <div className="test p-col-12">
                    <h3 style={{ marginTop: '.5em' }}>{msg('user.password.section')}</h3>
                </div>
                <div className="card card-w-title p-col-12">
                    <div className="p-grid form-group p-col-12">
                        {<div className="p-grid p-col-12">
                            <div className="p-grid p-col-4">
                                <div className="p-col-4"> <label>Mode d'authentification</label></div>
                                <div className=""><span className="obligatoire">*</span></div>
                            </div>
                            {AUTHENTICATION_MODES.map((auth_mode) => {
                                return (
                                    <div key={auth_mode.key} className="flex align-items-center p-col-4">
                                        <RadioButton inputId={auth_mode.key} name="auth_mode" value={auth_mode.key} onChange={(e) => this.handleAuthChange(e)} checked={this.state.user.authenticationMode === auth_mode.key} />
                                        <label htmlFor={auth_mode.key} className="ml-2">{auth_mode.key}</label>
                                    </div>
                                );
                            })}
                        </div>}
                        {this.state.user.authenticationMode === AUTHENTICATION_MODE_WASHADMIN && <div className="p-grid p-col-6">
                            <div className="p-grid p-col-4">
                                <div className="p-col-8"> <label>{msg('user.fields.password')}</label></div>                               
                                <div className=""><span className="obligatoire">*</span></div>
                            </div>
                            <div className="p-col-8">
                                <span className="md-inputfield">
                                    <Password onChange={e => this.setState({ password: Object.assign({}, this.state.password, { password: e.target.value }) })} minLength="5" id="newUserPwd1" />
                                </span>
                                {this.state.ispassword && <Message severity="error" text={msg('user.control.password')} key="password" />}
                            </div>
                        </div>}
                        {this.state.user.authenticationMode === AUTHENTICATION_MODE_WASHADMIN && <div className="p-grid p-col-6">
                            <div className="p-grid p-col-4">
                                <div className="p-col-8"> <label>{msg('user.fields.passwordconfirm')}</label></div>                              
                                <div className=""><span className="obligatoire">*</span></div>
                            </div>
                            <div className="p-col-8">
                                <span className="md-inputfield">
                                    <Password onChange={e => this.setState({ password: Object.assign({}, this.state.password, { confirmPassword: e.target.value }) })} minLength="5" id="newUserPwd2" />
                                </span>
                                {this.state.ispasswordConfirm && <Message severity="error" text={msg('user.control.password')} key="confirmPassword" />}
                            </div>
                        </div>}
                    </div>
                </div>
                {borneAccessAuthorized && 
                <div className="test p-col-12">
                    <div className="p-grid">
                        <div className="p-col-4">
                            <h3 style={{ marginTop: '.5em' }}>{msg('user.borne.connection')}</h3>
                        </div>
                        <div className="p-grid p-col-2" style={{ marginTop: '.3em' }}>
                            <div className="p-col-3"><label>{msg('user.fields.borne.access.actif')} </label></div>
                            <div className="p-col-8">
                                <Checkbox onChange={e => this.handleBorneAccessChange(e)} value={this.state.user.borneAccessEnabled} checked={this.state.user.borneAccessEnabled}/>
                            </div>
                        </div>
                    </div>
                </div>}
                {borneAccessAuthorized && this.state.user.borneAccessEnabled && 
                <div className="card card-w-title p-col-12">
                    <div className="p-grid form-group p-col-12">
                        <div className="p-grid p-col-6 access-code-gen">
                            <div className="p-grid p-col-8">
                                <div className="p-grid p-col-4">
                                    <div className="p-col-11"> <label>{msg('user.fields.borne.access.code')}</label></div>
                                    <div className="p-col-1"></div>
                                </div>
                                <div className="p-grid p-col-8">
                                    <div className="p-col-6">
                                        <span className="md-inputfield">
                                            <InputText value={this.state.user.borneAccessCode} minLength="8" maxLength="8" size="8" keyfilter="int" readOnly={true} />
                                        </span>
                                    </div>
                                    <div className="p-col-6">
                                        <Button icon="pi-md-sync" onClick={this.generateAccessCode} title={(msg('user.borne.access.code.reset'))}/>
                                    </div>
                                </div>
                                <div className="p-grid p-col-12 qrcode-mail">
                                    <div className="p-col-12">
                                        <Checkbox onChange={e => this.handleBorneAccessEmailSendChange(e)} value={this.state.user.borneAccessEmailSend} checked={this.state.user.borneAccessEmailSend}/>
                                        <label>{msg('user.borne.qr.access.code.send.email')}</label>
                                    </div>
                                    <div className="p-col-12 info"><span>{msg('user.borne.access.code.reset.info')}</span></div>
                                </div>                                
                            </div>
                            <div className="p-col-4"><QRCode id="qr-borneAccessCodeId" value={this.state.user.borneAccessCode} size={200} level={"H"} /></div>                       
                        </div>
                        <div className="p-grid p-col-6">
                            <div className="p-grid p-col-4">
                                <div className="p-col-6"> <label>{msg('user.fields.borne.access.level')}</label></div>
                                <div className="p-col-1"></div>
                                <div className=""><span className="obligatoire">*</span></div>
                            </div>
                            <div className="p-col-8">
                                <span className="md-inputfield md-inputfield-fill">
                                    <Dropdown value={this.state.user.borneAccessLevel} options={this.state.borneAccessLevels}
                                        onChange={e => this.handleBorneAccessLevelChange(e)}
                                        optionLabel="key" dataKey="value" placeholder="" style={{ minWidth: '60%' }} />
                                </span>
                                {this.state.isBorneAccessLevel && <Message severity="error" text={msg('user.input.levelAccessBorne.required')} key="borneAccessLevel" />}
                            </div>                            
                        </div>
                    </div>
                </div>}
                <div className="p-grid p-col-12">
                    <div className="p-grid p-col-9">
                    </div>
                    <div className="p-grid p-col-3">
                        <Button label={(msg('user.validate'))} title={(msg('user.validate'))} onClick={this.doOnSave} className="button" disabled={!cAuthorized || !isAdminGroup} />
                        <Link to="/users">
                            <Button  label={(msg('user.reset'))} className="button" />
                        </Link>
                    </div>
                </div>

                <MessageDialog className={'message-dialog warn-message'} visible={this.state.isApiError} title={msg('message.dialog.error.title')}
                    message={msg(this.state.message, { login: this.state.user.login})}
                    onHide={() => this.setState({ isApiError: false, message: '' })} />
            </div>);
    }
}
export default CreationUser;