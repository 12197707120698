import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { Message } from 'primereact/message';
import { AutoComplete } from 'primereact/autocomplete';
import { rolesValidator, GROUPS, STATIONGROUPS, READ, UPDATE } from '../../../utils/RolesValidator';
import MessageDialog from '../../common/MessageDialog';
import msg from '../../../texts/msg';
import './group.css';
import '../../common/css/common.css';

export class UpdateGroup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isModifiable: true,
            checked: false,
            closable: false,
            isname: false,
            isCompanyName: false,
            isRoles: false,
            locations: [],
            group: {
                id: null,
                name: '',
                description: '',
                companyName: {},
                role: {},
                stationGroups: [],
                location: {}
            },
            query: '',
            isInitialized: false
        }
        this.doOnSave = this.doOnSave.bind(this);
        this.updateGroup = this.updateGroup.bind(this);
        this.filterStationGroups = this.filterStationGroups.bind(this);
        this.unselectStationGroup = this.unselectStationGroup.bind(this);
    }

    componentDidMount() {
        this.props.loadRole();
        let filter = { label: this.state.query, page: 0, pageSize: 10, feature: STATIONGROUPS};
        this.props.loadStationGroups(filter);
    }

    componentDidUpdate(prevProps) {
        if (this.props.group && this.props.group.id && !this.state.isInitialized) {            
            let company = this.props.group.companyName && this.props.group.companyName.key ? 
                {key: this.props.group.companyName.key, value: this.props.group.companyName.value, label: msg('group.companyName.' + this.props.group.companyName.key)} : {};
            let group = {
                id: this.props.group.id,
                name: this.props.group.name,
                description: this.props.group.description,
                companyName: company,
                role: this.props.group.role ? this.props.group.role : {},
                stationGroups: this.props.group.stationGroups ? this.props.group.stationGroups : [],
                location: this.props.group.location
            }
            if (group.location) {
                group.location.msgName = group.location.name ? msg('role.authorisation.name.' + group.location.name) : '';
            }
            this.setState({ group: group, isInitialized: true });
        }
        let prevChange = prevProps.groupUpdate && prevProps.groupUpdate.lastChange;
        let lastChange = this.props.groupUpdate && this.props.groupUpdate.lastChange;
        let change = (lastChange && (!prevChange || prevChange < lastChange))
        if (change) {
            this.props.history.push('/groups');
            return;
        }
        prevChange = prevProps.stationGroupsData && prevProps.stationGroupsData.lastChange;
        lastChange = this.props.stationGroupsData && this.props.stationGroupsData.lastChange;
        change = (lastChange && (!prevChange || prevChange < lastChange))
        if (change) {
            let stationGroups = [];
            let stationGroupIds = [];
            if (this.state.group.stationGroups) {
                this.state.group.stationGroups.forEach(g => { if (g.id) { stationGroupIds.push(g.id) } });
            }
            this.props.stationGroupsData.stationGroups.forEach(
                g => { if (!stationGroupIds.includes(g.id)) { stationGroups.push(g) } }
            );
            this.setState({ filteredStationGroups: stationGroups });
        }

        let prevError = prevProps.error && prevProps.error.timestamp;
        let lastError = this.props.error && this.props.error.timestamp;
        let error = (lastError && (!prevError || prevError < lastError));
        if (error) {
            let isError = this.props.error.message && this.props.error.message.startsWith('error.users.') ? true : false;
            this.setState({ isApiError: isError,  message: this.props.error.message});
        }
    }

    updateGroup() {
        this.props.updateGroup(this.state.group.id, this.state.group);
    }

    validateFields() {
        return (!this.state.group.name || !this.state.group.name.trim()
            || (this.props.roleData.userCartadis && (!this.state.group.companyName || this.state.group.companyName.length === 0))
            || (!this.state.group.role || !this.state.group.role.id));
    }

    fieldsControl() {

        if (!this.state.group.name || !this.state.group.name.trim()) {
            this.setState({ isname: true });
        } else {
            this.setState({ isname: false });
        }

        if (!this.state.group.companyName || !this.state.group.companyName.value) {
            this.setState({ isCompanyName: true });

        } else {
            this.setState({ isCompanyName: false });
        }

        if (!this.state.group.role || !this.state.group.role.id) {
            this.setState({ isRoles: true });
        } else {
            this.setState({ isRoles: false });
        }
    }

    doOnSave() {
        if (this.validateFields()) {
            this.fieldsControl();
        } else {
            this.updateGroup();
        }
    }
    
    locationTemplate(option) {
        return option ? msg('role.authorisation.name.' + option.name) : '';
    }

    sort(a, b) {
        let aName = a ? msg('role.authorisation.name.' + a.name) : '';
        let bName = b ? msg('role.authorisation.name.' + b.name) : '';
        return aName.localeCompare(bName);
    }

    filterStationGroups(event, isDropdownClick) {
        let query = event.query.toLowerCase();
        if (!isDropdownClick) {
            if (!query || query === this.state.query) {
                this.setState({ query: query });
                return;
            }
        } else if (query && (query === this.state.query)) {
            return;
        }
        let filter = {
            label: isDropdownClick ? this.state.query : query,
            page: 0,
            pageSize: 10,
            feature: STATIONGROUPS
        };
        this.props.loadStationGroups(filter);
        this.setState({ query: query });
    }

    unselectStationGroup(event) {
        let unselectGroup = event.value;
        if (unselectGroup && this.state.group.stationGroups) {
            let stationGroups = [];
            this.state.group.stationGroups.forEach(g => { if (g.id !== unselectGroup.id) stationGroups.push(g) });
            this.setState({
                query: '',
                filteredStationGroups: null,
                group: Object.assign({}, this.state.group, { stationGroups: stationGroups })
            });
            let filter = { label: '', page: 0, pageSize: 10 };
            this.props.loadStationGroups(filter);
        }
    }

    render() {
        let authorized = rolesValidator.validate([GROUPS]);
        let rAuthorized = authorized.toString().includes(READ);
        let uAuthorized = authorized.toString().includes(UPDATE);
        let rolesData = this.props.roleData ? this.props.roleData : null;
        let cartadisUser = rolesData ? rolesData.userCartadis : null;
        const companyNames = [
            { key: 'cartadis' , value: 'cartadis', label: msg('group.companyName.cartadis') },
            { key: 'total' , value: 'total', label: msg('group.companyName.total') },
            { key: 'other' , value: 'other', label: msg('group.companyName.other')}
        ];

        let locations = this.state.locations;
        if (this.state.group.role && this.state.group.role.id) {
            let itemtemporaire = [];
            locations = [];
            if (locations.length === 0) {
                this.state.group.role.authorizations.forEach(function (item) {
                    itemtemporaire.push(item);
                });
                itemtemporaire.forEach(function (item) {
                    item.msgName = item.name ? msg('role.authorisation.name.' + item.name) : '';
                    locations.push(item);
                });
            }
        }
        locations.sort(this.sort);

        return rAuthorized && (
            <div className="card commonClass">
                <br />
                <div className="test p-col-12">
                    <h3 style={{ marginTop: '.5em' }}>{msg('group.identity')}</h3>
                </div>
                <div className="card card-w-title p-col-12">
                    <div className="p-grid form-group p-col-12">
                        {<div className="p-grid p-col-6">
                            <div className="p-grid p-col-3">
                                <div className="p-col-5"><label>{msg('group.fields.name')}</label></div>
                                <div className=""><span className="obligatoire">*</span></div>
                                <div className="p-col-1"></div>
                            </div>
                            <div className="p-col-9">
                                <span className="md-inputfield">
                                    <InputText value={this.state.group.name} onChange={e => this.setState({ isModifiable: false, group: Object.assign({}, this.state.group, { name: e.target.value }) })} 
                                        readOnly={this.state.group.role.technical} disabled={this.state.group.role.technical ? "disabled" : ""}  maxLength="250" size="60"/>
                                </span>
                                {this.state.isname && <Message severity="error" text={msg('group.input.name.required')} key="name" />}
                            </div>
                        </div>}
                        {cartadisUser && <div className="p-grid p-col-6">
                            <div className="p-grid p-col-5">
                                <div className="p-col-9"> <label>{msg('group.fields.companyName')}</label></div>
                                <div className=""><span className="obligatoire">*</span></div>
                            </div>
                            <div className="p-col-6">
                                <span className="md-inputfield">
                                    <Dropdown value={this.state.group.companyName} options={companyNames} optionLabel="label"
                                        onChange={e => this.setState({ isModifiable: false, group: Object.assign({}, this.state.group, { companyName: e.target.value }) })}
                                        placeholder="Selectionnez" style={{ minWidth: '60%' }} readOnly={this.state.group.role.technical} disabled={this.state.group.role.technical ? true : false}
                                    />
                                </span>
                                {this.state.isCompanyName && <Message severity="error" text={msg('group.input.companyNames.required')} key="companyNames" />}
                            </div>
                        </div>}
                    </div>
                    <div className="p-grid form-group p-col-12">
                        {<div className="p-grid p-col-6">
                            <div className="p-grid p-col-3">
                                <div className="p-col-8"> <label>{msg('group.fields.description')}</label></div>
                            </div>
                            <div className="p-col-9">
                                <span className="md-inputfield">
                                    <InputText value={this.state.group.description} onChange={e => this.setState({ isModifiable: false, group: Object.assign({}, this.state.group, { description: e.target.value }) })} 
                                        maxLength="250" size="90" readOnly={this.state.group.role.technical} disabled={this.state.group.role.technical ? "disabled" : ""}/>
                                </span>
                            </div>
                        </div>}
                    </div>
                </div>
                <br />
                <div className="test p-col-12">
                    <div className="p-grid">
                        <div className="p-col-2">
                            <h3 style={{ marginTop: '.5em' }}>{msg('group.role')}</h3>
                        </div>
                    </div>
                </div>
                <div className="card card-w-title p-col-12">
                    <br />
                    <div className="p-grid form-group p-col-12">
                        {rolesData && <div className="p-grid p-col-6">
                            <div className="p-grid p-col-3">
                                <div className="p-col-4"> <label>{msg('group.fields.role')}</label></div>
                                <div className=""><span className="obligatoire">*</span></div>
                            </div>
                            <div className="p-col-7">
                                <span className="md-inputfield md-inputfield-fill">
                                    <Dropdown value={this.state.group.role} options={rolesData.roles}
                                        onChange={e => this.setState({ isModifiable: false, group: Object.assign({}, this.state.group, { role: e.target.value }) })}
                                        optionLabel="name" dataKey="id" placeholder="Selectionnez" style={{ minWidth: '80%' }} 
                                        readOnly={this.state.group.role.technical} disabled={this.state.group.role.technical ? "disabled" : ""}/>
                                </span>
                                {this.state.isRoles && <Message severity="error" text={msg('group.input.role.required')} key="role" />}
                            </div>
                        </div>}
                        {!this.state.group.role.technical && <div className="p-grid p-col-6">
                            <div className="p-grid p-col-4">
                                <div className="p-col-12"> <label>{msg('group.fields.home.page')}</label></div>
                            </div>
                            <div className="p-col-8">
                                <span className="md-inputfield md-inputfield-fill">
                                    <Dropdown value={this.state.group.location} options={locations} itemTemplate={this.locationTemplate}
                                        onChange={e => this.setState({ isModifiable: false, group: Object.assign({}, this.state.group, { location: e.target.value }) })}
                                        optionLabel="msgName" placeholder={msg('group.home.page.all')} style={{ minWidth: '80%' }} />
                                </span>
                            </div>
                        </div>}
                    </div>
                </div>
                <br />
                <div className="test p-col-12">
                    <div className="p-grid">
                        <div className="p-col-2">
                            <h3 style={{ marginTop: '.5em' }}>{msg('group.station')}</h3>
                        </div>
                    </div>
                </div>
                <div className="card card-w-title p-col-12">
                    <br />
                    <div className="p-grid form-group p-col-12">
                        <div className="p-grid p-col-12">
                            <label htmlFor="acSimple">{msg('group.stations.group')}</label>
                            <div className="p-col-12" style={{ marginBottom: '10px' }}>
                                <span className="infos">{msg('group.stations.group.select.message')}</span>
                                <br /><br />
                                <AutoComplete minLength={0} placeholder={msg('group.stations.group.select.all')} id="stations" size={60} dropdown={true} multiple={true} field="name"
                                    suggestions={this.state.filteredStationGroups} value={this.state.group.stationGroups} delay={100}
                                    completeMethod={this.filterStationGroups} onDropdownClick={e => this.filterStationGroups(e, true)}
                                    onChange={e => this.setState({ filteredStationGroups: null, isModifiable: false, group: Object.assign({}, this.state.group, { stationGroups: e.target.value }) })}
                                    onUnselect={this.unselectStationGroup}
                                    readOnly={this.state.group.role.technical} disabled={this.state.group.role.technical ? "disabled" : ""} />
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <div className="p-grid p-col-12">
                    <div className="p-grid p-col-9">
                    </div>
                    <div className="p-grid p-col-3">
                        <Button label={(msg('group.validate'))} title={(msg('group.validate'))} onClick={this.doOnSave} className="button" 
                            disabled={this.state.group.role.technical || !uAuthorized} />
                        <Link to="/groups">
                            <Button  label={(msg('group.reset'))} className="button" />
                        </Link>
                    </div>
                </div>
                <MessageDialog className={'message-dialog error-message'} visible={this.state.isApiError} title={msg('message.dialog.error.title')}
                    message={msg(this.state.message, { name: this.state.group.name})}
                    onHide={() => this.setState({ isApiError: false, message: '' })} />
            </div >);
    }
}
export default UpdateGroup;