import { FIRMWARE, FIRMWARES, FIRMWARES_SUCCESS, FIRMWARE_DELETE_BY_ID, FIRMWARE_DELETE_BY_ID_SUCCESS, FIRMWARE_SUCCESS } from "../actions/firmwares";


const state0 = { data: null, error: null };

export const firmwaresReducer = (state = state0, action) => {
	switch (action.type) {
		case FIRMWARES:
			return {
				state,
				error: null
			};
		case FIRMWARES_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
			};
		default:
			return state;
	}
};

export const firmwareReducer = (state = state0, action) => {
	switch (action.type) {
		case FIRMWARE:
			return {
				state,
				error: null
			};
		case FIRMWARE_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
			};
		default:
			return state;
	}
};

export const firmwareDeleteByIdReducer = (state = state0, action) => {
	switch (action.type) {
		case FIRMWARE_DELETE_BY_ID:
			return {
				state,
				error: null
			};
		case FIRMWARE_DELETE_BY_ID_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
			};
		default:
			return state;
	}
};