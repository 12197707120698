import msg from "../texts/msg";

const CODE_CLIENT_MAX_LENGTH = 10;

export const validateInputText = function (values) {
  if (!values) {
    return false;
  }
  let t = values;
  if (!Array.isArray(t)) {
    t = [values];
  }
  if (t.length === 0) {
    return false;
  }
  let i = 0;
  for (i = 0; i < t.length; i++) {
    let s = t[i];
    if (!s || !s.toString().trim()) {
      return false;
    }
  }
  return true;
};

export const capitalizeFirstLetter = (s) => {
  const preposition = ["DE", "DES", "DU", "LA", "LES"];
  let r = "";
  if (s !== null && s !== undefined) {
    const list = s.split(" ");

    for (let i = 0; i < list.length; i++) {
      if (typeof list[i] === "number" || preposition.includes(list[i])) {
        r += list[i].toLowerCase();
      } else {
        r += list[i].charAt(0).toUpperCase() + list[i].slice(1).toLowerCase();
      }
      r += " ";
    }
  }
  return r;
};
export const updateInputNumberFormat = (c) => {
  let code = c.replace(/\s+/g, "");
  let l = code.length;
  let obj = {
    status: true,
    formattedCode: code,
  };
  if (l < CODE_CLIENT_MAX_LENGTH) {
    obj.formattedCode = String(code).padStart(CODE_CLIENT_MAX_LENGTH, "0");
  } else if (l > CODE_CLIENT_MAX_LENGTH) {
    // ^0 character enables to match the zero at the beginning of the string
    let v = code.replace(/^0+/, "");
    if (v.length < CODE_CLIENT_MAX_LENGTH) {
      obj.formattedCode = String(v).padStart(CODE_CLIENT_MAX_LENGTH, "0");
    } else if (v.length === CODE_CLIENT_MAX_LENGTH) {
      obj.formattedCode = v;
    } else {
      obj.status = false;
    }
  }
  return obj;
};
export const isValidateInputNumber = (c) => {
  //remove empty space from begin, end and between caracters in a number
  let code = c.replace(/\s+/g, "");
  if (code.length > CODE_CLIENT_MAX_LENGTH) {
    // ^0 character enables to match the zero at the beginning of the string
    let v = code.replace(/^0+/, "");
    if (v.length > CODE_CLIENT_MAX_LENGTH) return false;
  }
  return true;
};

export const splitElement = (e, separator) => {
  let words = e.toString().split(separator);
  if(words.length > 1){
    return words[0].trim();
  } 
  return "";
}

export const checkDuration = (str) => {
   let first = str.substring(0, str.indexOf(' '));
   return (first > 0 ? str : msg("transaction.usage.duration.indeterminate"));
}
