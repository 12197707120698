import { ofType} from 'redux-observable';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError} from  'rxjs/operators';
import {faillure } from '../actions';
import { AUTHORISATION, authorisationsSuccess, ADD_ROLE, addRoleSuccess, LOAD_ROLE, 
	loadRoleSuccess, ROLES, rolesSuccess, ROLE_DELETE_BY_ID, roleDeleteByIdSuccess, 
	ROLEUPDATE, roleSuccess, ROLE_UPDATE, roleUpdateSuccess} from '../actions/role';
import { authProviderType } from '../AuthProviderType';

export const rolesEpic = (epicAction)  => {
	return  epicAction.pipe(
			ofType(ROLES),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlRoles = apiRootUri + "roles";
				const req = ajax({
					  	url: urlRoles + "/roles?page=" + action.data.page+"&pageSize=" + action.data.pageSize,
					  	method: 'GET',				  
						headers: {
							'Content-Type': 'application/json'
						},
						withCredentials: true,
					});					
				return req.pipe(
            			map(res => {return rolesSuccess(res);}),
            			catchError(res => { return of(faillure(res));})
            		)
			}),
	);
};

export const authorisationsEpic = (epicAction)  => {
	return  epicAction.pipe(
			ofType(AUTHORISATION),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlRoles = apiRootUri + "roles";
				const req = ajax({
					  	url: urlRoles + "/role/authorisation",
					  	method: 'GET',				  
						headers: {
							'Content-Type': 'application/json'
						},
						withCredentials: true,
					});				
				return req.pipe(
            			map(res => {return authorisationsSuccess(res);}),
            			catchError(res => { return of(faillure(res));})
            		)
			}),
	);	
};

export const addRoleEpic = (epicAction)  => {
	return  epicAction.pipe(
			ofType(ADD_ROLE),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlRoles = apiRootUri + "roles";
				const req = ajax({
					  	url: urlRoles + "/role",
					  	method: 'POST',				  
						headers: {
							'Content-Type': 'application/json'
						},
						withCredentials: true,
						body: action.data.role
					});
					
				return req.pipe(
            			map(res => {
							return addRoleSuccess(res);}),
            			catchError(res => { return of(faillure(res));})
            		)
			}),
	);
	
};

export const loadRoleEpic = (epicAction) => {
	return  epicAction.pipe(
			ofType(LOAD_ROLE),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlRoles = apiRootUri + "roles";
				const req = ajax({
						url: urlRoles+ "/roles/role/",
						method: 'GET',
						withCredentials: true,
					  });
				return req.pipe(
            			map(res => { return loadRoleSuccess(res); }),
            			catchError(res => { return of(faillure(res));})
            		)
			}),
	);	
};

export const roleDeleteByIdEpic = (epicAction)  => {
	return  epicAction.pipe(
			ofType(ROLE_DELETE_BY_ID),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlRoles = apiRootUri + "roles";
				const req = ajax({
					url: urlRoles + "/" +action.data.roleId,
					method: 'DELETE',					  
					headers: {
					  'Content-Type': 'application/json'
				  },
				  withCredentials: true
			 	});
				return req.pipe(
					map(res => {return roleDeleteByIdSuccess(res);}),
					catchError(res => { return of(faillure(res));})
				)
			}),
	);	

};

export const roleEpic = (epicAction)  => {
	return  epicAction.pipe(
			ofType(ROLEUPDATE),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlRoles = apiRootUri + "roles";
				const req = ajax({
					  	url: urlRoles + "/" + action.data.roleId,
					  	method: 'GET',				  
						headers: {
							'Content-Type': 'application/json'
						},
						withCredentials: true
					});				
				return req.pipe(
            			map(res => {return roleSuccess(res);}),
            			catchError(res => { return of(faillure(res));})
            		)
			}),
	);	
};

export const roleUpdateEpic = (epicAction)  => {
	return  epicAction.pipe(
			ofType(ROLE_UPDATE),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlRoles = apiRootUri + "roles";
				const req = ajax({
						url: urlRoles + "/" + action.data.roleId,
					  	method: 'PUT',
					  	headers: {
							'Content-Type': 'application/json'
						},
						withCredentials: true,
					  	body: action.data.role
					});
				return req.pipe(
            			map(res => { return roleUpdateSuccess(res);}),
            			catchError(res => { return of(faillure(res));})
            		)
			}),
	);
};