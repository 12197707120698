import  i18n  from '../i18n';

let calendarLocale = {
	FR: { 
		firstDayOfWeek: 1, 
		dayNames: ["dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi"], 
		dayNamesShort: ["dim.", "lun.", "mar.", "mer.", "jeu.", "ven.", "sam."], 
		dayNamesMin: ["di","lu","ma","me","je","ve","sa"], 
		monthNames: ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"], 
		monthNamesShort: ["janv.", "févr.", "mars", "avr.", "mai", "juin","juil.", "août", "sept.", "oct.", "nov.", "déc."], 
		today: 'Aujourd\'hui'
	},

	EN: { 
		firstDayOfWeek: 0, 
		dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"], 
		dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"], 
		dayNamesMin: ["Su","Mo","Tu","We","Th","Fr","Sa"], 
		monthNames: [ "January","February","March","April","May","June","July","August","September","October","November","December" ], 
		monthNamesShort: [ "Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ], 
		today: 'Today', clear: 'Clear', weekHeader: 'Wk' 
	}
};

export const getCalendarLocale = () => {
	return i18n.language && i18n.language.toUpperCase().startsWith('EN')  ?  calendarLocale.EN : calendarLocale.FR;
};