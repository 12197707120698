export const EMAIL_PATTERN = /^\w+([.'-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;
export const PASSWORD_PATTERNS =[ /.*[a-z].*/, /.*[A-Z].*/, /.*[0-9].*/, /.*[^a-zA-Z0-9].*/ ];
export const TIME_PATTERN0 = /^[0-2]?[0-9]?:?[0-6]?[0-9]?$/;
export const TIME_PATTERN = /^[0-2]{1}[0-9]{1}:[0-6]{1}[0-9]{1}$/;
export const GPS_LATITUDE = /^(\+|-)?(?:90(?:(?:\.0{1,20})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,20})?))$/;
export const GPS_LONGITUDE = /^(\+|-)?(?:180(?:(?:\.0{1,20})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,20})?))$/;
export const TEXT = /^[a-zA-Z0-9? !@#$&()'_{}éèàç\\-`.+,/"]*$/;
export const IP_PATTERN = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
export const NUMBER_PATTERN = /^\d+$/;
export const EMAIL_VALID_TEXT = /^[a-zA-Z0-9 _éèàç\\-]*$/;
export const DATE_PATTERN = /^\d\d\/\d\d\/\d\d\d\d$/;
export const SPECIALCARACTER_PATTERN = /[^a-zA-Z0-9\s ]/g;