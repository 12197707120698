import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Dashboard from './Dashboard';
import Analysis from './Analysis';
import { dataTitlePanel } from '../../common/dataTitlePanel';
import { rolesValidator, MAINTENANCE } from '../../../utils/RolesValidator';
import '../../common/css/activity-analysis.css';
import '../../common/css/activity-analysis-xsm.css';
import '../../common/css/activity-analysis-sm.css';
import '../../common/css/activity-analysis-md.css';
import '../../common/css/activity-analysis-lg.css';
import '../../common/css/activity-analysis-xlg.css';
import '../../common/css/activity-analysis-xxlg.css';

 class Maintenance extends Component {

    constructor(props) {
        super(props);
        this.state = {submenu: this.props.match.params.submenu};
        this.loadDashboard = this.loadDashboard.bind(this);
        this.loadAnalysis = this.loadAnalysis.bind(this);
        this.loadFilter = this.loadFilter.bind(this);
        this.loadFilterStations = this.loadFilterStations.bind(this);
    }
    
    componentDidUpdate(prevProps) {
        if(this.props.match.params.submenu !== prevProps.match.params.submenu){
            this.setState({submenu: this.props.match.params.submenu});
        }
    }

    loadFilter(filter) {
        this.props.loadFilter(MAINTENANCE, filter);
    }
    
    loadFilterStations(filter) {
    	this.props.loadFilterStations(MAINTENANCE, filter);
    }

    loadDashboard() {
        let filter = {};
        this.props.loadBornesStatus(filter);
        this.props.loadStationsMap(MAINTENANCE, filter);
        this.props.setDataInfoBar(dataTitlePanel(null));
    }

    loadAnalysis(filter) {
        this.props.loadBornesAnalysis(filter);
        this.props.setDataInfoBar(dataTitlePanel(filter));
    }

    render() {
        let authorized = rolesValidator.validate([MAINTENANCE]);
        let bornesStatus = this.props.bornesStatusData ? this.props.bornesStatusData : 
                                {refrechTime: new Date(), errors: 0, majorWarnings: 0, minorWarnings: 0, runnings: 0};
        let dashboard = <Dashboard data={bornesStatus} stationsMap={this.props.stationsMapData} load={this.loadDashboard} />;
        let maintenanceItem = '';
        switch(this.state.submenu) {
            case 'dashboard' : {     
                maintenanceItem = dashboard;
                break;
            }
            case 'analysis' : {     
                maintenanceItem = <Analysis load={this.loadAnalysis} loadFilter={this.loadFilter} loadFilterStations={this.loadFilterStations}
                    data={this.props.bornesAnalysisData} filterData={this.props.filterData} filterStations={this.props.filterStations} />;
                break;
            }
            default : {
                maintenanceItem = dashboard;
            }
        }
        let maintenancePage = (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card">
                        {maintenanceItem}
                    </div>
                </div>
            </div>
        );
        return authorized && maintenancePage;
    }
    
}

export default withRouter(Maintenance);