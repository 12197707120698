import React, { Component } from 'react';
import { COMMISSIONS, rolesValidator, READ } from "../../../utils/RolesValidator";
import { CONSULT_MODE, CREATION_MODE, LIST_MODE } from "./CommissionRenderModes";
import CommissionList from './CommissionList';
import CommissionForm from './CommissionForm';

class Commission extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submenu: this.props.match.params.submenu,
            buttonSelectd : true,
            renderMode: LIST_MODE
        }

        this.create = this.create.bind(this);
        this.consult = this.consult.bind(this);        
        this.validate = this.validate.bind(this);
        this.cancel = this.cancel.bind(this);
        this.delete = this.delete.bind(this);
        this.loadCommissions = this.loadCommissions.bind(this);
        this.loadCommission = this.loadCommission.bind(this);
        this.renderList = this.renderList.bind(this);
    }

    componentDidMount() {
        this.props.setDataInfoBar(null);
    }

    componentDidUpdate(prevProps) {
        if(this.props.match.params.submenu !== prevProps.match.params.submenu) {
            this.setState({submenu: this.props.match.params.submenu});
            if(this.props.match.params.submenu === 'list') {
                this.setState({renderMode: LIST_MODE});
            }
        }
    }

    create() {
        this.setState({renderMode: CREATION_MODE});
        this.props.history.push('/commissions/form');
    }

    consult(commissionId) {
        this.setState({renderMode: CONSULT_MODE, commissionId: commissionId});
        this.props.history.push('/commissions/form');
    }

    validate(commission) {
        if(this.state.renderMode === CREATION_MODE) {
            this.props.createCommission(commission);
        } else if (this.state.renderMode === CONSULT_MODE) {
            this.props.updateCommission(commission);
        }
    }

    cancel() {
        this.setState({renderMode: LIST_MODE});
    }

    loadCommission(commissionId) {
        this.props.loadCommission(commissionId);
    }

    loadCommissions(page , pageSize) {
        this.props.loadCommissions(page, pageSize);
    }

    delete(commissionId) {
        this.props.deleteCommission(commissionId);
        this.setState({renderMode: LIST_MODE});
    }

    renderList() {
        this.setState({renderMode: LIST_MODE});
    }

    getLastChange() {
        let lastChange0 = this.props.createCommissionData && this.props.createCommissionData.data ? this.props.createCommissionData.data.lastChange : null;
        let lastChange1 = this.props.updateCommissionData && this.props.updateCommissionData.data && this.props.updateCommissionData.data.lastChange && 
                    (this.props.updateCommissionData.data.lastChange > lastChange0) ? this.props.updateCommissionData.data.lastChange : lastChange0;
        let lastChange2 = this.props.deleteCommissionData && this.props.deleteCommissionData.data && this.props.deleteCommissionData.data.lastChange && 
                    (this.props.deleteCommissionData.data.lastChange > lastChange1) ? this.props.deleteCommissionData.data.lastChange : lastChange1;
        return lastChange2;
    }

    render() {
        let authorized = rolesValidator.validate([COMMISSIONS]);
        let rAuthorized = authorized.toString().includes(READ);
        let commissions = this.props.loadCommissionsData && this.props.loadCommissionsData.data ? this.props.loadCommissionsData.data : [];
        let commission = this.props.loadCommissionData && this.props.loadCommissionData.data ? this.props.loadCommissionData.data : null; 
        let lastChange = this.getLastChange();
        let commissionMode = <CommissionList create={this.create} consult={this.consult}
                                load={this.loadCommissions}  delete={this.delete}
                                    data={commissions} lastChange={lastChange} error={this.props.error} />;
        let formModes = [CREATION_MODE, CONSULT_MODE];
        if(this.state.renderMode && formModes.includes(this.state.renderMode)){            
            let lcommissionId = this.state.renderMode === CREATION_MODE ? null : this.state.commissionId;
            let lcommission = this.state.renderMode === CREATION_MODE ? null : commission;
            commissionMode = <CommissionForm  validate={this.validate} cancel={this.cancel}
                                    mode={this.state.renderMode}  commissionId={lcommissionId}
                                    loadCommission={this.loadCommission} commission={lcommission}
                                    error={this.props.error} lastChange={lastChange} renderList={this.renderList} />;
        }
        return rAuthorized && commissionMode;
    }
}

export default Commission;