import React, { Component } from 'react';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import { AutoComplete } from 'primereact/autocomplete';
import { userSessionParamsHandler } from '../../utils/UserSessionParamsHandler.js';
import msg from '../../texts/msg';
import {getYesterdayDate} from '../../utils/dateTimeUtils';
import GetVisibilityIcon from '@material-ui/icons/Visibility';
import UploadService from "../../middleware/download-upload-files.service";

const emtyChar = "";
class ReportingExploitantFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isStationsListEmpty: false,
            isFirstInitAchieved: false,
            initImplantsFilter : [],
            initSapCodesFilter : [],
            initBatchNumberFilter : [],
            filtredStations: [],
            filtredExploitants: [],
            filtredBatchNumbers: [],
            stationsPage: 0,
            stationsPageSize: 50,
            exploitant: {
                date: getYesterdayDate(new Date()),
                implant: emtyChar,
                name:  emtyChar,
                sapCode : emtyChar,
                batchNumber: emtyChar,
                validData: false
            },
            imlantQuery: '',
            sapCodeQuery: '',
            feature: '',
        }
        this.apply = this.apply.bind(this);
        this.reset = this.reset.bind(this);
        this.handleDateSelect = this.handleDateSelect.bind(this);
        this.fieldsControl = this.fieldsControl.bind(this);
        this.handleConsultFileClick = this.handleConsultFileClick.bind(this);
        this.filterStationsWithContractsBySapCode = this.filterStationsWithContractsBySapCode.bind(this);
        this.filterStationsWithContractsByImplant = this.filterStationsWithContractsByImplant.bind(this);
        this.filterStationsWithContractsByBatchNumber = this.filterStationsWithContractsByBatchNumber.bind(this);
        this.handleImplantChange = this.handleImplantChange.bind(this);
        this.handleImplantSelect = this.handleImplantSelect.bind(this);        
        this.handleSapCodeChange = this.handleSapCodeChange.bind(this);
        this.handleSapCodeSelect = this.handleSapCodeSelect.bind(this);        
        this.handleBatchNumberChange = this.handleBatchNumberChange.bind(this);
        this.handleBatchNumberSelect = this.handleBatchNumberSelect.bind(this);        
    }

    componentDidMount() {
         let filter = this.buildFilter();
         this.props.initDataInfoBarReport(filter);
         let stationFilter = {
                date: new Date(this.state.exploitant.date).setHours(1),
                label: emtyChar,
                feature: 'implant',
                page: this.state.stationsPage,
                pageSize: this.state.stationsPageSize
            };
          this.props.loadFilterStationsWithContracts(stationFilter);
          this.setState({ isFirstInitAchieved: true, feature: stationFilter.feature});
    }

    componentDidUpdate(prevProps) {
        let prevChange = prevProps.filterStationsWithContracts ? prevProps.filterStationsWithContracts.lastChange : null;
        let lastChange = this.props.filterStationsWithContracts ? this.props.filterStationsWithContracts.lastChange : null;
        let change = (lastChange && (!prevChange || prevChange < lastChange))
        if (change) {
            let filtredStations = []; 
            let filtredExploitants = []; 
            let filtredBatchNumbers = [];
            let filtredStationsMap = {};
            this.props.filterStationsWithContracts.stations.forEach(s => {
                let ss = filtredStationsMap[s.implant];
                if(ss){
                    ss.batchNumbers.push(s.batchNumber);
                } else {
                    ss = {
                        date: this.state.exploitant.date,
                        implant: s.implant,
                        name: s.implant + ' (' + s.name + ')',
                        sapCode: s.contract.sapCode,
                        batchNumbers: [s.batchNumber]
                    };
                    filtredStationsMap[s.implant] = ss;
                    filtredStations.push(ss);
                }
            });
            if(this.state.exploitant.implant && this.state.exploitant.sapCode){
                filtredStations.filter(e => e.sapCode === this.state.exploitant.sapCode && e.implant === this.state.exploitant.implant)                
                    .forEach(e => e.batchNumbers.forEach(b => filtredBatchNumbers.push(b)) );                   
            }
            filtredStations.forEach(e => {if(!filtredExploitants.includes(e.sapCode)) {filtredExploitants.push(e.sapCode)}});
            this.setState({filtredStations: filtredStations, filtredExploitants: filtredExploitants, filtredBatchNumbers: filtredBatchNumbers});
            this.setState({ exploitant: Object.assign({}, this.state.exploitant, {batchNumber: filtredBatchNumbers.length > 0 ? filtredBatchNumbers[0] : emtyChar})});
        }
    } 

    handleSapCodeChange(event){
        this.setState({ exploitant: Object.assign({}, this.state.exploitant, {sapCode: event.value})});
    }

    handleSapCodeSelect(event){
        let value = event.value;
        let filtredBatchNumbers = [];        
        this.state.filtredStations.filter(e => e.sapCode === value).forEach(e => e.batchNumbers.forEach(b => filtredBatchNumbers.push(b)));
        let batchNumber =  filtredBatchNumbers.length > 0 ? filtredBatchNumbers[0] : this.state.exploitant.batchNumber;
        let implant = this.state.exploitant && this.state.exploitant.implant ? this.state.exploitant.implant : ''
        let name = this.state.exploitant && this.state.exploitant.implant ? this.state.exploitant.implant : ''
        if(!implant){
            let implants = this.state.filtredStations.filter(e => e.sapCode === value)
            if(implants.length > 0){
                implant = implants[0].implant;
                name = implants[0].implant + ' (' + implants[0].name + ')';
            }
        }
        this.setState({ exploitant: Object.assign({}, this.state.exploitant, {sapCode: value, implant : implant, name: name, batchNumber: batchNumber}), filtredBatchNumbers: filtredBatchNumbers });
    }
    
    handleImplantChange(event){
        this.setState({ exploitant: Object.assign({}, this.state.exploitant, {implant: event.value})});
    }

    handleImplantSelect(event){
        let value = event.value;
        let filtredBatchNumbers = [];        
        this.state.filtredStations.filter(e => e.sapCode === value.sapCode && e.implant === value.implant).forEach(e => e.batchNumbers.forEach(b => filtredBatchNumbers.push(b)));
        let batchNumber =  filtredBatchNumbers.length > 0 ? filtredBatchNumbers[0] : this.state.exploitant.batchNumber;
        this.setState({ exploitant: Object.assign({}, this.state.exploitant, value, {batchNumber: batchNumber}), filtredBatchNumbers: filtredBatchNumbers });
    }

    handleBatchNumberChange(event){
        let value = event.value;
        this.setState({ exploitant: Object.assign({}, this.state.exploitant, {batchNumber: value}) });
    }
    
    handleBatchNumberSelect(event){
        let value = event.value;
        this.setState({ exploitant: Object.assign({}, this.state.exploitant, {batchNumber: value}) });
    }

    filterStationsWithContractsBySapCode(event, isDropdownClick) {
        this.filterStationsWithContractsQuery(event, isDropdownClick, "sapCode");
    }

    filterStationsWithContractsByImplant(event, isDropdownClick) {
        this.filterStationsWithContractsQuery(event, isDropdownClick, "implant");
    }

    filterStationsWithContractsQuery(event, isDropdownClick, feature){
        let query = event.query.toUpperCase();
        let savedQuery = feature === "sapCode" ? this.state.sapCodeQuery : this.state.implantQuery
        if (!isDropdownClick) {
            if (!query || query === savedQuery) {
                return;
            }
        } else if (query && (query === savedQuery)) {
            return;
        }
        let label = isDropdownClick ? savedQuery : query;
        let filter = {
            label: label,
            feature: feature,
            implant: feature === "implant" ? label : this.state.exploitant.implant,
            sapCode: feature === "sapCode" ? label : this.state.exploitant.sapCode,
            date: this.state.exploitant.date,
            page: this.state.stationsPage,
            pageSize: this.state.stationsPageSize
        };
        this.props.loadFilterStationsWithContracts(filter);
        if(feature === "sapCode"){
            this.setState({ sapCodeQuery: query});
        } else {
            this.setState({ implantQuery: query});
        }
        this.setState({feature: filter.feature });
    }

    filterStationsWithContractsByBatchNumber(event) {
        let filtredBatchNumbers = [];
        if(this.state.exploitant){
            this.state.filtredStations.filter(e => e.sapCode === this.state.exploitant.sapCode && e.implant === this.state.exploitant.implant)
                .forEach(e => e.batchNumbers.forEach(b => filtredBatchNumbers.push(b)));  
            this.setState({filtredBatchNumbers: filtredBatchNumbers });
        }
    }

    handleDateSelect(e) {
        if(e.value){
            let stationFilter = {
                date : new Date(e.value).setHours(1),
                label: emtyChar,
                feature: emtyChar,
                implant: this.state.exploitant.implant,
                sapCode: this.state.exploitant.sapCode,
                page: this.state.stationsPage,
                pageSize: this.state.stationsPageSize
            };
            if(this.state.exploitant.implant) {
                stationFilter.label = this.state.exploitant.implant;
                stationFilter.feature = "implant";
            } else if(this.state.exploitant.sapCode) {
                stationFilter.label = this.state.exploitant.sapCode;
                stationFilter.feature = "sapCode";
            }
            if(stationFilter.feature) {
                this.props.loadFilterStationsWithContracts(stationFilter);
            }
            this.setState({exploitant : Object.assign({}, this.state.exploitant, {date: e.value, batchNumber: emtyChar}), filtredBatchNumbers: []});
       }
    }

    implantTemplate(item) {
        return item.name;
    }

    fieldsControl(){
     let valid = true;
     if(this.state.exploitant.date){
        this.setState({ isDateRequired: false });
     } else {
        this.setState({ isDateRequired: true });
        valid = false;
     }
     if((this.state.exploitant.sapCode && (this.state.exploitant.sapCode.length > 0)) || (this.state.exploitant.implant && (this.state.exploitant.implant.length > 0))){
        this.setState({ isSapCodeOrImplantRequired: false});
     } else {
        this.setState({ isSapCodeOrImplantRequired: true});
        valid = false;
     }
    
     this.props.updateIsSapCodeOrImplantRequired(!valid);
     this.setState({exploitant : Object.assign({}, this.state.exploitant, {validData: valid})})
      return valid;     
    }

    apply() {
        let filter = this.buildFilter();
        this.props.initDataInfoBarReport(filter);
         if(this.fieldsControl()){
            this.props.apply(filter);
            userSessionParamsHandler.fireFilterChange(filter);
         }
    }

    reset() {
        this.setState({exploitant: Object.assign({}, this.state.exploitant, {date : getYesterdayDate(new Date()), 
        implant : emtyChar, name : emtyChar, sapCode : emtyChar, batchNumber : emtyChar, validData : false}), isDateRequired : false, 
        isSapCodeOrImplantRequired : false})
        let filter = {
            implant : msg("common.undefined.status"),
            date: getYesterdayDate(new Date()),
            sapCode : msg("common.undefined.status"),
            batchNumber :  msg("common.undefined.status")
        };
        this.props.initDataInfoBarReport(filter);
        this.setState({filtredStations: [], filtredBatchNumbers: [], isStationsListEmpty : false});
        userSessionParamsHandler.fireFilterChange(filter);
        this.props.updateIsSapCodeOrImplantRequired(false);
    }

    buildFilter() {
        let filter = {};
            filter.filename = msg('report.exploitant.filename') + '.pdf';
            filter.implant = (this.state.exploitant.implant && this.state.exploitant.implant.length !== 0) ? this.state.exploitant.implant: emtyChar;
            filter.date = this.state.exploitant.date ? this.state.exploitant.date: getYesterdayDate(new Date());
            filter.sapCode = (this.state.exploitant.sapCode && this.state.exploitant.sapCode.length !== 0) ? this.state.exploitant.sapCode.toString() : emtyChar;
            filter.batchNumber = (this.state.exploitant.batchNumber && this.state.exploitant.batchNumber.length !== 0) ? this.state.exploitant.batchNumber.toString() : emtyChar;
        return filter; 
    }

    handleConsultFileClick(v) {
        let filter = {
            path: v.value
        };
        UploadService.getFileDataForConsult("reporting/exploitant-report", filter.filename, filter);
    }

    render() {
        let applyLabel = this.props.applyLabel ? this.props.applyLabel : msg('filter.apply');
        let resetLabel = this.props.resetLabel ? this.props.resetLabel : msg('filter.reset');
        return (
            <div className="p-grid p-col-12 filter">
                <div className="p-card p-col" style={{ marginLeft: "10px", height: "300px" }}>
                    <div className="p-grid p-justify-between p-col">
                        <div className="p-col-3"> <label>{msg('report.exploitant.date')}</label><span className="obligatoire">*</span></div>
                        <div className="p-col">
                            {<Calendar dateFormat='dd/mm/yy'
                            onChange={(e) => this.handleDateSelect(e)}
                            selectOtherMonths={false} value={this.state.exploitant.date}
                            readOnlyInput={true}
                            />}
                            {this.state.isDateRequired && <Message severity="error" text={msg('report.exploitant.date.required')} key="name" />}
                        </div>
                        <div className="p-col-3"></div>
                    </div>
                    <div className="p-grid p-justify-between p-col">
                            <div className="p-col-3">{msg('report.exploitant.codeclient')}</div>
                            <AutoComplete className= "p-col" placeholder={msg('filter.parameter.selection')}
                            minLength={0} delay={250} id="spaCodes" dropdown={true} multiple={false}
                            value={this.state.exploitant.sapCode}
                            suggestions={this.state.filtredExploitants}
                            completeMethod={this.filterStationsWithContractsBySapCode} onDropdownClick={e => this.filterStationsWithContractsBySapCode(e, true)}
                            onChange={this.handleSapCodeChange} onSelect={this.handleSapCodeSelect} 
                        /> 
                        <div className="p-col-3"></div> 
                    </div>
                    <div className="p-grid p-justify-between p-col">
                        <div className="p-col-3">{msg('report.exploitant.implant')}</div>
                        <AutoComplete className= "p-col" placeholder={msg('filter.parameter.selection')} 
                            minLength={0} delay={250} id="implants" dropdown={true} multiple={false}
                            field="implant"
                            value={this.state.exploitant}
                            itemTemplate={this.implantTemplate}
                            suggestions={this.state.filtredStations}                                     
                            completeMethod={this.filterStationsWithContractsByImplant} onDropdownClick={e => this.filterStationsWithContractsByImplant(e, true)}
                            onChange={this.handleImplantChange} onSelect={this.handleImplantSelect}                                
                        />
                        <div className="p-col-3"></div>
                    </div>
                    <div className="p-grid p-justify-between p-col">
                        <div className="p-col-3">{msg('report.exploitant.batchNumber')}</div>
                        <AutoComplete className= "p-col" placeholder={msg('filter.parameter.selection')}
                            minLength={0} delay={0} id="batchNumbers" dropdown={true} multiple={false}
                            value={this.state.exploitant.batchNumber}
                            suggestions={this.state.filtredBatchNumbers}
                            completeMethod={this.filterStationsWithContractsByBatchNumber}
                            onChange={this.handleBatchNumberChange} onSelect={this.handleBatchNumberSelect}
                            />
                        <div className="p-col-3"></div>
                    </div>
                    <div className="p-grid p-col">
                        <div className={" p-col-6 apply-btn"}>
                            <Button label={applyLabel} title={applyLabel} onClick={this.apply} key={applyLabel} />
                        </div>
                        <div className={"p-col-6 reset-btn"} >
                            <Button label={resetLabel} title={resetLabel} onClick={this.reset} key={resetLabel} />
                        </div>
                    </div>
                    { this.state.isStationsListEmpty && <div className="p-col-12">
                            <Message severity="error" text={msg('report.exploitant.filter.no.data')} key="name" />
                    </div>}
                </div>
                <div className={"p-card p-col"} style={{ marginLeft: "10px", height: "300px" , overflowY : "auto"}}>
                    <h2>{msg('report.exploitant.daily')}</h2>
                    {this.props.exploitantReport && this.state.exploitant.validData && (
                    <div className={"p-col-12"}>
                        {this.props.exploitantReport.filesData.length > 0 && 
                            this.props.exploitantReport.filesData.map((r) => {
                        return <div key = {r.key} className="p-grid">
                        <div className="p-col-12 p-md-9"> <span>{r.label}</span></div>
                            <div className="p-col-12 p-md-3" style={{ marginTop: "-10px", textAlign: 'right'}}>
                                    <GetVisibilityIcon  fontSize="small" style={{ marginTop: "9px", marginBottom: '-4px', color: "#1565C0", cursor: "pointer" }}
                                    onClick={() => this.handleConsultFileClick(r)} />
                                    {<label style={{ fontStyle: 'italic', marginLeft: '5px', color: "#1565C0", cursor: "pointer" }}
                                    onClick={() => this.handleConsultFileClick(r)}>
                                {msg('report.exploitant.visualize')}</label>}
                            </div>
                        </div>})}
                        {this.props.exploitantReport.filesData.length === 0 && 
                        <div className="p-col-12"  >
                            { <label style={{ fontStyle: 'italic'}} >{msg('report.exploitant.notfound')}</label>} 
                        </div>
                        } 
                    </div> )}
                    {(!this.props.exploitantReport || !this.state.exploitant.validData) && 
                    <div className="p-col-12">
                        <div className="p-col-12">
                        { <label style={{ fontStyle: 'italic'}} >{msg('report.exploitant.empty.list')}</label>} 
                        </div>
                    </div>} 
                </div> 
         </div>);
    }
}

export default  ReportingExploitantFilter;