import React, { Component } from 'react'
import { InputText } from 'primereact/inputtext';
import { ProgressBar } from 'primereact/progressbar';
import { Button } from 'primereact/button';
import UploadService from "../../../middleware/download-upload-files.service"
import MessageDialog from '../../common/MessageDialog';
import { FIRMWARE, rolesValidator, UPDATE, READ } from '../../../utils/RolesValidator';
import msg from '../../../texts/msg';
import '../../common/css/common.css';
import { Link } from 'react-router-dom';

export class UpdateFirmware extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedFiles: undefined,
            currentFile: undefined,
            progress: 0,
            message: "",
            fileInfos: [],
            name: '',
            description: '',
            isNotFile: false,
            isNotype: false,
            isDone: false,
            isPatternError: false,
            borneType: '',
            isNameValid: false,
            sessionExpired: false,
            isModifiable: true,
            isInitialized: false,
            isConfig: false
        };
    }

    componentDidMount() {
        this.props.setDataInfoBar([{}]);
    }

    onFileChange = event => {
        this.setState({ selectedFile: event.files[0] });
        this.setState({ name: event.files[0].name });
        this.setState({ progress: 0 });
        let name = event.files[0].name ? event.files[0].name.toUpperCase().split('_') : "";
        if (name.length > 1 && (name.includes('TL16') || name.includes('TL17') || name.includes('TL18') || name.includes('TK2'))) {
            var yourRegex = /^[0-9]+$/g
            var atLeastOneMatches = name[1].split('.').some(e => yourRegex.test(e));
            if (atLeastOneMatches) {
                this.setState({ isNameValid: false });
            }
        } else {
            this.setState({ isNameValid: true });
            this.setState({ name: event.files[0].name });
        }
    };

    onFileUpload = () => {
        let valid = true;
        let currentFile = this.state.selectedFile;
        this.setState({
            progress: 0,
            currentFile: currentFile,
        });    
        if (valid)
            UploadService.upload(currentFile, this.state.description, null, this.state.firmwareId, (event) => {
                this.setState({ progress: Math.round((100 * event.loaded) / event.total), });
            }).then((response) => {
                this.setState({ currentFile: undefined, description: '' });
                this.setState({ isDone: true });
                this.setState({ progress: 0, currentFile: null });
                this.props.history.push('/firmwares');
            }).catch((error) => {
                if (error.response.status === "401" || error.response.status === 401) {
                    this.setState({ sessionExpired: true });
                } else {
                    this.setState({ isError: true, isPatternError: true, message: error.response.data.message });
                    this.setState({ progress: 0, currentFile: null });
                }
            });
    };


    handleErrorChange(e) {
        this.setState({ isDone: false, isError: false, isNameValid: false, sessionExpired: false, selectedFile: null, message: null })
        this.setState({ currentFile: undefined });
    }

    handleErrorExpiredSessionChange(e) {
        this.setState({ isDone: false, isError: false, isNameValid: false, sessionExpired: false, selectedFile: null, message: null })
        this.setState({ currentFile: undefined });
        this.props.history.push('/');
        this.props.history.push('/firmwares');
    }

    componentDidUpdate(prevProps) {
        if (!this.state.isInitialized && this.state.isModifiable && this.props.firmware && this.props.firmware.id) {
            this.setState({
                description: this.props.firmware.description,
                firmwareId: this.props.firmware.id,
                firmwareName: this.props.firmware.name,
                isInitialized: true,
                isConfig: false
            });
        }
        let prevError = prevProps.error && prevProps.error.timestamp;
        let lastError = this.props.error && this.props.error.timestamp;
        let error = (lastError && (!prevError || prevError < lastError));
        if (error) {
            let isError = this.props.error.message && this.props.error.message.startsWith('error.config.borne') ? true : false;
            this.setState({ isApiError: isError });
        }
    }
  
    render() {
        const {
            currentFile,
            progress,
        } = this.state;

        let authorized = rolesValidator.validate([FIRMWARE]);
        let rAuthorized = authorized.toString().includes(READ);
        let uAuthorized = authorized.toString().includes(UPDATE);
        return rAuthorized && (
            <div className="commonClass roleClass">               
                <div className="test p-grid p-col-12" style={{ marginLeft: '1px' }}>
                    <div className="p-grid p-col-6">
                        <h4 className="p-col-4" style={{ marginTop: '-.2em' }}>{msg('firmware.fields.title.update')}</h4>
                        <h4 className="p-col-2" style={{ marginTop: '-.2em' }}>{this.state.firmwareName}</h4>
                    </div>                 
                </div>
                {currentFile && (<div className="test p-col-12">
                    <ProgressBar value={progress}></ProgressBar>
                </div>)}
                <div className="card card-w-title p-col-12">
                    <div className="p-grid form-group p-col-12">
                        <div className="p-grid form-group p-col-12">
                            
                            {<div className="p-grid p-col-6">
                                <div className="p-grid p-col-3">
                                    <div className="p-col-6"> <label>{msg('firmware.fields.description')}</label></div>
                                </div>
                                <div className="p-col-9">
                                    <span className="md-inputfield">
                                        <InputText className="inputClass" value={this.state.description}
                                            onChange={e => this.setState({ isModifiable: false, description: e.target.value })}
                                            placeholder="Description" type="text" size="60" disabled={this.state.isConfig} />
                                    </span>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
                <br></br>
                <br />
                <div className="p-grid p-col-12">
                    <div className="p-grid p-col-9">
                    </div>
                    <div className="p-grid p-col-3">
                        <Button label={(msg('user.validate'))} title={(msg('user.validate'))}
                            onClick={this.onFileUpload} disabled={this.state.isConfig || !uAuthorized}
                            className="button" />
                        <Link to="/firmwares">
                            <Button  label={(msg('user.reset'))} className="button" />
                        </Link>
                    </div>
                </div>
                <br />
                <div className="alert alert-light" role="alert">
                    <MessageDialog className={'message-dialog error-message'} visible={this.state.isError} title={msg('firmware.fields.upload.title.error')}
                        message={this.state.message}
                        onHide={e => this.handleErrorChange(e)} onCancel={e => this.handleErrorChange(e)} />
                    <MessageDialog className={'message-dialog error-message'} visible={this.state.isDone} title={msg('firmware.fields.upload.title.done')}
                        message={this.state.isDone && msg("firmware.fields.upload.done")}
                        onHide={e => this.handleErrorChange(e)} onCancel={e => this.handleErrorChange(e)} />
                    <MessageDialog className={'message-dialog error-message'} visible={this.state.isNameValid} title={msg('firmware.fields.upload.title.error')}
                        message={msg('firmware.file.upload.format')}
                        onHide={e => this.handleErrorChange(e)}
                        onCancel={e => this.handleErrorChange(e)} />
                    <MessageDialog className={'message-dialog error-message'} visible={this.state.sessionExpired} title={msg('firmware.fields.upload.title.error')}
                        message={msg("login.unauthorized.internalauthenticationserviceexception")}
                        onHide={e => this.handleErrorExpiredSessionChange(e)} onCancel={e => this.handleErrorExpiredSessionChange(e)} />
                </div>
                <br />
            </div>);
    }

}

export default UpdateFirmware;