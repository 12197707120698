export const COMMERCE_DASHBORD = 'COMMERCE_DASHBORD';
export const COMMERCE_DASHBORD_SUCCESS = 'COMMERCE_DASHBORD_SUCCESS';
export const COMMERCE_ANALYSIS = 'COMMERCE_ANALYSIS';
export const COMMERCE_ANALYSIS_SUCCESS = 'COMMERCE_ANALYSIS_SUCCESS';
export const COMMERCE_EXPLOIT_ANALYSIS = 'COMMERCE_EXPLOIT_ANALYSIS';
export const COMMERCE_EXPLOIT_ANALYSIS_SUCCESS = 'COMMERCE_EXPLOIT_ANALYSIS_SUCCESS';

export const commerceDashbord = (filter) => { 
	return ({
		type: COMMERCE_DASHBORD,
		data: {filter: filter},
		errorMessage: null,
		errorStatus: null
	});
};

export const commerceDashbordSuccess = (r) => {
	return ({
		type: COMMERCE_DASHBORD_SUCCESS,
		data: r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const commerceAnalysis = (filter) => { 
	return ({
		type: COMMERCE_ANALYSIS,
		data: {filter: filter},
		errorMessage: null,
		errorStatus: null
	});
};

export const commerceAnalysisSuccess = (r) => {
	return ({
		type: COMMERCE_ANALYSIS_SUCCESS,
		data: r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const commerceExploitAnalysis = (filter) => { 
	return ({
		type: COMMERCE_EXPLOIT_ANALYSIS,
		data: {filter: filter},
		errorMessage: null,
		errorStatus: null
	});
};

export const commerceExploitAnalysisSuccess = (r) => {
	return ({
		type: COMMERCE_EXPLOIT_ANALYSIS_SUCCESS,
		data: r.response,
		errorMessage: null,
		errorStatus: null
	});
};