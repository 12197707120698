import { ofType} from 'redux-observable';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError} from  'rxjs/operators';
import {equipmentsSuccess, equipmentByIdSuccess, closeAlarmSuccess, QRCODE_SEND_BY_ID, qrCodeSendByIdSuccess, loadServiceInformationsSuccess, OPEN_ALARMS, loadOpenAlarmsSuccess } from '../actions/equipments';
import {EQUIPMENTS,EQUIPMENT_BY_ID, CLOSE_ALARM, SERVICE_INFORMATIONS } from '../actions/equipments';
import {faillure } from '../actions';
import { authProviderType } from '../AuthProviderType';

export const equipmentsEpic = (epicAction)  => {
	return  epicAction.pipe(
		ofType(EQUIPMENTS),
		mergeMap((action) => {
			let apiRootUri = authProviderType.getApiRootUri();
			let urlEquipments = apiRootUri + "equipments";
			const req = ajax({
				url: urlEquipments + "/filter"
						+'?page='+ action.data.page + '&pageSize=' + action.data.pageSize,
				method: 'POST',				  
				headers: {
					'Content-Type': 'application/json'
				},
				withCredentials: true,
				body: action.data.filter
			}) ;					
			return req.pipe(map(res => equipmentsSuccess(res)), catchError(res => of(faillure(res))))
		}),
	);	
};

export const equipmentByIdEpic = (epicAction)  => {
	return  epicAction.pipe(
		ofType(EQUIPMENT_BY_ID),
		mergeMap((action) => {
			let apiRootUri = authProviderType.getApiRootUri();
			let urlEquipments = apiRootUri + "equipments";
			const req = ajax({
				url: urlEquipments + '/' + action.data.idEquipment + '?page=' + action.data.page + '&pageSize=' + action.data.pageSize,
				method: 'POST',					  
				headers: {
					'Content-Type': 'application/json'
				},
				withCredentials: true,
				body: action.data.filter
			});					
			return req.pipe(
				map(res => equipmentByIdSuccess(res)),
				catchError(res => of(faillure(res)))
			)
		}),
	);	
};

export const loadServiceInformationsEpic = (epicAction)  => {
	return  epicAction.pipe(
		ofType(SERVICE_INFORMATIONS),
		mergeMap((action) => {
			let apiRootUri = authProviderType.getApiRootUri();
			let urlEquipments = apiRootUri + "equipments";
			const req = ajax({
				url: urlEquipments + '/' + action.data.equipmentId + "/service-informations",
				method: 'GET',
				headers: {
					'Content-Type': 'application/json'
				},
				withCredentials: true,
			});
			return req.pipe(map(res => loadServiceInformationsSuccess(res)), catchError(res => of(faillure(res)))
			)
		})
	);
};

export const loadOpenAlarmsEpic = (epicAction)  => {
	return  epicAction.pipe(
		ofType(OPEN_ALARMS),
		mergeMap((action) => {
			let apiRootUri = authProviderType.getApiRootUri();
			let urlEquipments = apiRootUri + "equipments";
			const req = ajax({
				url: urlEquipments + '/' + action.data.equipmentId 
				+ '/open-alarms?page=' + action.data.page
				+ '&pageSize=' + action.data.pageSize,
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				withCredentials: true,
				body: action.data.filter
			});
			return req.pipe(map(res => loadOpenAlarmsSuccess(res)), catchError(res => of(faillure(res)))
			)
		})
	);
};

export const closeAlarmEpic = (epicAction)  => {
	return  epicAction.pipe(
		ofType(CLOSE_ALARM),
		mergeMap((action) => {
			let apiRootUri = authProviderType.getApiRootUri();
			let urlEquipments = apiRootUri + "equipments";
			const req = ajax({
				url: urlEquipments + '/alarm',
				method: 'POST',					  
				headers: {
					'Content-Type': 'application/json'
				},
				withCredentials: true,
				body: action.data.alarmId
			});					
			return req.pipe(
				map(res =>  closeAlarmSuccess(res)),
				catchError(res => of(faillure(res)))
			)
		}),
	);	
};

export const qrCodeSendByIdEpic = (epicAction)  => {
	return  epicAction.pipe(
			ofType(QRCODE_SEND_BY_ID),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlEquipments = apiRootUri + "equipments";
				const req = ajax({
					  url: urlEquipments + "/" + action.data.equipmentId + "/sendMail",
					  method: 'POST',
					  headers: {
							 'Content-Type': 'application/json'
						 },
					  withCredentials: true
					});
				return req.pipe(
            			map(res => {return qrCodeSendByIdSuccess(res);}),
            			catchError(res => { return of(faillure(res));})
            		)
			}),
	);
};