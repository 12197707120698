import {toLocaleDateTimeFormat} from '../../utils/dateTimeUtils';

export const SYSTEM_EVENTS='SYSTEM_EVENTS';
export const SYSTEM_EVENTS_SUCCESS= 'SYSTEM_EVENTS_SUCCESS';

export const systemEvents = (page, pageSize, filter) => {
	return ({
		type: SYSTEM_EVENTS,
		data: {page: page,  pageSize: pageSize, filter: filter},
		errorMessage: null,
		errorStatus: null
	});
};
export const systemEventsSuccess = (r) => {
	return ({
		type: SYSTEM_EVENTS_SUCCESS,
		data: formatDates(r.response),
		errorMessage: null,
		errorStatus: null
	});
};

function formatDates(response) {
	let data = response && response.events ? response.events : [];
	data = data.map(e => {
		e.creationDate = toLocaleDateTimeFormat(e.creationDate); 
		return e;
	});	
	response.events = data;
	return response;
}