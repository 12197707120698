import React, { Component } from 'react';
import { ActivityAnalysisChart } from './ActivityAnalysisChart';

export default class ActivityAnalysis extends Component {
	
	render() {
		let activityClass = this.props.activityClass;
		let activityIcon = this.props.activityIcon;
		let activityImageSrc= this.props.activityImageSrc;
		let label = this.props.label;
		let hValue = this.props.data && this.props.data[this.props.hValueName];
		hValue = this.props.rounding ? (hValue ? hValue.toFixed(2) : '0.00'): hValue;
		let firstCharItems = this.props.firstCharItemsName;
		let secondCharItems = this.props.secondCharItemsName;
		let firstCharItemsData = this.props.data && firstCharItems ? this.props.data[firstCharItems] : null;
		let secondCharItemsData = this.props.data && secondCharItems ? this.props.data[secondCharItems] : null;
		return (
			<div className={"p-grid " + activityClass}>
				<div className="p-col-12 p-grid analysis-header">		
					<div className={"p-col-2 " + activityClass + "-icon"}>
					{activityIcon ? <span className={"pi pi-md-"  + activityIcon}/> :
						<img id="imageActivity"  type="image" src={activityImageSrc} alt={activityImageSrc}/>}
					</div>
					<div className={"p-col-5 " + activityClass + "-label"}>
						<span className="title"><h2>{label}</h2></span>
					</div>
					<div className={"p-col-5 " + activityClass + "-amount"}>
						{!this.props.hideHeaderValue && hValue && <div className="amount"><h1>{hValue + (this.props.hUnit ? ' ' + this.props.hUnit: '')}</h1></div>}
					</div>
					{this.props.subHeader}
				</div>
				<div className="p-col-12 activity-chart">
					{firstCharItemsData && (<ActivityAnalysisChart data={firstCharItemsData} label={this.props.chartLabels[0]} 
							type="doughnut" itemValueName={this.props.itemValueName} unit={this.props.unit} rounding={this.props.rounding}/>)}
				</div>
				<div className="p-col-12 activity-chart">
					{secondCharItemsData && (<ActivityAnalysisChart data={secondCharItemsData} label={this.props.chartLabels[1]}
							type="doughnut" itemValueName={this.props.itemValueName} unit={this.props.unit} rounding={this.props.rounding}/>)}
				</div>
			</div>
		);
	}
}

