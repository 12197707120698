import { CONFIG_MATERIELS, CONFIG_MATERIELS_SUCCESS, CONFIG_MATERIEL, CONFIG_MATERIEL_SUCCESS, CONFIG_MATERIEL_DELETE_BY_ID, CONFIG_MATERIEL_DELETE_BY_ID_SUCCESS} from "../actions/hardwares";


const state0 = { data: null, error: null };

export const configMaterielsReducer = (state = state0, action) => {
	switch (action.type) {
		case CONFIG_MATERIELS:
			return {
				state,
				error: null
			};
		case CONFIG_MATERIELS_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
			};
		default:
			return state;
	}
};

export const configMaterielReducer = (state = state0, action) => {
	switch (action.type) {
		case CONFIG_MATERIEL:
			return {
				state,
				error: null
			};
		case CONFIG_MATERIEL_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
			};
		default:
			return state;
	}
};

export const configMaterielDeleteByIdReducer = (state = state0, action) => {
	switch (action.type) {
		case CONFIG_MATERIEL_DELETE_BY_ID:
			return {
				state,
				error: null
			};
		case CONFIG_MATERIEL_DELETE_BY_ID_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
			};
		default:
			return state;
	}
};