import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { AutoComplete } from 'primereact/autocomplete';
import { Message } from 'primereact/message';
import { TabView, TabPanel } from 'primereact/tabview';
import { InputTextarea } from 'primereact/inputtextarea';
import msg from '../../../texts/msg';
import MessageDialog from '../../common/MessageDialog';
import { STATIONS, STATIONGROUPS, rolesValidator, CREATE } from '../../../utils/RolesValidator.js';
import { GPS_LATITUDE, GPS_LONGITUDE } from '../../../utils/RegexPatterns';
import { getCalendarLocale } from '../../../texts/locales/calendar-locale';
import { validateInputText, updateInputNumberFormat, isValidateInputNumber } from '../../../utils/validateInputData';
import { IP_PATTERN, NUMBER_PATTERN } from '../../../utils/RegexPatterns';
import {TimeTable, getDefaultTimetableRefCopie} from './TimeTable';
import {languages, saleChannels} from '../../../utils/CommonData';
import { Contract, POSTotalEnergies } from './Contract';
import './station.css';
import '../../common/css/common.css';

export class CreateStation extends Component {
    constructor(props) {
        super(props);
        let sDate = new Date();
        sDate.setDate(1);
        let eDate = new Date();
        
        this.state = {
            isEndDateAftrStartDate: true,
            isEndDateValid: true,
            isStartDateValid: true,
            keyDown: '',
            locale: getCalendarLocale(),
            filter: {},
            station: {
                id: null,
                name: '',
                brand: '',
                implant: '',
                comment: '',
                gpsLatitude: '',
                gpsLongitude: '',
                ticketFooter: '',
                ticketHeader: '',
                language: {},
                timezone: { key: msg('station.timezone'), value: 1 },
                priceZone: {},
                commission: {},
                contract: {
                    saleChannel: {key: '', value: ''},
                    contractCicd: false,
		            contractCicdDate: null,
		            contractStart: sDate,
		            contractEnd: eDate,
		            comment: '',
		            sapCode: '',
		            sapName: '',
                    user: {key: '', value: ''},
                    applicationLotNumber: POSTotalEnergies
                },
                useCoinSuction: false,
                coinSuctionDuration: 5,
                voipServer: '',
                inetPingTest: '',
                timetable: getDefaultTimetableRefCopie()
            },
            userFilter: {
            },
            query: ''

        }
        this.doOnSave = this.doOnSave.bind(this);
        this.addStations = this.addStations.bind(this);
        this.fieldsControl = this.fieldsControl.bind(this);
        this.validateFields = this.validateFields.bind(this);
        this.optionTemplate = this.optionTemplate.bind(this);
        this.filterUser = this.filterUser.bind(this);
        this.filterStationGroups = this.filterStationGroups.bind(this);
        this.handleSaleChannelChange = this.handleSaleChannelChange.bind(this);
        this.onContractCicdChange = this.onContractCicdChange.bind(this);
        this.onApplicationLotNumberChange = this.onApplicationLotNumberChange.bind(this);
        this.onContractStartChange = this.onContractStartChange.bind(this);
        this.onContractEndChange = this.onContractEndChange.bind(this);
        this.onContractUserChange = this.onContractUserChange.bind(this);
        this.onContractSapCodeChange = this.onContractSapCodeChange.bind(this);
        this.onContractSapNameChange = this.onContractSapNameChange.bind(this);
        this.onContractCommentChange = this.onContractCommentChange.bind(this);
        this.setTimetableState = this.setTimetableState.bind(this);
    }
    addStations() {
        let o = updateInputNumberFormat(this.state.station.contract.sapCode)
        let station = Object.assign({}, this.state.station, {
            contract: Object.assign({}, this.state.station.contract, {sapCode: o.formattedCode})
        });
        if(!station.contract.user) {
            station.contract.user = {key: '', value: ''};
        }
        this.props.addStation(station);
    }
    fieldsControl() {
        if (!this.state.station.name) {
            this.setState({ isName: true });
        } else {
            this.setState({ isName: false });
        }
        if (!this.state.station.brand) {
            this.setState({ isBrand: true });
        } else {
            this.setState({ isBrand: false });
        }
        if (!this.state.station.implant) {
            this.setState({ isImplant: true })
        } else {
            this.setState({ isImplant: false });
        }
        if (!this.state.station.priceZone || !this.state.station.priceZone.value) {
            this.setState({ isPriceZone: true })
        } else {
            this.setState({ isPriceZone: false });
        }
        if (!this.state.station.commission || !this.state.station.commission.key) {
            this.setState({ isCommissionRate: true });
        } else {
            this.setState({ isCommissionRate: false });
        }

        if (!this.state.station.contract.saleChannel || !this.state.station.contract.saleChannel.value) {
            this.setState({ isSaleChannel: true })
        } else {
            this.setState({ isSaleChannel: false });
        }
        if ((this.state.station.contract.sapCode.trim().length === 0) || (!this.state.station.contract.sapCode)) {
            this.setState({ issapCode: true, isSapCodeFormat: false })
        } else {
            this.setState({ issapCode: false });
            this.setState({ isSapCodeFormat: !isValidateInputNumber(this.state.station.contract.sapCode) })
        }
        if (!this.state.station.contract.sapName) {
            this.setState({ issapName: true })
        } else {
            this.setState({ issapName: false });
        }
        if (!this.state.station.contract.contractStart || !this.state.station.contract.contractStart.toString().trim()) {
            this.setState({ isStartDateValid: false })
        } else {
            this.setState({ isStartDateValid: true });
        }
        if (!this.state.station.contract.contractEnd || !this.state.station.contract.contractEnd.toString().trim()) {
            this.setState({ isEndDateValid: false })
        } else {
            this.setState({ isEndDateValid: true });
        }
    }
    validateFields() {
        let fields = [this.state.station.name, this.state.station.brand, this.state.station.implant, this.state.station.priceZone && this.state.station.priceZone.value,
            this.state.station.commission && this.state.station.commission.key];
        if (!validateInputText(fields)) {
            return false;
        }

        fields = [this.state.station.contract.saleChannel && this.state.station.contract.saleChannel.value
            , this.state.station.contract.sapName, this.state.station.contract.sapCode, this.state.station.contract.contractStart, this.state.station.contract.contractEnd, this.state.station.contract.sapCode];
        if (!validateInputText(fields)) {
            return false;
        }
        if (!isValidateInputNumber(this.state.station.contract.sapCode))
            return false;
        return true;
    }


    componentDidMount() {
        this.props.loadCommissions(0, 100000);
        this.props.loadPriceZones(0, 100000);
        this.props.loadUsers(0, 10, this.state.userFilter);
        let filter = { label: this.state.query, page: 0, pageSize: 10, feature: STATIONGROUPS };
        this.props.loadStationGroups(filter);
    }

    componentDidUpdate(prevProps, nextProps) {
        let prevError = prevProps.error && prevProps.error.timestamp;
        let lastError = this.props.error && this.props.error.timestamp;
        let error = (lastError && (!prevError || prevError < lastError));
        if (error) {
            let isError = this.props.error.message && this.props.error.message.startsWith('error.station.') ? true : false;
            this.setState({ isApiError: isError });
        }

        let prevCreate = prevProps.station && prevProps.station.lastChange;
        let lastCreate = this.props.station && this.props.station.lastChange;
        let created = (lastCreate && (!prevCreate || prevCreate < lastCreate))
        if (created) {
            this.props.history.push('/stations');
        }

        let prevChange = prevProps.users && prevProps.users.lastChange;
        let lastChange = this.props.users && this.props.users.lastChange;
        let change = (lastChange && (!prevChange || prevChange < lastChange))
        if (change) {
            let users = []
            this.props.users.users.forEach(u => users.push({ key: u.id, value: u.name }));
            this.setState({ filteredUsers: users });
        }
        prevChange = prevProps.stationGroupsData && prevProps.stationGroupsData.lastChange;
        lastChange = this.props.stationGroupsData && this.props.stationGroupsData.lastChange;
        change = (lastChange && (!prevChange || prevChange < lastChange))
        if (change) {
            let stationGroups = []
            this.props.stationGroupsData.stationGroups.forEach(g => stationGroups.push(g));
            this.setState({ filteredStationGroups: stationGroups });
        }

    }

    doOnSave() {
        let valid = true;
        if (!this.validateFields()) {
            this.fieldsControl();
        } else {
            this.setState({ isImplant: false, isBrand: false, isName: false, isPriceZone: false});
            if (this.state.station.gpsLatitude || this.state.station.gpsLongitude) {
                if (this.state.station.gpsLatitude && !GPS_LATITUDE.test(this.state.station.gpsLatitude)) {
                    this.setState({ gpsLatitudeNotValid: true });
                    valid = false;
                } else {
                    this.setState({ gpsLatitudeNotValid: false });
                }
                if (this.state.station.gpsLongitude && !GPS_LONGITUDE.test(this.state.station.gpsLongitude)) {
                    this.setState({ gpsLongitudeNotValid: true });
                    valid = false;
                } else {
                    this.setState({ gpsLongitudeNotValid: false });
                }
            }
            if (this.state.station.useCoinSuction && this.state.station.coinSuctionDuration && !NUMBER_PATTERN.test(this.state.station.coinSuctionDuration)) {
                this.setState({ coinSeductionNotValid: true });
                valid = false;
            } else {
                this.setState({ coinSeductionNotValid: false });
            }

            if (this.state.station.voipServer && !IP_PATTERN.test(this.state.station.voipServer)) {
                this.setState({ ipNotValid: true });
                valid = false;
            } else {
                this.setState({ ipNotValid: false });
            }
            if (this.state.station.inetPingTest && !IP_PATTERN.test(this.state.station.inetPingTest)) {
                this.setState({ inetPingTestNotValid: true });
                valid = false;
            } else {
                this.setState({ inetPingTestNotValid: false });
            }
            if (this.state.station.contract.contractEnd.getTime() < this.state.station.contract.contractStart.getTime()) {
                this.setState({ isEndDateAftrStartDate: false });
                valid = false;
            }

            if (valid) {
                this.addStations();
            }
        }
    }

    onContractCicdChange(e) {
        this.setState({station: Object.assign({}, this.state.station, {contract: Object.assign({}, this.state.station.contract, {contractCicd: e.checked})}) })
    }

    onApplicationLotNumberChange(e) {
        this.setState({station: Object.assign({}, this.state.station, {contract: Object.assign({}, this.state.station.contract, {applicationLotNumber: e.target.value})})});
    }

    onContractStartChange(e) {
        this.setState({station: Object.assign({}, this.state.station, {contract: Object.assign({}, this.state.station.contract, {contractStart: e.value})}) });
    }
    
    onContractEndChange(e) {
        this.setState({station: Object.assign({}, this.state.station, {contract: Object.assign({}, this.state.station.contract, {contractEnd: e.value})}) });
    }

    onContractUserChange(e) {
        this.setState({station: Object.assign({}, this.state.station, {contract: Object.assign({}, this.state.station.contract, {user: e.target.value})}) });
    }

    onContractSapCodeChange(e) {
        this.setState({station: Object.assign({}, this.state.station, {contract: Object.assign({}, this.state.station.contract, {sapCode: e.target.value})}) });
    }

    onContractSapNameChange(e) {
        this.setState({station: Object.assign({}, this.state.station, {contract: Object.assign({}, this.state.station.contract, {sapName: e.target.value})}) });
    }

    onContractCommentChange(e) {
        this.setState({station: Object.assign({}, this.state.station, {contract: Object.assign({}, this.state.station.contract, {comment: e.target.value})}) });
    }

    handleSaleChannelChange(e) {
        this.setState({station: Object.assign({}, this.state.station, {contract: Object.assign({}, this.state.station.contract, {saleChannel: e.target.value, contractCicd: e.target.value.value === 'CODO'})})});
    }

    optionTemplate(option, emptyValueReplace) {
        return (
            <div>
                <span style={{ color: 'black', visibility: 'visible' }}>
                    {!option.key ? emptyValueReplace : option.key}
                </span>
            </div>
        );
    }

    filterUser(event, isDropdownClick) {
        let query = event.query.toLowerCase();
        if (!isDropdownClick) {
            if (!query || query === this.state.query) {
                this.setState({ query: query });
                return;
            }
        } else if (query && (query === this.state.query)) {
            return;
        }
        let label = isDropdownClick ? this.state.query : query;
        let lFilter = Object.assign({}, this.state.userFilter);
        lFilter.label = label;
        this.props.loadUsers(0, 10, lFilter);
        this.setState({ query: query });
    }

    filterStationGroups(event, isDropdownClick) {
        let query = event.query.toLowerCase();
        if (!isDropdownClick) {
            if (!query || query === this.state.query) {
                return;
            }
        } else if (query && (query === this.state.query)) {
            return;
        }
        let filter = {
            label: isDropdownClick ? this.state.query : query,
            page: 0,
            pageSize: 10,
            feature: STATIONGROUPS
        };
        this.props.loadStationGroups(filter);
        this.setState({ query: query });
    }

    handleInputText(v, n, ticket_name ) {
      const ticket_hdr = "ticket_hdr";
      let charNbrLimitation = 30;
      const breakLinePos = 31;
      const char= "\n";
      let space = 0;
      let lines = v.split(char);

      for(let i = 0; i < lines.length; i++)
        {
          if (lines[i].length <= charNbrLimitation) continue;
          let j = 0; space = charNbrLimitation;
          while (j++ <= charNbrLimitation)
               {
                 if (lines[i].charAt(j) === ' ') space = j;
               }

          lines[i + 1] = lines[i].substring(space + 1) + (lines[i + 1] || "");
          lines[i] = lines[i].substring(0, space);
        }
      if(lines.length <= n)
        {
          v= lines.slice(0, breakLinePos).join(char);

          if(ticket_name.valueOf() === ticket_hdr)
          {
          this.setState({ isModifiable: false, station: Object.assign({}, this.state.station, { ticketHeader: v }) })
          } else
          {
          this.setState({ isModifiable: false, station: Object.assign({}, this.state.station, { ticketFooter: v }) })
          }
        }
    }

    setTimetableState(timetable) {
        this.setState({station: Object.assign({}, this.state.station, {timetable: timetable })});
    }

    render() {
        let authorized = rolesValidator.validate(STATIONS);
        let cAuthorized = authorized.toString().includes(CREATE);

        let commissionsTemporaire = this.props.commissionsData ? this.props.commissionsData : [];
        let pricezonesTemporaire = this.props.priceZonesData ? this.props.priceZonesData : [];
        let commissions = [];
        let pricezones = [];

        if (commissionsTemporaire.length !== 0) {
            commissionsTemporaire.forEach(e => commissions.push({ key: e.name, value: e.id }));
        }
        if (pricezonesTemporaire.length !== 0) {
            pricezonesTemporaire.forEach(e => pricezones.push({ id: e.id, key: e.name + " ( " + e.version + " )", value: e.id.toString(), label : ""}));
        }

        const timezones = [
            { key: msg('station.timezone'), value: 1 }
        ];

        return cAuthorized && (
            <div className="card commonClass">
                <TabView>
                    <TabPanel header={msg('station.identity')}>
                            <div className="test p-col-12">
                                <h3 style={{ marginTop: '.5em' }}>{msg('station.identity')}</h3>
                            </div>
                            <div className="card card-w-title p-col-12">
                                <div className="p-grid form-group p-col-12">
                                    {<div className="p-grid p-col-6">
                                        <div className="p-grid p-col-3">
                                            <div className="p-col-8"><label>{msg('station.fields.implant')}</label></div>
                                            <div className=""><span className="obligatoire">*</span></div>
                                        </div>
                                        <div className="p-col-9">
                                            <InputText value={this.state.station.implant} onChange={e => this.setState({ station: Object.assign({}, this.state.station, { implant: e.target.value }) })} maxLength="255" />
                                            {this.state.implantNotValid && <Message severity="error" text={msg('station.message.not.valid.text')} key="startTimeError" />}
                                            {this.state.isImplant && <Message severity="error" text={msg('station.input.implant.required')} key="name" />}
                                        </div>
                                    </div>}
                                    {<div className="p-grid p-col-6">
                                        <div className="p-grid p-col-3">
                                            <div className="p-col-8"><label>{msg('station.fields.name')}</label></div>
                                            <div className=""><span className="obligatoire">*</span></div>
                                        </div>
                                        <div className="p-col-9">
                                            <InputText value={this.state.station.name} onChange={e => this.setState({ station: Object.assign({}, this.state.station, { name: e.target.value }) })} maxLength="255" />
                                            {this.state.nameNotValid && <Message severity="error" text={msg('station.message.not.valid.text')} key="startTimeError" />}
                                            {this.state.isName && <Message severity="error" text={msg('station.input.name.required')} key="name" />}
                                        </div>
                                    </div>}
                                </div>
                                <div className="p-grid form-group p-col-12">
                                    {<div className="p-grid p-col-6">
                                        <div className="p-grid p-col-3">
                                            <div className="p-col-8"> <label>{msg('station.fields.brand')}</label></div>
                                            <div className=""><span className="obligatoire">*</span></div>
                                        </div>
                                        <div className="p-col-9">
                                            <InputText value={this.state.station.brand} onChange={e => this.setState({ station: Object.assign({}, this.state.station, { brand: e.target.value }) })} maxLength="255" />
                                            {this.state.brandNotValid && <Message severity="error" text={msg('station.message.not.valid.text')} key="startTimeError" />}
                                            {this.state.isBrand && <Message severity="error" text={msg('station.input.brand.required')} key="lastname" />}
                                        </div>
                                    </div>}
                                    {<div className="p-grid p-col-6">
                                        <div className="p-grid p-col-3">
                                            <div className="p-col-8"> <label>{msg('station.fields.comment')}</label></div>
                                        </div>
                                        <div className="p-col-9">
                                            <InputText className="inputClass" id="float-input" type="text" size="50" value={this.state.station.comment} onChange={e => this.setState({ station: Object.assign({}, this.state.station, { comment: e.target.value }) })} />
                                            {this.state.commentNotValid && <Message severity="error" text={msg('station.message.not.valid.text')} key="startTimeError" />}
                                        </div>
                                    </div>}
                                </div>
                                <div className="p-grid form-group p-col-12">
                                    {pricezones && <div className="p-grid p-col-6">
                                        <div className="p-grid p-col-3">
                                            <div className="p-col-8"><label>{msg('station.fields.pricezone')}</label></div>
                                            <div className=""><span className="obligatoire">*</span></div>
                                        </div>
                                        <div className="p-col-9">
                                            <Dropdown value={this.state.station.priceZone} options={pricezones}
                                                onChange={e => this.setState({ station: Object.assign({}, this.state.station, { priceZone: e.target.value }) })}
                                                optionLabel="key" placeholder="Selectionnez" style={{ width: 'auto' }} />
                                            {this.state.isPriceZone && <Message severity="error" text={msg('station.input.pricezone.required')} key="name" />}
                                        </div>
                                    </div>}
                                    {commissions && <div className="p-grid p-col-6">
                                        <div className="p-grid p-col-3">
                                            <div className="p-col-8"> <label>{msg('station.fields.commission')}</label></div>
                                            <div className=""><span className="obligatoire">*</span></div>
                                        </div>
                                        <div className="p-col-9">
                                            <Dropdown value={this.state.station.commission} options={commissions}
                                                onChange={e => this.setState({ station: Object.assign({}, this.state.station, { commission: e.target.value }) })}
                                                optionLabel="key" placeholder="Selectionnez" style={{ width: 'auto' }} />
                                                {this.state.isCommissionRate && <Message severity="error" text={msg('station.input.commission.rate.required')} key="commissionRate" />}
                                        </div>
                                    </div>}
                                </div>
                            </div>
                            <br />
                        <div className="test p-col-12">
                            <h3 style={{ marginTop: '.5em' }}>{msg('Contrats')}</h3>
                        </div>
                        <div className="card card-w-title p-col-12">
                            <div className="p-grid form-group p-col-12 contracts-view">
                            <Contract visible={true}
                                title={msg('station.contract')}
                                contract={this.state.station.contract}
                                onCicdChange={this.onContractCicdChange}
                                onStartChange={this.onContractStartChange}
                                onEndChange={this.onContractEndChange}
                                onSaleChannelChange={this.handleSaleChannelChange}
                                onUserChange={this.onContractUserChange}
                                onSapCodeChange={this.onContractSapCodeChange}
                                onApplicationLotNumberChange={this.onApplicationLotNumberChange}
                                onSapNameChange={this.onContractSapNameChange}
                                onCommentChange={this.onContractCommentChange}
                                saleChannels={saleChannels}
                                filteredUsers={this.state.filteredUsers}
                                filterUser={this.filterUser}
                                optionTemplate={this.optionTemplate}
                                errors={{
                                    isStartDateValid: this.state.isStartDateValid,
                                    isEndDateValid: this.state.isEndDateValid,
                                    isEndDateAftrStartDate: this.state.isEndDateAftrStartDate,
                                    isSaleChannel: this.state.isSaleChannel,
                                    issapCode: this.state.issapCode,
                                    isSapCodeFormat: this.state.isSapCodeFormat,
                                    issapName: this.state.issapName
                                }}
                                locale={this.state.locale}
                                readOnly={false} />
                            </div>
                        </div>
                        <br />
                            <div className="test p-col-12">
                                <h3 style={{ marginTop: '.5em' }}>{msg('station.ticket')}</h3>
                            </div>
                            <div className="card card-w-title p-col-12">
                              <div className="p-grid form-group p-col-12">
                                 {<div className="p-grid p-col-6">
                                    <div className="p-grid p-col-3">
                                        <div className="p-col-12"> <label>{msg('station.fields.tickethdr')}</label></div>
                                    </div>
                                    <div className="p-col-9">
                                        <span className="md-inputfield">
                                            <InputTextarea rows={8} cols={28} maxLength={250} autoResize={true}  value={this.state.station.ticketHeader}
                                            onChange={e => this.handleInputText(e.target.value, 8,"ticket_hdr")} />
                                        </span>
                                        {this.state.hdrNotValid && <Message severity="error" text={msg('station.message.not.valid.text')} key="ticketHeaderError" />}
                                    </div>
                                 </div>}
                                 {<div className="p-grid p-col-6">
                                    <div className="p-grid p-col-3">
                                        <div className="p-col-12"><label>{msg('station.fields.ticketftr')}</label></div>
                                    </div>
                                    <div className="p-col-9">
                                        <span className="md-inputfield">
                                            <InputTextarea rows={3} cols={28} maxLength={100}  autoResize={true} value={this.state.station.ticketFooter}
                                             onChange={e => this.handleInputText(e.target.value,3,"ticket_ftr")} />
                                        </span>
                                        {this.state.ftrNotValid && <Message severity="error" text={msg('station.message.not.valid.text')} key="ticketFooterError" />}
                                    </div>
                                 </div>}
                               </div>
							   </div>
                            <br/>
                            <div className="test p-col-12">
                                <h3 style={{ marginTop: '.5em' }}>{msg('station.divers')}</h3>
                            </div>
                            <div className="card card-w-title p-col-12">
                                <div className="p-grid form-group p-col-12">
                                    {<div className="p-grid p-col-6">
                                        <div className="p-grid p-col-3">
                                            <div className="p-col-8"><label>{msg('station.fields.language')}</label></div>
                                        </div>
                                        <div className="p-col-9">
                                            <span className="md-inputfield md-inputfield-fill">
                                                <Dropdown value={this.state.station.language} options={languages}
                                                    onChange={e => this.setState({ station: Object.assign({}, this.state.station, { language: e.target.value }) })}
                                                    optionLabel="key" placeholder="Selectionnez" style={{ width: 'auto' }} />
                                            </span>
                                        </div>
                                    </div>}
                                    {<div className="p-grid p-col-6">
                                        <div className="p-grid p-col-3">
                                            <div className="p-col-12"> <label>{msg('station.fields.timezone')}</label></div>
                                        </div>
                                        <div className="p-col-9">
                                            <span className="md-inputfield md-inputfield-fill">
                                                <Dropdown value={this.state.station.timezone} options={timezones}
                                                    onChange={e => this.setState({ station: Object.assign({}, this.state.station, { timezone: e.target.value }) })}
                                                    optionLabel="key" placeholder="Selectionnez" style={{ minWidth: '60%' }} disabled />
                                            </span>
                                        </div>
                                    </div>}
                                </div>
                                <div className="p-grid form-group p-col-12">
                                    {<div className="p-grid p-col-6">
                                        <div className="p-grid p-col-3">
                                            <div className="p-col-8"><label>{msg('station.fields.gpsLatitude')}</label></div>
                                        </div>
                                        <div className="p-col-9">
                                            <span className="md-inputfield">
                                                <InputText value={this.state.station.gpsLatitude} onChange={e => this.setState({ station: Object.assign({}, this.state.station, { gpsLatitude: e.target.value }) })} />
                                            </span>
                                            {this.state.gpsLatitudeNotValid && <Message severity="error" text={msg('station.message.not.valid.latitude')} key="startTimeError" />}
                                        </div>
                                    </div>}
                                    {<div className="p-grid p-col-6">
                                        <div className="p-grid p-col-3">
                                            <div className="p-col-8"> <label>{msg('station.fields.gpsLongitude')}</label></div>
                                        </div>
                                        <div className="p-col-9">
                                            <span className="md-inputfield">
                                                <InputText value={this.state.station.gpsLongitude} onChange={e => this.setState({ station: Object.assign({}, this.state.station, { gpsLongitude: e.target.value }) })} />
                                            </span>
                                            {this.state.gpsLongitudeNotValid && <Message severity="error" text={msg('station.message.not.valid.longitude')} key="startTimeError" />}
                                        </div>
                                    </div>}
                                </div>
                            </div>
                            <br />
                            <div className="test p-col-12">
                                <h3 style={{ marginTop: '.5em' }}>{msg('group.stations.group')}</h3>
                            </div>
                            <div className="card card-w-title p-col-12">
                                <br />
                                <div className="form-group p-col-12">
                                    <div className="p-grid p-col-12">
                                        <div className="p-col-12" style={{ marginBottom: '10px' }}>
                                            <span className="infos">{msg('group.stations.group.select.message')}</span>
                                            <br></br>
                                            <br></br>
                                            <AutoComplete minLength={0} placeholder={msg('group.stations.group.select.all')} id="stations" size={60} dropdown={true} multiple={true} field="name"
                                                suggestions={this.state.filteredStationGroups} value={this.state.station.stationGroups} delay={100}
                                                completeMethod={this.filterStationGroups} onDropdownClick={e => this.filterStationGroups(e, true)}
                                                onChange={e => this.setState({ filteredStationGroups: null, isModifiable: false, station: Object.assign({}, this.state.station, { stationGroups: e.target.value }) })} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </TabPanel>
                    <TabPanel header={msg('station.advanced.settings')}>
                        <div className="test p-col-12">
                            <h3 style={{ marginTop: '.5em' }}>{msg('station.advanced.settings')}</h3>
                        </div>
                        <div className="card card-w-title p-col-12">
                            <div className="p-grid form-group p-col-12">
                                {<div className="p-grid p-col-6">
                                    <div className="p-grid p-col-5">
                                        <div className="p-col-12"><label>{msg('station.advanced.settings.useCoinSuction')}</label></div>
                                    </div>
                                    <div className="p-col-7">
                                        <span className="md-inputfield">
                                            <Checkbox value={this.state.station.useCoinSuction} onChange={e => this.setState({ station: Object.assign({}, this.state.station, { useCoinSuction: e.checked }) })}
                                                checked={this.state.station.useCoinSuction}></Checkbox>
                                        </span>
                                    </div>
                                </div>}
                                {this.state.station.useCoinSuction && <div className="p-grid p-col-6">
                                    <div className="p-grid p-col-5">
                                        <div className="p-col-12"><label>{msg('station.advanced.settings.coinSuctionDuration')}</label></div>
                                    </div>
                                    <div className="p-col-7">
                                        <span className="md-inputfield">
                                            <InputText value={this.state.station.coinSuctionDuration} onChange={e => this.setState({ station: Object.assign({}, this.state.station, { coinSuctionDuration: e.target.value }) })}
                                            />
                                        </span>
                                        {this.state.coinSeductionNotValid && <Message severity="error" text={msg('equipment.message.not.valid.number')} key="startTimeError" />}
                                    </div>
                                </div>}
                            </div>
                            <div className="p-grid form-group p-col-12">
                                {<div className="p-grid p-col-6">
                                    <div className="p-grid p-col-5">
                                        <div className="p-col-12"><label>{msg('station.advanced.settings.voip.ip')}</label></div>
                                    </div>
                                    <div className="p-col-7">
                                        <span className="md-inputfield">
                                            <InputText value={this.state.station.voipServer} onChange={e => this.setState({ station: Object.assign({}, this.state.station, { voipServer: e.target.value }) })} />
                                        </span>
                                        {this.state.ipNotValid && <Message severity="error" text={msg('equipment.message.not.valid.ip')} key="voipServerError" />}
                                    </div>
                                </div>}
                                {<div className="p-grid p-col-6">
                                    <div className="p-grid p-col-5">
                                        <div className="p-col-12"><label>{msg('station.advanced.settings.voip.inetPingTest')}</label></div>
                                    </div>
                                    <div className="p-col-7">
                                        <span className="md-inputfield">
                                            <InputText value={this.state.station.inetPingTest} onChange={e => this.setState({ station: Object.assign({}, this.state.station, { inetPingTest: e.target.value }) })} />
                                        </span>
                                        {this.state.inetPingTestNotValid && <Message severity="error" text={msg('equipment.message.not.valid.ip')} key="startTimeError" />}
                                    </div>
                                </div>}
                            </div>

                        </div>

                        <div className="test p-col-12">
                            <h3 style={{ marginTop: '.5em' }}>{msg('station.advanced.settings.timetable')}</h3>
                        </div>
                    <div className="card card-w-title p-col-12">
                        <TimeTable timetable={this.state.station.timetable}  setTimetableState={this.setTimetableState} />
                    </div>
                </TabPanel>
            </TabView>
            <div className="p-grid p-col-12">
                <div className="p-grid p-col-9"/>
                    <div className="p-grid p-col-3">
                        <Button label={(msg('station.validate'))} title={(msg('station.validate'))} onClick={this.doOnSave} className="button" disabled={!cAuthorized}/>
                        <Link to="/stations">
                            <Button label={(msg('station.reset'))} className="button" />
                        </Link>
                    </div>
                </div>
                <MessageDialog className={'message-dialog error-message'} visible={this.state.isApiError} title={msg('message.dialog.error.title')}
                    message={this.state.isApiError && msg(this.props.error.message)}
                    onHide={() => this.setState({ isLignSelected: false, isApiError: false })} />
            </div>
        );
    }
}

export default CreateStation;