import React, { Component } from 'react';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Message } from 'primereact/message';
import { AutoComplete } from 'primereact/autocomplete';
import { userSessionParamsHandler } from '../../utils/UserSessionParamsHandler.js';
import msg from '../../texts/msg';
import { getCalendarLocale } from '../../texts/locales/calendar-locale';
import './css/bornefilter.css';

let defaultPeriodType = { key: 'DAY', value: msg('filter.period.type.day') };
let defaultNoTransactionPeriod = { key: 'all', value: msg('filter.period.no.transaction.all') };
let ALL_ALARM_KEY = 'ALL';
let ERROR_ALARM_KEY = 'ERROR';
let WARNING_ALARM_KEY = 'MAJOR_WARNING';
let MINOR_WARNING_ALARM_KEY = 'MINOR_WARNING';
let RANNING_ALARM_KEY = 'INFO';
let NOT_ASSOCIATED_KEY = 'NOT_ASSOCIATED';
let NOT_TRANSACTION_KEY = 'NOT_TRANSACTION';
let ALL_ALARM = { key: ALL_ALARM_KEY, value: '', label: msg('filter.value.alarm.all') };
let ERROR_ALARM = { key: ERROR_ALARM_KEY, value: ERROR_ALARM_KEY, label: msg('filter.value.alarm.error') };
let WARNING_ALARM = { key: WARNING_ALARM_KEY, value: WARNING_ALARM_KEY, label: msg('filter.value.alarm.major_warning') };
let MINOR_WARNING_ALARM = { key: MINOR_WARNING_ALARM_KEY, value: MINOR_WARNING_ALARM_KEY, label: msg('filter.value.alarm.minor_warning') };
let RANNING_ALARM = { key: RANNING_ALARM_KEY, value: RANNING_ALARM_KEY, label: msg('filter.value.alarm.info') };
let NOT_ASSOCIATED = { key: NOT_ASSOCIATED_KEY, value: NOT_ASSOCIATED_KEY, label: msg('filter.value.Terminal.to.be.installed') };
let NOT_TRANSACTION = { key: NOT_TRANSACTION_KEY, value: NOT_TRANSACTION_KEY, label: msg('filter.value.Terminal.to.not.transaction') };

let ALL_ALARM_TYPES = [
    ALL_ALARM,
    ERROR_ALARM,
    WARNING_ALARM,
    MINOR_WARNING_ALARM,
    RANNING_ALARM,
    NOT_ASSOCIATED,
    NOT_TRANSACTION
];

class BorneFilter extends Component {
    constructor(props) {
        super(props);
        let sDate = new Date();
        sDate.setDate(1);
        sDate.setHours(0);
        sDate.setMinutes(0);
        sDate.setSeconds(0);
        let eDate = new Date();
        eDate.setHours(23);
        eDate.setMinutes(59);
        eDate.setSeconds(59);
        let lDate = new Date();
        lDate.setHours(23);
        lDate.setMinutes(59);
        lDate.setSeconds(59);
        let lStations = props.initFilter && props.initFilter.stations ? props.initFilter.stations : [];
        this.state = {
            locale: getCalendarLocale(),
            startDate: sDate,
            endDate: eDate,
            maxEndDate: lDate,
            saleChannels: props.initFilter && props.initFilter.saleChannels ? props.initFilter.saleChannels : [],
            groupStations: props.initFilter && props.initFilter.groupStations ? props.initFilter.groupStations : [],
            stations: lStations,
            contracts: props.initFilter && props.initFilter.contracts ? props.initFilter.contracts : [],
            borneTypes: props.initFilter && props.initFilter.borneTypes ? props.initFilter.borneTypes : [],
            bornes: props.initFilter && props.initFilter.bornes ? props.initFilter.bornes : [],
            equipments: props.initFilter && props.initFilter.equipments ? props.initFilter.equipments : [],
            batchNumbers: [],
            alarmType: !this.props.defaultAlarmType ? ALL_ALARM :
                {
                    key: this.props.defaultAlarmType,
                    value: this.props.defaultAlarmType,
                    label: msg('filter.value.alarm.' + this.props.defaultAlarmType.toLowerCase())
                },
            alarmTypes: ALL_ALARM_TYPES,
            periodTypes: [defaultPeriodType],
            periodNoTransactions: props.initFilter && props.initFilter.periodNoTransactions ? props.initFilter.periodNoTransactions : [defaultNoTransactionPeriod],
            query: '',
            applyOnMount: this.props.applyOnMount,
            stationsPage: 0,
            stationsPageSize: 50,
            stationsPlaceHolderClass: lStations && lStations.length > 0 ? '' : 'st-placeholder',
        }
        this.loadFilter = this.loadFilter.bind(this);
        this.apply = this.apply.bind(this);
        this.reset = this.reset.bind(this);
        this.optionTemplate = this.optionTemplate.bind(this);
        this.optionTemplateSaleChannels = this.optionTemplateSaleChannels.bind(this);
        this.batchNumbersOptionTemplate = this.batchNumbersOptionTemplate.bind(this);
        this.filterStations = this.filterStations.bind(this);
        this.handleChannelChange = this.handleChannelChange.bind(this);
        this.handleStationChange = this.handleStationChange.bind(this);
        this.handleStationSelect = this.handleStationSelect.bind(this);
        this.handleBorneTypeChange = this.handleBorneTypeChange.bind(this);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.handleGroupStationChange = this.handleGroupStationChange.bind(this);
        this.handleContractChange = this.handleContractChange.bind(this);
        this.handleEquipmentChange = this.handleEquipmentChange.bind(this);        
    }

    componentDidMount() {
        let filter = this.buildFilter();
        if (this.props.initDataInfoBarReport) {
            this.props.initDataInfoBarReport(filter);
        }
        this.loadFilter(filter);
        if(this.props.showStation){
            let stationFilter = {
                label: this.state.query,
                saleChannels: this.props.showChannel ? this.state.saleChannels : [],
                page: this.state.stationsPage,
                pageSize: this.state.stationsPageSize
            };
            this.props.loadFilterStations(stationFilter);
        }

        if (this.props.applyOnMount !== false) {
            this.apply();
            return;
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.initFilter &&
            this.props.initFilter.stations &&
            this.props.initFilter !== prevProps.initFilter) {
            this.setState({ initStations: this.props.initFilter.stations });
        }
        if (this.props.filterData && this.props.filterData !== prevProps.filterData) {
            let init = this.state.initStations && this.state.initStations.length > 0;
            if(init){
                let stations = this.props.filterData.stations.filter(s => this.state.initStations.filter(ss => ss.key === s.key).length > 0);
                this.setState({ stations: stations, initStations: null });
            }
        }
        if (!this.state.initStations && prevState.initStations
            && this.state.stations && this.state.stations.length > 0) {
            this.apply();
        }
        let prevChange = prevProps.filterStations && prevProps.filterStations.lastChange;
        let lastChange = this.props.filterStations && this.props.filterStations.lastChange;
        let change = (lastChange && (!prevChange || prevChange < lastChange))
        if (change) {
            let stations = [];
            this.props.filterStations.stations.forEach(s => stations.push({key: s.id.toString(), value: s.implant + ' (' + s.name + ')'}));
            this.setState({ filteredStations: stations });
        }
    }

    loadFilter() {
        let filter = this.buildFilter();
        this.props.loadFilter(filter);
        userSessionParamsHandler.fireFilterChange(filter, this.props.filterId);
    }

    handleChannelChange(e) {
        let filter = this.buildFilter();
        filter.saleChannels = [e.value];
        this.setState({ saleChannels: filter.saleChannels, stations: [], stationsPlaceHolderClass :'st-placeholder'});
        if(e.value.key === msg('station.contract.schannel.lotus')){
            //reinit
            let o = e.value;
            o.key = o.value;
            filter.saleChannels = [o];
        }
        this.props.loadFilter(filter);
    }

    handleGroupStationChange(e) {
        let filter = this.buildFilter();
        filter.groupStations = [e.value];
        this.setState({
            groupStations: filter.groupStations,
            stations: [],
            stationsPlaceHolderClass: 'st-placeholder',
            contracts: [], 
            bornes: [],
            equipments: [],
            batchNumbers: []
        });
        this.props.loadFilter(filter);
    }

    getStationChangeValue(e){
        return this.props.stationSingle  ? [e.value] : e.value;
    }
    handleStationChange(e) {
        let filter = this.buildFilter();
        filter.stations = this.getStationChangeValue(e);
        this.setState({
            stations: filter.stations,
            stationsPlaceHolderClass: filter.stations && filter.stations.length > 0 ? '' : 'st-placeholder',
            bornes: [],        
            equipments: [],
            batchNumbers: [],
            startDate: filter.startDate, endDate: filter.endDate,
            stationInputError: false
        });
        if(this.props.stationSingle){
            return;
        }
        this.props.loadFilter(filter);
    }

    handleStationSelect(e) {
        if(!this.props.stationSingle){
            return;
        }
        let filter = this.buildFilter();
        filter.stations = this.getStationChangeValue(e);
        this.setState({
            stations: filter.stations,
            stationsPlaceHolderClass: filter.stations && filter.stations.length > 0 ? '' : 'st-placeholder',
            bornes: [],        
            equipments: [],
            batchNumbers: [],
            startDate: filter.startDate, endDate: filter.endDate,
            stationInputError: false
        });
        this.props.loadFilter(filter);
    }

    filterStations(event, isDropdownClick) {
        let query = event.query.toLowerCase();
        if (!isDropdownClick) {
            if (!query || query === this.state.query) {
                return;
            }
        } else if (query && (query === this.state.query)) {
            return;
        }
        let filter = {
            label: isDropdownClick ? this.state.query : query,
            saleChannels: this.props.showChannel ? this.state.saleChannels : [],
            page: this.state.stationsPage,
            pageSize: this.state.stationsPageSize
        };
        this.props.loadFilterStations(filter);
        this.setState({ query: query });
    }

    handleEquipmentChange(e) {
        let filter = this.buildFilter();
        filter.equipments = [e.value];
        this.setState({ equipments: filter.equipments, batchNumbers: [] });
        this.props.loadFilter(filter);
    }

    handleContractChange(e) {
        let filter = this.buildFilter();
        filter.contracts = [e.value];
        this.setState({
            contracts: filter.contracts,
            stations: [],
            stationsPlaceHolderClass: 'st-placeholder',
            bornes: [],
            equipments: [], 
            batchNumbers: [],
            startDate: filter.startDate, endDate: filter.endDate
        });
        this.props.loadFilter(filter);
    }

    handleBorneTypeChange(e) {
        let filter = this.buildFilter();
        filter.borneTypes = [e.value];
        this.setState({ borneTypes: filter.borneTypes });
        this.props.loadFilter(filter);
    }

    apply() {
        if ((this.props.showStation || this.props.showDodoStation) && this.props.stationRequired &&
            (!this.state.stations || this.state.stations.length === 0)) {
            this.setState({ stationInputError: true });
            return;
        }
        this.setState({ stationsInputError: false });
        let filter = this.buildFilter();
        this.setState({
            saleChannels: filter.saleChannels, groupStations: filter.groupStations, stations: filter.stations, contracts: filter.contracts, borneTypes: filter.borneTypes,
            bornes: filter.bornes, equipments: filter.equipments, batchNumbers: filter.batchNumbers,
            startDate: filter.startDate, endDate: filter.endDate, periodNoTransactions: filter.periodNoTransactions
        });
        this.props.apply(filter);
        userSessionParamsHandler.fireFilterChange(filter, this.props.filterId);
    }

    reset() {
        this.setState({ stationInputError: false });
        let filter = this.buildFilter(true);
        filter.saleChannels = [];
        filter.stations = [];
        filter.contracts = [];
        filter.groupStations = [];
        filter.borneTypes = [];
        filter.bornes = [];
        filter.equipments = [];
        filter.batchNumbers = [];
        filter.alarmType = ALL_ALARM.value;
        filter.periodTypes = [defaultPeriodType];
        filter.periodNoTransactions = [defaultNoTransactionPeriod];
        let sDate = new Date();
        sDate.setHours(0);
        sDate.setMinutes(0);
        sDate.setSeconds(0);
        sDate.setDate(1);
        filter.startDate = sDate;
        let eDate = new Date();
        eDate.setHours(23);
        eDate.setMinutes(59);
        eDate.setSeconds(59);
        filter.endDate = eDate;
        this.setState({
            saleChannels: filter.saleChannels, groupStations: filter.groupStations, stations: filter.stations, contracts: filter.contracts, borneTypes: filter.borneTypes,
            bornes: filter.bornes, equipments: filter.equipments, batchNumbers: filter.batchNumbers, alarmType: ALL_ALARM, alarmTypes: ALL_ALARM_TYPES, periodTypes: filter.periodTypes,
            startDate: filter.startDate, endDate: filter.endDate, periodNoTransactions: filter.periodNoTransactions, periodNoTransaction: filter.periodNoTransaction,
            stationsPlaceHolderClass: 'st-placeholder',

        });
        if(this.props.reset){
            this.props.reset(filter);
        } else {
            this.props.loadFilter(filter);
        }
        userSessionParamsHandler.fireFilterChange(filter, this.props.filterId);
    }

    buildFilter(reset) {
        let filter = reset ?  {label : ''} : userSessionParamsHandler.getFilter(this.props.filterId);
        if(!filter){
            filter = {label : ''};
        }
        if(!filter.label){
            filter.label = '';
        }
        if (this.props.showDate) {
            filter.startDate = new Date(this.state.startDate ? this.state.startDate : new Date());
            filter.endDate = new Date(this.state.endDate ? this.state.endDate : new Date());
            if (!this.props.showTime) {
                filter.startDate.setHours(0);
                filter.startDate.setMinutes(0);
                filter.startDate.setSeconds(0);
                filter.endDate.setHours(23);
                filter.endDate.setMinutes(59);
                filter.endDate.setSeconds(59);
            }
        }
        if (this.props.showChannel) {
            filter.saleChannels = this.state.saleChannels;
        }
        if (this.props.showStation) {
            filter.stations = this.state.stations;
        }
        if (this.props.showContract) {
            filter.contracts = this.state.contracts;
            filter.showContract = true;
        } else {
            filter.showContract = false;
        }
        if (this.props.showGroupStation) {
            filter.groupStations = this.state.groupStations;
        }
        if (this.props.showBorneType) {
            filter.borneTypes = this.state.borneTypes;
        }
        filter.equipments = this.props.showEquipments ? this.state.equipments : [];
        if(this.props.showBatchNumbers){
            filter.batchNumbers = this.state.batchNumbers;
            filter.showBacthNumber = true;
        } else {
            filter.batchNumbers  = [];
            filter.showBacthNumber = false;
        }
        if (this.props.showBorneSerial) {
            filter.bornes = this.state.bornes;
        }
        if (this.props.showAlarmType) {
            filter.alarmType = this.state.alarmType.value;
        }
        if (this.props.showTransactionPeriod) {
            filter.periodNoTransactions = this.state.periodNoTransactions;
        }
        if (this.props.showPeriodType) {
            filter.periodTypes = this.state.periodTypes;
        }
        return filter;
    }

    handleStartDateChange(e) {
        let change = false;
        let startDate = this.state.startDate;
        let endDate = this.state.endDate;
        if (e.value) {
            let dStart = new Date(e.value);
            if (dStart && !Number.isNaN(dStart.valueOf())) {                
                this.setState({ startDate: e.value });
                if (this.state.endDate && this.state.endDate.getTime() < dStart.getTime()) {
                    this.setState({ endDate: e.value });
                    endDate = e.value;
                }
                startDate = e.value;
                change = true;
            }
        }
        if(change && !(startDate === this.state.startDate && endDate === this.state.endDate)){
            let filter = this.buildFilter();
            filter.startDate = new Date(startDate ? startDate : new Date());
            filter.endDate = new Date(endDate ? endDate : new Date());
            this.props.loadFilter(filter);
        }
    }

    handleEndDateChange(e) {
        let change = false;
        let startDate = this.state.startDate;
        let endDate = this.state.endDate;
        if (e.value) {
            let dEnd = new Date(e.value);
            if (dEnd && !Number.isNaN(dEnd.valueOf())) {
                this.setState({ endDate: e.value });
                if (this.state.startDate && this.state.startDate.getTime() > dEnd.getTime()) {
                    this.setState({ startDate: e.value });
                    startDate = e.value;
                }
                endDate = e.value;
                change = true;
            }
        }
        if(change && !(startDate === this.state.startDate && endDate === this.state.endDate)){
            let filter = this.buildFilter();
            filter.startDate = new Date(startDate ? startDate : new Date());
            filter.endDate = new Date(endDate ? endDate : new Date());
            this.props.loadFilter(filter);
        }
    }

    optionTemplate(option, emptyValueReplace) {
        return (
            <div>
                <span style={{ color: 'black', visibility: 'visible' }}>
                    {!option.key ? emptyValueReplace : option.value}
                </span>
            </div>
        );
    }
    
    optionTemplateSaleChannels(option, emptyValueReplace) {
        return (
            <div>
                <span style={{ color: 'black', visibility: 'visible' }}>
                    {!option.key ? emptyValueReplace : option.key}
                </span>
            </div>
        );
    }

    batchNumbersOptionTemplate(option, emptyValueReplace) {
        return (
            <div>
                <span style={{ color: 'black', visibility: 'visible' }}>
                    {!option.key ? emptyValueReplace : msg('filter.batch.numbers.option', {p0: option.key, p1: option.value})}
                </span>
            </div>
        );
    }

    getPeriodType() {
        let type = null;
        if (this.props.showDate) {
            let showPeriodType = this.props.showPeriodType && this.props.filterData;
            if (showPeriodType && this.state.periodTypes[0]) {
                type = this.state.periodTypes[0].value;
            } else if (this.props.periodType) {
                type = this.props.periodType;
            } else {
                type = msg('filter.period.type.day');
            }
        }
        return type;
    }

    render() {

        let saleChannels = [
            {key: msg('station.contract.schannel.coco'), value : 'COCO'},
            {key: msg('station.contract.schannel.codo'), value : 'CODO',},
            {key: msg('station.contract.schannel.dodo'), value : 'DODO', },
            {key: msg('station.contract.schannel.lotus'), value : 'LOTUS', }
          ];
        let showTime = this.props.showTime === true ? this.props.showTime : false;
        let applyLabel = this.props.applyLabel ? this.props.applyLabel : msg('filter.apply');
        let resetLabel = this.props.resetLabel ? this.props.resetLabel : msg('filter.reset');
        let showPeriodType = this.props.showPeriodType && this.props.filterData;
        let showChannel = this.props.showChannel && this.props.filterData;
        let showGroupStation = this.props.showGroupStation && this.props.filterData;
        let showBorneType = this.props.showBorneType && this.props.filterData;
        let showAlarmType = this.props.showAlarmType && this.props.filterData;
        let showTransactionPeriod = this.props.showTransactionPeriod ? true : false;
        let showStation = this.props.showStation;
        let showDodoStation = this.props.showDodoStation;
        let showContract = this.props.showContract && this.props.filterData && this.props.filterData.contracts.length > 0;
        let showEquipments = this.props.showEquipments && this.props.filterData && this.props.filterData.equipments;
        let showBatchNumbers = this.props.showBatchNumbers && this.props.filterData && this.props.filterData.batchNumbers;
        let showBorneSerial = this.props.showBorneSerial && this.props.filterData && this.props.filterData.bornes.length > 0;
        let notAssociated = this.state.alarmType && this.state.alarmType.key === "NOT_ASSOCIATED" ? true : false
        let notTransaction = this.state.alarmType && this.state.alarmType.key === "NOT_TRANSACTION" ? true : false
        let periodTypes = [];
        let periodNoTransactions = [];
        if (showPeriodType && this.props.filterData.periodTypes) {
            this.props.filterData.periodTypes.forEach(e => { periodTypes.push({ 'key': e.key, 'value': msg('filter.period.type.' + e.value) }) });
        }
        if (showTransactionPeriod && this.props.filterData && this.props.filterData.periodNoTransactions) {
            this.props.filterData.periodNoTransactions.forEach(e => { periodNoTransactions.push({ 'key': e.key, 'value': msg('filter.period.no.transaction.' + e.value) }) });
        }
        let filterItemClassName = this.props.filterItemClassName ? this.props.filterItemClassName : "p-col-12";
        let filterBoxClassName = this.props.filterBoxClassName ? this.props.filterBoxClassName : "p-col-12";
        let stationsClass = filterBoxClassName + ' ' + ( this.state.stationsPlaceHolderClass ? this.state.stationsPlaceHolderClass : '');
        return (
            <div className="p-grid filter">
                {(this.props.title && <div className="p-col-12 title">{filterBoxClassName ? "" : this.props.title}</div>)}
                {showPeriodType &&
                <div className="p-col-12">
                    <span>{msg('filter.borne.type.cloture')}:</span>
                    <Dropdown value={this.state.periodTypes[0]} options={periodTypes} onChange={(e) => this.setState({ periodTypes: [e.value] })} optionLabel="value"
                        placeholder={msg('filter.borne.type.cloture')} className={filterItemClassName} dataKey="key"/>
                </div>}
                {this.props.showDate &&
                    <div className="p-col-12">
                        {(this.getPeriodType() === msg('filter.period.type.day')) &&
                        <div className="p-grid p-col-12">
                            <div className="p-col-1 from-date"><span>{msg('filter.date.from')}:</span></div>
                            <div className="p-col-11 p-md-5">
                                <Calendar dateFormat='dd/mm/yy' selectOtherMonths={true} hideOnDateTimeSelect={true}
                                    showTime={showTime} hourFormat={'24'} value={this.state.startDate} maxDate={this.state.endDate}
                                    onChange={(e) => this.handleStartDateChange(e)} readOnlyInput={true} locale={this.state.locale}
                                    style={{ marginLeft: '15px' }} />
                            </div>
                            <div className="p-col-1 to-date"><span>{msg('filter.date.to')}:</span></div>
                            <div className="p-col-11 p-md-5">
                                <Calendar dateFormat='dd/mm/yy' showIcon={false} selectOtherMonths={true} hideOnDateTimeSelect={true}
                                    showTime={showTime} hourFormat={'24'} value={this.state.endDate} minDate={this.state.startDate} maxDate={this.state.maxEndDate}
                                    onChange={(e) => this.handleEndDateChange(e)} readOnlyInput={true} locale={this.state.locale} />
                            </div>
                        </div>}
                        {(this.getPeriodType() === msg('filter.period.type.month')) &&
                        <div className="p-grid p-col-12">
                            <div className="p-col-1 from-date"><span>{msg('filter.date.from')}:</span></div>
                            <div className="p-col-11 p-md-5">
                                <Calendar dateFormat='mm/yy' selectOtherMonths={true} hideOnDateTimeSelect={true} view="month"
                                    showTime={false} hourFormat={'24'} value={this.state.startDate} maxDate={this.state.endDate}
                                    onChange={(e) => { this.setState({ startDate: e.value }); }} readOnlyInput={true} locale={this.state.locale}
                                    style={{ marginLeft: '15px' }} />
                            </div>
                            <div className="p-col-1 to-date"><span>{msg('filter.date.to')}:</span></div>
                            <div className="p-col-11 p-md-5">
                                <Calendar dateFormat='mm/yy' showIcon={false} selectOtherMonths={true} hideOnDateTimeSelect={true} view="month"
                                    showTime={false} hourFormat={'24'} value={this.state.endDate} minDate={this.state.startDate} maxDate={this.state.maxEndDate}
                                    onChange={(e) => { this.setState({ endDate: e.value }) }} readOnlyInput={true} locale={this.state.locale} />
                            </div>
                        </div>}
                        {(this.getPeriodType() === msg('filter.period.type.year')) &&
                        <div className="p-grid p-col-12">
                            <div className="p-col-1 from-date"><span>{msg('filter.date.from')}:</span></div>
                            <div className="p-col-11 p-md-5">
                                <Calendar type="month"
                                    showTime={false} value={this.state.startDate} maxDate={this.state.endDate}
                                    onChange={(e) => { this.setState({ startDate: e.value }); }} readOnlyInput={true} locale={this.state.locale}
                                    style={{ marginLeft: '15px' }}
                                    view="month"
                                    dateFormat="mm/yy"
                                    yearNavigator
                                    yearRange="2018:2100" />
                            </div>
                            <div className="p-col-1 to-date"><span>{msg('filter.date.to')}:</span></div>
                            <div className="p-col-11 p-md-5">
                                <Calendar dateFormat='mm/yy' showIcon={false} selectOtherMonths={true} hideOnDateTimeSelect={true} view="month"
                                    showTime={false} hourFormat={'24'} value={this.state.endDate} minDate={this.state.startDate} maxDate={this.state.maxEndDate}
                                    onChange={(e) => { this.setState({ endDate: e.value }) }} readOnlyInput={true} locale={this.state.locale} yearNavigator
                                    yearRange="2010:2100" />
                            </div>
                        </div>}
                    </div>}
                {showGroupStation &&
                <div className={filterBoxClassName}>
                    <Dropdown value={this.state.groupStations[0]} options={this.props.filterData.groupStations}
                        itemTemplate={o => this.optionTemplate(o, msg('filter.groupStation.all'))}
                        onChange={this.handleGroupStationChange}
                        optionLabel="value" placeholder={msg('filter.groupStation.all')} className={filterItemClassName} dataKey="key"/>
                </div>}
                {showChannel && this.state.saleChannels &&
                <div className={filterBoxClassName}>
                    <Dropdown value={this.state.saleChannels[0]} options={saleChannels}
                        itemTemplate={o => this.optionTemplateSaleChannels(o, msg('filter.channels.all'))}
                        onChange={this.handleChannelChange}                        
                        optionLabel="key" 
                        placeholder={msg('filter.channels.all')} className={filterItemClassName} dataKey="value"/>
                </div>}
                {showContract &&
                <div className={filterBoxClassName}>
                    <Dropdown value={this.state.contracts[0]} options={this.props.filterData.contracts}
                        itemTemplate={o => this.optionTemplate(o, msg('filter.contracts.all'))}
                        onChange={this.handleContractChange}
                        optionLabel="value" placeholder={msg('filter.contracts.all')}
                        className="p-col-12" dataKey="key"/>
                </div>}
                {showStation &&
                <div className={stationsClass} >
                    {this.props.stationRequired && <span className="obligatoire"> *</span>}                    
                    <AutoComplete minLength={0} placeholder={msg('filter.stations.all')} 
                        id="stations" dropdown={true} multiple={this.props.stationSingle ?  false : true} delay={100}
                        field="value" suggestions={this.state.filteredStations} value={this.props.stationSingle ? this.state.stations[0] : this.state.stations}
                        completeMethod={this.filterStations} onDropdownClick={e => this.filterStations(e, true)}
                        onChange={this.handleStationChange} onSelect={this.handleStationSelect} className={filterItemClassName}
                        inputStyle={this.props.styleStationInputAutocomplete} />
                    {this.props.stationRequired && this.state.stationInputError && <Message severity="error" text={msg('filter.stations.required')} key="stationsErr" />}                
                </div>}
                {showDodoStation &&
                    <div className={stationsClass} >
                        {this.props.stationRequired && <span className="obligatoire"> *</span>}
                        <AutoComplete minLength={0} placeholder={msg('filter.dodo.stations.all')}
                            id="dodoStations" dropdown={true} multiple={this.props.stationSingle ? false : true} delay={100}
                            field="value" suggestions={this.state.filteredStations} value={this.props.stationSingle ? this.state.stations[0] : this.state.stations}
                            completeMethod={this.filterStations} onDropdownClick={e => this.filterStations(e, true)}
                            onChange={this.handleStationChange} onSelect={this.handleStationSelect} className={filterItemClassName}
                            inputStyle={this.props.styleStationInputAutocomplete} />
                        {this.props.stationRequired && this.state.stationInputError && <Message severity="error" text={msg('filter.stations.required')} key="dodoStationsErr" />}
                    </div>}
                {showBorneType &&
                <div className={filterBoxClassName}>
                    <Dropdown value={this.state.borneTypes[0]} options={this.props.filterData.borneTypes}
                        itemTemplate={o => this.optionTemplate(o, msg('filter.bornetypes.all'))}
                        onChange={this.handleBorneTypeChange}
                        optionLabel="value" placeholder={msg('filter.bornetypes.all')} className={filterItemClassName} dataKey="key"/>
                </div>}
                {showEquipments &&
                <div className={filterBoxClassName}>
                    <Dropdown value={this.state.equipments[0]} options={this.props.filterData.equipments}
                        itemTemplate={o => this.optionTemplate(o, msg('filter.equipments.all'))} onChange={(e) => this.handleEquipmentChange(e)}
                        optionLabel="value" placeholder={msg('filter.equipments.all')} className={filterItemClassName}
                        disabled={this.props.filterData.equipments.length === 0} dataKey="key"/>
                </div>}
                {showBatchNumbers &&
                <div className={"p-col-12"}>
                    <Dropdown value={this.state.batchNumbers[0]} options={this.props.filterData.batchNumbers}
                        itemTemplate={o => this.batchNumbersOptionTemplate(o, msg('filter.batch.numbers.all'))} onChange={(e) => this.setState({ batchNumbers: [e.value] })}
                        optionLabel="label" placeholder={msg('filter.batch.numbers.all')} 
                        disabled={this.props.filterData.batchNumbers.length === 0} className="p-sm-12 p-md-4" dataKey="label"/>
                </div>}
                {showAlarmType &&
                <div className={filterBoxClassName}>
                    <Dropdown value={this.state.alarmType} options={this.state.alarmTypes}
                        onChange={(e) => this.setState({ alarmType: e.value })}
                        optionLabel="label" placeholder={msg('filter.alarms.all')} className={filterItemClassName} dataKey="key"/>
                </div>}
                {showBorneSerial && !notAssociated &&
                <div className={filterBoxClassName}>
                    <Dropdown value={this.state.bornes[0]} options={this.props.filterData.bornes}
                        itemTemplate={o => this.optionTemplate(o, msg('filter.bornes.all'))} onChange={(e) => this.setState({ bornes: [e.value] })}
                        optionLabel="value" placeholder={msg('filter.bornes.all')} className={filterItemClassName} dataKey="key"/>
                </div>}
                {showTransactionPeriod && notTransaction &&
                <div className={filterBoxClassName}>
                    <Dropdown value={this.state.periodNoTransactions[0]} options={periodNoTransactions}
                        itemTemplate={o => this.optionTemplate(o, msg('filter.period.no.transaction.all'))} onChange={(e) => this.setState({ periodNoTransactions: [e.value] })}
                        optionLabel="value" placeholder={msg('filter.period.no.transaction.all')} className={filterItemClassName} dataKey="key"/>
                </div>}
                <div className={this.props.filterBoxClassName ? "p-col-1 apply-btn" : "p-col-6 apply-btn"} style={{ width: 'auto', paddingTop: '15px' }} >
                    <Button label={applyLabel} title={applyLabel} onClick={this.apply} key={applyLabel} />
                </div>
                <div className={this.props.filterBoxClassName ? "p-col-1 reset-btn" : "p-col-6 reset-btn"} style={{ width: 'auto', paddingTop: '15px' }} >
                    <Button label={resetLabel} title={resetLabel} onClick={this.reset} key={resetLabel} />
                </div>
            </div>);
    }
}

export default BorneFilter;