import { AUTHORISATION, AUTHORISATION_SUCCESS, ADD_ROLE, ADD_ROLE_SUCCESS, LOAD_ROLE, LOAD_ROLE_SUCCESS, ROLES, ROLES_SUCCESS, ROLE_DELETE_BY_ID, ROLE_DELETE_BY_ID_SUCCESS, ROLEUPDATE, ROLEUPDATE_SUCCESS, ROLE_UPDATE, ROLE_UPDATE_SUCCESS } from "../actions/role";

const state0 = {data: null, error: null};


export const rolesReducer = (state = state0, action) => {
	switch (action.type) {
		case ROLES:
			return {
				state,
				error: null
			};
		case ROLES_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
			};
		default:
			return state;
	}
};

export  const authorisationsReducer = (state = state0, action) => {
	switch (action.type) {
		case AUTHORISATION:
			return {
				state,
				error: null
		};
		case AUTHORISATION_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};

export  const addRoleReducer = (state = state0, action) => {
	switch (action.type) {
		case ADD_ROLE:
			return {
				state,
				error: null
		};
		case ADD_ROLE_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};

export const loadRoleReducer = (state = state0, action) => {
	switch (action.type) {
		case LOAD_ROLE:
			return {
				state,
				error: null
		};
		case LOAD_ROLE_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};

export const roleDeleteByIdReducer = (state = state0, action) => {
	switch (action.type) {
		case ROLE_DELETE_BY_ID:
			return {
				state,
				error: null
			};
		case ROLE_DELETE_BY_ID_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
			};
		default:
			return state;
	}
};

export const roleReducer = (state = state0, action) => {
	switch (action.type) {
		case ROLEUPDATE:
		case ROLEUPDATE_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
			};
		default:
			return state;
	}
};

export const roleUpdateReducer = (state = state0, action) => {
	switch (action.type) {
		case ROLE_UPDATE:
			return {
				state,
				error: null
			};
		case ROLE_UPDATE_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
			};
		default:
			return state;
	}
};