import React, { Component } from 'react';
import {windowUtils} from '../../../ui/WindowUtils';
import ActivityAnalysis from '../../common/ActivityAnalysis';
import BorneFilter from '../../common/BorneFilter';
import {rolesValidator, TRADE_EXPLOIT} from '../../../utils/RolesValidator.js';
import msg from '../../../texts/msg';

export default class ExploitAnalysis extends Component {

    spiltPrestPayments(paymentItems) {
        if(!paymentItems){
            return null;
        }
        let p1 = {}
        let p2 = {};
        for (let e in paymentItems){
            if(paymentItems[e].type === 'GR' || paymentItems[e].type === 'LICENSE_PLATE'){
                p2[e] = paymentItems[e];
            } else if(paymentItems[e].type === 'TW' || paymentItems[e].type === 'PJEGR' 
                || paymentItems[e].type === 'PAID_CODE' || paymentItems[e].type === 'FREE_CODE'
                || paymentItems[e].type === 'HAPPY_HOUR'){
                let ee = p2[e] ? p2[e] : {label: 'TW/PJ/CO', amount: p2['TW/PJ/CO'] ? p2['TW/PJ/CO'].amount : 0};
                ee.amount = ee.amount + paymentItems[e].amount;
                p2['TW/PJ/CO'] = ee;
            } else if(paymentItems[e].type === 'CB' || paymentItems[e].type === 'CASH') {
                p1[e] = paymentItems[e];
            }
        }
        return [p1, p2];
    }

    filterVentePayments(paymentItems) {
        if(!paymentItems){
            return null;
        }
        let p = {};
        for (let e in paymentItems){
            if(paymentItems[e].type === 'GR' || paymentItems[e].type === 'CB' || paymentItems[e].type === 'CASH'){
                p[e] = paymentItems[e];
            } else if(paymentItems[e].type === 'TW' || paymentItems[e].type === 'PJEGR'){
                let ee = p[e] ? p[e] : {label: 'TW/PJE', amount: 0};
                ee.amount = ee.amount + paymentItems[e].amount;
                p['TW/PJE'] = ee;
            }
        }
        return p;
    }

    buildGlobalHeader(outMandatedAmount, commissionAmount) {
        let activityImageSrc="/assets/ultima/layout/images/cartadis/Vente_blanc.svg";
        return (
            <div className="p-col-12 p-grid activity-analysis global-header">
                <div className="p-col-6 p-grid activity-analysis global-header">
                    <div className="p-col-4 out-mandate-icon">
                        <img id="imageActivity"  type="image" src={activityImageSrc} alt={activityImageSrc}/>
                    </div>
                    <div className="p-col-8 p-grid out-mandate-amount">
                        <div className="p-col-12">{msg('exploit.analysis.outmandated.ca')}</div>
                        <div className="p-col-12">{outMandatedAmount.toFixed(2) + ' €'}</div>
                    </div>
                </div>
                <div className="p-col-6 p-grid activity-analysis global-header">
                    <div className="p-col-4 com-mandate-icon">
                        <span className="pi-md-rotate-90-degrees-ccw"/>
                    </div>
                    <div className="p-col-8 p-grid com-mandate-amount">
                        <div className="p-col-12">{msg('exploit.analysis.total.mandate')}</div>
                        <div className="p-col-12">{commissionAmount.toFixed(2) + ' €'}</div>
                    </div>
                </div>
            </div>
        );
    }

    buildSubHeader(subHeader) {
        return (
        <div className="p-col-12 p-grid sub-header">
            <div className="p-col-8 label">{subHeader[0].label}</div>
            <div className="p-col-4 amount" style={{color: subHeader[0].color}}>{subHeader[0].amount}</div>
            <div className="p-col-8 label">{subHeader[1].label}</div>
            <div className="p-col-4 amount" style={{color: subHeader[1].color}}>{subHeader[1].amount}</div>
        </div>);
    }

	render() {		
		let authorized = rolesValidator.validate([TRADE_EXPLOIT]);
        let data = this.props.data && this.props.data.activities ? this.props.data.activities.year : null;
        let commissionAmount = data && data.commissionAmount ? data.commissionAmount : 0;
        let prestations = {amount: 0, mandatedAmount: 0, outMandatedAmount: 0};
        if(data && data.prestations){
            let p = this.spiltPrestPayments(data.prestations.paymentItems);
            prestations.amount = data.prestations.amount;
            prestations.mandatedAmount = data.prestations.mandatedAmount;
            prestations.outMandatedAmount = data.prestations.outMandatedAmount;
            prestations.paymentItems = p ? p[0] : null;
            prestations.typeItems = p ? p[1] : null;
        }
        let ventes = {amount: 0, outMandatedAmount: 0, reloadAmount: 0};
        if(data && data.ventes){
            ventes.amount = data.ventes.amount;
            ventes.outMandatedAmount = data.ventes.outMandatedAmount;
            ventes.reloadAmount = data.ventes.reloadAmount;
            ventes.paymentItems = this.filterVentePayments(data.ventes.paymentItems);
        }
        let prestSubHeaderData = [{label: msg('exploit.analysis.prest.outmandated'), amount:  prestations.outMandatedAmount.toFixed(2) + ' €', color: '#00b752'}, 
                              {label: msg('exploit.analysis.prest.mandated'), amount:  prestations.mandatedAmount.toFixed(2) + ' €', color: '#00b752'}];
        let venteSubHeaderData = [{label: msg('exploit.analysis.vente'), amount: ventes.amount.toFixed(2) + ' €', color: '#4b99d3'}, 
                                {label: msg('exploit.analysis.vente.rec'), amount: ventes.reloadAmount.toFixed(2) + ' €', color: '#4b99d3'}];
        
        let globalHeader = this.buildGlobalHeader(ventes.outMandatedAmount, commissionAmount);
        let prestSubHeader = this.buildSubHeader(prestSubHeaderData);
        let venteSubHeader = this.buildSubHeader(venteSubHeaderData);
        
        let filter =   (<div className="p-col-12 p-md-4 activity-analysis" style={{margin: 0}}>
            <BorneFilter  apply={this.props.load} reset={this.props.load}
                loadFilter={(filter) => this.props.loadFilter(filter)} loadFilterStations={this.props.loadFilterStations}
                filterData={this.props.filterData} filterStations={this.props.filterStations}
				showDate={true} showStation={true} showEquipment={true} showPeriodType={false} 
				title={msg('analysis.analysisPeriod')} setDataInfoBar ={this.props.setDataInfoBar}/> 
        </div>);

		return authorized && (
			<div className="p-grid p-fluid dashboard" >
				{!windowUtils.isDesktop() && filter}
                <div className="p-col-12 p-md-8 p-grid activity-analysis exploit">
                    {globalHeader}
                    <div className="p-col-12 p-md-6 activity-analysis">
                        <ActivityAnalysis activityClass="prest"  activityIcon="local-car-wash" label={msg('featurekey')} 
                                data={ prestations} chartLabels={ [msg('payment.chart.outmandated.title'), msg('payment.chart.mandated.title')] }
                                itemValueName={'amount'} unit={'€'} rounding={true} firstCharItemsName={'paymentItems'} secondCharItemsName={'typeItems'}
                                subHeader={prestSubHeader} hValueName={'amount'} hUnit={'€'} hideHeaderValue={true}/>
                    </div>
                    <div className="p-col-12 p-md-6 activity-analysis">
                        <ActivityAnalysis activityClass="vente"  activityImageSrc="/assets/ultima/layout/images/cartadis/Vente_bleu.svg" label={msg('sellingkey')} 
                                data={ ventes } chartLabels={ [msg('payment.chart.title')]}  subHeader={venteSubHeader} hValueName={'amount'} hUnit={'€'}
                                itemValueName={'amount'} unit={'€'} rounding={true} firstCharItemsName={'paymentItems'} hideHeaderValue={true}/>
                    </div>
                </div>				
                {windowUtils.isDesktop() && filter}
            </div>
		);
	}
}