import React, { Component } from 'react';
import { Panel } from 'primereact/panel';
import { ActivityAnalysisChart } from '../../common/ActivityAnalysisChart';

export default class ActivityType extends Component {
	render() {
		let screenSize  = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		let activityImageSrc= this.props.activityImageSrc;
		let evol = this.props.evol;
		let evolClass = evol && evol !== 0 && (evol > 0 ? "pi pi-md-arrow-upward" : "pi pi-md-arrow-downward");

		let amount = this.props.amount ? this.props.amount : 0.00;

		const header = (<div className={"p-grid p-col-12 " + this.props.type} style={{ marginTop: "0px" }}>
				<div className={"p-col-3 " +   this.props.type + "-icon"}>
					{activityImageSrc ?<img className={"imageActivity"} id="imageActivity" type="image" src={activityImageSrc} alt="activity" />:<span className={"pi pi-md-" + this.props.icon} />}
				</div>
				<div className={"p-col-6 " + this.props.type + "-amount"}>
					<span className="title"><h2>{this.props.title}</h2></span>
					<span className="amount"><h1>{amount.toFixed(2)}€</h1></span>
				</div>
				<div className={"p-col-3 " + this.props.type + "-arrow"}>
					{evol && (<span>{evol + '%'} <i className={evolClass} ></i></span>)}
				</div>
			</div>);

		let charts = this.props.charts.map((chart, i) => {
			return chart.items && (
			<div key={i}>
				<span className={"chart-title-" + this.props.type}>{chart.title}</span>
				<div className={"p-grid " + this.props.type + " p-col-12 p-activity-commerce"} >
					<div className="p-lg-12 p-xl-12 p-sm-12 p-md-12 activity-chart">
						{<ActivityAnalysisChart type={chart.type} data={chart.items} itemValueName={'amount'} unit={'€'} rounding={true} />}
					</div>
				</div>
			</div>);
		});
 
		return (
			<Panel className="p-col-12 " header={header} style={{ marginTop: '2em' }} toggleable={true}  isOpen={false}  collapsed={screenSize<768 }>
				{charts}
			</Panel>
		);
	}
}