import React, { Component } from 'react'
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import TableData from '../../../utils/TableData';
import { rolesValidator, ROLE, CREATE, READ, UPDATE, DELETE } from '../../../utils/RolesValidator';
import { userSessionParamsHandler } from '../../../utils/UserSessionParamsHandler';
import msg from '../../../texts/msg';
import MessageDialog from '../../common/MessageDialog';
import './role.css';
import '../../common/css/common.css';
import { progressSpinnerHandler } from '../../../utils/ProgressSpinnerHandler'


export class Roles extends Component {

    constructor(props) {
        super(props);
        this.state = {
            first: 0,
            buttonSelectd: false,
            display: false,
            closable: false,
            isLignSelected: false,
            isApiError: false,
            filter : {}
        }
        this.loadRoles = this.loadRoles.bind(this);
        this.onSelection = this.onSelection.bind(this);
        this.deleteRole = this.deleteRole.bind(this);
        this.update = this.update.bind(this);
        this.name = this.name.bind(this);
        this.description = this.description.bind(this);
    }

    componentDidMount() {
        progressSpinnerHandler.show(true); 
        const page = 0;
        const pageSize = userSessionParamsHandler.getPageSize();
        this.loadRoles(page, pageSize);
        this.props.setDataInfoBar(null);
    }
    
    componentDidUpdate(prevProps) {
        let prevError = prevProps.error && prevProps.error.timestamp;
        let lastError = this.props.error && this.props.error.timestamp;
        let error = (lastError && (!prevError || prevError < lastError));
        if (error) {
            progressSpinnerHandler.show(false);
            let isError = this.props.error.message && this.props.error.message.startsWith('error.users.') ? true : false;
            this.setState({ isApiError: isError });
        }
        if(this.props.roles && this.props.roles !== prevProps.roles){
            progressSpinnerHandler.show(false);
        }
        let prevDelete = prevProps.roleDeleteById && prevProps.roleDeleteById.lastChange;
        let lastDelete = this.props.roleDeleteById && this.props.roleDeleteById.lastChange;
        let reload = (lastDelete && (!prevDelete || prevDelete < lastDelete));
        if (reload) {
            const page = 0;
            const pageSize = userSessionParamsHandler.getPageSize();
            this.loadRoles(page, pageSize);
        }
    }

    update() {
        if (this.state.roleId && this.state.buttonSelectd) {
            this.props.loadRoleupdate(this.state.roleId);
            this.setState({ buttonSelectd: false });
            this.props.history.push('/updateRole');
        } else {
            this.setState({ isLignSelected: true });
        }
    }

    deleteRole() {
        if (this.state.roleId) {
            this.setState({ showConfirm: true });
        } else {
            this.setState({ isLignSelected: true });
        }
    }

    confirmDeleteRole() {
        if (this.state.roleId) {
            this.props.dRole(this.state.roleId);
            this.setState({ buttonSelectd: false });
        } else {
            this.setState({ isLignSelected: true });
        }
        this.setState({ buttonSelectd: false });
    }

    loadRoles(page, pageSize) {
        progressSpinnerHandler.show(true); 
        this.props.loadRoles(page, pageSize);
    }

    onSelection(event) {
        if (event.value) {
            this.setState({ roleId: event.value.id });
            this.setState({ roleName: event.value.name });
            this.setState({ buttonSelectd: true });
        }
    }

    name(rowData) {
        let name = rowData.name ? rowData.name : "";
        if (name != null && name.length > 50)
            return <div className="hasTooltip p-ellipsis"> {name} <span> {name}  </span></div>;
        else
            return <div> {name} </div>

    }

    description(rowData) {
        let description = rowData.description ? rowData.description : "";
        if (description != null && description.length > 80)
            return <div className="hasTooltip p-ellipsis"> {description} <span> {description}  </span></div>;
        else
            return <div> {description} </div>
    }

    render() {
        let authorized = rolesValidator.validate([ROLE]);
        let cAuthorized = authorized.toString().includes(CREATE);
        let rAuthorized = authorized.toString().includes(READ);
        let uAuthorized = authorized.toString().includes(UPDATE);
        let dAuthorized = authorized.toString().includes(DELETE);

        let rolesColumns = [
            { isColWithBody: false, field: "id", header: "id", sortable: true, className: "visibilityHidden" },
            { isColWithBody: true, field: "name", header: msg('role.name'), body: this.name, sortable: false, className: "p-col-2" },
            { isColWithBody: true, field: "description", header: msg('role.description'), body: this.description, sortable: false, className: "p-col-3" },

        ];

        let roles = this.props.roles && this.props.roles.roles ? this.props.roles.roles : [];
        let totalPages = this.props.roles && this.props.roles.roles ? this.props.roles.totalPages : 0;
        let roleOutilsBar = [
            { filename: "Liste des roles.xlsx", iconSrc: "/assets/ultima/layout/images/cartadis/excel.svg", titleIcone: "Roles", tooltipTextIcone: msg('role.tooltip.export'), urlExport: "roles/roles-excel" }
        ]
        let ContextMenuContent = [
            {
              label: msg('role.update'),
              command: this.update
            },
            {
              label: msg('role.delete'),
              command: this.deleteRole
            }];
        let rolesTable = (
            <TableData data={roles} titleTable={msg('role.title.table')} totalPages={totalPages}
                ContextMenuContent={ContextMenuContent}
            	selectionMode={"single"} selection={this.state.rowSelection}
                onSelection={this.onSelection} outilsBar={roleOutilsBar} filter={this.state.filter} 
                getDataFunction={this.loadRoles} paramnFunctionData={null}                
                columns={rolesColumns} searchInTable={false} searchByName={false} />);


        return rAuthorized &&
            (
                <div className="card p-grid commonClass roleClass">
                    <div className="p-grid p-col-12">
                        <div className="p-col-4 p-md-4"><h2 className="roles-title">{msg('role.table.title')}</h2></div>
                        
                        <div className="p-grid p-col-8 p-md-8 item buttonPosition">
                            <div className={this.state.buttonSelectd ? "btnSelected item" : "btnNotSelected item"}>
                                <Button label={(msg('role.update'))} title={(msg('role.update'))} onClick={this.update} key="update"disabled={!(uAuthorized || rAuthorized)} />
                            </div>
                            <div className={this.state.buttonSelectd ? "btnSelected item" : "btnNotSelected item"} >
                                <Button label={(msg('role.delete'))} title={(msg('role.delete'))} onClick={() => { this.deleteRole() }} key="delete" disabled={!dAuthorized} />
                            </div>
                            <div className="btnSelected item">
                                <Link to="/CreateRole">
                                    <Button label={(msg('role.create.role'))} title={(msg('role.create.role'))} className="button" key="create" disabled={!cAuthorized} />
                                </Link>
                            </div>
                        </div>
                        <div className="p-col-12">
                            {rolesTable}
                        </div>                        
                    </div>
                    <MessageDialog className={'message-dialog error-message'} visible={this.state.isApiError} title={msg('message.dialog.error.title')}
                        message={this.state.isApiError && msg(this.props.error.message)}
                        onHide={() => this.setState({ isLignSelected: false, isApiError: false })} />
                    <MessageDialog confirm={true} className={'message-dialog confirm-message'} visible={this.state.showConfirm} title={msg('message.dialog.confirm.title')}
                        message={this.state.showConfirm && msg('role.delete.confirm.message', { roleName: this.state.roleName })} key="confirm-delete"
                        onConfirm={() => { this.setState({ showConfirm: false }); this.confirmDeleteRole() }} onCancel={() => this.setState({ showConfirm: false })} />
                </div >
            );
    }

}