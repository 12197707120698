import {NOTIFICATIONS_LOAD, NOTIFICATIONS_LOAD_SUCCESS,
	NOTIFICATION_LOAD_BY_ID, NOTIFICATION_LOAD_BY_ID_SUCCESS,
	NOTIFICATION_SEND_BY_ID, NOTIFICATION_SEND_BY_ID_SUCCESS,
	NOTIFICATION_SEND, NOTIFICATION_SEND_SUCCESS,
	NOTIFICATION_CREATE, NOTIFICATION_CREATE_SUCCESS,
	NOTIFICATION_UPDATE, NOTIFICATION_UPDATE_SUCCESS,
	NOTIFICATION_DELETE_BY_ID, NOTIFICATION_DELETE_BY_ID_SUCCESS,
	CREATE_CAMPAIGN, CREATE_CAMPAIGN_SUCCESS, UPDATE_CAMPAIGN, UPDATE_CAMPAIGN_SUCCESS, 
	LOAD_CAMPAIGNS, LOAD_CAMPAIGNS_SUCCESS,
	LOAD_FIRMWARES, LOAD_FIRMWARES_SUCCESS,
	LOAD_HARDWARES, LOAD_HARDWARES_SUCCESS,
	LOAD_PRICE_ZONES, LOAD_PRICE_ZONES_SUCCESS,
	LOAD_STATION_EQUIPMENTS, LOAD_STATION_EQUIPMENTS_SUCCESS,
	LOAD_CAMPAIGN, LOAD_CAMPAIGN_SUCCESS, LAUNCH_CAMPAIGN, LAUNCH_CAMPAIGN_SUCCESS,
	DELETE_CAMPAIGN, DELETE_CAMPAIGN_SUCCESS, LOAD_BORNES_STATUS, 
	LOAD_BORNES_STATUS_SUCCESS, LOAD_BORNES_ANALYSIS, LOAD_BORNES_ANALYSIS_SUCCESS,
	LOAD_DEFAULT_SUBSCRIBERS_SUCCESS, LOAD_DEFAULT_SUBSCRIBERS,
	LOAD_CAMPAIGN_EVENTS, LOAD_CAMPAIGN_EVENTS_SUCCESS } from '../actions/maintenance';


const state0 = {data: null, error: null};

export  const notificationsLoadReducer = (state = state0, action) => {
	switch (action.type) {
		case NOTIFICATIONS_LOAD:
			return {
				state,
				error: null
		};
		case NOTIFICATIONS_LOAD_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};

export  const notificationLoadByIdReducer = (state = state0, action) => {
	switch (action.type) {
		case NOTIFICATION_LOAD_BY_ID:
			return {
				state,
				error: null
		};
		case NOTIFICATION_LOAD_BY_ID_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};

export  const notificationDeleteByIdReducer = (state = state0, action) => {
	switch (action.type) {
		case NOTIFICATION_DELETE_BY_ID:
			return {
				state,
				error: null
		};
		case NOTIFICATION_DELETE_BY_ID_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};

export  const notificationCreateReducer = (state = state0, action) => {
	switch (action.type) {
		case NOTIFICATION_CREATE:
			return {
				state,
				error: null
		};
		case NOTIFICATION_CREATE_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};

export  const notificationUpdateReducer = (state = state0, action) => {
	switch (action.type) {
		case NOTIFICATION_UPDATE:
			return {
				state,
				error: null
		};
		case NOTIFICATION_UPDATE_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};


export  const notificationSendReducer = (state = state0, action) => {
	switch (action.type) {
		case NOTIFICATION_SEND:
			return {
				state,
				error: null
		};
		case NOTIFICATION_SEND_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};

export  const notificationSendByIdReducer = (state = state0, action) => {
	switch (action.type) {
		case NOTIFICATION_SEND_BY_ID:
			return {
				state,
				error: null
		};
		case NOTIFICATION_SEND_BY_ID_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};

export const loadDefaultSubscribersReducer = (state = state0, action) => {
	switch (action.type) {
		case LOAD_DEFAULT_SUBSCRIBERS:
			return {
				state,
				error: null 
		};
		case LOAD_DEFAULT_SUBSCRIBERS_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};

export const loadCampaignsReducer = (state = state0, action) => {
	switch (action.type) {
		case LOAD_CAMPAIGNS:
			return {
				state,
				error: null
		};
		case LOAD_CAMPAIGNS_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};

export const loadCampaignReducer = (state = state0, action) => {
	switch (action.type) {
		case LOAD_CAMPAIGN:
			return {
				state,
				error: null
		};
		case LOAD_CAMPAIGN_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};

export const createCampaignReducer = (state = state0, action) => {
	switch (action.type) {
		case CREATE_CAMPAIGN:
			return {
				state,
				error: null
		};
		case CREATE_CAMPAIGN_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};

export const updateCampaignReducer = (state = state0, action) => {
	switch (action.type) {
		case UPDATE_CAMPAIGN:
			return {
				state,
				error: null
		};
		case UPDATE_CAMPAIGN_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};

export const launchCampaignReducer = (state = state0, action) => {
	switch (action.type) {
		case LAUNCH_CAMPAIGN:
			return {
				state,
				error: null
		};
		case LAUNCH_CAMPAIGN_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};

export const deleteCampaignReducer = (state = state0, action) => {
	switch (action.type) {
		case DELETE_CAMPAIGN:
			return {
				state,
				error: null
		};
		case DELETE_CAMPAIGN_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};

export const loadFirmwaresReducer = (state = state0, action) => {
	switch (action.type) {
		case LOAD_FIRMWARES:
			return {
				state,
				error: null
		};
		case LOAD_FIRMWARES_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};

export const loadHardwaresReducer = (state = state0, action) => {
	switch (action.type) {
		case LOAD_HARDWARES:
			return {
				state,
				error: null
		};
		case LOAD_HARDWARES_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};

export const loadPriceZonesReducer = (state = state0, action) => {
	switch (action.type) {
		case LOAD_PRICE_ZONES:
			return {
				state,
				error: null
		};
		case LOAD_PRICE_ZONES_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};

export const loadStationEquipmentsReducer = (state = state0, action) => {
	switch (action.type) {
		case LOAD_STATION_EQUIPMENTS:
			return {
				state,
				error: null
		};
		case LOAD_STATION_EQUIPMENTS_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};

export const loadCampaignEventsReducer = (state = state0, action) => {
	switch (action.type) {
		case LOAD_CAMPAIGN_EVENTS:
			return {
				state,
				error: null
		};
		case LOAD_CAMPAIGN_EVENTS_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};

export const loadBornesStatusReducer = (state = state0, action) => {
	switch (action.type) {
		case LOAD_BORNES_STATUS:
			return {
				state,
				error: null
		};
		case LOAD_BORNES_STATUS_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};

export const loadBornesAnalysisReducer = (state = state0, action) => {
	switch (action.type) {
		case LOAD_BORNES_ANALYSIS:
			return {
				state,
				error: null
		};
		case LOAD_BORNES_ANALYSIS_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};