import { of} from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, switchMap, catchError } from  'rxjs/operators';
import { ofType } from 'redux-observable';

import {LOGIN, LOGOUT, loginSuccess, logoutSuccess, REDIRECT_TO_PROVIDER, redirectToProviderSuccess, REDIRECT_TO_API_SERVER, redirectToApiServerSuccess, 
	loadRoleFeaturesSuccess, LOAD_ROLEFEATURES } from '../actions/login';
import { faillure } from '../actions';
import { authProviderType } from '../AuthProviderType';

export const loginEpic = (epicAction)  => {	
	return  epicAction.pipe(
		ofType(LOGIN),
		switchMap((action) => {
			let apiRootUri = authProviderType.getApiRootUri();
			let reqObject = {
				url: apiRootUri + "auth/login",
				method: 'POST',
				headers:{'Content-Type': 'application/x-www-form-urlencoded'},
				body: {'username': action.data.username,'password': action.data.password, 'tzoffset': new Date().getTimezoneOffset() },
				withCredentials: true,
				timestamp: new Date().getTime()
			  }		
			  
				
			const req = ajax(reqObject);
			return req.pipe(map(res => loginSuccess(res)), catchError(res => of(faillure(res))));
		})
	);
};

export const logoutEpic = (epicAction)  => {
	return  epicAction.pipe(
		ofType(LOGOUT),
		switchMap((action) => {
			let apiDomainUri = authProviderType.getApiDomainUri();
			let apiRootUri = authProviderType.getApiRootUri();
			let logoutUrl =  (authProviderType.getAuthType() === 'cartadis' ? apiRootUri + "auth/logout" :  apiDomainUri + "total/auth/oauthlogout");
			let logoutMethod = (authProviderType.getAuthType() === 'cartadis' ?  'POST' : 'GET');
			const req = ajax({
			  url: logoutUrl,
			  method: logoutMethod,
			  withCredentials: true,
			  timestamp: new Date().getTime()
			});
			return req.pipe(map(res => logoutSuccess(res)), catchError(res => of(faillure(res))));
		})
	);	
};

export const loadRoleFeaturesEpic = (epicAction)  => {
	return  epicAction.pipe(
		ofType(LOAD_ROLEFEATURES),
		switchMap((action) => {
			const req = ajax({
			  url: authProviderType.getApiRootUri() + "auth/rolefeatures",
			  method: 'GET',
			  headers: {
				'Content-Type': 'application/json'
			  },
			  withCredentials: true,
			});
			return req.pipe(map(res => loadRoleFeaturesSuccess(res)), catchError(res => of(faillure(res))));
		})
	);	
};

export const redirectToProviderEpic = (epicAction)  => {
	return  epicAction.pipe(
		ofType(REDIRECT_TO_PROVIDER),
		switchMap((action) => {
			const req = ajax({
			  url: authProviderType.getApiDomainUri() + "oauth2/authorization/" + action.data.authRegistrationID,
			  method: 'GET',
			  withCredentials: true,
			  timestamp: new Date().getTime()
			});
			return req.pipe(map(res => redirectToProviderSuccess(res)), catchError(res => of(faillure(res))));
		})
	);	
};

export const redirectToApiServerEpic = (epicAction)  => {	
	return  epicAction.pipe(
		ofType(REDIRECT_TO_API_SERVER),
		switchMap((action) => {
			let apiDomainUri = authProviderType.getApiDomainUri();
			const req = ajax({
				url: apiDomainUri + "login/oauth2/code/"+ action.data.authRegistrationID + window.location.search,
				method:'POST',
				withCredentials: true,
				timestamp: new Date().getTime()
			  });
			return req.pipe(map(res => redirectToApiServerSuccess(res)), catchError(res => of(faillure(res))));
		})
	);
};