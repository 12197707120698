
export const  PAGE_SIZE_CHANGE = 'PAGE_SIZE_CHANGE';
export const  FILTER_CHANGE = 'FILTER_CHANGE';

export const onPageSizeChange = (pageSize, id) => {
	return ({
		type: PAGE_SIZE_CHANGE + (id ?  ('_' + id) : ''),
		id: id,
		pageSize: pageSize,
	});
};

export const onFilterChange = (filter, id) => {
	return ({
		type: FILTER_CHANGE + (id ?  ('_' + id) : ''),
		id: id,
		filter: filter
	});
};