import React, { Component } from 'react';
import { toLocaleDateTimeFormat } from '../../../utils/dateTimeUtils';
import msg from '../../../texts/msg.js';
import CampaignReportChart from './CampaignReportChart';
import '../Administration.css';

class CampaignReport extends Component {

    getComment(campaignEvent) {
        let parameters = [];
        if (campaignEvent && campaignEvent.parameters) {
            parameters = campaignEvent.parameters.split(';')
        }
        if (!campaignEvent.comments && campaignEvent.parameters) {
            return parameters[0];
        } else {
            if(parameters.length > 0) {
                let params = {};
                let i = 0;
                for(i = 0; i < parameters.length; i++){               
                    params["p" + i] = parameters[i];
                }
                return msg(campaignEvent.comments, params);
            } else {
                return msg(campaignEvent.comments);
            }
        }
    }

    getConfigurationValue(){
        let label = '';
        if(this.props.campaign.firmware && this.props.campaign.firmware.key){
            label = this.props.campaign.firmware.value;
        }
        if(this.props.campaign.hardware && this.props.campaign.hardware.key){
            label += label ? ' / ' : '';
            label += this.props.campaign.hardware.value;
        }
        if(this.props.campaign.priceZone && this.props.campaign.priceZone.key){
            label += label ? ' / ' : '';
            label += this.props.campaign.priceZone.value;
        }
        return label;
    }

    getConfigurationDesc(){
        let label = '';
        if(this.props.campaign.firmware && this.props.campaign.firmware.key){
            label = this.props.campaign.firmware.label;
        }
        if(this.props.campaign.hardware && this.props.campaign.hardware.key){
            label += label ? ' / ' : '';
            label += this.props.campaign.hardware.label;
        }
        if(this.props.campaign.priceZone && this.props.campaign.priceZone.key){
            label += label ? ' / ' : '';
            label += this.props.campaign.priceZone.label;
        }
        return label;
    }

    getConfigurationLabel(){
        let value = '';
        if(this.props.campaign.firmware && this.props.campaign.firmware.key){
            value = msg('campaign.form.firmware.title');
        }
        if(this.props.campaign.hardware && this.props.campaign.hardware.key){
            value += value ? ' / ' : '';
            value += msg('campaign.form.hardware.title');
        }
        if(this.props.campaign.priceZone && this.props.campaign.priceZone.key){
            value += value ? ' / ' : '';
            value += msg('campaign.form.pricezone.title');
        }
        return value;
    }

    getGlobalInfoHeight() {
        let k = 0.50;
        return (window.innerHeight * k) + 'px';
    }

    getInfoHeight() {
        let k = 0.40;
        return (window.innerHeight * k) + 'px';
    }

    render() {
        return (
        <div className="p-grid p-col-12 campaign-info" style={{maxHeight: this.getGlobalInfoHeight()}}>
            <div className={"card card-w-title p-col-12 p-lg-4 info"} style={{maxHeight: this.getInfoHeight()}}>
                <div className="p-col-12 campaign-title"><h3>{msg('campaign.report.info.title')}</h3></div>
                <div className="p-col-12 p-grid">
                    <div className="p-col-6">
                        <label>{msg('campaign.form.input.name')} :</label>
                    </div>
                    <div className="p-col-6">
                        {this.props.campaign.name}
                    </div>
                </div>
                <div className="p-col-12 p-grid">
                    <div className="p-col-6">
                        <label>{msg('campaign.form.input.type')} :</label>
                    </div>
                    <div className="p-col-6">{this.props.campaign.type ? this.props.campaign.type.label : ''}</div>
                </div>
                <div className="p-col-12 p-grid">
                    <div className="p-col-6">
                        <label>{msg('campaign.form.input.description')} :</label>
                    </div>
                    <div className="p-col-6">{this.props.campaign.description}</div>    
                </div>
                <div className="p-col-12 p-grid">
                    <div className="p-col-6"><label>{msg('campaign.form.status.label')} :</label></div>
                    <div className="p-col-6"> {msg('campaign.status.' + this.props.campaign.status)} </div>
                </div>
                <div className="p-col-12 p-grid">
                    <div className="p-col-6"><label>{msg('campaign.form.equipments.count')} :</label></div>
                    <div className="p-col-6"> {this.props.campaign.equipments && Array.isArray(this.props.campaign.equipments) ? this.props.campaign.equipments.length : 0} </div>
                </div>
                {(this.props.campaign.readOnly || this.props.planning) &&
                <div className="p-col-12 p-grid">
                    <div className="p-col-6"><label>{this.getConfigurationLabel()} :</label></div>
                    <div className="p-col-6"> {this.getConfigurationValue()} </div>                    
                    <div className="p-col-12 config-desc"><span>{this.getConfigurationDesc()}</span></div>
                </div>}             
            </div>
            <div className={"card card-w-title p-col-12 p-lg-4 histo"} style={{maxHeight: this.getInfoHeight()}}>
                <div className="p-col-12 campaign-title"><h3>{msg('campaign.report.histo.title')}</h3></div>
                {
                    this.props.campaignEvents && this.props.campaignEvents.map((e, i) => {
                        let d = toLocaleDateTimeFormat(e.creationDate);
                        d = (d && d.length === 19) ? d.substring(0, 16) : '';
                        return (<div className="p-col-12 p-grid" key={i}>
                            <div className="p-col-4">{d}</div>
                            <div className="p-col-6">{ this.getComment(e) }</div>
                            <div className="p-col-2">{e.type === 'USER'? e.source : msg('filter.item.' + e.type.toLowerCase())}</div>
                        </div>);
                    })
                }
            </div>
             <div className={"card card-w-title p-col-12 p-lg-4 progress"} style={{maxHeight: this.getInfoHeight()}}>
                <div className="p-col-12 campaign-title"><h3>{msg('campaign.report.progress.title')}</h3></div>
                <CampaignReportChart campaign={this.props.campaign} />
             </div>      
        </div>);
    }
}

export default CampaignReport;