import React, {Component} from 'react';

import SystemEvents from './SystemEvents';

import {rolesValidator, SERVER} from '../../utils/RolesValidator.js';
import './server.css'

export class Server extends Component {

	constructor(props) {
		super(props);
		this.state = {submenu: this.props.match.params.submenu};
		this.loadSystemEvents = this.loadSystemEvents.bind(this);
	}

	componentDidUpdate(prevProps) {
        if(this.props.match.params.submenu !== prevProps.match.params.submenu){
            this.setState({submenu: this.props.match.params.submenu});
        }
	}
	
	loadSystemEvents(page, pageSize,filter) {
		if(filter==null) filter={startDate: null,endDate: null,typeEventSystem:null}
		this.props.loadSystemEvents( page, pageSize,filter );
	}

    render() {
		
		let authorized = rolesValidator.validate(SERVER);

		let serverPage = (
			<div className="p-grid">
				<div className="p-col-12">
					<div className="card card-w-title">
						<h1>Paramétrages</h1>
					</div>
				</div>
			</div>
		);
		
		let serverItem = '';
        switch(this.state.submenu) {
            case 'systemevents' : {
                serverItem =  <SystemEvents load={this.loadSystemEvents} data={this.props.systemEvents} loadSystemEventsFilter={this.props.loadSystemEventsFilter} systemEventsFilterData={this.props.systemEventsFilterData} setDataInfoBar={this.props.setDataInfoBar}  />;
                break;
			}
			case 'settings' : {
                serverItem =  serverPage;
                break;
			}
			default : 
				serverItem =  serverPage;
       };
		
	   return authorized && serverItem;
			
    }
}