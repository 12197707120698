import React, { Component } from 'react';
import msg from '../texts/msg';

export class CartadisFooter extends Component {

	render() {
		const namCompany = ' - ' + process.env.REACT_APP_COMPANY + ' - '
		const namApp = process.env.REACT_APP_NAME
		const version = process.env.REACT_APP_VERSION
		const currentYear = process.env.REACT_APP_CURRENT_YEAR
		const builVesrion = process.env.REACT_APP_BUILD_VERSION
		let logoImg = (<div className="logoFotter logoSizeMobile"  ></div>);
		return (
			<div className="footer " id="layout-footer"  >
				<div className="p-grid" >
					<div className="fontSizeMobile p-col-12  p-md-12 p-lg-12 " style={{ textAlign: 'center', marginTop: '5px', marginBottom:'-8px' }}>
						{logoImg}  © {currentYear} {namCompany} {namApp}  {msg('foter.version')}  {version} ({builVesrion})
					</div>
				</div>
				<div className="p-grid" style={{ textAlign: 'center' }}>
					<div className="fontSizeMobile p-col-12  p-md-12 p-lg-12 " style={{ textAlign: 'center', paddingLeft: '7%'}}>
						<a href="/assets/resource/WashAdmin_CDP.pdf" target = "_blank"><span style={{ fontSize: '12px'}}>{msg('foter.personal.data.charter')}</span></a>
						<span>  -  </span>
						<a href="/assets/resource/WashAdmin_CGU.pdf" target = "_blank"><span style={{ fontSize: '12px'}}>{msg('foter.conditions.general')}</span></a>
					</div>
				</div>
			</div>

		);
	}
}