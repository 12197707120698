import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';

import configureStore from './middleware/configureStore';
import CartadisWrapper from './ui/CartadisWrapper';

import 'primeflex/primeflex.css';
import 'primereact/resources/primereact.min.css';


import './texts/i18n';

let store = configureStore();

ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter>
			<CartadisWrapper/>
		</BrowserRouter>
	</Provider>, 
	document.getElementById('root')
);