import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import {
	COMMERCE_DASHBORD, COMMERCE_ANALYSIS, COMMERCE_EXPLOIT_ANALYSIS,
	commerceDashbordSuccess, commerceAnalysisSuccess, commerceExploitAnalysisSuccess
} from '../actions/commerce';
import { faillure } from '../actions';
import { authProviderType } from '../AuthProviderType';

export const commerceDashbordEpic = (epicAction) => {
	return epicAction.pipe(
		ofType(COMMERCE_DASHBORD),
		mergeMap((action) => {
			let apiRootUri = authProviderType.getApiRootUri();
			let urlCommerceDashbord = apiRootUri + "trade/dashboard";
			const req = ajax({
				url: urlCommerceDashbord,
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				withCredentials: true,
				body: action.data.filter
			});			
			return req.pipe(
				map(res => { return commerceDashbordSuccess(res); }),
				catchError(res => { return of(faillure(res)); })
			)
		}),
	);

};

export const commerceAnalysisEpic = (epicAction) => {
	return epicAction.pipe(
		ofType(COMMERCE_ANALYSIS),
		mergeMap((action) => {
			let apiRootUri = authProviderType.getApiRootUri();
			let urlCommerceAnalysis = apiRootUri + "trade/analysis";
			const req = ajax({
				url: urlCommerceAnalysis,
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				withCredentials: true,
				body: action.data.filter
			});
			return req.pipe(
				map(res => { return commerceAnalysisSuccess(res); }),
				catchError(res => { return of(faillure(res)); })
			)
		}),
	);

};

export const commerceExploitAnalysisEpic = (epicAction) => {
	return epicAction.pipe(
		ofType(COMMERCE_EXPLOIT_ANALYSIS),
		mergeMap((action) => {
			let apiRootUri = authProviderType.getApiRootUri();
			let urlCommerceExploit = apiRootUri + "trade/exploit";
			const req = ajax({
				url: urlCommerceExploit,
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				withCredentials: true,
				body: action.data.filter
			});
			return req.pipe(
				map(res => { return commerceExploitAnalysisSuccess(res); }),
				catchError(res => { return of(faillure(res)); })
			)
		}),
	);

};