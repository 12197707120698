export const GROUPS = 'GROUPS';
export const GROUPS_SUCCESS = 'GROUPS_SUCCESS';

export const GROUP = 'GROUP';
export const GROUP_SUCCESS = 'GROUP_SUCCESS';

export const GROUP_BY_ID = 'GROUP_BY_ID';
export const GROUP_BY_ID_SUCCESS = 'GROUP_BY_ID_SUCCESS';

export const ADD_GROUP = 'ADD_GROUP';
export const ADD_GROUP_SUCCESS = 'ADD_GROUP_SUCCESS';

export const GROUP_UPDATE = 'GROUP_UPDATE';
export const GROUP_UPDATE_SUCCESS = 'GROUP_UPDATE_SUCCESS';

export const GROUP_DELETE_BY_ID ='GROUP_DELETE_BY_ID';
export const GROUP_DELETE_BY_ID_SUCCESS ='GROUP_DELETE_BY_ID_SUCCESS';





export const groups = (page, pageSize, groupFilterDto) => {
	return ({
		type: GROUPS,
		data: { page: page, pageSize: pageSize, groupFilterDto: groupFilterDto },
		errorMessage: null,
		errorStatus: null
	});
};

export const groupsSuccess = (r) => {
	return ({
		type: GROUPS_SUCCESS,
		data: r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const groupById = (id, page, pageSize, groupFilterDto) => { 
	return ({
		type: GROUP_BY_ID,
		data: {id: id, page: page,  pageSize: pageSize, groupFilterDto: groupFilterDto},
		errorMessage: null,
		errorStatus: null
	});
};

export const groupByIdSuccess = (r) => {
	return ({
		type: GROUP_BY_ID_SUCCESS,
		data: r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const addGroup = (group) => {
	return ({
		type: ADD_GROUP,
		data: { group: group },
		errorMessage: null,
		errorStatus: null
	});
};

export const addGroupSuccess = (r) => {
	return ({
		type: ADD_GROUP_SUCCESS,
		data: r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const group = (groupId) => {
	return ({
		type: GROUP,
		data: { groupId: groupId },
		errorMessage: null,
		errorStatus: null
	});
};
export const groupSuccess = (r) => {
	return ({
		type: GROUP_SUCCESS,
		data: r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const groupUpdate = (groupId, group) => { 
	return ({
		type: GROUP_UPDATE,
		data: {groupId:groupId, group: group},
		errorMessage: null,
		errorStatus: null
	});
};

export const groupUpdateSuccess = (r) => {
	return ({
		type: GROUP_UPDATE_SUCCESS,
		data: r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const groupDeleteById = (groupId) => { 
	return ({
		type: GROUP_DELETE_BY_ID,
		data: {groupId: groupId},
		errorMessage: null,
		errorStatus: null
	});
};

export const groupDeleteByIdSuccess = (r) => {
	return ({
		type: GROUP_DELETE_BY_ID_SUCCESS,
		data: r.response,
		errorMessage: null,
		errorStatus: null
	});
};