import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError } from  'rxjs/operators';
import { systemEventsSuccess } from '../actions/server';
import { SYSTEM_EVENTS } from '../actions/server';
import { faillure } from '../actions';
import { authProviderType } from '../AuthProviderType';

export const systemEventsEpic = (epicAction)  => {
	return  epicAction.pipe(
			ofType(SYSTEM_EVENTS),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let systemEventsUrl = apiRootUri + 'server/system-events';
				const req = ajax({
						url: systemEventsUrl + '?page=' + action.data.page + '&pageSize=' + action.data.pageSize,
						method: 'POST',					  
						headers: {
							'Content-Type': 'application/json'
						},
						withCredentials: true,
						body: action.data.filter
					});
					
				return req.pipe(
            			map(res => { return systemEventsSuccess(res);}),
            			catchError(res => { return of(faillure(res));})
            		)
			}),
	);
	
};