export const FIRMWARES='FIRMWARES';
export const FIRMWARES_SUCCESS= 'FIRMWARES_SUCCESS';
export const FIRMWARE='FIRMWARE';
export const FIRMWARE_SUCCESS= 'FIRMWARE_SUCCESS';
export const FIRMWARE_DELETE_BY_ID='FIRMWARE_DELETE_BY_ID';
export const FIRMWARE_DELETE_BY_ID_SUCCESS= 'FIRMWARE_DELETE_BY_ID_SUCCESS';

export const firmwares = (page, pageSize, filter) => {
	return ({
		type: FIRMWARES,
		data: {page: page,  pageSize: pageSize, filter: filter},
		errorMessage: null,
		errorStatus: null
	});
};
export const firmwaresSuccess = (r) => {
	return ({
		type: FIRMWARES_SUCCESS,
		data: r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const firmware = (firmwareId) => {
	return ({
		type: FIRMWARE,
		data: {firmwareId: firmwareId },
		errorMessage: null,
		errorStatus: null
	});
};
export const firmwareSuccess = (r) => {
	return ({
		type: FIRMWARE_SUCCESS,
		data: r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const firmwareDeleteById = (firmwareId) => { 
	return ({
		type: FIRMWARE_DELETE_BY_ID,
		data: {firmwareId: firmwareId},
		errorMessage: null,
		errorStatus: null
	});
};

export const firmwareDeleteByIdSuccess = (r) => {
	return ({
		type: FIRMWARE_DELETE_BY_ID_SUCCESS,
		data: r.response,
		errorMessage: null,
		errorStatus: null
	});
};