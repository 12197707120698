import React, { Component } from 'react';
import PropTypes from 'prop-types';
import msg from '../texts/msg';
import {rolesValidator,  ADMINISTRATION, EQUIPMENTS, TRADE, MAINTENANCE} from '../utils/RolesValidator.js';
export class CartadisBreadcrumb extends Component {

    static propTypes = {
        match: PropTypes.object
    }

    getIconOrTitle(type) {

        if (rolesValidator.validate([ADMINISTRATION, EQUIPMENTS])) {
            if (type === 'icon') return 'dock';
            else return msg('menu.admin.equipements'); 
        } else if (rolesValidator.validate([TRADE])) {
            if (type === 'icon') return 'equalizer';
            else return msg('user.item.comercials');
        } else if (rolesValidator.validate([MAINTENANCE])) {
            if (type === 'icon') return 'network_check';
            else return msg('user.item.maintenance');
        }
    }
    render() {
        const dataInfo = this.props.getDataInfoBar();
        const selectItemMenu = this.props.getSelectItemMenu();
        return (
            <div className="layout-breadcrumb mobileHide layout-breadcrumb-size-screen-642-to-1366 topBar" >

                <div className="p-grid " >
                    <div className="p-col-fixed " style={{ 'width': '38px', 'backgroundColor': '#e4032e' }} >
                        <p>
                            <i className="material-icons" style={{ 'marginTop': '-4px','marginLeft':'4px' }}>
                                {selectItemMenu != null ? selectItemMenu.icon : this.getIconOrTitle("icon") }
                            </i>
                        </p>
                  </div> 
                  <div  className="p-col-11 p-md-3 p-lg-2" style={{ 'backgroundColor': '#e4032e'}} >
                        <p style={{ 'float': 'left' }}>{selectItemMenu != null ? selectItemMenu.label : this.getIconOrTitle("title") }</p>
                  </div>
                  { dataInfo && 
                  <div className=" p-col p-col-align-center " style={{ 'padding': '0px 0px 0px 0px', 'marginRight': '10px', 'marginLeft': '5px', 'float': 'left' }}>
                        <ul className="breadcrumbInfo">
                            { 
                               dataInfo.map((data) => {
                               if(data.key === "titleFilter") {
                                  return <li  key={data.key} className="titleFilterClass"> {data.value} </li>
                                } else if(data.key === "implantAndNameStation"){
                                    return <li key={data.key} className="titleFilterClass"> {data.value} </li>
                                } else if(data.value){
                                    return <li key={data.key} style={{color: 'with',textTransform:"none"}} >{data.value}</li>
                                } else {
                                    return '';
                                }
                            })
                            }  
                        </ul>
                    </div>
                    
                    }
                    
                  </div>  
               
                
            </div>

        );
    }
}