import {SYSTEM_EVENTS, SYSTEM_EVENTS_SUCCESS } from '../actions/server';

const state0 = {data: null, error: null};

export  const systemEventsReducer = (state = state0, action) => {
	switch (action.type) {
		case SYSTEM_EVENTS:
			return {
				state,
				error: null
		};
		case SYSTEM_EVENTS_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};