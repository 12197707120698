import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import MessageDialog from '../../common/MessageDialog';
import { Message } from 'primereact/message';
import { validatePassword } from './validatePassword';
import msg from '../../../texts/msg';

const emtyChar = '';
export class PasswordUpdate extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ispassword: false,
            ispasswordConfirm: false,
            buttonSelectd: false,
            password: emtyChar,
            confirmPassword: emtyChar,
        }
        this.apply = this.apply.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    cancel() {
        this.setState({
            ispassword: false,
            ispasswordConfirm: false,
            buttonSelectd: false,
            password: emtyChar,
            confirmPassword: emtyChar
        });
        this.setState({ confirmPassword: emtyChar });
        this.props.cancel(this.resetPassword());
    }

    resetPassword() {
        return {
            password: emtyChar,
            confirmPassword: emtyChar,
            buttonSelectd: false,
        }
    }


    apply() {
        if ((!this.state.password || this.state.password.length < 8) ||
            (!this.state.confirmPassword || this.state.confirmPassword.length < 8)) {
            if (!this.state.password || this.state.password.length < 8) {
                this.setState({ ispassword: true });
            } else {
                this.setState({ ispassword: false });
            }
            if (!this.state.confirmPassword || this.state.confirmPassword.length < 8) {
                this.setState({ ispasswordConfirm: true });
            } else {
                this.setState({ ispasswordConfirm: false });
            }
        } else {
            if (this.state.password !== this.state.confirmPassword) {
                this.setState({ showWarn: true, warnMessage: msg('user.password.not.match') })
            } else {
                if (!validatePassword(this.state.password)) {
                    this.setState({ showWarn: true, warnMessage: msg('user.control.reges.password') })
                } else {
                    this.props.apply(this.buildPassword());
                    this.setState({
                        ispassword: false,
                        ispasswordConfirm: false,
                        buttonSelectd: false,
                        password: emtyChar,
                        confirmPassword: emtyChar
                    });
                }
            }
        }
    }

    buildPassword() {
        return {
            password: this.state.password,
            confirmPassword: this.state.confirmPassword
        }
    }

    render() {
        return (
            <div className={"commonClass"}>
                <div className="test p-col-12">
                    <h3 style={{ marginTop: '.5em' }}>{msg('user.fields.initialis.password', { username: this.props.username })}</h3>
                </div>
                <div className="card card-w-title p-col-12">
                    <br />
                    <div className="p-grid form-group p-col-12">
                        
                        {<div className="p-grid p-col-6">
                            <div className="p-grid p-col-4">
                                <div className="p-col-10"> <label>{msg('user.fields.password')}</label></div>
                                <div className=""><span className="obligatoire">*</span></div>
                            </div>
                            <div className="p-col-8">
                            <span className="md-inputfield">
                                    <Password onChange={e => this.setState({ password: e.target.value })} minLength="5" id="pwd1" />
                                </span>
                                {this.state.ispassword && <Message severity="error" text={msg('user.control.password')} key="password" />}
                            </div>
                        </div>}
                        {<div className="p-grid p-col-6">
                            <div className="p-grid p-col-4">
                                <div className="p-col-10"> <label>{msg('user.fields.passwordconfirm')}</label></div>
                                <div className=""><span className="obligatoire">*</span></div>
                            </div>
                            <div className="p-col-8">
                            <span className="md-inputfield">
                                    <Password onChange={e => this.setState({ confirmPassword: e.target.value })} minLength="5" id="pwd2" />
                                </span>
                                {this.state.ispasswordConfirm && <Message severity="error" text={msg('user.control.password')} />}
                            </div>
                        </div>}
                    </div>
                </div>           
                <br />
                <div className="p-grid p-col-12">
                    <div className="p-grid p-col-9">
                    </div>
                    <div className="p-grid p-col-3">
                        <Button label={msg('user.validate')} title={msg('user.validate')} onClick={this.apply} className="button" />
                        <Button label={msg('user.reset')} onClick={this.cancel} className="button" />
                    </div>
                </div>
                <MessageDialog className={'message-dialog warn-message'} visible={this.state.showWarn} title={msg('message.dialog.warn.title')}
                    message={this.state.showWarn && this.state.warnMessage} key="warn-select"
                    onHide={() => { this.setState({ showWarn: false, warnMessage: '' }) }} />
            </div>
        )
    }
}

export default PasswordUpdate;