import { ofType} from 'redux-observable';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError} from  'rxjs/operators';
import {configMaterielsSuccess, configMaterielSuccess, configMaterielDeleteByIdSuccess} from '../actions/hardwares';
import {CONFIG_MATERIELS, CONFIG_MATERIEL, CONFIG_MATERIEL_DELETE_BY_ID} from '../actions/hardwares';
import {faillure } from '../actions';
import { authProviderType } from '../AuthProviderType';

export const configMaterielsEpic = (epicAction)  => {
	return  epicAction.pipe(
			ofType(CONFIG_MATERIELS),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlHardwares = apiRootUri + "hardware";
				const req = ajax({
					  	url: urlHardwares + "/hardwares?page=" + action.data.page+"&pageSize=" + action.data.pageSize,
					  	method: 'POST',				  
						headers: {
							'Content-Type': 'application/json'
						},
						withCredentials: true,
						body: action.data.filter
					});					
				return req.pipe(
            			map(res => {return configMaterielsSuccess(res);}),
            			catchError(res => { return of(faillure(res));})
            		)
			}),
	);
};

export const configMaterielEpic = (epicAction)  => {
	return  epicAction.pipe(
			ofType(CONFIG_MATERIEL),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlHardwares = apiRootUri + "hardware";
				const req = ajax({
					  	url: urlHardwares + "/" + action.data.hardwareId,
					  	method: 'GET',				  
						headers: {
							'Content-Type': 'application/json'
						},
						withCredentials: true,
					});				
				return req.pipe(
            			map(res => {return configMaterielSuccess(res);}),
            			catchError(res => { return of(faillure(res));})
            		)
			}),
	);	
};

export const configMaterielDeleteByIdEpic = (epicAction)  => {
	return  epicAction.pipe(
			ofType(CONFIG_MATERIEL_DELETE_BY_ID),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlHardwares = apiRootUri + "hardware";
				const req = ajax({
					url: urlHardwares + "/" +action.data.hardwareId,
					method: 'DELETE',					  
					headers: {
					  'Content-Type': 'application/json'
				  },
				  withCredentials: true,
			 	});
				return req.pipe(
					map(res => {return configMaterielDeleteByIdSuccess(res);}),
					catchError(res => { return of(faillure(res));})
				)
			}),
	);	

};