import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { loadStationsSuccess, loadStationsMapSuccess, stationSuccess,
	addStationSuccess, stationUpdateSuccess, stationDeleteByIdSuccess, 
	STATION, ADD_STATION, STATION_DELETE_BY_ID,STATION_UPDATE, 
	LOAD_STATIONS_MAP,
	UPDATE_CONFIG_STATION,
	updateConfigStationSuccess,
	CHANGE_STATION_CONTRACT,
	changeStationContractSuccess,
	loadStationPriceZonesSuccess,
	LOAD_STATION_PRICEZONES,
	loadStationCommissionsSuccess,
	LOAD_STATION_COMMISSIONS} from '../actions/station';
import { LOAD_STATIONS } from '../actions/station';
import { faillure } from '../actions';
import { authProviderType } from '../AuthProviderType';

export const loadStationsEpic = (epicAction) => {
	return epicAction.pipe(
		ofType(LOAD_STATIONS),
		mergeMap((action) => {
			let apiRootUri = authProviderType.getApiRootUri();
			let urlStation = apiRootUri + 'stations';
			const req = ajax({
				url: urlStation + '/filter',
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				withCredentials: true,
				body: action.data.filter
			});
			return req.pipe(
				map(res => { return loadStationsSuccess(res); }),
				catchError(res => { return of(faillure(res)); })
			)
		}),
	);
};

export const addStationEpic = (epicAction) => {
	return epicAction.pipe(
		ofType(ADD_STATION),
		mergeMap((action) => {
			let apiRootUri = authProviderType.getApiRootUri();
			let urlStation = apiRootUri + 'stations';
			const req = ajax({
				url: urlStation,
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				withCredentials: true,
				body: action.data.station
			});

			return req.pipe(
				map(res => {
					return addStationSuccess(res);
				}),
				catchError(res => { return of(faillure(res)); })
			)
		}),
	);

};

export const stationUpdateEpic = (epicAction) => {
	return epicAction.pipe(
		ofType(STATION_UPDATE),
		mergeMap((action) => {
			let apiRootUri = authProviderType.getApiRootUri();
			let urlStation = apiRootUri + 'stations';
			const req = ajax({
				url: urlStation + '/' + action.data.stationId,
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json'
				},
				withCredentials: true,
				body: action.data.station
			});
			return req.pipe(
				map(res => { return stationUpdateSuccess(res); }),
				catchError(res => { return of(faillure(res)); })
			)
		}),
	);
};

export const changeStationContractEpic = (epicAction) => {
	return epicAction.pipe(
		ofType(CHANGE_STATION_CONTRACT),
		mergeMap((action) => {
			let apiRootUri = authProviderType.getApiRootUri();
			let urlStation = apiRootUri + 'stations';
			const req = ajax({
				url: urlStation + '/contract/' + action.data.stationId,
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json'
				},
				withCredentials: true
			});
			return req.pipe(
				map(res => { return changeStationContractSuccess(res); }),
				catchError(res => { return of(faillure(res)); })
			)
		}),
	);
};

export const stationDeleteByIdEpic = (epicAction) => {
	return epicAction.pipe(
		ofType(STATION_DELETE_BY_ID),
		mergeMap((action) => {
			let apiRootUri = authProviderType.getApiRootUri();
			let urlStation = apiRootUri + 'stations';
			const req = ajax({
				url: urlStation + '/' + action.data.stationId,
				method: 'DELETE',
				headers: {
					'Content-Type': 'application/json'
				},
				withCredentials: true
			});
			return req.pipe(
				map(res => { return stationDeleteByIdSuccess(res); }),
				catchError(res => { return of(faillure(res)); })
			)
		}),
	);

};

export const stationEpic = (epicAction) => {
	return epicAction.pipe(
		ofType(STATION),
		mergeMap((action) => {
			let apiRootUri = authProviderType.getApiRootUri();
			let urlStation = apiRootUri + 'stations';
			const req = ajax({
				url: urlStation + '/' + action.data.implant,
				method: 'GET',
				headers: {
					'Content-Type': 'application/json'
				},
				withCredentials: true
			});
			return req.pipe(
				map(res => { return stationSuccess(res); }),
				catchError(res => { return of(faillure(res)); })
			)
		}),
	);
};

export const loadStationsMapEpic = (epicAction) => {
	return epicAction.pipe(
		ofType(LOAD_STATIONS_MAP),
		mergeMap((action) => {
			let apiRootUri = authProviderType.getApiRootUri();
			const req = ajax({
				url: apiRootUri + action.data.feature + '/stations-map',
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: action.data.filter,
				withCredentials: true
			});
			return req.pipe(
				map(res => { return loadStationsMapSuccess(res); }),
				catchError(res => { return of(faillure(res)); })
			)
		}),
	);
};

export const updateConfigStationEpic = (epicAction)  => {
	return  epicAction.pipe(
			ofType(UPDATE_CONFIG_STATION),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlStation = apiRootUri + 'stations';
				const req = ajax({
						url: urlStation + '/' + action.data.stationId + '/configuration',
					  	method: 'POST',					  
					  	headers: {
							'Content-Type': 'application/json'
						},
						withCredentials: true
					});
				return req.pipe(
            			map(res => { return updateConfigStationSuccess(res);}),
            			catchError(res => { return of(faillure(res));})
            		)
			}),
	);
};

export const loadStationPriceZonesEpic = (epicAction) => {
	return epicAction.pipe(
		ofType(LOAD_STATION_PRICEZONES),
		mergeMap((action) => {
			let apiRootUri = authProviderType.getApiRootUri();
			let urlStation = apiRootUri + 'stations';
			const req = ajax({
				url: urlStation + '/pricezones?page=' + action.data.page + '&pageSize=' + action.data.pageSize,
				method: 'GET',
				headers: {
					'Content-Type': 'application/json'
				},
				withCredentials: true,
			});
			return req.pipe(
				map(res => { return loadStationPriceZonesSuccess(res); }),
				catchError(res => { return of(faillure(res)); })
			)
		}),
	);
};

export const loadStationCommissionsEpic = (epicAction) => {
	return epicAction.pipe(
		ofType(LOAD_STATION_COMMISSIONS),
		mergeMap((action) => {
			let apiRootUri = authProviderType.getApiRootUri();
			let urlStation = apiRootUri + 'stations';
			const req = ajax({
				url: urlStation + '/commissions?page=' + action.data.page + '&pageSize=' + action.data.pageSize,
				method: 'GET',
				headers: {
					'Content-Type': 'application/json'
				},
				withCredentials: true,
			});
			return req.pipe(
				map(res => { return loadStationCommissionsSuccess(res); }),
				catchError(res => { return of(faillure(res)); })
			)
		}),
	);
};