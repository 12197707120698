export const ROLES = 'ROLES';
export const ROLES_SUCCESS = 'ROLES_SUCCESS';

export const AUTHORISATION = 'AUTHORISATION';
export const AUTHORISATION_SUCCESS = 'AUTHORISATION_SUCCESS';

export const ADD_ROLE = 'ADD_ROLE';
export const ADD_ROLE_SUCCESS = 'ADD_ROLE_SUCCESS';

export const LOAD_ROLE = 'LOAD_ROLE';
export const LOAD_ROLE_SUCCESS = 'LOAD_ROLE_SUCCESS';

export const ROLE_DELETE_BY_ID = 'ROLE_DELETE_BY_ID';
export const ROLE_DELETE_BY_ID_SUCCESS = 'ROLE_DELETE_BY_ID_SUCCESS';

export const ROLEUPDATE = 'ROLEUPDATE';
export const ROLEUPDATE_SUCCESS = 'ROLEUPDATE_SUCCESS';

export const ROLE_UPDATE = 'ROLE_UPDATE';
export const ROLE_UPDATE_SUCCESS = 'ROLE_UPDATE_SUCCESS';




export const roles = (page, pageSize) => {
	return ({
		type: ROLES,
		data: { page: page, pageSize: pageSize},
		errorMessage: null,
		errorStatus: null
	});
};
export const rolesSuccess = (r) => {
	return ({
		type: ROLES_SUCCESS,
		data: r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const authorisations = () => {
	return ({
		type: AUTHORISATION,
		data: { },
		errorMessage: null,
		errorStatus: null
	});
};

export const authorisationsSuccess = (r) => {
	return ({
		type: AUTHORISATION_SUCCESS,
		data: r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const addRole = (role) => {
	return ({
		type: ADD_ROLE,
		data: { role: role },
		errorMessage: null,
		errorStatus: null
	});
};

export const addRoleSuccess = (r) => {
	return ({
		type: ADD_ROLE_SUCCESS,
		data: r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const loadRole = () => { 
	return ({
		type: LOAD_ROLE,
		data: {},
		errorMessage: null,
		errorStatus: null
	});
};

export const loadRoleSuccess = (r) => {
	return ({
		type: LOAD_ROLE_SUCCESS,
		data: r.response ,
		errorMessage: null,
		errorStatus: null
	});
};

export const roleDeleteById = (roleId) => { 
	return ({
		type: ROLE_DELETE_BY_ID,
		data: {roleId: roleId},
		errorMessage: null,
		errorStatus: null
	});
};

export const roleDeleteByIdSuccess = (r) => {
	return ({
		type: ROLE_DELETE_BY_ID_SUCCESS,
		data: r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const role = (roleId) => {
	return ({
		type: ROLEUPDATE,
		data: { roleId: roleId },
		errorMessage: null,
		errorStatus: null
	});
};
export const roleSuccess = (r) => {
	return ({
		type: ROLEUPDATE_SUCCESS,
		data: r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const roleUpdate = (roleId, role) => { 
	return ({
		type: ROLE_UPDATE,
		data: {roleId: roleId, role: role},
		errorMessage: null,
		errorStatus: null
	});
};

export const roleUpdateSuccess = (r) => {
	return ({
		type: ROLE_UPDATE_SUCCESS,
		data: r.response,
		errorMessage: null,
		errorStatus: null
	});
};