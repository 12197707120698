import React, { Component } from 'react';
import classNames from 'classnames';

//let avatar = "/assets/ultima/layout/images/avatar.png";

export class CartadisInlineProfile extends Component {

    constructor() {
        super();
        this.state = {
            expanded: false
        };
        this.onClick = this.onClick.bind(this);
    }

    onClick(event) {
       this.setState({expanded: !this.state.expanded});
        event.preventDefault();
    }

    render() {
        return  <div>
                    <div className={classNames('profile', {'profile-expanded': this.state.expanded})} >
                        <button className="p-link"  >
                            <span className="profile-name">{this.props.user ? this.props.user.firstname + ' ' + this.props.user.lastname : ''}</span>
                        </button>
                    </div>
                    
                    <ul className="ultima-menu profile-menu">
                        <li role="menuitem">
                            <button  className="p-link ripplelink" tabIndex={this.state.expanded ? null : '-1'}>
                                <i className="material-icons">person</i>
                                <span>Profile</span>
                            </button>
                        </li>
                        <li role="menuitem">
                            <button  className="p-link ripplelink" tabIndex={this.state.expanded ? null : '-1'}>
                                <i className="material-icons">security</i>
                                <span>Privacy</span>
                            </button>
                        </li>
                        <li role="menuitem">
                            <button  className="p-link ripplelink" tabIndex={this.state.expanded ? null : '-1'}>
                                <i className="material-icons">settings_application</i>
                                <span>Settings</span>
                            </button>
                        </li>
                        <li role="menuitem">
                            <button  className="p-link ripplelink" tabIndex={this.state.expanded ? null : '-1'} onClick={this.props.onLogout}>
                                <i className="material-icons">power_settings_new</i>
                                <span>Logout</span>
                            </button>
                        </li>
                    </ul>
                </div>
    }
}