export const LOAD_COMMISSIONS = "LOAD_COMMISSIONS";
export const LOAD_COMMISSIONS_SUCCESS = "LOAD_COMMISSIONS_SUCCESS";
export const LOAD_COMMISSION = "LOAD_COMMISSION";
export const LOAD_COMMISSION_SUCCESS = "LOAD_COMMISSION_SUCCESS";
export const CREATE_COMMISSION = "CREATE_COMMISSION";
export const CREATE_COMMISSION_SUCCESS = "CREATE_COMMISSION_SUCCESS";
export const UPDATE_COMMISSION = "UPDATE_COMMISSION";
export const UPDATE_COMMISSION_SUCCESS = "UPDATE_COMMISSION_SUCCESS";
export const DELETE_COMMISSION = "DELETE_COMMISSION";
export const DELETE_COMMISSION_SUCCESS = "DELETE_COMMISSION_SUCCESS";

export const loadCommissions = (page, pageSize) => {
	return ( {
		type : LOAD_COMMISSIONS,
		data : {page: page, pageSize: pageSize},
		errorMessage: null,
		errorStatus: null
	});
};

export const loadCommissionsSuccess = (r) => {
	return ( {
		type : LOAD_COMMISSIONS_SUCCESS,
		data : r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const loadCommission = (commissionId) => {
	return ( {
		type : LOAD_COMMISSION,
		data : {commissionId: commissionId},
		errorMessage: null,
		errorStatus: null
	});
};

export const loadCommissionSuccess = (r) => {
	return ( {
		type : LOAD_COMMISSION_SUCCESS,
		data : r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const createCommission = (commission) => {
	return ( {
		type : CREATE_COMMISSION,
		data : {commission: commission},
		errorMessage: null,
		errorStatus: null
	});
};

export const createCommissionSuccess = (r) => {
	return ( {
		type : CREATE_COMMISSION_SUCCESS,
		data : r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const updateCommission = (commission) => {
	return ( {
		type : UPDATE_COMMISSION,
		data : {commission: commission},
		errorMessage: null,
		errorStatus: null
	});
};

export const updateCommissionSuccess = (r) => {
	return ( {
		type : UPDATE_COMMISSION_SUCCESS,
		data : r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const deleteCommission = (commissionId) => {
	return ( {
		type : DELETE_COMMISSION,
		data : {commissionId: commissionId},
		errorMessage: null,
		errorStatus: null
	});
};

export const deleteCommissionSuccess = (r) => {
	return ( {
		type : DELETE_COMMISSION_SUCCESS,
		data : r.response,
		errorMessage: null,
		errorStatus: null
	});
};