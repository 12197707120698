import {ADMINEQUIPMENTS,ADMINEQUIPMENTS_SUCCESS,  CREATE_EQUIPMENT, CREATE_EQUIPMENT_SUCCESS, EQUIPMENT_DELETE_BY_ID, EQUIPMENT_DELETE_BY_ID_SUCCESS, EQUIPMENT_UPDATE, EQUIPMENT_UPDATE_SUCCESS, EQUIPMENT, EQUIPMENT_SUCCESS, UPDATE_BORNE, UPDATE_BORNE_SUCCESS} from '../actions/adminequipments';

const state0 = {data: null, error: null};

export  const adminequipmentsReducer = (state = state0, action) => {
	switch (action.type) {
		case ADMINEQUIPMENTS:
			return {
				state,
				error: null
		};
		case ADMINEQUIPMENTS_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};

export const createEquipmentReducer = (state = state0, action) => {
	switch (action.type) {
		case CREATE_EQUIPMENT:
			return {
				state,
				error: null
		};
		case CREATE_EQUIPMENT_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};

export const equipmentDeleteByIdReducer = (state = state0, action) => {
	switch (action.type) {
		case EQUIPMENT_DELETE_BY_ID:
			return {
				state,
				error: null
			};
		case EQUIPMENT_DELETE_BY_ID_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
			};
		default:
			return state;
	}
};

export const equipmentReducer = (state = state0, action) => {
	switch (action.type) {
		case EQUIPMENT:
		case EQUIPMENT_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
			};
		default:
			return state;
	}
};

export const equipmentUpdateReducer = (state = state0, action) => {
	switch (action.type) {
		case EQUIPMENT_UPDATE:
			return {
				state,
				error: null
			};
		case EQUIPMENT_UPDATE_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
			};
		default:
			return state;
	}
};

export const updateBorneReducer = (state = state0, action) => {
	switch (action.type) {
		case UPDATE_BORNE:
			return {
				state,
				error: null
		};
		case UPDATE_BORNE_SUCCESS:
			return {
				state,
				data: action.data,
				error: null
		};
		default: 
			return state;
	}
};