import React, { Component } from 'react';
import CommonAnalysisChart from './CommonAnalysisChart';
import msg from '../../texts/msg';
import { colorUtlis } from '../../utils/ColorUtlis';

export class ActivityAnalysisChart extends Component {

	buildChartModel() {
		let items = this.props.data ? this.props.data : [];
		let itemsArray = [];
		let value = this.props.itemValueName;
		let unit = this.props.unit;
		let rounding = this.props.rounding;
		for (let e in items) { if (items[e][value] && items[e][value] > 0) itemsArray.push(items[e]); };
		itemsArray.sort((a, b) => b[value] - a[value]);
		let chartLabels = [];
		let chartData = [];
		let backgroundColorData = [];
		let lengthItemsArray = itemsArray.length;
		if (lengthItemsArray > 6) {
			let t = [];
			let i = 0;
			let m = 0.0;
			for (i = 0; i < lengthItemsArray; i++) {
				if (i < 5 || (i === 5 && lengthItemsArray === 6)) {
					t.push(itemsArray[i]);
				} else {
					m = m + itemsArray[i][value];
				}
			}
			if (m > 0.0) {
				let te = {label: msg('analysis.other')};
				te[value] = m;
				t.push(te);
				itemsArray = t;
			}
		}
		itemsArray.forEach((e, index) => {
			let lValue = (rounding ? (e[value] ? e[value].toFixed(2) : '0.00') : (e[value] ? e[value] : '0'))
							+ (unit ? ' ' + unit : '');
			chartLabels.push({ label: e.label, value: lValue});
			chartData.push(e[value]);
			let color = e.color ? msg(e.color) : colorUtlis.colorItemByLabel(e.label);
			if (index < 5) {
				backgroundColorData.push(color ? color : colorUtlis.defaultColorsList[index % 5]);
			} else {
				backgroundColorData.push(lengthItemsArray > 6 ? colorUtlis.defaultColorsList[5] : (color ? color : colorUtlis.defaultColorsList[index % 5]));
			}			
		});
		return {labels: chartLabels, data: chartData, backgroundColors: backgroundColorData};
	}

	render() {
		let chartModelData = this.buildChartModel();
		let chartModel = {
			labels: chartModelData.labels,
			datasets: [{
				data: chartModelData.data,
				backgroundColor: chartModelData.backgroundColors,
				hoverBackgroundColor: chartModelData.backgroundColors
			}]
		};
		return (
			<CommonAnalysisChart type={this.props.type} chartModel={chartModel} title={this.props.label} className="p-col-12 analyse" />
		);
	}
}