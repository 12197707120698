export const FAILLURE = 'FAILLURE';

export const faillure = (error) => {
	if(!error.response && !error.error && error.message){
		return ({
			type: FAILLURE,
			data: null,
			errorMessage: error.message,
			errorStatus: error.status,
			errorSource: error ? error.path : null,
			errorTimestamp: error.timestamp ? error.timestamp : new Date().getTime(),
			errorParams: '',
			errorUrl: error.request && error.request.url ? error.request.url : ''
		});
	}
	return ({
		type: FAILLURE,
		data: null,
		errorMessage: error.response ? error.response.message : error.error,
		errorStatus: error.response ? error.response.status : error.status,
		errorSource: error.response ? error.response.path : null,
		errorTimestamp: error.request && error.request.timestamp ? error.request.timestamp : (error.response ? error.response.timestamp : error.timestamp),
		errorParams: error.response ? error.response.params : '',
		errorUrl: error.request && error.request.url ? error.request.url : ''
	});
};