import { PAGE_SIZE_CHANGE, FILTER_CHANGE } from "../actions/userSessionParams";

export const onPageSizeChangeReducer = (state = {pageSizes : {}}, action) => {
	if(action.type === PAGE_SIZE_CHANGE) {
		return ({
			state,
			pageSize: action.pageSize
		});
	} else if(action.id && action.type === (PAGE_SIZE_CHANGE + '_' + action.id) ){
		let pageSizes = state.pageSizes ? state.pageSizes : {};
		pageSizes[action.id] = {pageSize: action.pageSize};
		return ({
			state,
			pageSizes: pageSizes
		});
	}
	return state;
};

export const onFilterReducer = (state = {}, action) => {
	if(action.type === FILTER_CHANGE){
		return ({
			state,
			filter: action.filter
		});
	} else if(action.id && action.type === (FILTER_CHANGE + '_' + action.id) ){
		let filters = state.filters ? state.filters : {};
		filters[action.id] = {filter: action.filter};
		return ({
			state,
			filters: filters
		});
	}
	return state;
};