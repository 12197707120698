export const LOAD_FILTER = "LOAD_FILTER";
export const LOAD_FILTER_SUCCESS = "LOAD_FILTER_SUCCESS";
export const LOAD_FILTER_STATIONS = "LOAD_FILTER_STATIONS";
export const LOAD_FILTER_STATIONS_SUCCESS = "LOAD_FILTER_STATIONS_SUCCESS";
export const LOAD_FILTER_STATIONS_WITH_CONTRACTS = "LOAD_FILTER_STATIONS_WITH_CONTRACTS";
export const LOAD_FILTER_STATIONS_WITH_CONTRACTS_SUCCESS = "LOAD_FILTER_STATIONS_WITH_CONTRACTS_SUCCESS";
export const LOAD_FILTER_DODO_STATIONS = "LOAD_FILTER_DODO_STATIONS";
export const LOAD_FILTER_DODO_STATIONS_SUCCESS = "LOAD_FILTER_DODO_STATIONS_SUCCESS";
export const LOAD_SYSTEM_EVENTS_FILTER = "LOAD_SYSTEM_EVENTS_FILTER";
export const LOAD_SYSTEM_EVENTS_FILTER_SUCCESS = "LOAD_SYSTEM_EVENTS_FILTER_SUCCESS";
export const LOAD_FILTER_EXPLOITANTS_REPORTS = "LOAD_FILTER_EXPLOITANTS_REPORTS";
export const LOAD_FILTER_EXPLOITANTS_REPORTS_SUCCESS = "LOAD_FILTER_EXPLOITANTS_REPORTS_SUCCESS";

export const loadFilter = (feature, filter) => { 
	return ({
		type: LOAD_FILTER,
		data: {feature: feature, filter: filter},
		errorMessage: null,
		errorStatus: null
	});
};

export const loadFilterSuccess = (r) => {

	return ({
		type: LOAD_FILTER_SUCCESS,
		data: r.response ,
		errorMessage: null,
		errorStatus: null
	});
};

export const loadFilterStations = (feature, filter) => { 
	return ({
		type: LOAD_FILTER_STATIONS,
		data: {feature: feature, filter: filter},
		errorMessage: null,
		errorStatus: null
	});
};

export const loadFilterStationsSuccess = (r) => {

	return ({
		type: LOAD_FILTER_STATIONS_SUCCESS,
		data: r.response ,
		errorMessage: null,
		errorStatus: null
	});
};

export const loadFilterStationsWithContracts = (feature, filter) => { 
	return ({
		type: LOAD_FILTER_STATIONS_WITH_CONTRACTS,
		data: {feature: feature, filter: filter},
		errorMessage: null,
		errorStatus: null
	});
};

export const loadFilterStationsWithContractsSuccess = (r) => {

	return ({
		type: LOAD_FILTER_STATIONS_WITH_CONTRACTS_SUCCESS,
		data: r.response ,
		errorMessage: null,
		errorStatus: null
	});
};

export const loadFilterDodoStations = (feature, filter) => {

	return ({
		type: LOAD_FILTER_DODO_STATIONS,
		data: {feature: feature, filter: filter},
		errorMessage: null,
		errorStatus: null
	});
};

export const loadFilterDodoStationsSuccess = (r) => {

	return ({
		type: LOAD_FILTER_DODO_STATIONS_SUCCESS,
		data: r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const loadSystemEventsFilter = (feature) => { 
	return ({
		type: LOAD_SYSTEM_EVENTS_FILTER,
		data: {feature: feature},
		errorMessage: null,
		errorStatus: null
	});
};
	
export const loadSystemEventsFilterSuccess = (r) => {
	return ({
		type: LOAD_SYSTEM_EVENTS_FILTER_SUCCESS,
		data: r.response ,
		errorMessage: null,
		errorStatus: null
	});
};


export const loadFilterExploitantsReports = (feature, filter) => { 
	return ({
		type: LOAD_FILTER_EXPLOITANTS_REPORTS,
		data: {feature: feature, filter: filter},
		errorMessage: null,
		errorStatus: null
	});
};

export const loadFilterExploitantsReportsSuccess = (r) => {

	return ({
		type: LOAD_FILTER_EXPLOITANTS_REPORTS_SUCCESS,
		data: r.response ,
		errorMessage: null,
		errorStatus: null
	});
};
