import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import msg from '../texts/msg';

//let avatar = '/assets/ultima/layout/images/avatar.png';

export class CartadisTopbar extends Component {

    static defaultProps = {
        onMenuButtonClick: null,
        onTopbarMenuButtonClick: null,
        onTopbarItemClick: null,
        profileMode: null,
        horizontal: false,
        topbarMenuActive: false,
        activeTopbarItem: null,
        onRightPanelButtonClick: null
    }

    static propTypes = {
        onMenuButtonClick: PropTypes.func.isRequired,
        onTopbarMenuButtonClick: PropTypes.func.isRequired,
        onTopbarItemClick: PropTypes.func.isRequired,
        profileMode: PropTypes.string.isRequired,
        horizontal: PropTypes.bool.isRequired,
        topbarMenuActive: PropTypes.bool.isRequired,
        activeTopbarItem: PropTypes.string,
        onRightPanelButtonClick: PropTypes.func.isRequired
    }

    constructor() {
        super();
        this.state = {};
    }

    onTopbarItemClick(event, item) {
        if(this.props.onTopbarItemClick) {
            this.props.onTopbarItemClick({
                originalEvent: event,
                item: item
            });
        }
    }
    render() {
    	let topbarItemsClassName = classNames('topbar-items animated fadeInDown', {'topbar-items-visible': this.props.topbarMenuActive});
    	let logoClass = process.env.REACT_APP_TARGET_RUNTIME === 'world' ? 'logo-wash' : 'logo';
        let logoImg = (<div className={logoClass}></div>);
        let logoWorldImg = process.env.REACT_APP_TARGET_RUNTIME === 'world'? (<div className="logo-world"></div>) : '';
        
        let d = new Date();
    	let dateHour =  d.toLocaleDateString() + ' ' + d.toLocaleTimeString();
 return (
       <div className="topbar clearfix wash-topbar" >
                    <div className="topbar-right">
                    {this.props.logedIn && (<button className="p-link" id="menu-button" onClick={this.props.onMenuButtonClick}>
                            <i></i>
                        </button>)}
                        {logoWorldImg} {logoImg}
                        {this.props.logedIn && this.props.user && (
                        (<button className="topbar-items p-link" onClick={this.props.onLogout}>
                            <i className="material-icons">power_settings_new</i>
                        </button>))}
                        <ul className={topbarItemsClassName}>
                            {(this.props.logedIn && this.props.user && (this.props.profileMode === 'top' || this.props.horizontal)) && 
                                <li className={classNames('profile-item', {'active-top-menu': this.props.activeTopbarItem === 'profile'})}>
                                    <button className="p-link" onClick={(e) => this.onTopbarItemClick(e, 'profile')}>
                                        {/* <img className="profile-image" src={avatar} alt="Profile"/> */}
                                        <i className="material-icons">person</i>
                                        <span className="topbar-item-name">{this.props.user ? this.props.user.firstname + ' ' + this.props.user.lastname : ''}</span>
                                    </button>
                                    <ul className="ultima-menu animated fadeInDown">
                                    {this.props.user.authProvider && (this.props.user.authProvider.toLowerCase() === "undefined" || this.props.user.authProvider.toLowerCase() === "washadmin") &&
                                        (<li role="menuitem">
                                            <button className="p-link" onClick={this.props.onProfileClick}>
                                                <i className="material-icons">lock</i>
                                                <span>{msg('user.fields.password')}</span>
                                            </button>
                                        </li>)}
                                    </ul>
                            </li>}
                        </ul>
                        <span className="topbar-items" style={{marginLeft: '10px'}} >{this.props.logedIn && this.props.user ? this.props.user.firstname + ' ' + this.props.user.lastname : ''}</span>
                        <span className="topbar-items">{dateHour}</span>
                    </div>
                </div>);
    }
}