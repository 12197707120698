import { ofType} from 'redux-observable';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError} from  'rxjs/operators';
import {adminequipmentsSuccess, createEquipmentSuccess, equipmentDeleteByIdSuccess, EQUIPMENT_UPDATE, equipmentUpdateSuccess, equipmentSuccess, UPDATE_BORNE, updateBorneSuccess} from '../actions/adminequipments';
import {ADMINEQUIPMENTS,CREATE_EQUIPMENT, EQUIPMENT_DELETE_BY_ID, EQUIPMENT} from '../actions/adminequipments';
import {faillure } from '../actions';
import { authProviderType } from '../AuthProviderType';

export const adminequipmentsEpic = (epicAction)  => {
	return  epicAction.pipe(
			ofType(ADMINEQUIPMENTS),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlEquipments = apiRootUri + "adminequipments";
				const req = ajax({
						url: urlEquipments + "/equipmentsAdmin"
								+'?page='+ action.data.page + '&pageSize=' + action.data.pageSize,
					  	method: 'POST',				  
						headers: {
							'Content-Type': 'application/json'
						},
						withCredentials: true,
						body: action.data.filter
				}) ;					
				return req.pipe(map(res => adminequipmentsSuccess(res)), catchError(res => of(faillure(res))))
			}),
	);	
};

export const createEquipmentEpic = (epicAction)  => {
	return  epicAction.pipe(
			ofType(CREATE_EQUIPMENT),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlEquipments = apiRootUri + "adminequipments";
				const req = ajax({
						url: urlEquipments + '/equipment',
					  	method: 'POST',					  
					  	headers: {
							'Content-Type': 'application/json'
						},
						withCredentials: true,
					  	body: action.data.equipment
					});
				return req.pipe(
            			map(res => { return createEquipmentSuccess(res);}),
            			catchError(res => { return of(faillure(res));})
            		)
			}),
	);
};

export const equipmentDeleteByIdEpic = (epicAction)  => {
	return  epicAction.pipe(
			ofType(EQUIPMENT_DELETE_BY_ID),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlEquipments = apiRootUri + "adminequipments";
				const req = ajax({
					url: urlEquipments + "/" +action.data.equipmentId,
					method: 'DELETE',					  
					headers: {
					  'Content-Type': 'application/json'
				  },
				  withCredentials: true
			 	});
				return req.pipe(
					map(res => {return equipmentDeleteByIdSuccess(res);}),
					catchError(res => { return of(faillure(res));})
				)
			}),
	);	

};

export const equipmentEpic = (epicAction)  => {
	return  epicAction.pipe(
			ofType(EQUIPMENT),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlEquipments = apiRootUri + "adminequipments";
				const req = ajax({
					  	url: urlEquipments + "/" + action.data.equipmentId,
					  	method: 'GET',				  
						headers: {
							'Content-Type': 'application/json'
						},
						withCredentials: true
					});				
				return req.pipe(
            			map(res => {return equipmentSuccess(res);}),
            			catchError(res => { return of(faillure(res));})
            		)
			}),
	);	
};

export const equipmentUpdateEpic = (epicAction)  => {
	return  epicAction.pipe(
			ofType(EQUIPMENT_UPDATE),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlEquipments = apiRootUri + "adminequipments";
				const req = ajax({
						url: urlEquipments + "/" + action.data.equipmentId,
					  	method: 'PUT',
					  	headers: {
							'Content-Type': 'application/json'
						},
						withCredentials: true,
					  	body: action.data.equipment
					});
				return req.pipe(
            			map(res => { return equipmentUpdateSuccess(res);}),
            			catchError(res => { return of(faillure(res));})
            		)
			}),
	);
};

export const updateBorneEpic = (epicAction)  => {
	return  epicAction.pipe(
			ofType(UPDATE_BORNE),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlEquipments = apiRootUri + "adminequipments";
				const req = ajax({
						url: urlEquipments + '/' + action.data.equipmentId + '/configuration',
					  	method: 'POST',					  
					  	headers: {
							'Content-Type': 'application/json'
						},
						withCredentials: true
					});
				return req.pipe(
            			map(res => { return updateBorneSuccess(res);}),
            			catchError(res => { return of(faillure(res));})
            		)
			}),
	);
};