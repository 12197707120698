import { toDefaultDateFormat } from '../../utils/dateTimeUtils';
import msg from '../../texts/msg';

export function dataTitlePanel(filter){
    if (filter) {
         let  equipmentName = filter.equipments && filter.equipments.length > 0 ? filter.equipments.map((data )=>{
                 return data.value != null && data.value !== '' ? data.value :  msg('filter.equipments.all') ;
             }):msg('filter.equipments.all');
             return  [
                 {key :"titleFilter" ,value :  msg('filter.in.progress')  },
                 {key :"startDate",value :msg('filter.date.from').toLowerCase()+"   " + toDefaultDateFormat(new Date(filter.startDate)) },
                 {key :"endDate",value :msg('filter.date.to').toLowerCase() +"  " + toDefaultDateFormat(new Date(filter.endDate))+";"},
                 { key: "Station", value: filter.stations && filter.stations.length > 0 && filter.stations[0].value ? filter.stations[0].value +  ";" : msg('filter.stations.all') +  ";"},
                 {key : "equipmentName" ,value : equipmentName  }
             ] 
     } 
     else return [{key :"titleFilter" ,value :  " " }]
 }

 export function dataTitlePanelDashboard(filter){
    if (filter) {
         let  equipmentName = filter.equipments && filter.equipments.length > 0 ? filter.equipments.map((data )=>{
                 return data.value != null && data.value !== '' ? data.value :  msg('filter.equipments.all') ;
             }):msg('filter.equipments.all');
             return  [
                 {key :"titleFilter" ,value :  msg('filter.in.progress')  },
                 { key: "Groupe de stations", value: filter.groupStations && filter.groupStations.length > 0 && filter.groupStations[0].value ? filter.groupStations[0].value +  ";" : msg('filter.groupStation.all') +  ";"},
                 { key: "Station", value: filter.stations && filter.stations.length > 0 && filter.stations[0].value ? filter.stations[0].value +  ";" : msg('filter.stations.all') +  ";"},
                 { key: "Canal de vente", value: filter.saleChannels && filter.saleChannels.length > 0 && filter.saleChannels[0].value ? filter.saleChannels[0].value +  ";" : msg('filter.channels.all') +  ";"},
                 {key : "equipmentName" ,value : equipmentName  }
             ] 
     } 
     else return [{key :"titleFilter" ,value :  " " }]
 }