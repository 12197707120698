
export const toLocaleDateTime = (s) => {
    let d = null;
    if(s) {
        if(typeof(s) === 'string') {
            d = new Date(s.replace('+0000', 'Z'));
        } else if (typeof(s) === 'number') {
            d = new Date(s);
        } else {
            d  = s;
        }
    }
    return d;
};

 export const toLocaleDateTimeFormat = (s) => {
    let d = null;
    if(s) {
        if(typeof(s) === 'string') {
            d = new Date(s.replace('+0000', 'Z'));
        } else if (typeof(s) === 'number') {
            d = new Date(s);
        } else {
            d  = s;
        }
    }
    let dd =  d ? d.toLocaleDateString() + ' ' + d.toLocaleTimeString() : '';
    return dd;
};

export const toDefaultDateFormat = (date) => {
    return date ? date.toLocaleDateString() : '' ;
}

export const formatDateTime = (d) => {
    let ld = d ? toLocaleDateTime(d) : new Date();
    let refreshTime =  ld.getHours() + "h" + (ld.getMinutes() < 10 ? '0'+ld.getMinutes() : ld.getMinutes());
    let fdd =  ld.toLocaleDateString();
    return {date: fdd, time: refreshTime};
}

export const toTimestamp = d => {
    let dd = toLocaleDateTime(d);
    return dd ? dd.getTime() : null;
}

export const compareDates = (d1,d2) => {
    if(d1 && d2) {
        if(d1 > d2) return d1;
        else  return d2
     } else return d1 || d2
}

export const getYesterdayDate = (today) => {
    let yesterday= new Date(today);
    yesterday = yesterday.setDate(today.getDate()-1);
    return new Date(yesterday);
}