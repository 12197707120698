import React, {Component} from 'react';
import { Message } from 'primereact/message';
import {rolesValidator, REPORTING,EXPLOITANTSREPORT} from '../../utils/RolesValidator';
import ReportingExploitantFilter from './ReportingExploitantFilter';
import { toDefaultDateFormat} from '../../utils/dateTimeUtils';
import msg from '../../texts/msg';
import downloadUploadFilesService from '../../middleware/download-upload-files.service';
import BorneFilter from '../common/BorneFilter';
let DAILY_REPORT='dailyReport';
let MANDAT_CO_REPORT = 'mandatCoReport';
let MANDAT_CO_DODO_REPORT = 'mandatCoDodoReport';
let MANDAT_CO_REPORT_URL = 'reporting/mandat-co-report';
let MANDAT_CO_DODO_REPORT_URL = 'reporting/mandat-co-dodo-report';
export class ReportingExploitant extends Component {

	constructor(props) {
        super(props);
        this.state = {
            isSapCodeOrImplantRequired: false,
            dailyReportBtnSelected: true,
            submenu: "",
            reportType: "",
            stationRequired: false,
            stationSingle: false
        };
        
        this.loadFilterStationsWithContracts = this.loadFilterStationsWithContracts.bind(this);
        this.loadReport = this.loadReport.bind(this);
        this.initDataInfoBarReport = this.initDataInfoBarReport.bind(this);
        this.handleDailyReportClick = this.handleDailyReportClick.bind(this);
        this.handleMandatCoReportClick = this.handleMandatCoReportClick.bind(this);        
        this.getStateByReportType = this.getStateByReportType.bind(this);
        this.updateIsSapCodeOrImplantRequired = this.updateIsSapCodeOrImplantRequired.bind(this);
    }

    componentDidMount() {
        let lSubmenu = this.props.match.params.submenu;
        this.setState({
            submenu: lSubmenu,
            reportType: DAILY_REPORT
        });
    }

    componentDidUpdate(prevProps) {
     // to do when another type of exploitant report will be taken intou account.
     // Currently, we take into account only DAILY exploitant report
    }
    
    handleDailyReportClick(){
         this.setState(this.getStateByReportType(DAILY_REPORT));
     }

     handleMandatCoReportClick(){
        this.setState(this.getStateByReportType(MANDAT_CO_REPORT));
    }
    handleMandatCoDodoReportClick(){
        this.setState(this.getStateByReportType(MANDAT_CO_DODO_REPORT));
    }


     getStateByReportType(reportType){
        switch(reportType) {
            case DAILY_REPORT:
            return {
                reportType: DAILY_REPORT,
                dailyReportBtnSelected: !this.state.dailyReportBtnSelected,
                mandatCoReportBtnSelected: false,
                mandatCoDodoReportBtnSelected: false,
                stationRequired: false,
                stationSingle: false
            };
            case MANDAT_CO_REPORT: 
            return {
                reportType: MANDAT_CO_REPORT,
                dailyReportBtnSelected: false,
                mandatCoReportBtnSelected: !this.state.mandatCoReportBtnSelected,
                mandatCoDodoReportBtnSelected: false,
                stationRequired: true,
                stationSingle: true
            };
            case MANDAT_CO_DODO_REPORT:
            return {
                reportType: MANDAT_CO_DODO_REPORT,
                dailyReportBtnSelected: false,
                mandatCoReportBtnSelected: false,
                mandatCoDodoReportBtnSelected: !this.state.mandatCoDodoReportBtnSelected,
                stationRequired: true,
                stationSingle: true
            }
            default:
                return this.state;
        }
    }

    updateIsSapCodeOrImplantRequired(v){
        this.setState({isSapCodeOrImplantRequired : v})
    }
    loadFilterStationsWithContracts(filter) {
        this.props.loadFilterStationsWithContracts(REPORTING, filter);
    }

    loadFilter(filter) {
        this.props.loadFilter(REPORTING, filter);
    }
    
    loadFilterStations(filter) {
        this.props.loadFilterStations(REPORTING, filter);
    }

    initDataInfoBarReport(filter){
        this.props.setDataInfoBar(this.dataInfobar(filter));
    }

    loadReport(filter) {
        switch (this.state.reportType) { 
            case DAILY_REPORT:
                    this.props.loadFilterExploitantsReports(REPORTING, filter)
                break;
            case MANDAT_CO_REPORT:
                let filename = msg('report.exploitant.mandat.co') + '.pdf';
                let exportUrl = MANDAT_CO_REPORT_URL;
                this.props.setDataInfoBar(this.dataInfobar(filter));
                downloadUploadFilesService.getFileData(exportUrl, filename, filter);
                break;
            case MANDAT_CO_DODO_REPORT:
                filename = msg('report.exploitant.mandat.co.dodo') + '.pdf';
                exportUrl = MANDAT_CO_DODO_REPORT_URL;
                this.props.setDataInfoBar(this.dataInfobar(filter));
                downloadUploadFilesService.getFileData(exportUrl, filename, filter);
                break;
            default:
        }
    }

    dataInfobar(filter){
        if(!filter) {
            return [{key :"titleFilter" ,value :  " " }];
        }
        return [
            {key: "titleFilter", value: msg('filter.in.progress')  },
            {key: "date", value: msg('filter.date') + ": " + toDefaultDateFormat(new Date(filter.date)) + ";"},
            {key: "sapCode", value: msg('filter.exploitant.sapCode')+ ": " + filter.sapCode + ";"},
            {key: "implant", value: msg('filter.exploitant.implant')+ ": " + filter.implant + ";"},
            {key: "batchNumber", value: msg('filter.exploitant.batchNumber')+ ": " + filter.batchNumber}
        ]
    }

    render() {
        let exploitantsReportAuthorized = rolesValidator.validate(EXPLOITANTSREPORT) && (this.state.submenu === 'daily');
        return exploitantsReportAuthorized && 
        (<div className="card p-grid p-col-12 report">
                {(<div className="p-grid p-col-12" id="reportExploitantsId">
                        <div className="p-col-12"><h1>{msg('report.exploitant.title')}</h1></div>
                        <div className="p-grid p-col-12 p-md-2 export-button">
                             <div className="p-col-12" >
                                  <a href="#dailyReportExploitantId">
                                     <div id="dailyReport" className={"mandat pi-md-sync " + (this.state.dailyReportBtnSelected ? "buttonSelected" : "buttonNotSelected")}
                                        alt={msg('report.exploitant.daily')}
                                        onClick={ () => this.handleDailyReportClick() } key='commission' />
                                   </a>                                    
                             </div>
                             <div className="p-col-12" style={{height:'40px'}}><span>{msg('report.exploitant.daily')}</span></div>
                        </div>
                        <div className="p-grid p-col-12 p-md-2 export-button">
                             <div className="p-col-12" >
                                  <a href="#mandatCoReportFilterId">
                                     <div id="mandatCoReport" className={"mandat pi-md-rotate-90-degrees-ccw " + (this.state.mandatCoReportBtnSelected ? "buttonSelected" : "buttonNotSelected")}
                                        alt={msg('report.exploitant.daily')}
                                        onClick={ () => this.handleMandatCoReportClick() } key='mandat-co' />
                                   </a>                                    
                             </div>
                             <div className="p-col-12" style={{height:'40px'}}><span>{msg('report.exploitant.mandat.co')}</span></div>
                        </div>
                        <div className="p-grid p-col-12 p-md-2 export-button">
                             <div className="p-col-12" >
                                  <a href="#mandatCoDodoReportFilterId">
                                     <div id="mandatCoDodoReport" className={"mandat pi-md-rotate-90-degrees-ccw " + (this.state.mandatCoDodoReportBtnSelected ? "buttonSelected" : "buttonNotSelected")}
                                        alt={msg('report.exploitant.daily')}
                                        onClick={ () => this.handleMandatCoDodoReportClick() } key='mandat-co-dodo' />
                                   </a>
                             </div>
                             <div className="p-col-12" style={{height:'40px'}}><span>{msg('report.exploitant.mandat.co.dodo')}</span></div>
                        </div>
                {this.state.dailyReportBtnSelected &&                
                <div className="p-grid p-col-12" id="dailyReportExploitantId">
                    <div className="p-col-12">
                        <h2>{msg('report.exploitant.criteria')}</h2>
                        {this.state.isSapCodeOrImplantRequired ? <Message severity="error" text={msg('report.exploitant.sapCode.or.Implant.required')} key="name" />
                        :<p id="sapCodeImplantRequiredId"><span>{msg('report.exploitant.sapCode.or.Implant.required')}</span> </p>}
                         <hr/>
                    </div>
                    <ReportingExploitantFilter apply={this.loadReport}
                       loadFilterStationsWithContracts={filter => this.loadFilterStationsWithContracts(filter)}
                       filterStationsWithContracts={this.props.filterStationsWithContracts}
                       exploitantReport = {this.props.exploitantReport}
                       initDataInfoBarReport={this.initDataInfoBarReport}
                       applyLabel={msg('report.exploitant.consult')} 
                       filterItemClassName={"p-md-6"}
                       updateIsSapCodeOrImplantRequired={this.updateIsSapCodeOrImplantRequired}/>
                </div>}
                {this.state.mandatCoReportBtnSelected &&
                <div className="p-grid p-col-12" id="mandatCoReportFilterId">
                    <div className="p-col-12"><h2>{msg('report.exploitant.criteria')}</h2></div>
                    <div className="p-col-12"><hr/></div>
                    <BorneFilter apply={this.loadReport}
                        loadFilter={filter => this.loadFilter(filter)}
                        loadFilterStations={filter => this.loadFilterStations(filter)}
                        filterData={this.props.filterData}
                        filterStations={this.props.filterStations}
                        applyOnMount={false}
                        showDate={true} showTime={false} showMonth={true} showPeriodType={false}
                        showStation={true} stationRequired={this.state.stationRequired} stationSingle={this.state.stationSingle}
                        showChannel={false} showEquipments={false} showBatchNumbers={false} 
                        initDataInfoBarReport={this.initDataInfoBarReport}
                        applyLabel={msg('report.payments.btnlabel')} title="" filterItemClassName={"p-md-4"}/>
                </div>}
                {this.state.mandatCoDodoReportBtnSelected &&
                <div className="p-grid p-col-12" id="mandatCoDodoReportFilterId">
                    <div className="p-col-12"><h2>{msg('report.exploitant.criteria')}</h2></div>
                    <div className="p-col-12"><hr/></div>
                    <BorneFilter apply={this.loadReport}
                        loadFilter={filter => this.loadFilter(filter)}
                        loadFilterStations={filter => this.props.loadFilterDodoStations(REPORTING, filter)}
                        filterData={this.props.filterData}
                        filterStations={this.props.filterDodoStations}
                        applyOnMount={false}
                        showDate={true} showTime={false} showMonth={true} showPeriodType={false}
                        showDodoStation={true} stationRequired={this.state.stationRequired} stationSingle={this.state.stationSingle}
                        showChannel={false} showEquipments={false} showBatchNumbers={false}
                        initDataInfoBarReport={this.initDataInfoBarReport}
                        applyLabel={msg('report.payments.btnlabel')} title="" filterItemClassName={"p-md-4"}/>
                </div>}
                </div>)}
        </div>);
    }
}