import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import MessageDialog from '../../common/MessageDialog';
import { CONSULT_MODE } from './CommissionRenderModes';
import msg from '../../../texts/msg';
import { rolesValidator, COMMISSIONS, UPDATE, CREATE } from '../../../utils/RolesValidator';
import '../../common/css/common.css';

const emtyChar = '';
const defaultRateValue = 0;
const defaultMandateValue = false;

class CommissionForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            commission: {
                id: emtyChar,
                name: emtyChar,
                description: emtyChar,
                twPrestationRate: { value: defaultRateValue, mandate: defaultMandateValue },
                grPrestationRate: { value: defaultRateValue, mandate: defaultMandateValue },
                twReloadRate: { value: defaultRateValue, mandate: defaultMandateValue },
                cbPrestationRate: { value: defaultRateValue, mandate: defaultMandateValue },
                cashPrestationRate: { value: defaultRateValue, mandate: defaultMandateValue }
            }
        };

        this.validate = this.validate.bind(this);
        this.checkApiErrors = this.checkApiErrors.bind(this);
        this.checkReloadOnChange = this.checkReloadOnChange.bind(this);
        this.handleTwPrestationRateChange = this.handleTwPrestationRateChange.bind(this);
        this.handleGrPrestationRateChange = this.handleGrPrestationRateChange.bind(this);
        this.handleTwReloadRateChange = this.handleTwReloadRateChange.bind(this);
    }

    componentDidMount() {
        if (this.props.commissionId) {
            this.props.loadCommission(this.props.commissionId);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.checkApiErrors(prevProps)) {
            return;
        }
        if (this.checkReloadOnChange(prevProps)) {
            return;
        }
        this.initCommission(prevProps);
    }

    checkApiErrors(prevProps) {
        let prevError = prevProps.error && prevProps.error.timestamp;
        let lastError = this.props.error && this.props.error.timestamp;
        let error = (lastError && (!prevError || prevError < lastError));
        if (error) {
            let isError = this.props.error.message && this.props.error.message.startsWith('error.commissions.') ? true : false;
            this.setState({ isApiError: isError, message: this.props.error.message });
            return true;
        }
        return false;
    }

    checkReloadOnChange(prevProps) {
        let reload = (this.props.lastChange && (!prevProps.lastChange || prevProps.lastChange < this.props.lastChange));
        if (reload) {
            this.props.renderList();
            return true;
        }
        return false;
    }

    initCommission(prevProps) {
        if (!prevProps.commission && this.props.commission) {
            let commissionId = this.props.mode === CONSULT_MODE ? this.props.commission.id : '';
            let lcommission = {
                id: commissionId,
                name: this.props.commission.name,
                description: this.props.commission.description,
                twPrestationRate: this.props.commission.twPrestationRate ? this.props.commission.twPrestationRate : { value: defaultRateValue, mandate: defaultMandateValue },
                grPrestationRate: this.props.commission.grPrestationRate ? this.props.commission.grPrestationRate : { value: defaultRateValue, mandate: defaultMandateValue },
                twReloadRate: this.props.commission.twReloadRate ? this.props.commission.twReloadRate : { value: defaultRateValue, mandate: defaultMandateValue },
                cbPrestationRate: this.props.commission.cbPrestationRate ? this.props.commission.cbPrestationRate : { value: defaultRateValue, mandate: defaultMandateValue },
                cashPrestationRate: this.props.commission.cashPrestationRate ? this.props.commission.cashPrestationRate : { value: defaultRateValue, mandate: defaultMandateValue }
            }
            this.setState({ commission: lcommission });
        }
    }

    handleTwPrestationRateChange(e) {
        let rate = 0;
        if (e.target.value === "") {
            rate = 0;
        } else {
            rate = Number.parseInt(e.target.value);
        }
        let mandate = this.state.commission.twPrestationRate.mandate;

        if (rate >= 0 && !Number.isNaN(rate)) {
            if (rate >= 0 && rate <= 100) {
                this.setState(prevState => ({
                    commission: {
                        ...prevState.commission,
                        twPrestationRate: {
                            ...prevState.commission.twPrestationRate,
                            value: rate,
                            mandate: mandate
                        }
                    },
                    twPrestationRateInputError: false
                }));

                if ((rate === 0 && mandate) || (rate > 0 && !mandate)) {
                    this.setState({ twPrestationRateInputWarning: true });
                } else {
                    this.setState({ twPrestationRateInputWarning: false });
                }
            }
        }
    }

    handleGrPrestationRateChange(e) {
        let rate = 0;
        if (e.target.value === "") {
            rate = 0;
        } else {
            rate = Number.parseInt(e.target.value);
        }
        let mandate = this.state.commission.grPrestationRate.mandate;

        if (rate >= 0 && !Number.isNaN(rate)) {
            if (rate >= 0 && rate <= 100) {
                this.setState(prevState => ({
                    commission: {
                        ...prevState.commission,
                        grPrestationRate: {
                            ...prevState.commission.grPrestationRate,
                            value: rate,
                            mandate: mandate
                        }
                    },
                    grPrestationRateInputError: false
                }));

                if ((rate === 0 && mandate) || (rate > 0 && !mandate)) {
                    this.setState({ grPrestationRateInputWarning: true });
                } else {
                    this.setState({ grPrestationRateInputWarning: false });
                }
            }
        }
    }
    handleTwReloadRateChange(e) {
        let rate = 0;
        if (e.target.value === "") {
            rate = 0;
        } else {
            rate = Number.parseInt(e.target.value);
        }
        let mandate = this.state.commission.twReloadRate.mandate;

        if (rate >= 0 && !Number.isNaN(rate)) {
            if (rate >= 0 && rate <= 100) {
                this.setState(prevState => ({
                    commission: {
                        ...prevState.commission,
                        twReloadRate: {
                            ...prevState.commission.twReloadRate,
                            value: rate,
                            mandate: mandate
                        }
                    },
                    twReloadRateInputError: false
                }));

                if ((rate === 0 && mandate) || (rate > 0 && !mandate)) {
                    this.setState({ twReloadRateInputWarning: true });
                } else {
                    this.setState({ twReloadRateInputWarning: false });
                }
            }
        }
    }
    handleCbPrestationRateChange(e) {
        let rate = 0;
        if (e.target.value === "") {
            rate = 0;
        } else {
            rate = Number.parseInt(e.target.value);
        }
        let mandate = this.state.commission.cbPrestationRate.mandate;

        if (rate >= 0 && !Number.isNaN(rate)) {
            if (rate >= 0 && rate <= 100) {
                this.setState(prevState => ({
                    commission: {
                        ...prevState.commission,
                        cbPrestationRate: {
                            ...prevState.commission.cbPrestationRate,
                            value: rate,
                            mandate: mandate
                        }
                    },
                    cbPrestationRateInputError: false
                }));

                if ((rate === 0 && mandate) || (rate > 0 && !mandate)) {
                    this.setState({ cbPrestationRateInputWarning: true });
                } else {
                    this.setState({ cbPrestationRateInputWarning: false });
                }
            }
        }
    }
    handleCashPrestationRateChange(e) {
        let rate = 0;
        if (e.target.value === "") {
            rate = 0;
        } else {
            rate = Number.parseInt(e.target.value);
        }
        let mandate = this.state.commission.cashPrestationRate.mandate;

        if (rate >= 0 && !Number.isNaN(rate)) {
            if (rate >= 0 && rate <= 100) {
                this.setState(prevState => ({
                    commission: {
                        ...prevState.commission,
                        cashPrestationRate: {
                            ...prevState.commission.cashPrestationRate,
                            value: rate,
                            mandate: mandate
                        }
                    },
                    cashPrestationRateInputError: false
                }));

                if ((rate === 0 && mandate) || (rate > 0 && !mandate)) {
                    this.setState({ cashPrestationRateInputWarning: true });
                } else {
                    this.setState({ cashPrestationRateInputWarning: false });
                }
            }
        }
    }

    handleTwPrestationCheckboxChange(e) {
        let value = this.state.commission.twPrestationRate.value;
        let mandate = e.checked;

        this.setState(prevState => ({
            commission: {
                ...prevState.commission,
                twPrestationRate: {
                    ...prevState.commission.twPrestationRate,
                    value: value,
                    mandate: mandate
                }
            }
        }));

        if ((mandate && value === 0) || (!mandate && value > 0)) {
            this.setState({ twPrestationRateInputWarning: true });
        } else {
            this.setState({ twPrestationRateInputWarning: false });
        }
    }
    handleGrPrestationCheckboxChange(e) {
        let value = this.state.commission.grPrestationRate.value;
        let mandate = e.checked;

        this.setState(prevState => ({
            commission: {
                ...prevState.commission,
                grPrestationRate: {
                    ...prevState.commission.grPrestationRate,
                    value: value,
                    mandate: mandate
                }
            }
        }));

        if ((mandate && value === 0) || (!mandate && value > 0)) {
            this.setState({ grPrestationRateInputWarning: true });
        } else {
            this.setState({ grPrestationRateInputWarning: false });
        }
    }
    handleCbPrestationCheckboxChange(e) {
        let value = this.state.commission.cbPrestationRate.value;
        let mandate = e.checked;

        this.setState(prevState => ({
            commission: {
                ...prevState.commission,
                cbPrestationRate: {
                    ...prevState.commission.cbPrestationRate,
                    value: value,
                    mandate: mandate
                }
            }
        }));

        if ((mandate && value === 0) || (!mandate && value > 0)) {
            this.setState({ cbPrestationRateInputWarning: true });
        } else {
            this.setState({ cbPrestationRateInputWarning: false });
        }
    }
    handleCashPrestationCheckboxChange(e) {
        let value = this.state.commission.cashPrestationRate.value;
        let mandate = e.checked;

        this.setState(prevState => ({
            commission: {
                ...prevState.commission,
                cashPrestationRate: {
                    ...prevState.commission.cashPrestationRate,
                    value: value,
                    mandate: mandate
                }
            }
        }));

        if ((mandate && value === 0) || (!mandate && value > 0)) {
            this.setState({ cashPrestationRateInputWarning: true });
        } else {
            this.setState({ cashPrestationRateInputWarning: false });
        }
    }
    handleTwReloadCheckboxChange(e) {
        let value = this.state.commission.twReloadRate.value;
        let mandate = e.checked;

        this.setState(prevState => ({
            commission: {
                ...prevState.commission,
                twReloadRate: {
                    ...prevState.commission.twReloadRate,
                    value: value,
                    mandate: mandate
                }
            }
        }));

        if ((mandate && value === 0) || (!mandate && value > 0)) {
            this.setState({ twReloadRateInputWarning: true });
        } else {
            this.setState({ twReloadRateInputWarning: false });
        }
    }

    validate() {
        let valid = true;
        if (!this.state.commission.name || !this.state.commission.name.trim()) {
            this.setState({ nameInputError: true });
            valid = false;
        } else {
            this.setState({ nameInputError: false });
        }
        if (!this.state.commission.twPrestationRate || (this.state.commission.twPrestationRate.value !== 0 && !this.state.commission.twPrestationRate.value) || !this.state.commission.twPrestationRate.value.toString().trim()
            || !(this.state.commission.twPrestationRate.value >= 0 && this.state.commission.twPrestationRate.value <= 100)) {
            this.setState({ twPrestationRateInputError: true, twPrestationRateInputErrorMessage: msg('commission.profile.form.input.twprestation.rate.required') });
            valid = false;
        }
        else {
            this.setState({ twPrestationRateInputError: false });
        }
        if (!this.state.commission.grPrestationRate || (this.state.commission.grPrestationRate.value !== 0 && !this.state.commission.grPrestationRate.value) || !this.state.commission.grPrestationRate.value.toString().trim()
            || !(this.state.commission.grPrestationRate.value >= 0 && this.state.commission.grPrestationRate.value <= 100)) {
            this.setState({ grPrestationRateInputError: true, grPrestationRateInputErrorMessage: msg('commission.profile.form.input.grprestation.rate.required') });
            valid = false;
        } else {
            this.setState({ grPrestationRateInputError: false });
        }
        if (!this.state.commission.twReloadRate || (this.state.commission.twReloadRate.value !== 0 && !this.state.commission.twReloadRate.value) || !this.state.commission.twReloadRate.value.toString().trim()
            || !(this.state.commission.twReloadRate.value >= 0 && this.state.commission.twReloadRate.value <= 100)) {
            this.setState({ twReloadRateInputError: true, twReloadRateInputErrorMessage: msg('commission.profile.form.input.twreload.rate.required') });
            valid = false;
        } else {
            this.setState({ twReloadRateInputError: false });
        }
        if (!this.state.commission.cbPrestationRate || (this.state.commission.cbPrestationRate.value !== 0 && !this.state.commission.cbPrestationRate.value) || !this.state.commission.cbPrestationRate.value.toString().trim()
            || !(this.state.commission.cbPrestationRate.value >= 0 && this.state.commission.cbPrestationRate.value <= 100)) {
            this.setState({ cbPrestationRateInputError: true, cbPrestationRateInputErrorMessage: msg('commission.profile.form.input.cbprestation.rate.required') });
            valid = false;
        } else {
            this.setState({ cbPrestationRateInputError: false });
        }
        if (!this.state.commission.cashPrestationRate || (this.state.commission.cashPrestationRate.value !== 0 && !this.state.commission.cashPrestationRate.value) || !this.state.commission.cashPrestationRate.value.toString().trim()
            || !(this.state.commission.cashPrestationRate.value >= 0 && this.state.commission.cashPrestationRate.value <= 100)) {
            this.setState({ cashPrestationRateInputError: true, cashPrestationRateInputErrorMessage: msg('commission.profile.form.input.cashprestation.rate.required') });
            valid = false;
        } else {
            this.setState({ cashPrestationRateInputError: false });
        }

        if (!valid) {
            return;
        }
        let vCommission = {
            id: this.props.mode === CONSULT_MODE ? this.state.commission.id : '',
            name: this.state.commission.name,
            description: this.state.commission.description,
            twPrestationRate: this.state.commission.twPrestationRate,
            grPrestationRate: this.state.commission.grPrestationRate,
            twReloadRate: this.state.commission.twReloadRate,
            cbPrestationRate: this.state.commission.cbPrestationRate,
            cashPrestationRate: this.state.commission.cashPrestationRate
        };
        this.props.validate(vCommission);
    }

    getFormTitle() {
        if (this.props.commission && this.props.mode === CONSULT_MODE) {
            return msg('commission.profile.form.title', { profileName: (this.state.commission ? this.state.commission.name : emtyChar) });
        } else {
            return msg('commission.profile.form.new.title', { profileName: (this.state.commission ? this.state.commission.name : emtyChar) });
        }
    }


    render() {
        let authorized = rolesValidator.validate([COMMISSIONS]);
        let cAuthorized = authorized.toString().includes(CREATE);
        let uAuthorized = authorized.toString().includes(UPDATE);
        return (
            <div className="p-grid commonClass card card-w-title">
                <div className="p-col-12"><h2>{this.getFormTitle()}</h2></div>
                <div className="card p-col-12">
                    <div className="p-col-12 p-grid form-group">
                        <div className="p-col-3">
                            <label>{msg('commission.profile.form.input.name')}</label>
                            <span className="obligatoire"> *</span>
                        </div>
                        <div className="p-col-9">
                            <span className="md-inputfield">
                                <InputText value={this.state.commission.name} className="inputClass" id="name" type="text" placeholder={msg('commission.profile.form.input.name')} size="30"
                                    onChange={e => this.setState({ commission: Object.assign({}, this.state.commission, { name: e.target.value }), nameInputError: false })}
                                    key="name" readOnly={this.state.commission.readOnly} />
                            </span>
                            {this.state.nameInputError && <Message severity="error" text={msg('commission.profile.form.input.name.required')} key="name" />}
                        </div>
                        <div className="p-col-3">
                            <label>{msg('commission.profile.form.input.description')}</label>
                        </div>
                        <div className="p-col-9">
                            <span className="md-inputfield md-inputfield-fill">
                                <InputText value={this.state.commission.description} className="inputClass" id="description" type="text" placeholder={msg('commission.profile.form.input.description')} size="30"
                                    onChange={e => this.setState({ commission: Object.assign({}, this.state.commission, { description: e.target.value }) })} key="description"
                                    readOnly={this.state.commission.readOnly} />
                            </span>
                        </div>
                    </div>
                </div>
                <div className="card card-w-title p-col-12">
                    <div className="p-col-12 p-grid" style={{ display: 'flex', flexDirection: 'row' }} >
                        <div className="p-col-3">
                            <h3>{msg('commission.profile.form.rates.title')}</h3>
                        </div>
                        <div className="p-col-2" style={{ display: 'flex', flexDirection: 'column', paddingLeft: '5%' }}>
                            <h3>{msg('commission.profile.form.rates.column.mandate')}</h3>

                        </div>
                        <div className="p-col-7" style={{ marginLeft: '-2%' }}>
                            <h3>{msg('commission.profile.form.rates.column.rate.title')}</h3>
                            <span>{msg('commission.profile.form.rates.column.rate.subtitle')}</span>
                        </div>
                    </div>
                    <div className="p-col-12 p-grid form-group">
                        <div className="p-col-12 p-grid">
                            <div className="p-col-4">
                                <label>{msg('commission.profile.form.input.twprestation.rate.label')} </label>
                                <span className="obligatoire"> *</span>
                            </div>
                            <div className="p-col-1">
                                <Checkbox value={this.state.commission.twPrestationRate}
                                    onChange={e => this.handleTwPrestationCheckboxChange(e)}
                                    checked={this.state.commission.twPrestationRate.mandate} readOnly={false}/>
                            </div>
                            <div className="p-col-5">
                                <span className="md-inputfield">
                                    <InputText value={this.state.commission.twPrestationRate.value} className="inputClass" id="twPrestationRate"
                                        size="15" maxLength="3" key="twPrestationRateTxt"
                                        onChange={e => this.handleTwPrestationRateChange(e)} />
                                </span>
                                {this.state.twPrestationRateInputError && <Message severity="error" text={this.state.twPrestationRateInputErrorMessage} key="twPrestationRateErr" />}
                                {this.state.twPrestationRateInputWarning && <Message severity="warn" text={msg('commission.profile.form.warning.message')} key="twPrestationRateWarn" />}
                            </div>
                        </div>
                        <div className="p-col-12 p-grid">
                            <div className="p-col-4">
                                <label>{msg('commission.profile.form.input.grprestation.rate.label')} </label>
                                <span className="obligatoire"> *</span>
                            </div>
                            <div className="p-col-1">
                                <Checkbox value={this.state.commission.grPrestationRate}
                                    onChange={e => this.handleGrPrestationCheckboxChange(e)}
                                    checked={this.state.commission.grPrestationRate.mandate} readOnly={false} />
                            </div>
                            <div className="p-col-5">
                                <span className="md-inputfield">
                                    <InputText value={this.state.commission.grPrestationRate.value} className="inputClass" id="grPrestationRate"
                                        size="15" maxLength="3" key="grPrestationRateTxt"
                                        onChange={e => this.handleGrPrestationRateChange(e)} />
                                </span>
                                {this.state.grPrestationRateInputError && <Message severity="error" text={this.state.grPrestationRateInputErrorMessage} key="grPrestationRateErr" />}
                                {this.state.grPrestationRateInputWarning && <Message severity="warn" text={msg('commission.profile.form.warning.message')} key="grPrestationRateWarn" />}
                            </div>
                        </div>
                        <div className="p-col-12 p-grid">
                            <div className="p-col-4">
                                <label>{msg('commission.profile.form.input.cbprestation.rate.label')} </label>
                                <span className="obligatoire"> *</span>
                            </div>
                            <div className="p-col-1">
                                <Checkbox value={this.state.commission.cbPrestationRate}
                                    onChange={e => this.handleCbPrestationCheckboxChange(e)}
                                    checked={this.state.commission.cbPrestationRate.mandate} readOnly={false} />
                            </div>
                            <div className="p-col-5">
                                <span className="md-inputfield">
                                    <InputText value={this.state.commission.cbPrestationRate.value} className="inputClass" id="cbPrestationRate"
                                        size="15" maxLength="3" key="cbPrestationRateTxt"
                                        onChange={e => this.handleCbPrestationRateChange(e)} />
                                </span>
                                {this.state.cbPrestationRateInputError && <Message severity="error" text={this.state.cbPrestationRateInputErrorMessage}
                                    key="cbPrestationRateErr" />}
                                {this.state.cbPrestationRateInputWarning && <Message severity="warn" text={msg('commission.profile.form.warning.message')} key="cbPrestationRateWarn" />}

                            </div>
                        </div>
                        <div className="p-col-12 p-grid">
                            <div className="p-col-4">
                                <label>{msg('commission.profile.form.input.cashprestation.rate.label')} </label>
                                <span className="obligatoire"> *</span>
                            </div>
                            <div className="p-col-1">
                                <Checkbox value={this.state.commission.cashPrestationRate}
                                    onChange={e => this.handleCashPrestationCheckboxChange(e)}
                                    checked={this.state.commission.cashPrestationRate.mandate} readOnly={false} />
                            </div>
                            <div className="p-col-5">
                                <span className="md-inputfield">
                                    <InputText value={this.state.commission.cashPrestationRate.value} className="inputClass" id="cashPrestationRate"
                                        size="15" maxLength="3" key="cashPrestationRateTxt"
                                        onChange={e => this.handleCashPrestationRateChange(e)} />
                                </span>
                                {this.state.cashPrestationRateInputError && <Message severity="error" text={this.state.cashPrestationRateInputErrorMessage}
                                key="cashPrestationRateErr" />} 
                                {this.state.cashPrestationRateInputWarning && <Message severity="warn" text={msg('commission.profile.form.warning.message')} key="cashPrestationRateWarn" />}
                            </div>
                        </div>
                        <div className="p-col-12 p-grid">
                            <div className="p-col-4">
                                <label>{msg('commission.profile.form.input.twreload.rate.label')} </label>
                                <span className="obligatoire"> *</span>
                            </div>
                            <div className="p-col-1">
                                <Checkbox value={this.state.commission.twReloadRate}
                                    onChange={e => this.handleTwReloadCheckboxChange(e)}
                                    checked={this.state.commission.twReloadRate.mandate} readOnly={false} />
                            </div>
                            <div className="p-col-5">
                                <span className="md-inputfield">
                                    <InputText value={this.state.commission.twReloadRate.value} className="inputClass" id="twReloadRate"
                                        size="15" maxLength="3" key="twReloadRateTxt"
                                        onChange={e => this.handleTwReloadRateChange(e)} />
                                </span>

                                {this.state.twReloadRateInputError && <Message severity="error" text={this.state.twReloadRateInputErrorMessage} key="twReloadRateErr" />}
                                {this.state.twReloadRateInputWarning && <Message severity="warn" text={msg('commission.profile.form.warning.message')} key="twReloadRateWarn" />}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-grid p-col-12">
                    <div className="p-grid p-col-9">
                    </div>
                    <div className="p-grid p-col-3">
                        <Button label={msg('commission.profile.form.button.validate')} title={msg('commission.profile.form.button.validate')}
                            key="validate" disabled={this.state.commission.readOnly || !(cAuthorized || uAuthorized)}
                            onClick={this.validate} />
                        <Button label={msg('campaign.form.button.cancel')} title={msg('campaign.form.button.cancel')}
                            key="cancel" onClick={e => { this.setState({ commission: null }); this.props.cancel(); }} />
                    </div>
                </div>


                <MessageDialog className={'message-dialog error-message'} visible={this.state.isApiError} title={msg('message.dialog.error.title')}
                    message={this.state.isApiError && msg(this.state.message)}
                    onHide={() => this.setState({ isApiError: false, message: '' })} />
            </div>
        )
    }
}

export default CommissionForm;