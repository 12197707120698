
export const CREATE = 'c';
export const READ = 'r';
export const UPDATE = 'u';
export const DELETE = 'd';

export const ADMINISTRATION = 'administration';
export const EQUIPMENTS = 'equipments';
export const ADMINEQUIPMENTS = 'adminequipments';
export const STATIONGROUPS = 'stationgroups';
export const STATIONS = 'stations';
export const USERS = 'users';
export const USERS_BORNEACCESS = 'users_borneaccess';
export const ROLE = 'roles';
export const GROUPS = 'groups';
export const PRICEZONE = 'pricezone';
export const COMMISSIONS = 'commissions';
export const TRADE = 'trade';
export const TRADE_EXPLOIT = 'trade_exploit';
export const REPORTING = 'reporting';
export const FINANCIALMANDATE = "financialmandate";
export const EXPLOITANTSREPORT = "exploitants_report";
export const MAINTENANCE = 'maintenance';
export const NOTIFICATIONS = 'notifications';
export const CAMPAIGNS = 'campaigns';
export const CAMPAIGNFIRMWARE = 'campaignfirmware';
export const CAMPAIGNHARDWARE = 'campaignhardware';
export const CAMPAIGNPRICEZONE = 'campaignpricezone';
export const SERVER = 'server';
export const CUSTOMIZATION = 'customization';
export const FIRMWARE = 'firmware';
export const HARDWARE = 'hardware';
export const TELEACTIONS = 'teleactions';

const UI_FEATURES_MAPPING = [
    {id: ADMINISTRATION, value: ADMINISTRATION},
    {id: EQUIPMENTS, value: EQUIPMENTS},
    {id: ADMINEQUIPMENTS, value: ADMINEQUIPMENTS},
    {id: STATIONGROUPS, value: STATIONGROUPS},
    {id: STATIONS, value: STATIONS},
    {id: USERS, value: USERS},
    {id: USERS_BORNEACCESS, value: USERS_BORNEACCESS},
    {id: ROLE, value: ROLE},
    {id: TRADE, value: TRADE},
    {id: TRADE_EXPLOIT, value: TRADE_EXPLOIT},
    {id: REPORTING, value: REPORTING},
    {id: FINANCIALMANDATE, value: FINANCIALMANDATE},
    {id: EXPLOITANTSREPORT, value: EXPLOITANTSREPORT},
    {id: MAINTENANCE, value: MAINTENANCE},
    {id: NOTIFICATIONS, value: NOTIFICATIONS},
    {id: CAMPAIGNS, value: CAMPAIGNS},
    {id: CAMPAIGNFIRMWARE, value: CAMPAIGNFIRMWARE},
    {id: CAMPAIGNHARDWARE, value: CAMPAIGNHARDWARE},
    {id: CAMPAIGNPRICEZONE, value: CAMPAIGNPRICEZONE},
    {id: COMMISSIONS, value: COMMISSIONS},
    {id: SERVER, value: SERVER},
    {id: CUSTOMIZATION, value: CUSTOMIZATION},
    {id: GROUPS, value: GROUPS},
    {id: PRICEZONE, value: PRICEZONE},
    {id: FIRMWARE, value: FIRMWARE},
    {id: HARDWARE, value: HARDWARE},
    {id: TELEACTIONS, value: TELEACTIONS},
];

class RolesValidator {
    features = [];
    uiFeatures = UI_FEATURES_MAPPING;
    companyTypes = [];

    setFeatures(features) {
        this.features = features ? features : [];
    }

    setCompanyTypes(companyTypes) {
        this.companyTypes = companyTypes ? companyTypes : [];
    }

    getCompanyTypes() {
        return this.companyTypes;
    }

    isCartadisOnly() {
        let lCompanyTypes = this.getCompanyTypes();
        return (lCompanyTypes && lCompanyTypes.length > 0 && lCompanyTypes.includes("cartadis")) ? true : false;
    }

    getUiFeature(uiFeatureId) {
    	
    	if(!Array.isArray(this.uiFeatures)){
            return null;
        }
        
        let i = 0;
        for(i = 0; i < this.uiFeatures.length; i++){
            if(this.uiFeatures[i].id === uiFeatureId){
                return this.uiFeatures[i];
            }
        }
    	
        return null;
    }

    validateOne(uiFeatureId) {    	
        if(!Array.isArray(this.features)){
            return false;
        }
        let feature = this.getUiFeature(uiFeatureId);
        if(!feature){
            return false;
        }
        let i = 0;
        for(i = 0; i < this.features.length; i++){
            if(this.features[i].name === feature.value){
                return this.features[i].featureOnly ?  true : this.features[i].permission;
            }
        }
       return false;
    }

    validate(uiFeatureIds) {
        if(!uiFeatureIds){
            return false;
        }
        let lFeatureIds =  Array.isArray(uiFeatureIds) ? uiFeatureIds : [uiFeatureIds];
        let i = 0;
        for(i = 0; i < lFeatureIds.length; i++){
            let permission = this.validateOne(lFeatureIds[i]);
            if(permission){
                return permission;
            }
        }

        return false;
    }

};

export const  rolesValidator  =  new RolesValidator();