import msg from '../../../texts/msg';
import { EMAIL_PATTERN } from '../../../utils/RegexPatterns';

const SEMI_COLON = ";";
const CAMMA = ",";

export const validateEmails = (caller) => {
    if(!caller.state.abonnement.destinataires || caller.state.abonnement.destinataires.length === 0){
        caller.setState({error: msg('subscription.maintenance.dest.emails.empty')});
        return false;
    }
    let emails = [];
    let destinataires  = caller.state.abonnement.destinataires;
    destinataires.forEach(e => {
        if(e) { 
            emails = e.indexOf(SEMI_COLON) > -1 ? emails.concat(e.split(SEMI_COLON)) : emails.concat(e.split(CAMMA));
        } else {
            emails.push(' ');
        }
    });
    let invalidEmails = [];
    emails.forEach((e)=>{ if(!EMAIL_PATTERN.test(e)){
        invalidEmails.push(e);
    }});
    if(invalidEmails.length > 0){
        caller.setState({error: msg('subscription.maintenance.dest.emails.invalid', {values: invalidEmails})});
        return false;
    }
    caller.setState({error: ""});
    return true;
};