export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const REDIRECT_TO_PROVIDER = 'REDIRECT_TO_PROVIDER';
export const REDIRECT_TO_PROVIDER_SUCCESS = 'REDIRECT_TO_PROVIDER_SUCCESS';
export const REDIRECT_TO_API_SERVER = 'REDIRECT_TO_API_SERVER';
export const REDIRECT_TO_API_SERVER_SUCCESS = 'REDIRECT_TO_API_SERVER_SUCCESS';
export const LOAD_ROLEFEATURES = 'LOAD_ROLEFEATURES';
export const LOAD_ROLEFEATURES_SUCCESS = 'LOAD_ROLEFEATURES_SUCCESS';

export const login = (username, password, authType) => { 
	return ({
		type: LOGIN,
		data: {username: username,  password : password, authType: authType},
		logedIn: false,
		authType: authType,
		errorMessage: null,
		errorStatus: null
	});
};
export const loginSuccess = (r) => {
	return ({
		type: LOGIN_SUCCESS,
		data: {
			features: r.response.features,
			companyTypes: r.response.companyTypes,
			user: {firstname: r.response.firstname, lastname: r.response.lastname, authProvider: r.response.authProvider, groupAdmin: r.response.groupAdmin},
			location: r.response.location,
			lastChange: r.response.lastChange,
			borneAutoConfigEnabled: r.response.borneAutoConfigEnabled,
			uploadMaxFileSize: r.response.uploadMaxFileSize
		},
		logedIn: true,
		authType: r.response.authType,
		errorMessage: null,
		errorStatus: null,
		errorSource: null
	});
};

export const logout = () => {
	return ({
		type: LOGOUT,
		data: {},
		logedOut: false,
		errorMessage: null,
		errorStatus: null
	});
};
export const logoutSuccess = (r) => {
	return ({
		type: LOGOUT_SUCCESS,
		data: null,
		logedOut: true,
		errorMessage: null,
		errorStatus: null,
		errorSource: null
	});
};

export const loadRoleFeatures = (authType) => {
	return ({
		type: LOAD_ROLEFEATURES,
		data: {authType: authType},
		logedIn: false,
		authType: authType,
		errorMessage: null,
		errorStatus: null
	});
};

export const loadRoleFeaturesSuccess = (r) => {
	return ({
		type: LOAD_ROLEFEATURES_SUCCESS,
		data: {
			features: r.response.features,
			companyTypes: r.response.companyTypes,
			user: { firstname: r.response.firstname, lastname: r.response.lastname, authProvider: r.response.authProvider, groupAdmin: r.response.groupAdmin },
			location: r.response.location,
			lastChange: r.response.lastChange,
			borneAutoConfigEnabled: r.response.borneAutoConfigEnabled
		},
		logedIn: true,
		authType: r.response.authType,
		errorMessage: null,
		errorStatus: null,
		errorSource: null
	});
};

export const redirectToProvider = (authRegistrationID) => {
	return ({
		type: REDIRECT_TO_PROVIDER,
		data: {authRegistrationID: authRegistrationID},
		errorMessage: null,
		errorStatus: null
	});
};
export const redirectToProviderSuccess = (r) => {
	return ({
		type: REDIRECT_TO_PROVIDER_SUCCESS,
		data: {redirectUrl: r.response.redirectUrl},
		errorMessage: null,
		errorStatus: null,
		errorSource: null
	});
};

export const redirectToApiServer = (authRegistrationID) => {
	return ({
		type: REDIRECT_TO_API_SERVER,
		data: {authRegistrationID: authRegistrationID},
		errorMessage: null,
		errorStatus: null
	});
};
export const redirectToApiServerSuccess = (r) => {
	return ({
		type: REDIRECT_TO_API_SERVER_SUCCESS,
		data: {status: r.status },
		errorMessage: null,
		errorStatus: null,
		errorSource: null
	});
};