import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import { Link } from 'react-router-dom';
import { PickList } from 'primereact/picklist';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { rolesValidator, ROLE, READ, UPDATE } from '../../../utils/RolesValidator';
import msg from '../../../texts/msg';
import MessageDialog from '../../common/MessageDialog';
import './role.css';
import '../../common/css/common.css';

const CARTADIS_TOTAL_API = "CARTADIS-TOTAL-API"
export class UpdateRole extends Component {

    constructor(props) {
        super(props);
        this.state = {
            checked: false,
            closable: false,
            isname: false,
            isCompanyName: false,
            role: {
                id: null,
                name: '',
                description: '',
                technical: false,
                authorizations: [],
                companyName: {},
            },
            source: [],
        }
        this.doOnSave = this.doOnSave.bind(this);
        this.updateRole = this.updateRole.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onAuthPermChange = this.onAuthPermChange.bind(this);
        this.targetAuthTemplate = this.targetAuthTemplate.bind(this);
    }

    componentDidMount() {
        this.props.loadRole();
        this.props.authorisations();
    }

    componentDidUpdate(prevProps) {
        let prevError = prevProps.error && prevProps.error.timestamp;
        let lastError = this.props.error && this.props.error.timestamp;
        let error = (lastError && (!prevError || prevError < lastError));
        if (error) {
            let isError = this.props.error.message && this.props.error.message.startsWith('error.users.') ? true : false;
            this.setState({ isApiError: isError, message: this.props.error.message });
        }
        if (!(prevProps.role && prevProps.role.id) && (this.props.role && this.props.role.id)) {
            let company = this.props.role.companyName && this.props.role.companyName.key ? 
                {key: this.props.role.companyName.key, value: this.props.role.companyName.value, label: msg('group.companyName.' + this.props.role.companyName.key)} : {};
            let authorizations = this.props.role.authorizations ? this.props.role.authorizations : [];
            authorizations.forEach(a => {
                a.create = a.permission && (a.permission.includes('c') ? true : false);
                a.update = a.permission && (a.permission.includes('u') ? true : false);
                a.read = a.permission && (a.permission.includes('r') ? true : false);
                a.delete = a.permission && (a.permission.includes('d') ? true : false);
            });
            let role = {
                id: this.props.role.id,
                name: this.props.role.name,
                description: this.props.role.description,
                technical: this.props.role.technical,
                authorizations: authorizations,
                companyName: company
            }
            this.setState({ role: role });
        }
        let prevCreate = prevProps.roleUpdate && prevProps.roleUpdate.lastChange;
        let lastCreate = this.props.roleUpdate && this.props.roleUpdate.lastChange;
        let created = (lastCreate && (!prevCreate || prevCreate < lastCreate))
        if (created) {
            this.props.history.push('/roles');
        }
    }

    updateRole() {
        this.props.updateRole(this.state.role.id, this.state.role);
    }

    onChange(event) {
        let authorizations = event.source;
        authorizations.forEach(a => a.source = true);
        this.setState({ source: authorizations });
        authorizations = event.target;
        authorizations.forEach(a => a.source = false);
        this.setState({ role: Object.assign({}, this.state.role, { authorizations: authorizations }) });
    }

    validateFields() {
        return (!this.state.role.name || !this.state.role.name.trim());
    }

    fieldsControl() {
        if (!this.state.role.name || !this.state.role.name.trim()) {
            this.setState({ isname: true });
        } else {
            this.setState({ isname: false });
        }

        if (!this.state.role.companyName || !this.state.role.companyName.value) {
            this.setState({ isCompanyName: true });

        } else {
            this.setState({ isCompanyName: false });
        }
    }

    doOnSave() {
        if (this.validateFields()) {
            this.fieldsControl();
        } else {
            this.updateRole();
        }
    }

    onAuthPermChange(e, id, c) {
        let authorizations = this.state.role && this.state.role.authorizations ? this.state.role.authorizations : [];
        let auths = authorizations.filter( a => a.id === id);
        if(auths.length > 0) {
            let a = auths[0];
            switch(c) {
                case 'c':
                    a.create = e.checked;
                    a.read = e.checked === true ? true : a.read;
                    a.delete = e.checked === false ? false : a.delete;
                    break;
                case 'r':
                    a.read = e.checked === false && (a.create === true || a.update === true || a.delete === true) ? true : e.checked;
                    break;
                case 'u':
                    a.update = e.checked;
                    a.read = e.checked === true ? true : a.read;
                    a.delete = e.checked === false ? false : a.delete;
                    break;
                case 'd':
                    a.delete = e.checked;
                    a.read = e.checked === true ? true : a.read;
                    a.create = e.checked === true ? true : a.create;
                    a.update = e.checked === true ? true : a.update;
                    break;
                default:
                    break;
            }
            a.permission = (a.create === true ? 'c' : '')  + (a.read === true ? 'r' : '') + (a.update === true ? 'u' : '') + (a.delete === true ? 'd' : '');
            this.setState({ role: Object.assign({}, this.state.role, { authorizations: authorizations })});
        }
    }

    targetAuthTemplate(a) {
        let ta = '';
        if(a) {
            ta = a.source || a.featureOnly ? msg('role.authorisation.name.' + a.name) :
            (<div className="p-col-12 p-grid perm-target">
                <div className="p-col-12 p-md-4">
                    <span>{msg('role.authorisation.name.' + a.name)}</span>
                </div>
                <div className="p-col-12 p-md-8 p-grid">
                    <div className="p-col-12 p-md-3 perm-checkbox">
                        <Checkbox inputId="cCreate" value="Création" checked={a.create === true ? true : false} 
                            onChange={e => this.onAuthPermChange(e, a.id, 'c')} key={"cCreate_" + a.id}/>
                        <label htmlFor="cCreate" className="p-checkbox-label">Création</label>
                    </div>
                    <div className="p-col-12 p-md-3 perm-checkbox">
                        <Checkbox inputId="cRead" value="Consultation" checked={a.read === true ? true : false}
                            onChange={e => this.onAuthPermChange(e, a.id, 'r')} key={"cRead_" + a.id} />
                        <label htmlFor="cRead" className="p-checkbox-label">Consultation</label>
                    </div>
                    <div className="p-col-12 p-md-3 perm-checkbox">
                        <Checkbox inputId="cUpdate" value="Modification" checked={a.update === true ? true : false}
                            onChange={e => this.onAuthPermChange(e, a.id, 'u')} key={"cUpdate_" + a.id}/>
                        <label htmlFor="cUpdate" className="p-checkbox-label">Modification</label>
                    </div>
                    <div className="p-col-12 p-md-3 perm-checkbox">
                        <Checkbox inputId="cDelete" value="Suppression" checked={a.delete === true ? true : false}
                            onChange={e => this.onAuthPermChange(e, a.id, 'd')} key={"cDelete_" + a.id}/>
                        <label htmlFor="cDelete" className="p-checkbox-label">Suppression</label>
                    </div>
                </div>
            </div>);
        }
        return ta;
    }

    authHeader() {
        return (<div className="p-grid p-col-12 perm-header">
            <div className="p-md-4 perm-source-header">{msg('role.auhto.available')}</div>
            <div className="p-md-2 perm-buttons-header"></div>
            <div className="p-md-6 p-grid perm-target-header">
                <div className="p-col-4">
                    {msg('role.auhto.selected')}
                </div>
                <div className="p-col-8 p-grid perm-head-checkbox">
                    <div className="p-col-3">
                        <label className="p-checkbox-label">Création</label>
                    </div>
                    <div className="p-col-3">
                        <label className="p-checkbox-label">Consultation</label>
                    </div>
                    <div className="p-col-3">
                        <label className="p-checkbox-label">Modification</label>
                    </div>
                    <div className="p-col-3">
                        <label className="p-checkbox-label">Suppression</label>
                    </div>
                </div>
            </div>
        </div>);
    }

    updateRoleauthorizations(a) {
       let arr = a.filter(e => (e && e.name !== "pos"))
        return arr
    }

    sort(a, b) {
        let aName = a ? msg('role.authorisation.name.' + a.name) : '';
        let bName = b ? msg('role.authorisation.name.' + b.name) : '';
        return aName.localeCompare(bName);
    }

    render() {
        let authorized = rolesValidator.validate([ROLE]);
        let rAuthorized = authorized.toString().includes(READ);
        let uAuthorized = authorized.toString().includes(UPDATE);
        let cartadisUser = this.props.roleData ? this.props.roleData.userCartadis : null;
        let authorizations = this.props.authorisationsData ? this.props.authorisationsData : null;
        let source = this.state.source;
        if (authorizations && this.state.source.length === 0) {
            authorizations.forEach(item => {
                item.source = true;
                source.push(item);
            });
        }        
        for (let i = source.length - 1; i >= 0; i--) {
            this.state.role.authorizations.forEach(function (item) {
                if (source[i] && (source[i].id === item.id)) {
                    source.splice(i, 1);
                }
            });
        }
        source.sort(this.sort);
        this.state.role.authorizations.forEach(item => item.source = false);
        this.state.role.authorizations.sort(this.sort);

        const companyNames = [
            { key: 'cartadis' , value: 'cartadis', label: msg('group.companyName.cartadis') },
            { key: 'total' , value: 'total', label: msg('group.companyName.total') },
            { key: 'other' , value: 'other', label: msg('group.companyName.other')}
        ];

        return rAuthorized && (
            <div className="card commonClass roleClass">
                <br />
                <div className="test p-col-12">
                    <h3 style={{ marginTop: '.5em' }}>{msg('role.update.role')}</h3>
                </div>
                <div className="card card-w-title p-col-12">
                    <div className="p-grid form-role p-col-12">
                        {<div className="p-grid p-col-6">
                            <div className="p-grid p-col-3">
                                <div className="p-col-6"> <label>{msg('role.fields.name')}</label></div>
                                <div className=""><span className="obligatoire">*</span></div>
                            </div>
                            <div className="p-col-9">
                                <span className="md-inputfield">
                                    <InputText value={this.state.role.name} onChange={e => this.setState({ role: Object.assign({}, this.state.role, { name: e.target.value }) })}
                                        readOnly={this.state.role.technical} disabled={this.state.role.technical ? "disabled" : ""} />
                                </span>
                                {this.state.isname && <Message severity="error" text={msg('role.input.name.required')} key="name" />}
                            </div>
                        </div>}
                        {cartadisUser && <div className="p-grid p-col-6">
                            <div className="p-grid p-col-6">
                                <div className="p-col-6"> <label>{msg('group.fields.companyName')}</label></div>
                                <div className=""><span className="obligatoire">*</span></div>
                            </div>
                            <div className="p-col-6">
                                <span className="md-inputfield">
                                    <Dropdown options={companyNames} optionLabel="label" value={this.state.role.companyName}
                                        onChange={e => this.setState({role: Object.assign({}, this.state.role, { companyName: e.target.value })})}
                                        placeholder="Selectionnez" style={{ minWidth: '60%' }}
                                        disabled={(CARTADIS_TOTAL_API === this.state.role.name)} 
                                    />
                                </span>
                                {this.state.isCompanyName && <Message severity="error" text={msg('group.input.companyNames.required')} key="companyNames" />}
                            </div>
                        </div>}
                        {<div className="p-grid p-col-6">
                            <div className="p-grid p-col-3">
                                <div className="p-col-8"> <label>{msg('group.fields.description')}</label></div>
                            </div>
                            <div className="p-col-9">
                                <span className="md-inputfield md-inputfield-fill">
                                    <InputText className="inputClass" value={this.state.role.description}
                                        onChange={e => this.setState({ role: Object.assign({}, this.state.role, { description: e.target.value }) })}
                                        placeholder="Description" type="text" size="60"
                                        readOnly={this.state.role.technical} disabled={this.state.role.technical ? "disabled" : ""} />
                                </span>
                            </div>
                        </div>}
                    </div>
                </div>
                <br />
                <div className="test p-col-12">
                    <h3 style={{ marginTop: '.5em' }}>{this.state.role.technical ? msg('role.fields.list.authorisation') : msg('role.fields.authorisation')}</h3>
                </div>
                <div className="card card-w-title p-col-12">
                    <div className="form-group p-grid p-col-12">
                        {authorizations && !this.state.role.technical &&
                        <div className="p-grid p-col-12">
                            {this.authHeader()}                         
                            <PickList source={source} target={this.state.role.authorizations} itemTemplate={this.targetAuthTemplate} className="p-col-12"
                                sourceHeader={""} targetHeader={""} responsive={true}
                                sourceStyle={{ height: '300px' }} targetStyle={{ height: '300px' }} showTargetControls={false} showSourceControls={false}
                                onChange={this.onChange}
                                readOnly={this.state.role.technical} disabled={this.state.role.technical ? "disabled" : ""} />
                        </div>}
                        {this.state.isAuthorise && <Message severity="error" text={msg('role.input.authorization.required')} key="authorization" />}
                    </div>                    
                    {this.state.role.authorizations && this.state.role.technical &&
                    <div className="p-grid form-group p-col-12">
                        {this.updateRoleauthorizations(this.state.role.authorizations).map((e,i) => {
                            return <span key={i} className="p-col-12">{msg('role.authorisation.name.' + e.name)}</span>
                        })}
                    </div>}
                </div>
                <div className="p-grid p-col-12">
                    <div className="p-col-3 p-md-9"/>
                    <div className="p-grid p-col-9 p-md-3">
                        <Button label={(msg('user.validate'))} title={(msg('user.validate'))} onClick={this.doOnSave} className="button"
                            disabled={this.state.role.technical || !uAuthorized} />
                        <Link to="/roles">
                            <Button label={(msg('user.reset'))} className="button" />
                        </Link>
                    </div>
                </div>
                <MessageDialog className={'message-dialog warn-message'} visible={this.state.isApiError} title={msg('message.dialog.error.title')}
                    message={msg(this.state.message, { roleName: this.state.role.name })}
                    onHide={() => this.setState({ isApiError: false, message: '' })} />
            </div>);
    }
}
export default UpdateRole;