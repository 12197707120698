
export const EQUIPMENTS_TABLE_ID = "EQUIPMENTS";
export const ADMIN_EQUIPMENTS_TABLE_ID = "ADMIN_EQUIPMENTS";
export const BORNES_TABLE_ID = "BORNES";
export const ALARMS_DIALOG_TABLE_ID = "ALARMS_DIALOG";
export const STATIONS_EQUIPMENT_TABLE_ID = "STATIONS_EQUIPMENT_TABLE_ID";
export const TABLE_IDs = [EQUIPMENTS_TABLE_ID, ADMIN_EQUIPMENTS_TABLE_ID, BORNES_TABLE_ID, ALARMS_DIALOG_TABLE_ID, STATIONS_EQUIPMENT_TABLE_ID];

class UserSessionParamsHandler {
    onPageSizeChange;
    onFilterChange;
    userSessionParams;

    init() {
        this.userSessionParams = {};
        this.initPageSize(50);
        this.setRowsPerPageOptions([25, 50, 100]);
        let i = 0;
        for(i = 0; i < TABLE_IDs.length; i++){
            let id = TABLE_IDs[i];
            if(id === ALARMS_DIALOG_TABLE_ID){
                this.initPageSize(5, ALARMS_DIALOG_TABLE_ID);        
                this.setRowsPerPageOptions([5, 10, 15], ALARMS_DIALOG_TABLE_ID);
            } else {
                this.initPageSize(50, id);        
                this.setRowsPerPageOptions([25, 50, 100], id);
            }           
        }
    }

    setOnPageSizeChange(h) {
        this.onPageSizeChange = h;
    }

    setOnFilterChange(f) {
        this.onFilterChange = f;
    }

    initPageSize(pageSize, id) {
        this.initParam("pageSize", pageSize, id)
    }

    initFilter(filter, id) {
        this.initParam("filter", filter, id)
    }

    getPageSize(id) {
        return this.getParam("pageSize", id);
    }

    getFilter(id) {
        return this.getParam("filter", id);
    }

    initParam(paramKey, paramValue, paramClientId) {
        if(!paramClientId){
            this.userSessionParams[paramKey] = paramValue;
        } else {
           let idParams = this.userSessionParams[paramClientId];
           if(!idParams){
                idParams = {};
           }
           idParams[paramKey] = paramValue;
           this.userSessionParams[paramClientId] = idParams;
        }
    }

    getParam(paramKey, paramClientId) {
        if(!paramClientId){
            return this.userSessionParams[paramKey];
        } else {
            let idParams = this.userSessionParams[paramClientId];
            if(!idParams){
                return this.userSessionParams[paramKey];
            }
            return idParams[paramKey];
        }
    }

    setRowsPerPageOptions(options, id) {
        if(!id){
            this.userSessionParams["rowsPerPageOptions"] = options;
        } else {
           let idParams = this.userSessionParams[id];
           if(!idParams){
                idParams = {};
           }
           idParams["rowsPerPageOptions"] = options;
           this.userSessionParams[id] = idParams;
        }
    }

    getRowsPerPageOptions(id) {
        if(!id || !this.userSessionParams[id]){
            return this.userSessionParams["rowsPerPageOptions"];
        } else {
            let idParams = this.userSessionParams[id];
            return idParams["rowsPerPageOptions"];
        }
    }

    updateParams(props, prevProps) {
        if(props.pageSize && props.pageSize !== prevProps.pageSize){
            this.initPageSize(props.pageSize ? props.pageSize : 50);
        }
        if(props.pageSizes && props.pageSizes !== prevProps.pageSizes){
            let i = 0;
            for(i=0; i < TABLE_IDs.length; i++){
                let id = TABLE_IDs[i];
                let pageSize = this.getPageSize(id);
                if(props.pageSizes[id] && props.pageSizes[id].pageSize !== pageSize){
                    let options = this.getRowsPerPageOptions(id);
                    this.initPageSize(props.pageSizes[id].pageSize ? props.pageSizes[id].pageSize : options[0], id);
                }
            }
        }
        if(props.filter && props.filter !== prevProps.filter){           
            this.initFilter(props.filter);
        }
        if(props.filters && props.filters !== prevProps.filters){
            let i = 0;
            for(i=0; i < TABLE_IDs.length; i++){
                let id = TABLE_IDs[i];
                let filter = this.getFilter(id);
                if(props.filters[id] && props.filters[id].filter !== filter){
                    this.initFilter(props.filters[id].filter, id);
                }
            }
        }
    }

    firePageSizeChange(pageSize, id) {
        if(this.onPageSizeChange){
            this.onPageSizeChange(pageSize, id);
            this.initPageSize(pageSize, id);
        }
    }

    fireFilterChange(filter, id) {
        if(this.onFilterChange){
            this.onFilterChange(filter, id);
            this.initFilter(filter, id);
        }
    }

}

export const userSessionParamsHandler = new UserSessionParamsHandler();
userSessionParamsHandler.init();