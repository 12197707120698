import React, { Component } from 'react';
import { Button } from 'primereact/button';
import msg from '../../texts/msg';
import { toLocaleDateTimeFormat } from '../../utils/dateTimeUtils';
import { userSessionParamsHandler, BORNES_TABLE_ID } from '../../utils/UserSessionParamsHandler';
import { EQUIPMENTS, ADMINEQUIPMENTS, rolesValidator } from '../../utils/RolesValidator';
import TableData from '../../utils/TableData';
import MessageDialog from '../common/MessageDialog';
import { progressSpinnerHandler } from '../../utils/ProgressSpinnerHandler'

const EMPTYCHAR = "";
class Bornes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isBtnAuthorized: false,
            isDisassociate:false,
            isApiError : false,
            message : EMPTYCHAR,
            equipmentId: null,
            borneId: null,
            filter : {
            },
            sortDirection: "ASC"
        }
        this.load = this.load.bind(this);
        this.loadByLabel = this.loadByLabel.bind(this);        
        this.onSort = this.onSort.bind(this);
        this.disassociateBorne = this.disassociateBorne.bind(this);
        this.onSelection = this.onSelection.bind(this);
        this.reinitParameters = this.reinitParameters.bind(this);
    }

    componentDidMount() {
        this.load(0, userSessionParamsHandler.getPageSize(BORNES_TABLE_ID), this.state.filter);
        this.props.setDataInfoBar(null);
    }

    componentDidUpdate(prevProps) {
        let prevError = prevProps.error && prevProps.error.timestamp;
        let lastError = this.props.error && this.props.error.timestamp;
        let error = (lastError && (!prevError || prevError < lastError));
        if (error) {
            progressSpinnerHandler.show(false);
            let isError = this.props.error.message && this.props.error.message.startsWith('error.borne.') ? true : false;
            this.setState({ isApiError: isError, message : this.props.error.message });      
        } else {
            if(this.props.bornesData && this.props.bornesData !== prevProps.bornesData){
                progressSpinnerHandler.show(false);
            }
            let prevDisassociate = prevProps.disassociateBorneData && prevProps.disassociateBorneData.lastChange;
            let lastDisassociate = this.props.disassociateBorneData && this.props.disassociateBorneData.lastChange;
            let disassociate = (lastDisassociate && (!prevDisassociate || prevDisassociate < lastDisassociate))
            if (disassociate) {
                this.setState({ isDisassociate: true, message : msg("support.borne.disassociation.done") });
            }
        }
    }
    reinitParameters(){
        this.setState({ isDisassociate: false, message: EMPTYCHAR, isApiError: false})
    }

    descriptionBody(rowData) {
        return (rowData.description && rowData.description.length > 80) ?
            <div className="hasTooltip p-ellipsis"> {rowData.description} <span> {rowData.description}  </span></div>:
            <div> {rowData.description} </div>
    }

    equipmentBody(rowData) {
        let  equipment = rowData.equipmentName ? rowData.equipmentName : "";
        equipment += rowData.equipmentId ? " (" + rowData.equipmentId + ")" : "";
        return (equipment && equipment.length > 80) ?
            <div className="hasTooltip p-ellipsis"> {equipment} <span> {equipment}  </span></div>:
            <div> {equipment} </div>
    }

    stationBody(rowData) {
        let  station = rowData.stationImplant? rowData.stationImplant : "";
        station += rowData.stationName ? " (" + rowData.stationName + ")" : "";
        return (station && station.length > 80) ?
            <div className="hasTooltip p-ellipsis"> {station} <span> {station}  </span></div>:
            <div> {station} </div>
    }

    productionDateBody(row) {
        return <div> {row.productionDate ? toLocaleDateTimeFormat(row.productionDate) : ""} </div>
    }

    installationDateBody(row) {
        return <div> {row.installationDate ? toLocaleDateTimeFormat(row.installationDate) : ""} </div>
    }

    associationDateBody(row) {
        return <div> {row.associationDate ? toLocaleDateTimeFormat(row.associationDate) : ""} </div>
    }

    disassociateBorne(){
        if(this.state.equipmentId && this.state.borneId){
           this.props.disassociateBorne(this.state.equipmentId,this.state.borneId)
        }
    }

    onSelection(event) {
        if (event.value) {
            this.setState({ equipmentId: event.value.equipmentId, borneId : event.value.id, 
                isBtnAuthorized : ((event.value.equipmentId && event.value.id) ? true : false) });
        }
    }

    load(page, pageSize, filter) {
        progressSpinnerHandler.show(true);
        filter.page = page;
        filter.pageSize = pageSize;
        this.props.loadBornes(filter);
        this.setState({filter: filter});
    }

    loadByLabel(quickSearch) {
        progressSpinnerHandler.show(true);
        let page = 0;
        let pageSize = userSessionParamsHandler.getPageSize(BORNES_TABLE_ID);
        let filter = Object.assign({}, this.state.filter);
        this.setState({ quickSearch: quickSearch })
        filter.label = quickSearch.idSearch ? quickSearch.idSearch : '';
        this.load(page, pageSize, filter);
    }

    onSort(e, page) {
        progressSpinnerHandler.show(true);
        let filter = Object.assign({}, this.state.filter);
        let sortDirection = this.state.sortDirection === "ASC" ? "DESC" : "ASC";
        filter.sortProperty = {key: e.sortField, value: sortDirection};
        this.load(page, userSessionParamsHandler.getPageSize(BORNES_TABLE_ID), filter);
        this.setState({sortDirection: sortDirection });
    }
    buildErrDialogParam(){
        let parameters = {
            visibility: false,
            title: EMPTYCHAR,
            message: EMPTYCHAR,
            className:EMPTYCHAR
        }
       if(this.state.isApiError){
            parameters.className='message-dialog error-message';
            parameters.visibility = this.state.isApiError;
            parameters.title = msg('error.borne.disassociation.title')
            parameters.message = this.state.message;
        } else if(this.state.isDisassociate) {
            parameters.className='message-dialog info-message';
            parameters.visibility = this.state.isDisassociate;
            parameters.title = msg('info.borne.disassociation.title')
            parameters.message = this.state.message;
        }
        return parameters
    }

    render() {
        let parameters = this.buildErrDialogParam();
        let authorized = rolesValidator.validate([EQUIPMENTS, ADMINEQUIPMENTS]) && rolesValidator.isCartadisOnly();
        let data = this.props.bornesData;
        let bornes = data && data.bornes ? data.bornes : [];
        let totalPages = data && data.totalPages ? data.totalPages : 0;
        let first = data && data.first ? data.first : 0;
        let bornesToolBar = [
            { 
            filename: "bornes-list.xlsx", 
            iconSrc: "/assets/ultima/layout/images/cartadis/excel.svg", 
            titleIcone: "Bornes", 
            tooltipTextIcone: msg('support.bornes.tooltip.export'), 
            urlExport: "equipments/bornes/bornes-excel" },
        ]

        let bornesColumns = [
            { isColWithBody: false, field: "id", header: "id", sortable: false, className: "visibilityHidden" },            
            { isColWithBody: false, field: "serialNumber", header: msg('support.borne.list.serialnumber'), sortable: true, className: "p-col-2" },
            { isColWithBody: true, field: "description", header: msg('support.borne.list.description'), body: this.descriptionBody, sortable: true, className: "p-col-2" },
            { isColWithBody: true, field: "equipmentName", header: msg('support.borne.list.equipment'), body: this.equipmentBody, sortable: true, className: "p-col-2" },
            { isColWithBody: true, field: "stationImplant", header: msg('support.borne.list.station'), body: this.stationBody, sortable: true, className: "p-col-2" },
            { isColWithBody: true, field: "productionDate", header: msg('support.borne.list.productiondate'), body: this.productionDateBody, sortable: true, className: "p-col-2" },            
            { isColWithBody: true, field: "installationDate", header: msg('support.borne.list.installationdate'), body: this.installationDateBody, sortable: true, className: "p-col-2" },
            { isColWithBody: true, field: "associationDate", header: msg('support.borne.list.associationdate'), body: this.associationDateBody, sortable: true, className: "p-col-2" }        
        ]
        let ContextMenuContent = this.state.equipmentId && this.state.borneId && [
            {
              label: msg('support.borne.disassociation'),
              command: this.disassociateBorne
            }];
        let bornesTable = <TableData data={bornes} totalPages={totalPages} first={first} ContextMenuContent={ContextMenuContent} 
            selectionMode={"single"} onSelection = {this.onSelection}
            getDataFunction={this.load} apply={this.loadByLabel}  paramnFunctionData={null} onSort={this.onSort}
            filter={this.state.filter} columns={bornesColumns} titleTable={ msg('support.borne.list.title')} 
            searchByName={true} nameTxtPlaceholder={msg('support.borne.list.quick.search.placeholder')}
            outilsBar={bornesToolBar} dataKey="id" tableId={BORNES_TABLE_ID}
        />;
        return authorized && (
        <div className="p-grid commonClass card card-w-title">
            <div className="p-col-12" >
                <div className="p-col-4  item">
                    <h2>{msg('support.borne.list')}</h2>
                </div>
                <div className="p-col-8 item">
                        <div className={this.state.isBtnAuthorized ? "btnSelected item" : "btnNotSelected item "}  >
                            <Button label={(msg('support.borne.disassociation'))} onClick={this.disassociateBorne} disabled = {!this.state.isBtnAuthorized} key="disassociation"/>
                        </div> 
                    </div>
            </div>
             <div className="p-col-12">
                {bornesTable}
                <MessageDialog className={parameters.className} visible={parameters.visibility} title={parameters.title}
                        message={parameters.message}
                        onHide={this.reinitParameters}  onCancel={this.reinitParameters}/>
            </div>
        </div>);
    }
}

export default Bornes;