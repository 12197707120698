import React, { Component } from 'react'
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import msg from '../../../texts/msg';
import MessageDialog from '../../common/MessageDialog';
import { toLocaleDateTimeFormat } from '../../../utils/dateTimeUtils';
import UploadService from "../../../middleware/download-upload-files.service";
import TableData from '../../../utils/TableData';
import { rolesValidator, HARDWARE, CREATE, READ, UPDATE, DELETE } from '../../../utils/RolesValidator';
import { progressSpinnerHandler } from '../../../utils/ProgressSpinnerHandler';
import { userSessionParamsHandler } from '../../../utils/UserSessionParamsHandler';
import '../../common/css/common.css';

export class Hardware extends Component {
    constructor(props) {
        super(props);
        this.state = {
            first: 0,
            buttonSelectd: false,
            display: false,
            closable: false,
            isLignSelected: false,
            isApiError: false,
            filter: { sortProperty: { key: 'uploadDate', value: "DESC" } },
            sortDirection: "DESC",
            searchObject: { idSearch: null, idValid: false },
            keyword: "",
        }
        this.onSelection = this.onSelection.bind(this);
        this.onSort = this.onSort.bind(this);
        this.loadConfigMateriels = this.loadConfigMateriels.bind(this);
        this.update = this.update.bind(this);
        this.deleteHardware = this.deleteHardware.bind(this);
        this.downloadTargetConfiguration = this.downloadTargetConfiguration.bind(this);
        this.searchByKeyword = this.searchByKeyword.bind(this);
    }

    componentDidMount() {
        progressSpinnerHandler.show(true);
        const page = 0;
        const pageSize = userSessionParamsHandler.getPageSize();
        this.loadConfigMateriels(page, pageSize);
        this.props.setDataInfoBar(null);
    }

    componentDidUpdate(prevProps) {
        let prevError = prevProps.error && prevProps.error.timestamp;
        let lastError = this.props.error && this.props.error.timestamp;
        let error = (lastError && (!prevError || prevError < lastError));
        if (error) {
            progressSpinnerHandler.show(false);
            let isError = this.props.error.message && this.props.error.message.startsWith('error.config.borne.') ? true : false;
            this.setState({ isApiError: isError });
        }
        if (this.props.configMateriels && this.props.configMateriels !== prevProps.configMateriels) {
            progressSpinnerHandler.show(false);
        }
        let prevDelete = prevProps.configMaterielDeleteById && prevProps.configMaterielDeleteById.lastChange;
        let lastDelete = this.props.configMaterielDeleteById && this.props.configMaterielDeleteById.lastChange;
        let reload = (lastDelete && (!prevDelete || prevDelete < lastDelete));
        if (reload) {
            let page = 0;
            let pageSize = userSessionParamsHandler.getPageSize();
            this.loadConfigMateriels(page, pageSize);
        }
    }



    onSelection(event) {
        if (event.value) {
            this.setState({ hardwareId: event.value.id });
            this.setState({ hardwareName: event.value.name });
            this.setState({ borneType: event.value.borne });
            this.setState({ config: event.value.config });
            this.setState({ buttonSelectd: true });
        }
    }

    loadConfigMateriels(page, pageSize) {
        progressSpinnerHandler.show(true);
        this.props.loadConfigMateriels(page, pageSize, this.state.filter);
    }

    name(row, column) {
        return row.name;
    }

    borneTypeBody(row, column) {
        return row.name ? row.name.split('_')[0] : "";
    }

    uploadDateBody(row, column) {
        return (<span>{toLocaleDateTimeFormat(row.uploadDate)}</span>);
    }

    deleteHardware() {
        if (this.state.hardwareId) {
            if (this.state.config) {
                this.setState({ isConfig: true });
            } else {
                this.setState({ showConfirm: true });
            }
        } else {
            this.setState({ isLignSelected: true });
        }
    }

    confirmDeleteHardware() {
        if (this.state.hardwareId) {
            this.props.deleteConfigMateriel(this.state.hardwareId);
            this.setState({ buttonSelectd: false });
        } else {
            this.setState({ isLignSelected: true });
        }
        this.setState({ buttonSelectd: false });
    }

    update() {
        if (this.state.hardwareId && this.state.buttonSelectd) {
            this.props.loadConfigMateriel(this.state.hardwareId);
            this.setState({ buttonSelectd: false });
            this.props.history.push('/hardware/update');
        } else {
            this.setState({ isLignSelected: true });
        }
    }

    downloadTargetConfiguration() {
        let configFileName = "hardware";
        if (this.state.hardwareId) {
            configFileName = this.state.hardwareName + "_" + this.state.hardwareId + ".json";
        }
        UploadService.getFileData("hardware/" + this.state.hardwareId + "/configuration", configFileName, null)
            .then((response) => {
                const fname = configFileName;
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    if (response.status === "401" || response.status === 401) {
                        this.setState({ buttonSelectd: false, sessionExpired: true });
                    } else if (response.status === 400 || response.status === "400") {
                        this.setState({ isNotDownload: true, buttonSelectd: false });
                    }
                    else {
                        this.setState({ buttonSelectd: false });
                        a.href = url;
                        a.download = fname;
                        a.click();
                    }
                    progressSpinnerHandler.show(false);
                });
            });
    }

    handleErrorChange(e) {
        this.setState({ isDone: false, isError: false, selectedFile: null, message: null, isApiError: false, isConfig: false })
        this.setState({ currentFile: undefined });
        if (this.state.sessionExpired) {
            this.setState({ sessionExpired: false });
            this.props.history.push('/');
            this.props.history.push('/hardwares');
        }
    }

    onSort = (e, page) => {
        progressSpinnerHandler.show(true);
        let pageSize = userSessionParamsHandler.getPageSize()
        let filter = Object.assign({}, this.state.filter);
        let sortDirection = this.state.sortDirection === "ASC" ? "DESC" : "ASC";
        filter.sortProperty = { key: e.sortField, value: sortDirection };
        this.props.loadConfigMateriels(page, pageSize, filter);
        this.setState({ sortDirection: sortDirection });
        this.setState({ filter: filter });
    }

    searchByKeyword(searchObject) {
        progressSpinnerHandler.show(true);
        let lFilter = Object.assign({}, this.state.filter);
        this.setState({ searchObject: searchObject })
        let keyword = searchObject.idSearch ? searchObject.idSearch : this.state.keyword;
        lFilter.label = keyword
        this.props.loadConfigMateriels(0, userSessionParamsHandler.getPageSize(), lFilter)
    }

    render() {

        let authorized = rolesValidator.validate([HARDWARE]);
        let cAuthorized = authorized.toString().includes(CREATE);
        let rAuthorized = authorized.toString().includes(READ);
        let uAuthorized = authorized.toString().includes(UPDATE);
        let dAuthorized = authorized.toString().includes(DELETE);

        let hardwareColumns = [
            { isColWithBody: false, field: "id", header: "id", sortable: false, className: "visibilityHidden" },
            { isColWithBody: true, field: "name", header: msg('configMateriel.name'), body: this.name, sortable: true, className: "p-col-2" },
            { isColWithBody: true, field: "comment", header: msg('configMateriel.description'), body: this.comment, sortable: true, className: "p-col-3" },
            { isColWithBody: true, field: "uploadDate", header: msg('configMateriel.uploadDate'), body: this.uploadDateBody, sortable: true, className: "p-col-3" },


        ];
        let hardwares = this.props.configMateriels && this.props.configMateriels.hardwares ? this.props.configMateriels.hardwares : [];
        let totalPages = this.props.configMateriels && this.props.configMateriels.hardwares ? this.props.configMateriels.totalPages : 0;

        let hardwaresOutilsBar = [
            { filename: "Liste des Config. matérielles.xlsx", iconSrc: "/assets/ultima/layout/images/cartadis/excel.svg", titleIcone: "Config. matérielle", tooltipTextIcone: msg('hardware.tooltip.export'), urlExport: "hardware/hardware-xls" }
        ]
        let ContextMenuContent = [
            {
                label: msg('hardware.update'),
                command: this.update
            },
            {
                label: msg('hardware.delete'),
                command: this.deleteHardware
            },
            {
                label: msg('hardware.button.config.download.tooltip'),
                command: this.downloadTargetConfiguration
            }
        ];
        let hardwaresTable = (
            <TableData data={hardwares} titleTable={msg('hardware.table.title')}
                totalPages={totalPages} ContextMenuContent={ContextMenuContent}
                selectionMode={"single"} selection={this.state.rowSelection}
                onSelection={this.onSelection} onSort={this.onSort}
                outilsBar={hardwaresOutilsBar} filter={this.state.filter}
                getDataFunction={this.loadConfigMateriels} paramnFunctionData={null}
                columns={hardwareColumns} searchInTable={false} searchByName={true}
                apply={this.searchByKeyword}
                nameTxtPlaceholder={msg('hardware.table.search.placeholder')} />);

        return rAuthorized && (
            <div className="card p-grid commonClass">
                <div className="p-col-12">
                    <div className="p-col-4 p-md-4 item">
                        <div className="p-col-12"><h2 className="titleAbonnement">{msg('hardware.title')}</h2></div>
                    </div>
                    <div className="p-col-8 item buttonPosition">
                        <div className={this.state.buttonSelectd ? "btnSelected item" : "btnNotSelected item "} style={{ marginLeft: '-5px' }}>
                            <Button label={(msg('hardware.update'))} title={(msg('hardware.update'))} onClick={this.update} key="update" disabled={!uAuthorized && !rAuthorized} />
                        </div>
                        <div className={this.state.buttonSelectd ? "btnSelected item " : "btnNotSelected item"} >
                            <Button label={(msg('hardware.delete'))} onClick={() => { this.deleteHardware() }} title={(msg('hardware.delete'))} key="delete" disabled={!dAuthorized} />
                        </div>
                        <div className="item btnSelected">
                            <Link to="/hardware/create">
                                <Button label={(msg('hardware.create.hardware'))} title={(msg('hardware.create.hardware'))} className="button" key="create" disabled={!cAuthorized} />
                            </Link>
                        </div>
                        <div className={this.state.buttonSelectd ? "btnSelected item " : "btnNotSelected item"} >
                            <Button label={(msg('hardware.button.config.download.tooltip'))} title={(msg('hardware.button.config.download.tooltip'))} onClick={() => this.downloadTargetConfiguration()}
                                key="config_download" disabled={!rAuthorized} />
                        </div>

                    </div>
                    {hardwaresTable}
                </div>

                <MessageDialog className={'message-dialog error-message'} visible={this.state.sessionExpired || this.state.isError || this.state.isApiError} title={msg('firmware.fields.upload.title.error')}
                    message={(this.state.sessionExpired && msg("login.unauthorized.internalauthenticationserviceexception"))
                        || (this.state.isError && this.state.message)
                        || (this.state.isApiError && msg(this.props.error.message))
                    }
                    onHide={e => this.handleErrorChange(e)} onCancel={e => this.handleErrorChange(e)} />

                <MessageDialog className={'message-dialog warn-message'} visible={this.state.isNotDownload || this.state.isConfig} title={msg('message.dialog.warn.title')}
                    message={(this.state.isNotDownload && msg('error.config.borne.target.configuration.cannot.be.build'))
                        || (this.state.isConfig && msg('error.config.borne.hardware.cant.delete'))}
                    onHide={() => this.setState({ isNotDownload: false, isConfig: false })} />

                <MessageDialog confirm={true} className={'message-dialog confirm-message'} visible={this.state.showConfirm && !this.state.isConfig} title={msg('message.dialog.confirm.title')}
                    message={this.state.showConfirm && msg('hardware.delete.confirm.message', { hardwareName: this.state.hardwareName })} key="confirm-delete"
                    onConfirm={() => { this.setState({ showConfirm: false }); this.confirmDeleteHardware() }} onCancel={() => this.setState({ showConfirm: false })} />

            </div >);
    }
}