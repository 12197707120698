import React, { Component } from 'react';
import { Button } from 'primereact/button';
import TableData from '../../../utils/TableData';
import MessageDialog from '../../common/MessageDialog';
import msg from '../../../texts/msg.js';
import { toLocaleDateTimeFormat } from '../../../utils/dateTimeUtils';
import { userSessionParamsHandler } from '../../../utils/UserSessionParamsHandler';
import '../../common/css/common.css';
import { CREATE, READ, UPDATE, DELETE, rolesValidator, CAMPAIGNS, CAMPAIGNFIRMWARE, CAMPAIGNHARDWARE, CAMPAIGNPRICEZONE } from '../../../utils/RolesValidator';

const emtyChar = '';
const MAX_LENGTH = 15

class CampaignList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            buttonSelectd: false,
            campaignId: emtyChar
        }

        this.onSelection = this.onSelection.bind(this);
        this.consult = this.consult.bind(this);
        this.duplicate = this.duplicate.bind(this);
        this.delete = this.delete.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.launchButtonBody = this.launchButtonBody.bind(this);
    }

    componentDidMount() {
        this.props.load(0, userSessionParamsHandler.getPageSize());
    }

    componentDidUpdate(prevProps) {
        let prevError = prevProps.error && prevProps.error.timestamp;
        let lastError = this.props.error && this.props.error.timestamp;
        let error = (lastError && (!prevError || prevError < lastError));
        if (error) {
            let isError = this.props.error.message && this.props.error.message.startsWith('error.campaigns.') ? true : false;
            this.setState({ isApiError: isError });
            return;
        }
        let reload = (this.props.lastChange && (!prevProps.lastChange || prevProps.lastChange < this.props.lastChange));
        if (reload) {
            this.props.load(0, userSessionParamsHandler.getPageSize());
        }
    }

    onSelection(event) {
        if (event.value) {
            this.setState({ 
                campaignId: event.value.id,
                campaignName: event.value.name,
                buttonSelectd: true,
                deleteButtonSelectd: !event.value.readOnly,
                isFirmware : event.value.firmware && event.value.firmware.value,
                isHardware : event.value.hardware && event.value.hardware.value,
                isPriceZone : event.value.priceZone && event.value.priceZone.value,
            });
        } else {
            this.setState({ buttonSelectd: false, deleteButtonSelectd: false });
        }
    }

    consult() {
        this.props.consult(this.state.campaignId);
    }

    duplicate() {
        this.props.duplicate(this.state.campaignId);
    }

    delete() {
        if (this.state.campaignId) {
            this.setState({ showConfirm: true });
        }
    }

    confirmDelete() {
        this.props.delete(this.state.campaignId);
        this.setState({ buttonSelectd: false, deleteButtonSelectd: false });
    }

    launchButtonBody(row) {
        let label = row.readOnly ? (row.restartOnFailed ? msg('campaign.list.button.restart') : '') : msg('campaign.list.button.launch');
        let icon = row.readOnly ? (row.restartOnFailed ? 'pi-md-replay' : '') : 'pi-md-send';        
        return (label ? <Button onClick={() => this.props.launch(row.id)} icon={icon} tooltip={label} key={row.id} /> : '');
    }

    startDateBody(row) {
        if (row.scheduledStartDate) {
            let scheduledStartDate = toLocaleDateTimeFormat(row.scheduledStartDate);
            if (scheduledStartDate.length > MAX_LENGTH)
                return <div className="hasTooltip p-ellipsis"> {scheduledStartDate} <span> {scheduledStartDate}  </span></div>;
            else
                return <div> {scheduledStartDate} </div>
        }
    }

    endDateBody(row) {
        if (row.endDate) {
            let endDate = toLocaleDateTimeFormat(row.endDate);
            if (endDate.length > MAX_LENGTH)
                return <div className="hasTooltip p-ellipsis"> {endDate} <span> {endDate}  </span></div>;
            else
                return <div> {endDate} </div>
        }
    }

    statusBody(row) {
        return (<span>{msg('campaign.status.' + row.status)}</span>);
    }

    getConfiguration(row) {
        return <ul>
            { (row.firmware && row.firmware.value && (row.firmware.value.length > MAX_LENGTH)  &&
            <li><div className="hasTooltip p-ellipsis"> {msg('campaign.list.firmware') + ': '
            + row.firmware.value} <span> {msg('campaign.list.firmware') + ': ' + row.firmware.value}</span></div>
            </li>)
            ||
            (row.firmware && row.firmware.value && (row.firmware.value.length <= MAX_LENGTH) &&
            <li> {msg('campaign.list.firmware') + ': ' + row.firmware.value}
            </li>)
            }
            
            { (row.hardware && row.hardware.value && (row.hardware.value.length > MAX_LENGTH)  &&
            <li><div className="hasTooltip p-ellipsis"> {msg('campaign.list.hardware') + ': '
            + row.hardware.value} <span> {msg('campaign.list.hardware') + ': ' + row.hardware.value}</span></div>
            </li>)
            ||
            (row.hardware && row.hardware.value && (row.hardware.value.length <= MAX_LENGTH) &&
            <li> {msg('campaign.list.hardware') + ': ' + row.hardware.value}
            </li>)
            }

            { (row.priceZone && row.priceZone.value && (row.priceZone.value.length > MAX_LENGTH)  &&
            <li><div className="hasTooltip p-ellipsis"> {msg('campaign.list.pricezone') + ': '
            + row.priceZone.value} <span> {msg('campaign.list.pricezone') + ': ' + row.priceZone.value}</span></div>
            </li>)
            ||
            (row.priceZone && row.priceZone.value && (row.priceZone.value.length <= MAX_LENGTH) &&
            <li> {msg('campaign.list.pricezone') + ': ' + row.priceZone.value}
            </li>)
            }
       </ul>
    }

    getName(row){
        let name = row.name
        if(name.length > MAX_LENGTH)
        return <div className="hasTooltip p-ellipsis"> {name} <span> {name}</span></div>;
        else
        return <div>{name} </div>
    }

    getProgression(row) {
        return <ul className={"progress"}>
            {row.progressions.map((e, i) => {
                return (
                    <li key={i} className={e.label.toLowerCase()}>
                        {msg('campaign.report.status.' + e.label.toLowerCase()) + ' (' + e.value.toFixed(2) + '%)'}
                    </li>
                );
            })} 
        </ul>
    }

    render() {
        let authorized = rolesValidator.validate(CAMPAIGNS);
        let rAuthorized = authorized.toString().includes(READ);
        let fwAuthorized = rolesValidator.validate(CAMPAIGNFIRMWARE);
        let hwAuthorized = rolesValidator.validate(CAMPAIGNHARDWARE);
        let pzAuthorized = rolesValidator.validate(CAMPAIGNPRICEZONE);
        let cAuthorized = fwAuthorized.toString().includes(CREATE) || hwAuthorized.toString().includes(CREATE) || pzAuthorized.toString().includes(CREATE);
        let uAuthorized = fwAuthorized.toString().includes(UPDATE) || hwAuthorized.toString().includes(UPDATE) || pzAuthorized.toString().includes(UPDATE);
        let  dAuthorized = false;
        if(this.state.isFirmware || this.state.isHardware){
            let f = true, h = true;
            if(this.state.isFirmware){
                f =  fwAuthorized.toString().includes(DELETE) 
            }
            if(this.state.isHardware){
                h =  hwAuthorized.toString().includes(DELETE)
            }
            dAuthorized = f && h;
        } else if(this.state.isPriceZone){
            dAuthorized = pzAuthorized.toString().includes(DELETE);
        }
        rAuthorized = rAuthorized || cAuthorized || uAuthorized || dAuthorized;
        let campaignsColumns = [
            { isColWithBody: false, field: "id", header: "id", sortable: false, className: "visibilityHidden" },
            { isColWithBody: false, field: "readOnly", sortable: false, className: "visibilityHidden" },
            { isColWithBody: true, field: "name", header: msg('campaign.list.name'), body: this.getName ,sortable: false, className: "p-md-2 p-col-12" },
            { isColWithBody: true, header: msg('campaign.list.configuration'), body: this.getConfiguration, sortable: false, className: "p-md-3 p-col-12" },
            { isColWithBody: true, field: "scheduledStartDate", header: msg('campaign.list.startdate'), body: this.startDateBody, sortable: false, className: "p-md-2 p-col-12" },
            { isColWithBody: true, field: "endDate", header: msg('campaign.list.enddate'), body: this.endDateBody, sortable: false, className: "p-md-2 p-col-12" },
            { isColWithBody: false, field: "equipmentsCount", header: msg('campaign.list.count'), sortable: false, className: "text-align-center p-md-1 p-col-12" },         
            { isColWithBody: true, field: "progressions", header: msg('campaign.list.progress'), body: this.getProgression, sortable: false, className: "p-md-2 p-col-12" },
            { isColWithBody: true, field: "status", header: msg('campaign.list.status'), body: this.statusBody, sortable: false, className: "p-md-1 p-col-12" },
            { isColWithBody: true, field: "launch", header: msg('campaign.list.button.launch'), body: this.launchButtonBody, sortable: false, className: "text-align-center p-md-1 p-col-12" }
        ];
        let toolsBar = [
			{ filename: "campaigns-list.xlsx", iconSrc: "/assets/ultima/layout/images/cartadis/excel.svg", titleIcone: msg('campaign.list.excel.export'), tooltipTextIcone: msg('campaign.list.excel.export'), urlExport: "campaigns/list/list-report" }
		]
        let consultBtnLabel = this.state.deleteButtonSelectd ? msg('campaign.button.update') : msg('campaign.button.consult')
        let campaigns = this.props.data && this.props.data.campaigns ? this.props.data.campaigns : [];
        let totalPages = this.props.data && this.props.data.totalPages ? this.props.data.totalPages : 0;
        let ContextMenuContent = this.state.deleteButtonSelectd? [
            {
              label: consultBtnLabel,
              command: this.consult
            },
            {
              label: msg('campaign.button.duplicate'),
              command: this.duplicate
            },
            {
              label: msg('campaign.button.delete'),
              command: this.delete
            }]
            : [
            {
              label: consultBtnLabel,
                    command: this.consult
            },
            {
              label: msg('campaign.button.duplicate'),
              command: this.duplicate
            }] ;
        let campaignsTable = (
            <TableData data={campaigns} titleTable={msg('campaign.list.title')} 
                totalPages={totalPages} ContextMenuContent={ContextMenuContent}
                getDataFunction={this.props.load} paramnFunctionData={null}
                selectionMode={"single"} onSelection={this.onSelection} columns={campaignsColumns} outilsBar={toolsBar} filter={{}}/>);
        return rAuthorized && (
            <div className="p-grid commonClass card card-w-title">
                <div className="p-col-12 campaign-title">
                    <h2>{msg('campaign.title')}</h2>
                </div>
                <div className="p-col-12">
                    <div className="item p-col-12 p-md-3" />
                    <div className={true ? "btnSelected item" : "btnNotSelected item"}>
                        <Button label={msg('campaign.button.create')} tooltip={msg('campaign.button.create')} key="create" onClick={this.props.create} disabled={!cAuthorized}  />
                    </div>
                    <div className={this.state.buttonSelectd ? "btnSelected item" : "btnNotSelected item"}>
                        <Button label={consultBtnLabel} tooltip={consultBtnLabel}
                            key="consult" onClick={this.consult} disabled={!(this.state.buttonSelectd && (rAuthorized || uAuthorized))} />
                    </div>
                    <div className={this.state.buttonSelectd ? "btnSelected item" : "btnNotSelected item"}>
                        <Button label={msg('campaign.button.duplicate')} tooltip={msg('campaign.button.duplicate')}
                            key="duplicate" onClick={this.duplicate} disabled={!(this.state.buttonSelectd && cAuthorized)} />
                    </div>
                    <div className={this.state.deleteButtonSelectd ? "btnSelected item" : "btnNotSelected item"}>
                        <Button label={msg('campaign.button.delete')} tooltip={msg('campaign.button.delete')}
                            key="delete" onClick={this.delete} disabled={!(this.state.buttonSelectd && dAuthorized)} />
                    </div>
                </div>
                <div className="p-col-12 campaigns-list">
                    {campaignsTable}
                </div>
                <MessageDialog confirm={true} className={'message-dialog confirm-message'} visible={this.state.showConfirm} title={msg('message.dialog.confirm.title')}
                    message={this.state.showConfirm && msg('campaign.delete.confirm.message', { campaignName: this.state.campaignName })} key="confirm-delete"
                    onConfirm={() => { this.setState({ showConfirm: false }); this.confirmDelete() }} onCancel={() => this.setState({ showConfirm: false })} />
                <MessageDialog className={'message-dialog error-message'} visible={this.state.isApiError} title={msg('message.dialog.error.title')}
                    message={this.state.isApiError && msg(this.props.error.message)}
                    onHide={() => this.setState({ isApiError: false })} />
            </div>
        );
    }
}

export default CampaignList;