import { ofType} from 'redux-observable';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { map, mergeMap, catchError} from  'rxjs/operators';
import { loadCommissionsSuccess, loadCommissionSuccess, createCommissionSuccess, 
        updateCommissionSuccess, deleteCommissionSuccess } from '../actions/commission';
import { LOAD_COMMISSIONS,
		CREATE_COMMISSION, UPDATE_COMMISSION, DELETE_COMMISSION, LOAD_COMMISSION } from '../actions/commission';
import { faillure } from '../actions';
import { authProviderType } from '../AuthProviderType';

export const loadCommissionsEpic = (epicAction)  => {
	return  epicAction.pipe(
			ofType(LOAD_COMMISSIONS),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlCommission = apiRootUri + 'commissions';
				const req = ajax({
						url: urlCommission + '?page='+action.data.page +'&pageSize='+action.data.pageSize,
					  	method: 'GET',					  
					  	headers: {
							'Content-Type': 'application/json'
						},
						withCredentials: true
					});
				return req.pipe(
            			map(res => { return loadCommissionsSuccess(res);}),
            			catchError(res => { return of(faillure(res));})
            		)
			}),
	);
};

export const loadCommissionEpic = (epicAction)  => {
	return  epicAction.pipe(
			ofType(LOAD_COMMISSION),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlCommission = apiRootUri + 'commissions';
				const req = ajax({
						url: urlCommission + '/' + action.data.commissionId + '?planning=' + action.data.planning,
					  	method: 'GET',					  
					  	headers: {
							'Content-Type': 'application/json'
						},
						withCredentials: true
					});
				return req.pipe(
            			map(res => { return loadCommissionSuccess(res);}),
            			catchError(res => { return of(faillure(res));})
            		)
			}),
	);
};

export const createCommissionEpic = (epicAction)  => {
	return  epicAction.pipe(
			ofType(CREATE_COMMISSION),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlCommission = apiRootUri + 'commissions';
				const req = ajax({
						url: urlCommission,
					  	method: 'POST',					  
					  	headers: {
							'Content-Type': 'application/json'
						},
						withCredentials: true,
					  	body: action.data.commission
					});
				return req.pipe(
            			map(res => { return createCommissionSuccess(res);}),
            			catchError(res => { return of(faillure(res));})
            		)
			}),
	);
};

export const updateCommissionEpic = (epicAction)  => {
	return  epicAction.pipe(
			ofType(UPDATE_COMMISSION),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlCommission = apiRootUri + 'commissions';
				const req = ajax({
						url: urlCommission + '/' + action.data.commission.id,
					  	method: 'PUT',					  
					  	headers: {
							'Content-Type': 'application/json'
						},
						withCredentials: true,
					  	body: action.data.commission
					});
				return req.pipe(
            			map(res => { return updateCommissionSuccess(res);}),
            			catchError(res => { return of(faillure(res));})
            		)
			}),
	);
};

export const deleteCommissionEpic = (epicAction)  => {
	return  epicAction.pipe(
			ofType(DELETE_COMMISSION),
			mergeMap((action) => {
				let apiRootUri = authProviderType.getApiRootUri();
				let urlCommission = apiRootUri + 'commissions';
				const req = ajax({
						url: urlCommission + '/' + action.data.commissionId,
					  	method: 'DELETE',					  
					  	headers: {
							'Content-Type': 'application/json'
						},
						withCredentials: true
					});
				return req.pipe(
            			map(res => { return deleteCommissionSuccess(res);}),
            			catchError(res => { return of(faillure(res));})
            		)
			}),
	);
};