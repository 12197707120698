export const PRICEZONE = 'PRICEZONE';
export const PRICEZONE_SUCCESS = 'PRICEZONE_SUCCESS';

export const PRICEZONE_BY_ID = 'PRICEZONE_BY_ID';
export const PRICEZONE_BY_ID_SUCCESS = 'PRICEZONE_BY_ID_SUCCESS';


export const pricezones = (page, pageSize) => {
	return ({
		type: PRICEZONE,
		data: { page: page, pageSize: pageSize},
		errorMessage: null,
		errorStatus: null
	});
};
export const pricezonesSuccess = (r) => {
	return ({
		type: PRICEZONE_SUCCESS,
		data: r.response,
		errorMessage: null,
		errorStatus: null
	});
};

export const pricezoneById = (id, page, pageSize, pricezoneId) => { 
	return ({
		type: PRICEZONE_BY_ID,
		data: {id: id, page: page,  pageSize: pageSize, pricezoneId: pricezoneId},
		errorMessage: null,
		errorStatus: null
	});
};

export const pricezoneByIdSuccess = (r) => {
	return ({
		type: PRICEZONE_BY_ID_SUCCESS,
		data: r.response,
		errorMessage: null,
		errorStatus: null
	});
};