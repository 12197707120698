import React, { Component } from 'react';
import msg from '../../../texts/msg';
import BorneFilter from '../../common/BorneFilter';
import { windowUtils } from '../../../ui/WindowUtils';
import ActivityAnalysis from '../../common/ActivityAnalysis';
import { withRouter } from 'react-router-dom';
import { progressSpinnerHandler } from '../../../utils/ProgressSpinnerHandler'

class Analysis extends Component {

    constructor(props) {
		super(props);
		let stationId = this.getRedirectStationId();
		this.state = stationId ? {initFilter: {stations: [{key: stationId, value: ''}]}} : {};
	}

	componentDidMount(){
        progressSpinnerHandler.show(true);
		let stationId = this.getRedirectStationId();
		if(stationId){
			this.setState({initFilter: {stations: [{key: stationId, value: ''}]}});
		}
    }
    componentDidUpdate(prevProps) {
        if(this.props.data && this.props.data !== prevProps.data){
            progressSpinnerHandler.show(false);
        }
    }
    
    getRedirectStationId(){
		let path = "/maintenance/analysis/";
		let uri = this.props.location.pathname;
		return uri.startsWith(path) ? uri.replace(path, "") : null;
	}

    render() {
        let alarms = {errors: this.props.data ? this.props.data.errors : null, warnings: this.props.data ? this.props.data.warnings : null};
        let gantryAlarms = {errors: this.props.data ? this.props.data.gantryErrors : null};
        let filter =   (<div className="p-col-12 p-md-4 activity-analysis" style={{margin: 0}}>
            <BorneFilter  apply={this.props.load} reset={this.props.load}
                loadFilter={(filter) => this.props.loadFilter(filter)} loadFilterStations={this.props.loadFilterStations}
                filterData={this.props.filterData} filterStations={this.props.filterStations}
				showDate={true} showStation={true} showEquipment={true} showPeriodType={false}
                showGroupStation={true} showChannel={true}  showContract={true}
				title={msg('analysis.analysisPeriod')} initFilter={this.state.initFilter}/> 
        </div>);
        return (            
            <div className="card p-grid p-fluid maint-dashboard">
                {!windowUtils.isDesktop() && filter}
                <div className="p-col-12 p-md-4 activity-analysis">
                    <ActivityAnalysis activityClass="borne"  activityIcon="dock" label={msg('maint.borne.analysis.borne.label')} 
						data={ alarms} chartLabels={ [msg('maint.borne.analysis.chart.label.errors'), msg('maint.borne.analysis.chart.label.warnings')] } hValueName={'count'} hUnit={'H.S'}
						itemValueName={'duration'} unit={'%'} rounding={false} firstCharItemsName={'errors'} secondCharItemsName={'warnings'}/>
                </div>
                <div className="p-col-12 p-md-4 activity-analysis">
                    <ActivityAnalysis activityClass="gantry" activityIcon="open-in-browser" label={msg('maint.borne.analysis.gantry.label')} 
						data={ gantryAlarms } chartLabels={ [msg('maint.borne.analysis.chart.label.gantry.errors')]} hValueName={'count'} hUnit={'H.S'}
						itemValueName={'duration'} unit={'%'} rounding={false} firstCharItemsName={'errors'} />
                </div>
                {windowUtils.isDesktop() && filter}
            </div>
        );
    }

}

export default withRouter(Analysis);