import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { Message } from 'primereact/message';
import QRCode from 'qrcode.react';
import MessageDialog from '../../common/MessageDialog';
import { EMAIL_PATTERN } from '../../../utils/RegexPatterns';
import { rolesValidator, USERS, USERS_BORNEACCESS, READ, UPDATE, ADMINISTRATION } from '../../../utils/RolesValidator';
import { toLocaleDateTimeFormat } from '../../../utils/dateTimeUtils';
import msg from '../../../texts/msg';
import '../../common/css/common.css';
import './User.css';
import { validateInputText } from '../../../utils/validateInputData';

const emtyChar = '';
const CARTADIS_COMPANY_TYPE = "cartadis";
const REF_BORNEACCESS_LEVELS = [
    {key: msg("user.borne.access.level.empty"), value: emtyChar},
	{key: msg("user.borne.access.level.admin"), value: 1},
	{key: msg("user.borne.access.level.technic"), value: 2},
	{key: msg("user.borne.access.level.operator"), value: 4}
];
const ADMIN_BORNEACCESS_LEVEL = REF_BORNEACCESS_LEVELS[1];
export class UpdateUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            checked: false,
            isfirstName: false,
            islastName: false,
            isemail: false,
            isidentifiant: false,
            isprofil: false,
            closable: false,
            isAssociated: false,
            addStation: false,
            isBorneAccessLevel:false,
            user: {
                id: null,
                login: emtyChar,
                firstname: emtyChar,
                lastname: emtyChar,
                email: emtyChar,
                comment: emtyChar,
                groups: [],
                enabled: true,
                borneAccess: false,
                borneAccessLevel: REF_BORNEACCESS_LEVELS[0],
                borneAccessCode: emtyChar,
                technical: false,
                borneAccessEmailSend: false,
                authProvider: null
            },
            borneAccessLevels: REF_BORNEACCESS_LEVELS.slice()
        }
        this.doOnSave = this.doOnSave.bind(this);
        this.association = this.association.bind(this);
        this.updateUser = this.updateUser.bind(this);
        this.fieldsControl = this.fieldsControl.bind(this);
        this.addStation = this.addStation.bind(this);
        this.generateAccessCode = this.generateAccessCode.bind(this);
        this.handleBorneAccessChange = this.handleBorneAccessChange.bind(this);
        this.handleBorneAccessEmailSendChange = this.handleBorneAccessEmailSendChange.bind(this);
        this.handleBorneAccessLevelChange = this.handleBorneAccessLevelChange.bind(this);
        this.handleGroupChange = this.handleGroupChange.bind(this);
    }

    componentDidMount() {
        this.props.loadGroups();
        this.props.setDataInfoBar(null);
    }

    componentDidUpdate(prevProps) {
        let prevError = prevProps.error && prevProps.error.timestamp;
        let lastError = this.props.error && this.props.error.timestamp;
        let error = (lastError && (!prevError || prevError < lastError));
        if (error) {
            let isError = this.props.error.message && this.props.error.message.startsWith('error.users.') ? true : false;
            this.setState({ isApiError: isError, message: this.props.error.message});
        }
       if (!(prevProps.user && prevProps.user.id) && (this.props.user && this.props.user.id)) {
           let user = {
               id: this.props.user.id,
               login: this.props.user.login,
               firstname: this.props.user.firstname,
               lastname: this.props.user.lastname,
               email: this.props.user.email ? this.props.user.email : emtyChar,
               comment: this.props.user.comment,
               groups: this.props.user.groups,
               enabled: this.props.user.enabled,
               borneAccessEnabled: this.props.user.borneAccessEnabled,
               borneAccessLevel: this.getBorneAccesLevel(this.props.user.borneAccessLevel),
               borneAccessCode: emtyChar,
               borneAccessDate: this.props.user.borneAccessDate,
               technical: this.props.user.technical,
               lastConnectionDate: this.props.user.lastConnectionDate,
               authProvider: this.props.user.authProvider,
               groupAdmin: this.props.user.groupAdmin
           }    
           this.applyGroupAccessLevel(user, user.borneAccessEnabled, user.borneAccessLevel, user.groups);
       }
       let prevCreate = prevProps.userUpdate && prevProps.userUpdate.lastChange;
       let lastCreate = this.props.userUpdate && this.props.userUpdate.lastChange;
       let created = (lastCreate && (!prevCreate || prevCreate < lastCreate))
       if (created) {
           this.props.history.push('/users');
       }
       let prevGenerate = prevProps.borneAccessCodeData && prevProps.borneAccessCodeData.generationDate;
       let lastGenerate = this.props.borneAccessCodeData && this.props.borneAccessCodeData.generationDate;
       let generate = (lastGenerate && (!prevGenerate || prevGenerate < lastGenerate))
       if (generate) {
           this.setState({ user: Object.assign({}, this.state.user, { borneAccessCode: this.props.borneAccessCodeData.code })});
       }
    }

    applyGroupAccessLevel(lUser,  lBorneAccessEnabled, lBorneAccessLevel, lGroups){
        let limitedLevels =  REF_BORNEACCESS_LEVELS.slice();
        let limitedLevel = lBorneAccessLevel;
        if(lBorneAccessEnabled && lGroups.length > 0){
            let llGroups = lGroups.filter(g => g.companyType !== CARTADIS_COMPANY_TYPE);
            if(llGroups.length > 0){
                limitedLevels =  REF_BORNEACCESS_LEVELS.filter(l => l.value !== ADMIN_BORNEACCESS_LEVEL.value);
                if(limitedLevels.length > 0){
                    if(lBorneAccessLevel.value === ADMIN_BORNEACCESS_LEVEL.value){
                        limitedLevel = limitedLevels[0];
                    }
                }
            }
        }
        this.setState({isModifiable: false, borneAccessLevels: limitedLevels, 
            user: Object.assign({}, lUser, { borneAccessEnabled: lBorneAccessEnabled, borneAccessLevel: limitedLevel, groups: lGroups}) });
    }

    getBorneAccesLevel(value) {
        if(!value){
            return  this.state.borneAccessLevels[0];
        }
        let i = 0;
        for(i = 0; i < this.state.borneAccessLevels.length; i++){
            let v =  this.state.borneAccessLevels[i];
            if(v.value === value){
                return v;
            }
        }
        return  this.state.borneAccessLevels[0];
    }

    updateUser() {
        let user = Object.assign({}, this.state.user);
        user.borneAccessLevel = this.state.user.borneAccessLevel.value;
        if (user.borneAccessEnabled) {
            if(!this.state.borneAccessCodeReset){
                user.borneAccessCode = emtyChar;
            }
        }
        this.props.updateUser(this.state.user.id, user);
    }

    fieldsControl() {
        let user = this.state.user;
        let valid = true;
        if (!user.firstname || !user.firstname.trim()) {
            this.setState({ isfirstName: true });
            valid = false;
        } else {
            this.setState({ isfirstName: false });
        }
        if (!user.lastname || !user.lastname.trim()) {
            this.setState({ islastName: true });
            valid = false;
        } else {
            this.setState({ islastName: false });
        }
        if (!user.email || !user.email.trim()) {
            this.setState({ isemailErr: true });
            valid = false;
        } else if (!EMAIL_PATTERN.test(this.state.user.email)) {
            this.setState({ isemailvalidErr: true });
            valid = false;
        } else {
            this.setState({ isemailvalidErr: false });
            this.setState({ isemailErr: false });
        }
        if (!user.groups || user.groups.length === 0) {
            this.setState({ isprofil: true });
            valid = false;
        } else {
            this.setState({ isprofil: false });
        }
        if(this.state.user.borneAccessEnabled && !validateInputText(this.state.user.borneAccessLevel.value)){
            this.setState({ isBorneAccessLevel: true });
            valid = false;
         } else {
            this.setState({ isBorneAccessLevel: false });
        }
        return valid;
    }

    doOnSave() {
        if (this.fieldsControl()) {
            this.updateUser();
        }
    }

    association() {
        this.setState({ isAssociated: false });
        this.setState({ addStation: false });
        this.props.history.push('/users');
    }

    addStation() {
        this.setState({ addStation: false });
        this.setState({ isAssociated: true });
    }

    generateAccessCode() {
        if(rolesValidator.validate(USERS_BORNEACCESS)){
            this.setState({borneAccessCodeReset: true});
            this.props.generateBorneAccessCode(); 
        }        
    }

    handleBorneAccessChange(e) {
        if(e.checked === true && this.props.user && !this.props.user.borneAccessEnabled && !this.props.user.borneAccessCode) {
            this.generateAccessCode();
        }
        this.applyGroupAccessLevel(this.state.user, e.checked, this.state.user.borneAccessLevel, this.state.user.groups);
    }

    handleBorneAccessEmailSendChange(e) {    
        this.setState({ user: Object.assign({}, this.state.user, { borneAccessEmailSend: e.checked })});
    }

    handleBorneAccessLevelChange(e) {    
        this.applyGroupAccessLevel(this.state.user, this.state.user.borneAccessEnabled, e.target.value, this.state.user.groups);        
    }

    handleGroupChange(e) {        
        this.applyGroupAccessLevel(this.state.user, this.state.user.borneAccessEnabled, this.state.user.borneAccessLevel, [e.target.value]);
    }

    renderFooter() {
        return (
            <div>
                <Button label="Ok" icon="pi pi-check" onClick={this.association} />
            </div>
        );
    }

    stationFooter() {
        return (
            <div>
                <Button label={msg('user.yes')} icon="pi pi-check" onClick={this.addStation} />
                <Button label={msg('user.no')} icon="pi pi-check" onClick={this.association} />
            </div>
        );
    }

    render() {
        let authorized = rolesValidator.validate([USERS]);
        let rAuthorized = authorized.toString().includes(READ);
        let uAuthorized = authorized.toString().includes(UPDATE);
        let isAdminGroup = this.props.adminUser.groupAdmin || rolesValidator.validate([ADMINISTRATION]);
        let user = this.state.user;
        let borneAccessAuthorized = !user.technical && rolesValidator.validate(USERS_BORNEACCESS);
        let groupInfos = this.props.groupsData ? this.props.groupsData : null;

        let addStation = (
            <Dialog visible={this.state.addStation} style={{ width: '30%' }} modal={false} onHide={() => this.setState({ addStation: false })} closable={this.state.closable}
                footer={this.stationFooter()}>
                <h3>{msg('user.station.add')}</h3>
            </Dialog>
        );

        let association = (
            <Dialog visible={this.state.isAssociated} style={{ width: '30%' }} modal={false} onHide={() => this.setState({ isLignSelected: false })} closable={this.state.closable}
                footer={this.renderFooter()}>
                <h3>{msg('user.station.not.implemented')}</h3>
            </Dialog>
        );

        return rAuthorized && (

            <div className="card users commonClass">
                {addStation}
                {association}
                <div className="test p-col-12">
                    <h3 style={{ marginTop: '.5em' }}>{msg('user.identity')}</h3>
                </div>
                <div className="card card-w-title p-col-12">
                    <div className="p-grid form-group p-col-12">
                        {<div className="p-grid p-col-6">
                            <div className="p-grid p-col-2">
                                <div className="p-col-5"><label>{msg('user.fields.firstname')}</label></div>
                                <div className="p-col-1"></div>
                                <div className=""><span className="obligatoire">*</span></div>
                            </div>
                            <div className="p-col-10">
                                <span className="md-inputfield">
                                    <InputText value={user.firstname} onChange={e => this.setState({ user: Object.assign({}, user, { firstname: e.target.value }) })} maxLength="255"/>
                                </span>
                                {this.state.isfirstName === true && <Message severity="error" text={msg('user.input.firstname.required')} key="firstname" />}
                            </div>
                        </div>}
                        {<div className="p-grid p-col-6">

                            <div className="p-grid p-col-3">
                                <div className="p-col-5"> <label>{msg('user.fields.lastname')}</label></div>
                                <div className="p-col-1"></div>
                                <div className=""><span className="obligatoire">*</span></div>
                            </div>
                            <div className="p-col-8">
                                <span className="md-inputfield">
                                    <InputText value={user.lastname} onChange={e => this.setState({ user: Object.assign({}, user, { lastname: e.target.value }) })} maxLength="255"/>
                                </span>
                                {this.state.islastName === true && <Message severity="error" text={msg('user.input.lastname.required')} key="lastname" />}
                            </div>
                        </div>}
                    </div>
                    <div className="p-grid form-group p-col-12">
                        {<div className="p-grid p-col-6">
                            <div className="p-grid p-col-2">
                                <div className="p-col-6"> <label>{msg('user.fields.email')}</label></div>
                                <div className="p-col-1"></div>
                                <div className=""><span className="obligatoire">*</span></div>
                            </div>
                            <div className="p-col-10">
                                <span className="md-inputfield">
                                    <InputText value={user.email} onChange={e => this.setState({ user: Object.assign({}, user, { email: e.target.value }) })} maxLength="255"/>
                                </span>
                                {this.state.isemailErr === true && <Message severity="error" text={msg('user.input.email.required')} key="emailErr" />}
                                {this.state.isemailvalidErr === true && <Message severity="error" text={msg('user.input.email.invalid')} key="emailvalidErr" />}
                            </div>
                        </div>}
                        {<div className="p-grid p-col-6">
                            <div className="p-grid p-col-3">
                                <div className="p-col-8"> <label>{msg('group.fields.description')}</label></div>
                            </div>
                            <div className="p-col-9">
                                <span className="md-inputfield md-inputfield-fill">
                                    <InputText value={user.comment} className="inputClass" id="float-input" placeholder="Description" type="text" size="30" onChange={e => this.setState({ user: Object.assign({}, user, { comment: e.target.value }) })} />
                                </span>
                            </div>
                        </div>}
                    </div>
                </div>
                <div className="test p-col-12">
                    <div className="p-grid">
                        <div className="p-col-4">
                            <h3 style={{ marginTop: '.5em' }}>{msg('user.connection')}</h3>
                        </div>
                        <div className="p-grid p-col-2" style={{ marginTop: '.3em' }}>
                            <div className="p-col-3"><label>{msg('user.fields.actif')} </label></div>
                            <div className="p-col-8"><Checkbox onChange={e => this.setState({ user: Object.assign({}, user, { enabled: e.checked }) })}
                                value={user.enabled} checked={user.enabled}></Checkbox></div>
                        </div>
                        <div className="p-grid p-col-6" style={{ marginTop: '.3em', fontStyle: 'italic', textAlign: 'right' }}>
                            <div className="p-col-12" ><label>{(user.authProvider === null || user.authProvider === "undefined") ? msg('user.authentification.undefined'): msg('user.authentification') + user.authProvider} </label></div>
                        </div>
                    </div>
                </div>
                <div className="card card-w-title p-col-12">
                    <div className="p-grid form-group p-col-12">
                        {<div className="p-grid p-col-6">
                            <div className="p-grid p-col-3">
                                <div className="p-col-12"> <label>{msg('user.fields.identifiant')}</label></div>
                            </div>
                            <div className="p-col-9">
                                <span className="md-inputfield">
                                    <InputText value={user.login} onChange={e => this.setState({ user: Object.assign({}, user, { login: e.target.value }) })} minLength="5" maxLength="255" disabled key="login"/>
                                </span>          
                            </div>
                        </div>}
                        {groupInfos && <div className="p-grid p-col-6">
                            <div className="p-grid p-col-3">
                                <div className="p-col-7"> <label>{msg('user.fields.profil')}</label></div>
                                <div className=""><span className="obligatoire">*</span></div>
                            </div>
                            <div className="p-col-9">
                                <span className="md-inputfield md-inputfield-fill">
                                    <Dropdown value={user.groups[0]} options={this.props.groupsData.groups}
                                        onChange={e => this.handleGroupChange(e)}
                                        optionLabel="name" dataKey="id" placeholder="Selectionnez" style={{ minWidth: '60%' }} 
                                        readOnly={user.technical} disabled={user.technical || this.props.groupsData.groups.length === 1 ? true : false}/>
                                </span>
                                {this.state.isprofil === true && <Message severity="error" text={msg('user.input.group.required')} key="group" />}
                            </div>
                            <div className="p-grid p-col-3">
                                <div className="p-col-12"> <label>{msg('user.fields.group.admin')}</label></div>
                            </div>
                            <div className="p-col-9">
                                <span className="md-inputfield md-inputfield-fill">
                                    <Checkbox onChange={e => this.setState({ user: Object.assign({}, user, { groupAdmin: e.checked }) })} value={user.groupAdmin} checked={user.groupAdmin} />
                                </span>
                            </div>
                        </div>}
                    </div>
                </div>
                {borneAccessAuthorized && 
                <div className="test p-col-12 access-code-gen">
                    <div className="p-grid">
                        <div className="p-col-4 connect-header">
                            <h3>{msg('user.borne.connection')}</h3>
                        </div>
                        <div className="p-grid p-col-2 checkbox-enabled">
                            <div className="p-col-3"><label>{msg('user.fields.borne.access.actif')} </label></div>
                            <div className="p-col-8"><Checkbox onChange={e => this.handleBorneAccessChange(e)}
                                value={this.state.user.borneAccessEnabled} checked={this.state.user.borneAccessEnabled} /></div>
                        </div>
                        {this.state.user.borneAccessEnabled && this.state.user.borneAccessDate &&
                        <div className="p-col-6 reset-date">
                            <span>{ msg('user.borne.access.code.reset.date') + ' ' + toLocaleDateTimeFormat(this.state.user.borneAccessDate)}</span>
                        </div>}
                    </div>
                </div>}
                {borneAccessAuthorized && this.state.user.borneAccessEnabled &&
                <div className="card card-w-title p-col-12">                   
                    <div className="p-grid form-group p-col-12">
                        <div className="p-grid p-col-6 access-code-gen">
                            <div className="p-grid p-col-8">
                                <div className="p-col-3"><label>{msg('user.fields.borne.access.code')}</label></div> 
                                <div className="p-grid p-col-9">
                                    <div className="p-col-4">
                                        <span className="md-inputfield">
                                            <InputText value={!this.state.borneAccessCodeReset ? "* * * * * * * *" : this.state.user.borneAccessCode} minLength="8" maxLength="8" size="8" keyfilter="int" readOnly={true} />
                                        </span>
                                    </div>
                                    <div className="p-col-8"><Button icon="pi-md-sync" onClick={this.generateAccessCode} title={(msg('user.borne.access.code.reset'))}/></div>
                                </div>
                                {this.state.borneAccessCodeReset &&
                                <div className="p-grid p-col-12 qrcode-mail">
                                    <div className="p-col-12">
                                        <Checkbox onChange={e => this.handleBorneAccessEmailSendChange(e)} value={this.state.user.borneAccessEmailSend} checked={this.state.user.borneAccessEmailSend}/>
                                        <label>{msg('user.borne.qr.access.code.send.email')}</label>
                                    </div>
                                    <div className="p-col-12 info"><span>{msg('user.borne.access.code.reset.info')}</span></div>
                                </div>}
                            </div>
                            {this.state.borneAccessCodeReset ?
                            <div className="p-col-4"><QRCode id="qr-borneAccessCodeId" value={this.state.user.borneAccessCode} size={200} level={"H"} /></div> : <div className="p-col-12"/>
                        }
                        </div>                                           
                        <div className="p-grid p-col-6">
                            <div className="p-grid p-col-4">
                                <div className="p-col-6"> <label>{msg('user.fields.borne.access.level')}</label></div>
                                <div className="p-col-1"></div>
                                <div className=""><span className="obligatoire">*</span></div>
                            </div>
                            <div className="p-col-8">
                                <span className="md-inputfield md-inputfield-fill">
                                    <Dropdown value={this.state.user.borneAccessLevel} options={this.state.borneAccessLevels}
                                        onChange={e => this.handleBorneAccessLevelChange(e)}
                                        optionLabel="key" dataKey="value" placeholder="Selectionnez" style={{ minWidth: '60%' }} />
                                </span>
                                 {this.state.isBorneAccessLevel && <Message severity="error" text={msg('user.input.levelAccessBorne.required')} key="borneAccessLevel" />}
                            </div>                            
                        </div>
                    </div>                    
                </div>}
                <div className="p-grid p-col-12">
                    <div className="p-grid p-col-9" />
                    <div className="p-grid p-col-3">
                        <Button label={(msg('user.validate'))} title={(msg('user.validate'))} onClick={this.doOnSave} className="button" disabled={!uAuthorized || !isAdminGroup} />
                        <Link to="/users">
                            <Button  label={(msg('user.reset'))} className="button" />
                        </Link>
                    </div>
                </div>
                <MessageDialog className={'message-dialog warn-message'} visible={this.state.isApiError} title={msg('message.dialog.error.title')}
                    message={msg(this.state.message, { login: this.state.user.login})}
                    onHide={() => this.setState({ isApiError: false, message: '' })} />
            </div>);
    }
}
export default UpdateUser;