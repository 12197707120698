import React, { Component } from 'react'
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import { Checkbox } from 'primereact/checkbox';
import TableData from '../../../utils/TableData';
import { rolesValidator, GROUPS, CREATE, READ, UPDATE, DELETE } from '../../../utils/RolesValidator';
import { userSessionParamsHandler } from '../../../utils/UserSessionParamsHandler';
import msg from '../../../texts/msg';
import MessageDialog from '../../common/MessageDialog';
import './group.css';
import '../../common/css/common.css';
import { progressSpinnerHandler } from '../../../utils/ProgressSpinnerHandler'

export class Groups extends Component {
    constructor(props) {
        super(props);
        this.state = {
            first: 0,
            buttonSelectd: false,
            display: false,
            closable: false,
            isLignSelected: false,
            isApiError: false,            
            sortProprety: { 'key': 'name', 'value': "ASC" },
            userSortProprety: { 'key': 'name', 'value': "ASC" },
            sortDirection: "ASC",
            userSortDirection: "ASC",
            groupFilter: {
                label : "",
                sortProprety: { 'key': 'name', 'value': "ASC" }
            },
            userFilter : {
                label : "",
                sortProprety: { 'key': 'name', 'value': "ASC" }
            }
        }

        this.loadGroups = this.loadGroups.bind(this);
        this.onSelection = this.onSelection.bind(this);
        this.onSort = this.onSort.bind(this);
        this.onSortGroupUsers = this.onSortGroupUsers.bind(this);
        this.loadUserByGroup = this.loadUserByGroup.bind(this);
        this.update = this.update.bind(this);
        this.deleteGroup = this.deleteGroup.bind(this);
        this.login = this.login.bind(this);
        this.name = this.name.bind(this);
        this.description = this.description.bind(this);
        this.roleName = this.roleName.bind(this);
        this.email = this.email.bind(this);
        this.searcheBykeyWord = this.searcheBykeyWord.bind(this);
        this.searcheUserGroupBykeyWord = this.searcheUserGroupBykeyWord.bind(this);
    }

    componentDidMount() {
        this.loadGroups(0, userSessionParamsHandler.getPageSize());
        this.props.setDataInfoBar(null);
    }

    componentDidUpdate(prevProps) {
        let prevError = prevProps.error && prevProps.error.timestamp;
        let lastError = this.props.error && this.props.error.timestamp;
        let error = (lastError && (!prevError || prevError < lastError));
        if (error) {
            progressSpinnerHandler.show(false);
            let isError = this.props.error.message && this.props.error.message.startsWith('error.users.') ? true : false;
            this.setState({ isApiError: isError });
        }
        if(this.props.groups && this.props.groups !== prevProps.groups){
            progressSpinnerHandler.show(false);
        }
        if(this.props.groupById && this.props.groupById !== prevProps.groupById){
            progressSpinnerHandler.show(false);
        }
        let prevDelete = prevProps.groupDeleteById && prevProps.groupDeleteById.lastChange;
        let lastDelete = this.props.groupDeleteById && this.props.groupDeleteById.lastChange;
        let reload = (lastDelete && (!prevDelete || prevDelete < lastDelete));
        if (reload) {
            const page = 0;
            const pageSize = userSessionParamsHandler.getPageSize();
            this.loadGroups(page, pageSize);
        }
    }

    loadGroups(page, pageSize) {       
        progressSpinnerHandler.show(true); 
        let lFilter = Object.assign({}, this.state.groupFilter);
        lFilter.label = this.state.rechecheObject ? this.state.rechecheObject.idSearch : "";
        lFilter.page = page;
        lFilter.pageSize = pageSize;
        this.props.loadGroups(page, pageSize, lFilter);
        this.setState({groupFilter : lFilter });
    }

    loadUserByGroup(page, pageSize) {
        progressSpinnerHandler.show(true); 
        let userFilter = Object.assign({}, this.state.userFilter);
        userFilter.label = this.state.rechecheObjectUser ? this.state.rechecheObjectUser.idSearch : "";
        this.props.loadUserByGroup(this.state.groupId, page, pageSize, userFilter);
        this.setState({ userFilter: userFilter });
    }

    update() {
        if (this.state.groupId && this.state.buttonSelectd) {
            this.props.loadGroup(this.state.groupId);
            this.setState({ buttonSelectd: false });
            this.props.history.push('/updateGroup');
        } else {
            this.setState({ isLignSelected: true });
        }
    }

    isenabled(rowData) {
        if (rowData.enabled) {
            return (<Checkbox checked={rowData.enabled}></Checkbox>);
        } else {
            return (<Checkbox checked={rowData.enabled}></Checkbox>);
        }
    }

    deleteGroup() {
        if (this.state.groupId) {
            this.setState({ showConfirm: true });
        } else {
            this.setState({ isLignSelected: true });
        }
    }

    confirmDeleteGroup() {
        if (this.state.groupId) {
            this.props.dGroup(this.state.groupId);
            this.setState({ buttonSelectd: false });
        } else {
            this.setState({ isLignSelected: true });
        }
        this.setState({ buttonSelectd: false });
    }

    onSelection(event) {
        const page = 0;
        const pageSize = userSessionParamsHandler.getPageSize();
        let userFilter = {
            label : "",
            sortProprety: { 'key': 'name', 'value': "ASC" }
        }
        if (event.value) {
            this.setState({ 
                groupId: event.value.id, 
                groupName: event.value.name,
                buttonSelectd: true,
                userFilter: userFilter
            });
            this.props.loadUserByGroup(event.value.id, page, pageSize, userFilter);
        }
    }

    userName(rowData) {      
        return (
            <div>
                <span >{rowData.name}</span> 
                {rowData.groupAdmin ? (<span className="obligatoire">(ADMIN)</span>)  : '' }
            </div>
        );
    }

    login(rowData) {
        var sous_chaine_one = rowData.login.substr(0, 25);
        if (rowData.login.length > 25) {
            return (
                <span >{sous_chaine_one}.. </span>
            );
        } else {
            return (
                <span >{rowData.login}</span>
            );
        }
    }

    renderFooter() {
        return (
            <div>
                <Button label="Ok" icon="pi pi-check" onClick={() => this.setState({ isLignSelected: false, isApiError: false })} />
            </div>
        );
    }

    name(rowData) {
        let name = rowData.name ? rowData.name : "";
        if (name != null && name.length > 35)
            return <div className="hasTooltip p-ellipsis"> {name} <span> {name}  </span></div>;
        else
            return <div> {name} </div>
    }

    description(rowData) {
        let description = rowData.description ? rowData.description : "";
        if (description != null && description.length > 50)
            return <div className="hasTooltip p-ellipsis"> {description} <span> {description}  </span></div>;
        else
            return <div> {description} </div>
    }

    roleName(rowData) {
        var role = rowData.role.name.substr(0, 50);
        if (rowData.role.name.length > 50) {
            return (			
				<span >{role}.. </span>
            );
        } else {
            return (
                <span >{rowData.role.name}</span>
            );
        }
    }

    email(rowData) {
        var email="";
		email = rowData.email && rowData.email.substr(0, 30);
		if(rowData.email && rowData.email.length > 30 ) {
			return (			
				<span >{email}.. </span>
			);
		} else {
			return (			
				<span >{rowData.email}</span>
			);
		}
		
    }

    searcheBykeyWord(rechecheObject) {
        progressSpinnerHandler.show(true); 
        let groupFilter = Object.assign({}, this.state.groupFilter);
        groupFilter.page = 0;
        groupFilter.pageSize = userSessionParamsHandler.getPageSize();
        groupFilter.label = rechecheObject.idSearch ? rechecheObject.idSearch : "";
        this.props.loadGroups(0, userSessionParamsHandler.getPageSize(), groupFilter);
        this.setState({ rechecheObject: rechecheObject, groupFilter: groupFilter });
    }

    searcheUserGroupBykeyWord(rechecheObjectUser) {
        progressSpinnerHandler.show(true); 
        let userFilter = Object.assign({}, this.state.userFilter);        
        userFilter.label = rechecheObjectUser.idSearch ? rechecheObjectUser.idSearch : "";    
        this.props.loadUserByGroup(this.state.groupId, 0, userSessionParamsHandler.getPageSize(), userFilter);
        this.setState({ rechecheObjectUser: rechecheObjectUser, userFilter: userFilter })
    }

    onSort = (e, page) => {
        progressSpinnerHandler.show(true); 
        let groupFilter = Object.assign({}, this.state.groupFilter);
        let sortDirection = this.state.sortDirection === "ASC" ? "DESC" : "ASC";
        groupFilter.sortProperty = {key: e.sortField, value: sortDirection};
        //TODO remove this line rechecheObject is already saved in the state,
        groupFilter.label = this.state.rechecheObject ? this.state.rechecheObject.idSearch : "";
        this.props.loadGroups(page, userSessionParamsHandler.getPageSize(), groupFilter);
        this.setState({ sortDirection: sortDirection, groupFilter: groupFilter});
    }

    onSortGroupUsers = (e, page) => {
        progressSpinnerHandler.show(true); 
        let userFilter = Object.assign({}, this.state.userFilter);
        let sortDirection = this.state.userSortDirection === "ASC" ? "DESC" : "ASC";
        //TODO remove this line rechecheObjectUser is already saved in the state,     
        userFilter.label = this.state.rechecheObjectUser ? this.state.rechecheObjectUser.idSearch : "";
        userFilter.sortProperty = {key: e.sortField, value: sortDirection};
        this.props.loadUserByGroup(this.state.groupId, page, userSessionParamsHandler.getPageSize(), userFilter);
        this.setState({ userSortDirection: sortDirection, userFilter: userFilter });
    }

    render() {
        let authorized = rolesValidator.validate([GROUPS]);
        let cAuthorized = authorized.toString().includes(CREATE);
        let rAuthorized = authorized.toString().includes(READ);
        let uAuthorized = authorized.toString().includes(UPDATE);
        let dAuthorized = authorized.toString().includes(DELETE);

        let groupsColumns = [
            { isColWithBody: false, field: "id", header: "id", sortable: true, className: "visibilityHidden" },
            { isColWithBody: true, field: "name", header: msg('group.name'), body: this.name, sortable: true, className: "p-col-2" },
            { isColWithBody: true, field: "description", header: msg('group.description'), body: this.description, sortable: false, className: "p-col-3" },
            { isColWithBody: true, field: "role.name", header: msg('group.roles'), body: this.roleName, sortable: true, className: "p-col-3" },
        ];

        let groups = this.props.groups && this.props.groups.groups ? this.props.groups.groups : [];
        let totalPages = this.props.groups && this.props.groups.groups ? this.props.groups.totalPages : 0;
        let groupeOutilsBar = [
            { filename: "Liste des groupes.xlsx", iconSrc: "/assets/ultima/layout/images/cartadis/excel.svg", titleIcone: "Groupes", tooltipTextIcone: msg('group.tooltip.export'), urlExport: "groups/groups-excel" }
        ]

        let ContextMenuContent = [
            {
              label: msg('group.update'),
              command: this.update
            },
            {
              label: msg('group.delete'),
              command: this.deleteGroup
            }];
        let groupsTable = (
            <TableData data={groups} titleTable={msg('group.table.title')} totalPages={totalPages}
                ContextMenuContent={ContextMenuContent}
            	selectionMode={"single"} selection={this.state.rowSelection}
                onSelection={this.onSelection} outilsBar={groupeOutilsBar} filter={this.state.groupFilter} 
                getDataFunction={this.loadGroups} paramnFunctionData={null}
                columns={groupsColumns} 
                searchInTable={false} searchByName={true} nameTxtPlaceholder={msg('group.search.by.name')}
                apply={this.searcheBykeyWord} 
                onSort={this.onSort}/>);

        // 

        let usersColumns = [
            { isColWithBody: false, field: "id", header: "id", sortable: true, className: "visibilityHidden" },
            { isColWithBody: true, field: "name", header: msg('user.name'), body: this.userName, sortable: true, className: "p-col-3" },
            { isColWithBody: true, field: "login", header: msg('user.login'), body: this.login, sortable: true, className: "p-col-3" },
            { isColWithBody: true, field: "email", header: msg('user.email'), body: this.email, sortable: true, className: "p-col-3" },
            { isColWithBody: false, field: "lastConnectionDateString", header: msg('user.lastConnection'), sortable: true, className: "p-col-2" },
            { isColWithBody: true, field: "enabled", header: msg('user.enabled'), body: this.isenabled, sortable: false, className: "p-col-1 activ-colone" },
        ];

        let users = this.state.buttonSelectd && this.props.groupById && this.props.groupById.users ? this.props.groupById.users : [];
        totalPages = this.props.groupById ? this.props.groupById.totalPages : 0;
        let first = this.props.groupById && this.props.groupById.first === true ? this.props.groupById.first : null;
        let titleTableUser = <p>{msg('group.user.title') + " "}  <span style={{ fontWeight: 'bold' }} > {this.state.groupName} </span></p>;

        let datatablebyUserByGroup = (
            <TableData data={users} titleTable={titleTableUser} totalPages={totalPages}
                getDataFunction={this.loadUserByGroup} paramnFunctionData={null} first={first}
                columns={usersColumns} searchInTable={false} searchByName={true} nameTxtPlaceholder={msg('user.search.by.name')}
                apply={this.searcheUserGroupBykeyWord} 
                onSort={this.onSortGroupUsers}
            />);


        return rAuthorized && (
            <div className="card p-grid commonClass">
                <div className="p-col-12">
                    <div className="p-col-4 p-md-4 item">
                        <div className="p-col-12"><h2 className="titleAbonnement">{msg('group.title')}</h2></div>
                    </div>
                    <div className="p-col-8 item buttonPosition">
                        <div className={this.state.buttonSelectd ? "btnSelected item" : "btnNotSelected item "} style={{ marginLeft: '-5px' }}>
                            <Button label={(msg('group.update'))} title={(msg('group.update'))} onClick={this.update} key="update" disabled={!(uAuthorized || rAuthorized)}/>
                        </div>
                        <div className={this.state.buttonSelectd ? "btnSelected item " : "btnNotSelected item"} >
                            <Button label={(msg('group.delete'))} title={(msg('group.delete'))} onClick={() => { this.deleteGroup() }} key="delete" disabled={!dAuthorized}/>
                        </div>
                        <div className="item btnSelected">
                            <Link to="/CreateGroup">
                                <Button label={(msg('group.create.group'))} title={(msg('group.create.group'))} className="button" key="create" disabled={!cAuthorized}/>
                            </Link>
                        </div>

                    </div>
                    {groupsTable}
                </div>
                <div className="p-col-12">
                    <div className="card card-w-title ContnairPanelFilter">

                    </div>
                    <div className="card card-w-title activity ">
                        {datatablebyUserByGroup}
                    </div>
                    <MessageDialog className={'message-dialog error-message'} visible={this.state.isApiError} title={msg('message.dialog.error.title')}
                        message={this.state.isApiError && msg(this.props.error.message)}
                        onHide={() => this.setState({ isLignSelected: false, isApiError: false })} />
                    <MessageDialog confirm={true} className={'message-dialog confirm-message'} visible={this.state.showConfirm} title={msg('message.dialog.confirm.title')}
                        message={this.state.showConfirm && msg('group.delete.confirm.message', { groupName: this.state.groupName })} key="confirm-delete"
                        onConfirm={() => { this.setState({ showConfirm: false }); this.confirmDeleteGroup() }} onCancel={() => this.setState({ showConfirm: false })} />
                </div>
            </div >);
    }
}